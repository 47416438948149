import { useMemo } from "react";
import { createPortal } from "react-dom";
import BasicButton from "../../components/Button/BasicButton";
import { Heroicons } from "../../components/Heroicon/Heroicon";
import { useToggle } from "../../hooks/useToggle";
import useInformationen from "../api/useInformationen";
import DateFormatter from "../helper/DateFormatter";
import Information from "../types/Information";

type DailyInformationProps = {
  date: Date;
  className?: string;
  onClick?: () => any;
};

const DailyInformation = (props: DailyInformationProps) => {
  const { informationen } = useInformationen(props.date);

  const isoLike = useMemo(() => DateFormatter.toIsoLike(props.date), [props.date]);
  const matchingInformationen = useMemo(
    () => informationen.filter((c) => c.bezugsDatum.isoLike === isoLike),
    [informationen, isoLike]
  );

  const amount = matchingInformationen.length;

  return (
    <>
      <BasicButton
        identifier="showDailyInformation"
        className={`relative isolate inline-flex px-2 min-h-0 h-full justify-center items-center content-start flex-shrink-0 ${
          amount ? "bg-red-500 text-white" : "bg-transparent text-primary-600"
        } ${props.className ?? ""}`}
        to={`${Information.Routes.Root}date/${isoLike}`}
        isLink={props.onClick === undefined}
        onClick={props.onClick}
      >
        <span className="relative inline-flex w-5 aspect-square">
          {amount === 0 ? (
            <Heroicons.Outline.InformationCircleIcon className="w-5 aspect-square" />
          ) : (
            <Heroicons.Solid.InformationCircleIcon className="w-5 aspect-square" />
          )}
        </span>
      </BasicButton>
    </>
  );
};
export default DailyInformation;
