import * as React from "react";
import { AppColors } from "../models/General";
import { SeperatorTable } from "./SeperatorTable";
import { Table } from "../models/Table";
import { AppState } from "../App";
import { Reservation } from "../models/Reservation";
import { ClockIcon, UsersIcon } from "@heroicons/react/solid";

type TableSelectProps = {
  appColors: AppColors;
  data: Table[];
  onTableSelection?: (table: Table) => void;
  onReservationSelection?: (res: Reservation) => void;
  currentTables: Table[] | undefined;
  selectedTables: Table[];
  reservations?: AppState["appReservations"];
  selectedReservation?: Reservation;
  loadingReservations?: Reservation[];
  resNameFullLenght?: boolean;
  canSelectReservation?: boolean;
  filterReservationByStates?: Reservation["state"][];
};

export const SelectableTable = React.memo(function SelectableTable(props: {
  appColors: AppColors;
  table: Table | SelectTable;
  isGrayed?: boolean;
  isHighlighted?: boolean;
  unfixedSize?: boolean;
  showRes?: boolean;
  onReservationClick?: (res: Reservation) => void;
  onClick?: (tab: Table) => void;
  selectedReservation?: Reservation;
  loadingReservations?: Reservation[];
  resNameFullLenght?: boolean;
  canSelectReservation?: boolean;
  filterReservationByStates?: Reservation["state"][];
}) {
  const _handleReservationClick = React.useCallback(
    (res: Reservation) => (props.showRes && props.onReservationClick ? props.onReservationClick(res) : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onReservationClick, props.showRes]
  );

  const _handleTableClick = React.useCallback(
    () => (props.onClick ? props.onClick(props.table) : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onClick, props.table]
  );

  const sortedOverview = React.useMemo(
    () =>
      props.table && (props.table as SelectTable).timeOverview
        ? Object.keys((props.table as SelectTable).timeOverview as timeOverview).sort((a, b) => {
            const aAsNumber = +a.replace(/:/g, "");
            const bAsNumber = +b.replace(/:/g, "");
            return aAsNumber - bAsNumber;
          })
        : null,
    [props.table]
  );

  const mappedReservations = React.useMemo(() => {
    if (sortedOverview) {
      const reservations = sortedOverview.flatMap(
        (key) => ((props.table as SelectTable).timeOverview as timeOverview)[key]
      );

      const filteredForState = props.filterReservationByStates
        ? reservations.filter((c) => props.filterReservationByStates!.every((f) => +f !== +c.state))
        : reservations;

      const sortedForState = filteredForState
        .sort((a, b) => +new Date(b.dateOfArrival) - +new Date(a.dateOfArrival))
        .sort((a, b) => {
          const aState = +a.state;
          const bState = +b.state;

          if (aState >= 5 && bState < 5) {
            return 1;
          } else if (aState >= 5 && bState >= 5) {
            return 0;
          } else if (aState < 5 && bState >= 5) {
            return -1;
          }
          return -1;
        });

      return sortedForState.map((reservation) => {
        const { guestAmount, name, id, state, dateOfArrival } = reservation;
        const _handleClick = (ev: React.MouseEvent) => {
          if (props.canSelectReservation) {
            ev.stopPropagation();
            _handleReservationClick(reservation);
          }
        };

        const date = new Date(dateOfArrival.slice(0, 10));
        const correctedTime = dateOfArrival.slice(11, 16);

        const [hours, minutes] = correctedTime.split(":").map((c) => +c);
        date.setHours(hours);
        date.setMinutes(minutes);

        const isStriked = state === 6 || state === 5;
        const timeDifference = (+date - +new Date()) / 36e5;

        const isSoon = state < 3 && !isStriked && timeDifference <= 1;
        const isSelected = props.selectedReservation?.id === id || false;
        const isLoading =
          props.selectedReservation?.id && props.loadingReservations
            ? props.loadingReservations.some((res) => res.id === props.selectedReservation?.id)
            : false;

        const timeString = new Date(dateOfArrival).toLocaleTimeString("de-de", {
          hour: "2-digit",
          minute: "2-digit",
        });

        return (
          <div
            key={id}
            className={`relative flex w-full flex-col justify-between items-start text-white px-px pt-px h-5 flex-grow-0 flex-shrink-0 mt-px ${
              isStriked ? "opacity-80" : "opacity-100"
            }`}
            onClick={_handleClick}
          >
            <div
              className={`absolute inset-0 pointer-events-none flex justify-center items-center w-full h-full px-1 rounded-full transition-opacity duration-200 ease-in-out ${
                isStriked ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className={`py-px bg-primary-600 opacity-70 w-full rounded-full mt-px`}></div>
            </div>
            <span
              className={`flex text-xs font-semibold px-1 bg-white w-full rounded select-none justify-start items-center content-start ${
                isSelected
                  ? `bg-primary-100 text-primary-600 shadow-outline`
                  : isSoon
                  ? `bg-primary-600 text-primary-100`
                  : `text-primary-500`
              } ${isLoading ? "animate-pulse bg-opacity-90" : "bg-opacity-100"}`}
            >
              <span className="flex h-5 items-center pr-1 tabular-nums">
                <ClockIcon className="flex h-4 w-3 pt-px px-px mx-px" />
                {timeString}
                <UsersIcon className="flex h-4 w-3 pt-px px-px mx-px ml-1" />
                {guestAmount.adultAmount}
                {!!guestAmount.childAmount ? "+" + guestAmount.childAmount : ""}
              </span>
              <span
                className={`text-xs px-px truncate text-left font-base ${props.resNameFullLenght ? "w-32" : "w-16"} ${
                  isSoon ? "font-semibold" : "font-light"
                }`}
              >
                {name}
              </span>
            </span>
          </div>
        );
      });
    } else {
      return null;
    }
  }, [
    _handleReservationClick,
    props.canSelectReservation,
    props.filterReservationByStates,
    props.loadingReservations,
    props.resNameFullLenght,
    props.selectedReservation?.id,
    props.table,
    sortedOverview,
  ]);

  return (
    <div
      className={`relative flex flex-1 ${
        props.unfixedSize ? "" : "w-24 h-24"
      } justify-center items-center p-1 cursor-pointer rounded-lg`}
    >
      <div
        className={`flex flex-1 flex-row flex-wrap h-full justify-start items-start content-start shadow-md p-1 ring-2 ${
          props.isGrayed
            ? `bg-gray-500 ring-transparent`
            : props.isHighlighted
            ? `bg-primary-300 ring-primary-700`
            : `bg-primary-400 ring-transparent`
        } rounded`}
        onClick={_handleTableClick}
      >
        {props.showRes ? (
          <div
            className={`flex flex-1 flex-col h-full w-full overflow-auto bg-primary-300 rounded shadow-inner justify-start items-start content-start`}
          >
            {props.table && (props.table as SelectTable).timeOverview !== undefined ? mappedReservations : null}
          </div>
        ) : null}

        {/* <span className={`text-white text-lg`}>{props.table.name}</span> */}
      </div>
      <div
        className={`absolute flex justify-center items-center bottom-0 right-0 h-6 w-9 text-primary-700 bg-white shadow rounded`}
      >
        <span className={`text-sm font-semibold`}>{props.table.name}</span>
      </div>
    </div>
  );
});

type overview = {
  [key: string]: timeOverview;
};

type timeOverview = { [key: string]: Reservation[] };

type SelectTable = Table & { timeOverview?: timeOverview };

export const TableSelect = ({ onTableSelection, ...props }: TableSelectProps) => {
  const [currentTables, setCurrentTables] = React.useState<Table[] | SelectTable[] | undefined>(props.data);
  const [selectedTable, setSelectedTable] = React.useState<Table | SelectTable | null>(null);

  const { data, reservations } = props;

  React.useEffect(() => {
    if (onTableSelection && selectedTable) {
      onTableSelection(selectedTable);
      setSelectedTable(null);
    }
  }, [selectedTable, onTableSelection]);

  React.useEffect(() => {
    setCurrentTables((curr) => {
      if (data !== undefined) {
        let tables = data;
        if (reservations) {
          const overview = _mapReservationsToOverview(reservations);
          tables = _mapTablesToSelectTables(data, overview);
        }
        return tables;
      } else return curr;
    });
  }, [data, reservations]);

  const _mapTablesToGroups = (tablesToMap: Table[]) => {
    const tables = tablesToMap
      .sort((a, b) => +a.name - +b.name)
      .reduce((a, b) => {
        let areaName = b.area.name;
        let groupName = b.group;
        if (groupName === null || groupName === "") {
          groupName = (b.name + "").slice(0, -1);
        }
        let currentArea = (a as any)[areaName];
        let currentGroup = [];
        if ((a as any)[areaName] && (a as any)[areaName][groupName]) {
          currentGroup = [...(a as any)[areaName][groupName], b];
        } else {
          currentGroup = [b];
        }

        return {
          ...a,
          [areaName]: currentArea ? { ...currentArea, [groupName]: currentGroup } : { [groupName]: currentGroup },
        };
      }, {});
    return tables;
  };

  const _mapReservationsToOverview = (reservationsToMap: Reservation[]) => {
    const overview: overview = reservationsToMap.reduce((a: overview, b) => {
      const tempRes = { ...b };
      if ((tempRes as any).table) {
        tempRes.tables = [(tempRes as any).table];
      }
      if (tempRes.tables) {
        const time = new Date(tempRes.dateOfArrival)
          .toLocaleDateString("de-de", { minute: "2-digit", hour: "2-digit", second: "2-digit" })
          .slice(-8, -3);
        tempRes.tables.map((tab) => {
          a[tab.name] = {
            ...(a[tab.name] || {}),
            [time]: [...((a[tab.name] || {})[time] || []), tempRes],
          };
          return tab;
        });
      }
      return a;
    }, {});
    return overview;
  };

  const _mapTablesToSelectTables = (tablesToMap: Table[], overview: overview) => {
    const selectTables: SelectTable[] = tablesToMap.reduce((a: SelectTable[], b) => {
      if (overview[b.name]) {
        const tempTable = { ...b, timeOverview: overview[b.name] };
        return [...a, tempTable];
      } else {
        return [...a, { ...b, timeOverview: undefined }];
      }
    }, []);
    return selectTables;
  };

  const _renderElement = React.useCallback(
    (data: Table | SelectTable) => {
      return (
        <SelectableTable
          appColors={props.appColors}
          isGrayed={
            props.currentTables && props.currentTables.some((tab) => tab.id === data.id || tab.name === data.name)
          }
          isHighlighted={props.selectedTables.some((tab) => data.id === tab.id)}
          table={data}
          onClick={() => setSelectedTable(data)}
          onReservationClick={
            props.selectedReservation
              ? () => setSelectedTable(data)
              : props.onReservationSelection
              ? (res) => (props.onReservationSelection as any)(res)
              : undefined
          }
          selectedReservation={props.selectedReservation}
          showRes
          resNameFullLenght={props.resNameFullLenght}
          canSelectReservation={props.canSelectReservation}
          filterReservationByStates={props.filterReservationByStates}
        ></SelectableTable>
      );
    },
    [
      props.appColors,
      props.canSelectReservation,
      props.currentTables,
      props.filterReservationByStates,
      props.onReservationSelection,
      props.resNameFullLenght,
      props.selectedReservation,
      props.selectedTables,
    ]
  );

  const mappedGroups = React.useMemo(() => _mapTablesToGroups(currentTables || []), [currentTables]);

  return (
    <SeperatorTable
      appColors={props.appColors}
      numberOfColumns={2}
      elementRender={_renderElement}
      data={mappedGroups}
    />
  );
};
