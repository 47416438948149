import { ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import * as uuid from "uuid";
import { useEventListener } from "../../hooks/useEventlistener";
import { useTimeout } from "../../hooks/useTimeout";
import { useToggle } from "../../hooks/useToggle";

const MODAL_BASE_ID = "MODAL_BASE";

const ModalEvents = (id: string) => ({
  showModal: `SHOW_MODAL_${id}`,
  hideModal: `HIDE_MODAL_${id}`,
});

type ModalProps = {
  startsVisible?: boolean;
  events: ReturnType<typeof ModalEvents>;
  element: HTMLElement | null;
  children?: ReactNode | ReactNode[];
};

const ModalDialouge: React.FC<ModalProps> = (props) => {
  const { isTimeoutactive, startTimeout, stopTimeout } = useTimeout(100);

  const [isVisible, , reveal, hide] = useToggle(props.startsVisible);

  useLayoutEffect(() => {
    startTimeout();
  }, [startTimeout, stopTimeout]);

  const onHide = useCallback(() => {
    const ev = new Event(props.events.hideModal);
    window.dispatchEvent(ev);
  }, [props.events.hideModal]);

  useEventListener(props.events.hideModal, hide);
  useEventListener(props.events.showModal, reveal);

  return props.element
    ? createPortal(
        <div
          className={`fixed top-0 left-0 inline-flex w-full h-full overflow-hidden justify-center items-end z-50 pointer-events-none`}
        >
          <div
            className={`absolute top-0 left-0 w-full h-full bg-gray-700 bg-opacity-20 transition-opacity duration-300 ease-in-out ${
              isVisible ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
            }`}
            onClick={onHide}
          ></div>
          <div
            className={`relative inline-flex flex-col min-w-0 min-h-0 transform transition-all duration-200 ease-in-out md:container md:mx-auto w-full md:w-2/3 lg:w-1/2 xl:w-1/3 ${
              isVisible && !isTimeoutactive
                ? "translate-y-0 opacity-100 pointer-events-auto"
                : "translate-y-10 opacity-0 pointer-events-none"
            } bg-gray-100 bg-opacity-90 backdrop-blur-4 dark:bg-gray-800 shadow-lg rounded-t-lg w-full min-w-0 min-h-0`}
          >
            {props.children}
          </div>
        </div>,
        props.element
      )
    : null;
};

const ModalBase = () => (
  <div
    className="absolute top-0 left-0 h-full w-full flex-grow-0 overflow-visible pointer-events-none"
    id={MODAL_BASE_ID}
  ></div>
);

const useModal = (startsVisible: boolean) => {
  const modalId = useMemo(() => uuid.v4(), []);

  const modalEvents = useRef(ModalEvents(modalId));
  const [element, setElement] = useState<HTMLElement | null>(document.getElementById(MODAL_BASE_ID));

  const [isVisible, , on, off] = useToggle(startsVisible);

  const hide = useCallback(() => {
    const { hideModal } = modalEvents.current;
    const ev = new Event(hideModal);
    window.dispatchEvent(ev);
  }, []);

  const reveal = useCallback(() => {
    const { showModal } = modalEvents.current;
    const ev = new Event(showModal);
    window.dispatchEvent(ev);
  }, []);

  useEventListener(modalEvents.current.hideModal, off);
  useEventListener(modalEvents.current.showModal, on);

  const Container = useCallback(
    (props: { children: JSX.Element | JSX.Element[] }) => (
      <ModalDialouge key={modalId} events={modalEvents.current} startsVisible={startsVisible} element={element}>
        {props.children}
      </ModalDialouge>
    ),
    [element, modalId, startsVisible]
  );

  useLayoutEffect(() => {
    setElement(document.getElementById(MODAL_BASE_ID));
  }, []);

  return { Container, reveal, hide, isVisible };
};

const ModalHandler = { ModalDialouge, ModalBase, useModal };
export default ModalHandler;
