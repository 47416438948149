import React, { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useIndex } from "../hooks/useIndex";
import { TailwindBackgroundColors } from "../models/General";

const colors: TailwindBackgroundColors[] = [
  "yellow",
  "amber",
  "orange",
  "red",
  "rose",
  "pink",
  "purple",
  "violet",
  "indigo",
  "darkBlue",
  "blue",
  "teal",
  "cyan",
  "lime",
  "green",
  "emerald",
  "fuchsia",
];

const intensities: (50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900)[] = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
];
const baseIntensities: typeof intensities = [100, 500, 200, 300, 900, 800];

export const LoadingBar = ({
  onColorChange,
  ...props
}: {
  color?: TailwindBackgroundColors;
  onColorChange?: (color: TailwindBackgroundColors) => any;
  initialColor?: TailwindBackgroundColors;
}) => {
  const initialColor = useRef(props.initialColor);
  const possibleColors = useRef<TailwindBackgroundColors[]>(props.color ? [props.color] : colors);

  const initialColorIndex = useMemo(() => {
    if (possibleColors.current?.some((c) => c === initialColor.current)) {
      return possibleColors.current?.findIndex((c) => c === initialColor.current);
    } else return 0;
  }, []);
  const { index, nextIndex } = useIndex(possibleColors.current.length, initialColorIndex, true, true);

  const _loadingBars = useMemo(
    () =>
      baseIntensities.map((intensity, i) => (
        <ColorBar key={i} color={possibleColors.current[index]} startIntensity={intensity} />
      )),
    [index]
  );

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      nextIndex();
    }, intensities.length * 5 * 120);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [nextIndex]);

  useEffect(() => {
    if (onColorChange) onColorChange(possibleColors.current[index]);
  }, [index, onColorChange]);

  return (
    <div className="flex flex-grow-0 w-full rounded-full opacity-100 z-20" style={twoPxHeight}>
      {_loadingBars}
    </div>
  );
};

const ColorBar = ({
  color,
  startIntensity,
}: {
  color: TailwindBackgroundColors;
  startIntensity: typeof intensities[0];
}) => {
  const startIndex = useRef(intensities.findIndex((c) => c === startIntensity));
  const { index, nextIndex } = useIndex(intensities.length, startIndex.current, true, true);
  const currentIntensity = useMemo(() => intensities[index], [index]);

  useLayoutEffect(() => {
    let interval = setInterval(() => {
      nextIndex();
    }, 120);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [nextIndex]);

  return (
    <div className={`flex-1 h-full bg-${color}-${currentIntensity} transition-colors duration-200 ease-linear`}></div>
  );
};

const twoPxHeight = {
  height: "2px",
};
