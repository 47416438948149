import { TrashIcon } from "@heroicons/react/outline";
import * as React from "react";
import { AppFunctions, AppState } from "../App";
import { useSettings } from "../clientApi/SettingsProvider";
import DebugContext from "../hooks/RemoteDebug/DebugContext";
import { ReservierungViewModelTypes } from "./Reservierung/ReservierungViewTypes";

type TestProps = {} & AppState & AppFunctions;

export const Test = (props: ReservierungViewModelTypes.Props) => {
  return (
    <div className="inline-flex flex-col w-full h-full min-h-0 justify-center items-start content-center ml-12 gap-2"></div>
  );
};
