import { useCallback, useLayoutEffect, useState } from "react";

const useStylesheet = () => {
  const [styleSheet, setStyleSheet] = useState<CSSStyleSheet | null>(null);
  const createStyleSheet = useCallback(() => {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    return style.sheet;
  }, []);

  const clearStyleSheet = useCallback(() => {
    if (styleSheet) {
      while (styleSheet.rules.length) {
        styleSheet.removeRule(0);
      }
    }
  }, [styleSheet]);

  useLayoutEffect(() => {
    setStyleSheet(createStyleSheet());
  }, [createStyleSheet]);

  return { styleSheet, clearStyleSheet };
};
export default useStylesheet;
