import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToggle } from "../../hooks/useToggle";
import { useToasterHandler } from "../ToasterHandler/useToasterHandler";
import Tastatur from "./Tastatur";

const useTastatur = (text?: string | null) => {
  const [isVisible, , show, hide] = useToggle(false);

  const [art, setArt] = useState<Tastatur.Art>(Tastatur.Art.Text);

  const isInitiallyShifted = useRef<boolean>((text ?? "").length - 1 < 0);
  const [isShifted, setIsShifted] = useState<boolean>(isInitiallyShifted.current);

  const [isShowingSpecialKeys, setIsShowingSpecialKeys] = useState(false);

  const textRef = useRef<string | null>(text ?? null);
  const [internalText, setInternalText] = useState<string | null>(text ?? null);
  const [focusedInputId, setFocusedInputId] = useState<string | null>(null);
  const focusedInput = useMemo(
    () => (focusedInputId ? (document.querySelector(`[id="${focusedInputId}"]`) as any as HTMLInputElement) : null),
    [focusedInputId]
  );

  const { addMessage } = useToasterHandler();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (focusedInput) {
      if (focusedInput && "value" in focusedInput) {
        textRef.current = (focusedInput as any).value;
        setInternalText(textRef.current);
        show();
        const scrollingElement = document.getElementById("TastaturScrollContainer");
        if (scrollingElement) {
          const height = scrollingElement.clientHeight;
          const input: HTMLInputElement = focusedInput as any as HTMLInputElement;
          const parentOffsetTop = input.parentElement?.offsetTop;

          const quarterHeight = height / 8;
          const halfHeight = height / 2;
          const halfInputHeight = input.clientHeight;

          if (parentOffsetTop !== undefined && halfInputHeight <= halfHeight) {
            const diff = quarterHeight - parentOffsetTop - halfInputHeight;
            console.log({ quarterHeight, halfInputHeight, diff, parentOffsetTop });
            timeout = setTimeout(() => {
              // scrollingElement.scrollTo({ top: diff, left: scrollingElement.scrollLeft });
              scrollingElement.style.transform = `translateY(${diff}px)`;
            }, 50);
          }
        }

        return () => {
          if (timeout) clearTimeout(timeout);
          textRef.current = null;
          hide();
        };
      }
    }
  }, [addMessage, focusedInput, focusedInputId, hide, show]);

  const [mode, setMode] = useState<Tastatur.Mode.Text | Tastatur.Mode.Signs>(Tastatur.Mode.Text);
  const switchMode = useCallback(() => {
    setMode((c) => (c === Tastatur.Mode.Signs ? Tastatur.Mode.Text : Tastatur.Mode.Signs));
  }, []);

  const toggleSpecialKeys = useCallback(() => {
    setIsShowingSpecialKeys((c) => !c);
  }, []);

  const toggleShift = useCallback(() => {
    setIsShifted((c) => !c);
  }, []);

  const onKeyPress = useCallback(
    (key: Tastatur.Key | Tastatur.Key.Special) => {
      let keyToSend = key;

      if (key === Tastatur.Key.Special.Shift) {
        return toggleShift();
      } else if (key === Tastatur.Key.Special.Backspace) {
        textRef.current = (textRef.current ?? "").slice(0, -1);
        return setInternalText(textRef.current);
      } else if (key === Tastatur.Key.Special.Space) {
        textRef.current = (textRef.current ?? "") + " ";
        return setInternalText(textRef.current);
      } else if (key === Tastatur.Key.Special.Switch) {
        return switchMode();
      } else if (key === Tastatur.Key.Special.Enter) {
        const selection = window.getSelection();
        if (selection?.anchorNode) {
          const event = new KeyboardEvent("keypress", { key: "Enter" });
          if (selection.anchorNode.nodeName !== "INPUT") {
            const input = Array.from(selection.anchorNode.childNodes).find((c) => c.nodeName === "INPUT");
            return input?.dispatchEvent(event);
          } else return selection.anchorNode.dispatchEvent(event);
        }
      } else if (key === Tastatur.Key.Special.SpecialChars) {
        return toggleSpecialKeys();
      } else if (typeof key === "string" && isShifted) {
        keyToSend = keyToSend.toString().toLocaleUpperCase();
      }

      textRef.current = (textRef.current ?? "") + keyToSend;
      setInternalText(textRef.current);
    },
    [isShifted, switchMode, toggleShift, toggleSpecialKeys]
  );

  useEffect(() => {
    if (isShowingSpecialKeys && mode === Tastatur.Mode.Signs) {
      return () => {
        setIsShowingSpecialKeys(false);
      };
    }
  }, [isShowingSpecialKeys, mode]);

  useEffect(() => {
    if ((internalText ?? "").length <= 0) {
      setIsShifted(true);
    } else {
      setIsShifted(false);
    }
  }, [internalText]);

  useEffect(() => {
    if (isVisible) {
      return () => {
        const scrollingElement = document.getElementById("TastaturScrollContainer");
        if (scrollingElement) {
          scrollingElement.style.transform = "initial";
        }
      };
    }
  }, [isVisible]);

  return {
    internalText,
    mode,
    isShifted,
    focusedInputId,
    setFocusedInputId,
    isVisible,
    show,
    hide,
    toggleShift,
    switchMode,
    onKeyPress,
    art,
    setArt,
    setInternalText,
    isShowingSpecialKeys,
    setIsShowingSpecialKeys,
  };
};
export default useTastatur;
