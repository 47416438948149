import { ReactNode, useDeferredValue, useMemo } from "react";
import useInformationen from "../api/useInformationen";
import DateFormatter from "../helper/DateFormatter";
import Information from "../types/Information";

type LimitHintProps = {
  date: Date | string;
  informationen?: Information<Information.Typ>[];
  className?: string;
  children?: ReactNode | ReactNode[];
};

const LimitHint = (props: LimitHintProps) => {
  const date = useMemo(() => DateFormatter.toDatum(props.date), [props.date]);
  const shouldLoad = useDeferredValue(props.informationen !== undefined);
  const { informationen } = useInformationen(date.obj, shouldLoad, shouldLoad);

  const matchingInformationen: Information<Information.Typ.Limit>[] = useMemo(
    () =>
      (props.informationen ?? informationen).filter(
        (c) => c.typ == Information.Typ.Limit && c.bezugsDatum.isoLike === date.isoLike
      ) as Information<Information.Typ.Limit>[],
    [date.isoLike, informationen, props.informationen]
  );

  return (
    <>
      {matchingInformationen.length ? props.children : null}
      {matchingInformationen.map(
        ({ data, id }) => (
          <span key={id} className={props.className}>
            {data.limit}
          </span>
        ),
        []
      )}
    </>
  );
};
export default LimitHint;
