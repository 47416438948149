import { Component, ReactNode } from "react";
import { Heroicons } from "./Heroicon/Heroicon";

class ErrorBoundary extends Component<
  { children?: ReactNode | ReactNode[] },
  {
    hasError: boolean;
    error: Error | null;
    errorInfo: any | null;
    canShare: boolean;
    shareableFiles: File[];
    hadShareError: boolean;
    shareErrorText?: string;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      canShare: false,
      shareableFiles: [],
      hadShareError: false,
      shareErrorText: undefined,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    const shareable = this._createShareableError(error, errorInfo);
    console.log({ shareable });
    const downloadableFile = this._createDownloadableFile(shareable);
    this.setState({
      error: error ?? null,
      errorInfo: errorInfo ?? null,
      canShare: Boolean((error || errorInfo) && this._isShareable(downloadableFile)),
      shareableFiles: downloadableFile ?? [],
    });
  }

  _isShareable = (downloadable: any) => {
    if (navigator && "canShare" in navigator && typeof (navigator as any).canShare === "function") {
      return (navigator as any).canShare({ files: downloadable });
    } else return false;
  };

  _createShareableError = (error?: any, errorInfo?: any) => {
    try {
      return JSON.stringify(
        { ...(error ? { name: error.name, message: error.message, stack: error.stack } : {}), ...(errorInfo || {}) },
        null,
        2
      );
    } catch (error) {
      return null;
    }
  };

  _shareError = async () => {
    try {
      if (navigator && this.state.canShare) {
        if ("share" in navigator && typeof navigator.share === "function") {
          await navigator.share({ files: this.state.shareableFiles });
        }
      } else throw new Error("Does not meet all requirements - unable to share");
    } catch (error) {
      this.setState({
        hadShareError: true,
        shareErrorText: (error as any).message,
      });
      console.error(error);
    }
  };

  _createDownloadableFile = (shareable: any) => {
    try {
      if (shareable) {
        const title = `${new Date().toISOString()}_Error_Stacktrace.json`;
        const file = new File([shareable], title, { type: "text/json", lastModified: Date.now() });
        return [file];
      } else return null;
    } catch (error) {
      return null;
    }
  };

  _downloadFile = () => {
    try {
      if (this.state.shareableFiles) {
        const anchor = document.createElement("a");
        const [file] = this.state.shareableFiles;
        if (!file) throw new Error("Could not get file");
        const toBlobUrl = URL?.createObjectURL ?? window.URL?.createObjectURL;
        if (toBlobUrl !== undefined) {
          const blobUrl = toBlobUrl(file);
          anchor.download = file.name;
          anchor.href = blobUrl;
          anchor.dataset.downloadurl = [file.type, file.name, blobUrl].join(":");

          anchor.click();
          anchor.remove();
        } else throw new Error("Could not generate Blob Url");
      } else throw new Error("Nothing to Download");
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col w-full max-w-screen-sm mx-auto px-4 py-6 gap-2 pb-32 overflow-y-auto overflow-x-hidden">
          <div className="inline-flex flex-col p-6 bg-gray-50 border border-gray-200 mt-6 gap-2">
            <h2 className="inline-flex text-xl text-red-500 font-semibold tracking-wide gap-2 justify-start items-center content-start">
              <Heroicons.Solid.ShieldExclamationIcon className="w-6 h-6" /> <span>Ein Fehler ist aufgetreten</span>
            </h2>
            <hr />
            <h2 className="text-sm text-red-500 font-bold tracking-wider">{this.state.error?.name || "???"}</h2>
            <h2 className="text-sm text-gray-700 whitespace-pre-wrap">{this.state.error?.message || "???"}</h2>
            <details className="inline-flex flex-col gap-2 text-gray-700 text-sm mt-6">
              <summary className="underline underline-offset-1">Details für Nerds</summary>
              <div className="inline-flex flex-col py-3 text-xs whitespace-pre-wrap">
                <h2 className="font-semibold tracking-wide mb-2">Stack</h2>
                <code className="leading-tight">{this.state.error?.stack}</code>

                <h2 className="font-semibold tracking-wide mt-8 mb-1">Component Stack</h2>
                <code className="leading-tight peer">
                  {this.state.errorInfo && "componentStack" in this.state.errorInfo
                    ? this.state.errorInfo.componentStack
                    : ""}
                </code>

                <h2 className="font-semibold tracking-wide mt-8 mb-1 peer-empty:inline-flex hidden">Error Info</h2>
                <code className="leading-tight peer-empty:inline-flex hidden">
                  {this.state.errorInfo && "componentStack" in this.state.errorInfo
                    ? JSON.stringify(this.state.errorInfo, null, 2)
                    : "--"}
                </code>
              </div>
            </details>
          </div>
          <a
            href={window.location.href}
            className="inline-flex min-h-0 font-semibold tracking-wide bg-primary-500 px-3 py-3 text-white gap-3 items-center text-base mt-6 flex-shrink-0"
          >
            <Heroicons.Outline.RefreshIcon className="w-5 h-5 flex-shrink-0 stroke-2" />
            <span className="text-sm">Seite neuladen</span>
          </a>
          <a
            href="/"
            className="inline-flex min-h-0 font-semibold tracking-wide bg-primary-500 px-3 py-3 text-white gap-3 items-center text-base flex-shrink-0"
          >
            <Heroicons.Outline.HomeIcon className="w-5 h-5 flex-shrink-0 stroke-2" />
            <span className="text-sm">Zurück zur Startseite</span>
          </a>
          {this.state.shareableFiles.length ? (
            <span className="text-xs text-gray-600 mt-4 flex-shrink-0">Ein Fehlerlog wurde vorbereitet:</span>
          ) : null}

          <button
            className="inline-flex min-h-0 font-semibold tracking-wide bg-primary-500 px-3 py-3 text-white gap-3 items-center disabled:bg-gray-300 disabled:text-gray-600 disabled:pointer-events-none flex-shrink-0"
            type="button"
            onClick={this._shareError}
            disabled={!this.state.canShare || this.state.hadShareError}
          >
            {this.state.hadShareError ? (
              <>
                <Heroicons.Outline.ExclamationIcon className="w-5 h-5 flex-shrink-0 stroke-2" />
                <span className="text-sm">Fehler: {this.state.shareErrorText}</span>
              </>
            ) : (
              <>
                <Heroicons.Outline.ShareIcon className="w-5 h-5 flex-shrink-0 stroke-2" />
                <span className="text-sm">Fehlerdetails teilen</span>
              </>
            )}
          </button>

          {this.state.shareableFiles ? (
            <button
              type="button"
              className="inline-flex min-h-0 font-semibold tracking-wide bg-primary-500 px-3 py-3 text-white gap-3 items-center disabled:bg-gray-500 disabled:text-gray-300 disabled:pointer-events-none flex-shrink-0"
              onClick={this._downloadFile}
              disabled={Boolean(!this.state.shareableFiles.length)}
            >
              <Heroicons.Outline.DownloadIcon className="w-5 h-5 flex-shrink-0 stroke-2" />
              <span className="text-sm">Fehlerdetails herunterladen</span>
            </button>
          ) : null}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
