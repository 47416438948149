import { Route, Routes } from "react-router-dom";
import DelayedNavigate from "../../../components/DelayedNavigate";
import { CancelledLoginView } from "../cancelledLogin/CancelledLoginView";
import { ForgotPasswordView } from "../forgotPasword/ForgotPasswordView";
import { LoginView } from "../login/LoginView";
import { WelcomeView } from "../welcome/WelcomeView";
import { useUnauthenticatedMainViewModel } from "./UnauthenticatedMainViewModel";
import { UnauthenticatedMainViewTypes } from "./UnauthenticatedMainViewTypes";

export const UnauthenticatedMainView = (props: UnauthenticatedMainViewTypes.Props) => {
  const viewModel = useUnauthenticatedMainViewModel(props);
  return (
    <Routes>
      <Route index element={<WelcomeView {...viewModel} />} />
      <Route path="/forgotPassword" element={<ForgotPasswordView />} />
      <Route path="/cancelledLogin" element={<CancelledLoginView />} />
      <Route path="/login/:shouldLogin" element={<LoginView {...viewModel} />} />
      <Route path="/login" element={<LoginView {...viewModel} />} />
      <Route path="/logout" element={<DelayedNavigate to="/" />} />
      <Route path="/*" element={<DelayedNavigate to="/" />} />
    </Routes>
  );
};
