import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { addMessage } from "../../components/ToasterHandler/useToasterHandler";
import WebsocketDebug from "../../models/WebsocketDebug";
import useEmergencyBrake from "../useEmergencyBrake";
import DebugCommand from "./DebugCommand";
import DebugConnection from "./DebugConnection";
import useDebugConnection from "./useDebugConnection";

const useDebugConnections = (accountName?: string, persStorageInit?: boolean) => {
  const [connections, setConnections] = useState<DebugConnection[]>([]);
  const { instance } = useMsal();

  const resetConnections = useCallback(() => {
    setConnections([]);
  }, []);

  const addToConnections = useCallback(
    ({
      sessionId,
      userAgent,
      accountName,
      version,
    }: Pick<WebsocketDebug<any>, "sessionId" | "userAgent" | "accountName" | "version">) => {
      setConnections((connections) => {
        const temp = [...connections];
        const index = temp.findIndex((c) => c.sessionId === sessionId);
        if (!!~index) {
          temp[index] = {
            ...temp[index],
            lastContact: new Date(),
            accountName,
          };
        } else {
          temp.push({
            sessionId,
            lastContact: new Date(),
            userAgent,
            accountName,
            version,
          });
        }
        return temp;
      });
    },
    []
  );

  const [brake, isBraking] = useEmergencyBrake();

  const onBrake = useCallback(() => {
    try {
      const ev = new CustomEvent("STORAGE_BLOCK", { detail: true });
      window.dispatchEvent(ev);
    } catch (error) {
      console.error(error);
    }
    brake();
  }, [brake]);

  const handleMessage = useCallback(
    (msg: WebsocketDebug<any>) => {
      addToConnections(msg);
      if (msg.type === DebugCommand.Logout) {
        addMessage({
          text: `Sie werden aus der Ferne ausgeloggt durch ${msg.accountName}`,
          title: "Abmeldung",
          color: "warning",
          icon: "ExclamationCircleIcon",
          delay: 5000,
        });
        setTimeout(() => {
          instance.logoutRedirect();
        }, 5000);
      } else if (msg.type === DebugCommand.EmergencyBrake) {
        onBrake();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToConnections, onBrake]
  );

  const debugConnection = useDebugConnection(handleMessage, accountName);
  const { sendMessage, greetAll } = debugConnection;

  const remoteLogout = useCallback(
    (sessionId: string) => {
      sendMessage(sessionId, DebugCommand.Logout, sessionId);
    },
    [sendMessage]
  );

  const remoteBrake = useCallback(
    (sessionId: string) => {
      sendMessage(sessionId, DebugCommand.EmergencyBrake, sessionId);
    },
    [sendMessage]
  );

  useEffect(() => {
    greetAll();
  }, [greetAll]);

  return { connections, remoteLogout, remoteBrake, resetConnections, ...debugConnection };
};
export default useDebugConnections;
