import { Route, Routes } from "react-router-dom";
import { ExportDayView } from "../day/ExportDayView";
import { useExportMainViewModel } from "./ExportMainViewModel";
import { ExportMainViewTypes } from "./ExportMainViewTypes";

export const ExportMainView = (props: ExportMainViewTypes.Props) => {
  const viewModel = useExportMainViewModel(props);
  return (
    <Routes>
      <Route path="/day/:date" element={<ExportDayView {...viewModel} />} />
      <Route path="/day/" element={<ExportDayView {...viewModel} />} />
      <Route path="/*" element={<ExportDayView {...viewModel} />} />
    </Routes>
  );
};
