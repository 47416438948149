import { DocumentTextIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import * as React from "react";
import ReactSwipe from "react-swipe";
import { AppFunctions, AppState } from "../App";
import { getEmail, getSummariesCallBack } from "../controller/ReservierungDataController";
import { useToggle } from "../hooks/useToggle";
import { Cause } from "../models/Cause";
import { Email } from "../models/Email";
import { AppColors, EMPTY_DATETIME, EMPTY_GUID } from "../models/General";
import { NewReservation, Reservation } from "../models/Reservation";
import { SummaryTable } from "../models/Summary";
import { useVeranstaltungSimpleViewModel } from "../views/VeranstaltungSimple/VeranstaltungSimpleViewModel";
import { CloseArrow } from "./CloseArrow";
import DateTable from "./DateTable";
import { DateTimeModule } from "./DateTimeModule";
import { GuestAmountPicker } from "./GuestAmountPicker";
import { Heroicon, Heroicons } from "./Heroicon/Heroicon";
import { InformationDetail } from "./InformationDetail";
import { NewReservationDetail } from "./NewReservationDetail";
import NewReservationEmail from "./NewReservationEmail";
import { SummaryBlock } from "./SummaryBlock";
import { TimePicker } from "./TimePicker";
import Toggle from "./Toggle";
import { ViewHeader } from "./ViewHeader";
import { ViewTopSpace } from "./ViewTopSpace";

const emailButtons = [{ label: "Keine Email" }, { label: "Mit Email" }];
const SWIPE_SPEED = 250;
const CHILD_COUNT = 5;

const ReservationCreation = (
  props: {
    restaurantId: string;
    appColors: AppColors;
    isCreating: boolean;
    create: (
      item: NewReservation,
      cause?: Cause,
      date?: Date,
      shouldConfirm?: boolean,
      isWalkIn?: boolean,
      createEmail?: boolean,
      email?: Email
    ) => Promise<void>;
    close?: () => void;
    getSummary: ({ year, month }: { year: number; month: number }, cb: getSummariesCallBack) => void;
    requestChannel: Reservation["requestChannel"] | undefined;
    incomingReservation: (Partial<Reservation> & { cause?: Cause }) | undefined;
    appIsStandalone: AppState["appIsStandalone"];
    appCauses?: AppState["appCauses"];
    dateTableSize?: "dense" | "full" | "week";
    appSummary?: AppState["appSummary"];
    isWalkIn?: boolean;
    currentDate: Date;
    appGetSummary?: AppFunctions["appGetSummary"];
    setAppState?: AppFunctions["setAppState"];
  } & Partial<NewReservation>
) => {
  const incomingDate = React.useMemo(() => {
    if (props.incomingReservation) {
      const { dateOfArrival } = props.incomingReservation;
      if (dateOfArrival) {
        return new Date(new Date(dateOfArrival).setHours(12)).toISOString().slice(0, 10);
      }
    } else if (props.dateOfArrival) {
      return props.dateOfArrival;
    } else {
      return props.currentDate.toISOString().slice(0, 10);
    }
    return;
  }, [props.currentDate, props.dateOfArrival, props.incomingReservation]);

  const [isWalkIn, setIsWalkIn] = React.useState(props.isWalkIn);

  const [currentDate, setCurrentDate] = React.useState<string | undefined>(incomingDate);
  const [currentTime, setCurrentTime] = React.useState<string | undefined>(
    (props.incomingReservation &&
      props.incomingReservation.dateOfArrival &&
      new Date(props.incomingReservation.dateOfArrival).toLocaleString("de-de", {
        hour: "2-digit",
        minute: "2-digit",
      }) + ":00") ||
      props.dateOfArrival
  );
  const [currentGuestAmount, setCurrentGuestAmount] = React.useState<Reservation["guestAmount"] | undefined>(
    (props.incomingReservation && props.incomingReservation.guestAmount) || props.guestAmount
  );
  const [currentDetails, setCurrentDetails] = React.useState<Partial<NewReservation> | undefined>({
    ...(props.incomingReservation || {
      name: props.name,
      requestChannel: props.requestChannel,
      email: props.email,
      note: props.note,
      phoneNumber: props.phoneNumber,
    }),
  });

  // const [lastCause, setLastCause] = React.useState<AppState["appCauses"][0] | undefined>(
  //   props.appCauses && props.appCauses[0] ? [...props.appCauses][0] : undefined
  // );

  const currentCause = React.useMemo(
    () =>
      props.cause || props.incomingReservation?.cause
        ? {
            ...(props.cause || props.incomingReservation?.cause),
            timestamp:
              (typeof props.cause?.timestamp === "string"
                ? new Date(props.cause.timestamp).toLocaleString("de-de")
                : props.cause?.timestamp) ?? undefined,
          }
        : null,
    [props.cause, props.incomingReservation?.cause]
  );

  const [scrollRef, setScrollRef] = React.useState<HTMLDivElement[]>([]);
  const [activeIndex, setActiveIndex] = React.useState(1);
  const [containerRef, setContainerRef] = React.useState<ReactSwipe | null>(null);

  const [shouldConfirm, toggleConfirm, doConfirm, doNotConfirm] = useToggle(true);
  const [shouldSendEmail, toggleSendEmail, doSendEmail, doNotSendEMail] = useToggle(false);

  const [emailSubject, setEmailSubject] = React.useState<string | null>(null);
  const [emailContent, setEmailContent] = React.useState<string | null>(null);
  const [emailRecipients, setEmailRecipients] = React.useState<string[]>([]);

  const _handleShouldSendEmail = React.useCallback(async () => {
    try {
      if (currentDetails && currentDate && currentTime && currentGuestAmount) {
        let date = new Date(`${currentDate}`);
        const [hours, minutes] = currentTime.split(":");
        date.setHours(+hours);
        date.setMinutes(+minutes);
        const newRes: NewReservation = {
          dateOfArrival: date.toISOString(),
          durationOfStay: 90,
          guestAmount: currentGuestAmount,
          name: currentDetails.name || "",
          requestChannel: currentDetails.requestChannel || "",
          email: currentDetails.email || "",
          note: currentDetails.note || "",
          phoneNumber: currentDetails.phoneNumber || "",
        };
        const emailResp = await getEmail(props.restaurantId, {
          ...newRes,
          id: EMPTY_GUID,
          causeId: EMPTY_GUID,
          createdBy: "",
          modifiedBy: "",
          leaveDateTime: EMPTY_DATETIME,
          leaveNotice: "",
          satisfaction: 5,
          state: 0,
          tables: [],
          listWordDocUrl: [],
        });
        setEmailContent(() => {
          setEmailRecipients(() => {
            setEmailSubject(() => {
              return emailResp.subject;
            });
            return emailResp.recipients;
          });
          return emailResp.content;
        });
      }
    } catch (error) {
      throw error;
    }
  }, [currentDate, currentDetails, currentGuestAmount, currentTime, props.restaurantId]);

  React.useEffect(() => {
    if (shouldSendEmail) {
      _handleShouldSendEmail();
    }
  }, [_handleShouldSendEmail, shouldSendEmail]);

  React.useEffect(() => {
    if (isWalkIn) {
      const now = new Date();
      setCurrentDate(() => {
        setCurrentTime(() => {
          setCurrentDetails((curr) => {
            doNotConfirm();
            if (containerRef) {
              containerRef.slide(2, SWIPE_SPEED);
              setActiveIndex(() => 2);
            }
            return { ...curr, requestChannel: "WalkIn", durationOfStay: 90 };
          });
          return now.toLocaleString("de-de", { hour: "2-digit", minute: "2-digit" }) + ":00";
        });
        return now.toISOString().slice(0, 10);
      });
    }
  }, [containerRef, doNotConfirm, isWalkIn]);

  const _handleRef = (index: number) => (ref: HTMLDivElement | null) => {
    if (ref !== null && scrollRef[index] === undefined) {
      setScrollRef((refs) => {
        refs[index] = ref;
        return refs;
      });
    }
  };

  const _handleIndex = (idx: number) => {
    setActiveIndex(idx);
  };

  const hasCheckedProps = React.useRef(false);

  React.useEffect(() => {
    const res = props.incomingReservation;
    if (res) {
      if (hasCheckedProps.current === false) {
        setScrollRef((currentRefs) => {
          setActiveIndex((currIdx) => {
            let missingStep = 1;
            if (res.dateOfArrival === undefined) {
              missingStep = 0;
            } else if (res.guestAmount === undefined || +res.guestAmount.adultAmount === 0) {
              missingStep = 2;
            } else if (res.name === undefined) {
              missingStep = 3;
            } else {
              missingStep = 4;
            }
            return missingStep;
          });
          return currentRefs;
        });
      }
    }
    hasCheckedProps.current = true;
  }, [hasCheckedProps, props.incomingReservation]);

  // React.useEffect(() => {
  //   if (lastCause && lastCause.type === "Call") {
  //     window.confirm(`Reservierung zum Letzten Anruf?\n${lastCause.from}`);
  //   }
  // }, [lastCause]);

  const [errorFlags, setErrorFlags] = React.useState<string[]>([]);
  React.useEffect(() => {
    setErrorFlags((c) => {
      const temp = [];
      if (!currentDate) temp.push("datum");
      if (!currentTime) temp.push("uhrzeit");
      if (!currentGuestAmount || !currentGuestAmount.adultAmount || currentGuestAmount.adultAmount <= 0)
        temp.push("gaeste");
      if (!currentDetails || !currentDetails.name) temp.push("details");
      return temp;
    });
  }, [currentDate, currentDetails, currentGuestAmount, currentTime]);

  const _handleCreation = React.useCallback(async () => {
    try {
      if (
        currentDate &&
        currentTime &&
        currentGuestAmount &&
        currentGuestAmount.adultAmount &&
        currentGuestAmount.adultAmount > 0 &&
        currentDetails
      ) {
        let date = new Date(`${currentDate}`);
        const [hours, minutes] = currentTime.split(":");
        date.setHours(+hours);
        date.setMinutes(+minutes);
        const newRes: NewReservation = {
          dateOfArrival: date.toISOString(),
          durationOfStay: 90,
          guestAmount: currentGuestAmount,
          name: currentDetails.name || "",
          requestChannel: currentDetails.requestChannel || "",
          email: currentDetails.email || "",
          note: currentDetails.note || "",
          phoneNumber: currentDetails.phoneNumber || "",
        };
        let cause = undefined;
        if (
          props.incomingReservation &&
          (isWalkIn
            ? window.confirm(
                `Ausgewählten Cause nutzen?\n${props.incomingReservation.name || ""}\n${
                  props.incomingReservation.email || ""
                }\n${props.incomingReservation.phoneNumber || ""}`
              )
            : true)
        ) {
          cause = props.incomingReservation.cause;
        }

        let email: Email | undefined = undefined;
        if (emailSubject && emailContent && emailRecipients) {
          email = {
            recipients: emailRecipients,
            subject: emailSubject,
            content: emailContent,
          };
        }

        const resp = await props.create(
          newRes,
          cause,
          date,
          shouldConfirm,
          isWalkIn,
          shouldSendEmail,
          shouldSendEmail ? email || undefined : undefined
        );
        return;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [
    currentDate,
    currentDetails,
    currentGuestAmount,
    currentTime,
    emailContent,
    emailRecipients,
    emailSubject,
    isWalkIn,
    props,
    shouldConfirm,
    shouldSendEmail,
  ]);

  const [listRef, setListRef] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (listRef) {
      disableBodyScroll(listRef);
      return () => {
        enableBodyScroll(listRef);
      };
    }
  }, [listRef]);

  const _handleNewDetails = React.useCallback((details: Partial<NewReservation>) => {
    return setCurrentDetails(details);
  }, []);

  const _handleDetailsContinue = React.useCallback(() => {
    if (containerRef) {
      containerRef.next();
    }
  }, [containerRef]);

  // React.useEffect(() => {
  //   if (props.isWalkIn !== undefined) setIsWalkIn(props.isWalkIn);
  // }, [props.isWalkIn]);

  const hasOpenedDate = React.useRef(false);

  React.useEffect(() => {
    if (activeIndex === 0) hasOpenedDate.current = true;
  }, [activeIndex]);

  React.useEffect(() => {
    if (
      currentDate &&
      (props.appSummary === null ||
        props.appSummary === undefined ||
        (props.appSummary !== null && props.appSummary !== undefined
          ? props.appSummary[currentDate || "leer"] === undefined
          : true))
    ) {
      console.log("true");
      const month = currentDate.slice(5, 7);
      const year = currentDate.slice(0, 4);
      if (props.appGetSummary && props.setAppState)
        props.appGetSummary.call(undefined, { year: +year, month: +month }, (sum: SummaryTable) => {
          if (props.setAppState && sum) {
            props.setAppState.call(undefined, "appSummary", sum);
          }
        });
    }
  }, [currentDate, props.appGetSummary, props.appSummary, props.setAppState]);

  const slideTo = React.useCallback(
    (n: number) => () => {
      if (containerRef) {
        containerRef.slide(n, SWIPE_SPEED);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef?.slide]
  );

  const onTransitionEnd = React.useCallback((idx: number, ele: any) => _handleIndex(idx), []);
  const swipeOptions = React.useMemo(
    () => ({
      startSlide: activeIndex,
      speed: SWIPE_SPEED,
      transitionEnd: onTransitionEnd,
    }),
    [activeIndex, onTransitionEnd]
  );

  React.useEffect(() => {
    if (shouldConfirm && currentDetails?.email && currentDetails.email !== "") {
      doSendEmail();
    } else {
      doNotSendEMail();
    }
  }, [currentDetails, doNotSendEMail, doSendEmail, shouldConfirm]);

  const { changeDate, currentVeranstaltung } = useVeranstaltungSimpleViewModel({
    appReservationDate: currentDate ? new Date(currentDate) : new Date(),
  });

  React.useEffect(() => {
    if (currentDate) {
      changeDate(new Date(currentDate));
    }
  }, [changeDate, currentDate]);

  return (
    <div className="flex flex-1 flex-col justify-start items-start h-full w-full">
      {props.close ? (
        <ViewHeader appColors={props.appColors}>
          <span className="flex flex-1 h-full justify-start items-center" onClick={props.close}>
            <CloseArrow color="white" appColors={props.appColors} close={() => null}></CloseArrow>
            <span>Zurück</span>
          </span>
        </ViewHeader>
      ) : null}
      <div className={`relative flex flex-1 flex-col w-full bg-primary-700 min-h-0 max-h-full h-full`}>
        <div className={`flex flex-col w-full pb-2 bg-primary-700 px-1 h-28 flex-shrink-0 flex-grow-0`}>
          <ViewTopSpace
            appColors={props.appColors}
            height="14"
            sideButton={
              currentDate === undefined
                ? {
                    label: "Walk-In",
                    onClick: () => setIsWalkIn((curr) => !curr),
                  }
                : undefined
            }
            backgroundIcon="CalendarIcon"
            backgroundIconProps={{ width: "60%" }}
          >
            <h3 className="flex flex-1 text-xl text-white justify-start items-center pl-3">
              {!isWalkIn
                ? currentDetails !== undefined
                  ? currentDetails.name || "Neue Reservierung"
                  : "Neue Reservierung"
                : ""}
              {isWalkIn && currentDetails !== undefined ? currentDetails.name || "Neuer Walk-In" : ""}
            </h3>
            <div className="flex justify-start items-center pl-3 space-x-3">
              {currentDate ? (
                <div className="flex justify-start flex-grow-0">
                  <InformationDetail appColors={props.appColors} icon="CalendarIcon">
                    {new Date(currentDate).toLocaleString("de-de", {
                      month: "2-digit",
                      day: "2-digit",
                      weekday: "short",
                      year: "2-digit",
                    })}
                  </InformationDetail>
                </div>
              ) : null}
              {currentTime ? (
                <div className="flex justify-start flex-grow-0">
                  <InformationDetail appColors={props.appColors} icon="ClockIcon">
                    {currentTime.slice(0, 5)}
                  </InformationDetail>
                </div>
              ) : null}
              {currentGuestAmount ? (
                <div className="flex justify-start flex-grow-0">
                  <InformationDetail appColors={props.appColors} icon="UserIcon">
                    {currentGuestAmount.childAmount
                      ? `${currentGuestAmount.adultAmount} + ${currentGuestAmount.childAmount}`
                      : currentGuestAmount.adultAmount}
                  </InformationDetail>
                </div>
              ) : null}
              {isWalkIn ? (
                <div className="flex justify-start flex-grow-0">
                  <InformationDetail appColors={props.appColors} icon="LogoutIcon">
                    Walk-In
                  </InformationDetail>
                </div>
              ) : null}
            </div>
          </ViewTopSpace>
          <div className="flex flex-grow-0 flex-shrink-0 w-full justify-around py-1 px-1 h-12 z-10">
            <ButtonWithIcon
              appColors={props.appColors}
              onClick={slideTo(0)}
              isActive={activeIndex === 0}
              icon="CalendarIcon"
            />
            <ButtonWithIcon
              appColors={props.appColors}
              onClick={slideTo(1)}
              isActive={activeIndex === 1}
              icon="ClockIcon"
            />
            <ButtonWithIcon
              appColors={props.appColors}
              onClick={slideTo(2)}
              isActive={activeIndex === 2}
              icon="UsersIcon"
            />
            <ButtonWithIcon
              appColors={props.appColors}
              onClick={slideTo(3)}
              isActive={activeIndex === 3}
              icon="InformationCircleIcon"
            />
            <ButtonWithIcon
              appColors={props.appColors}
              onClick={slideTo(4)}
              isActive={activeIndex === 4}
              icon="CheckIcon"
            />
            {currentCause?.id ? (
              <ButtonWithIcon
                appColors={props.appColors}
                onClick={slideTo(5)}
                isActive={activeIndex === 5}
                icon="DuplicateIcon"
              />
            ) : null}
          </div>
        </div>

        <div className="flex flex-9 h-full w-full overflow-hidden min-h-0">
          <ReactSwipe
            ref={setContainerRef}
            className="w-full h-full min-h-0 overflow-auto"
            childCount={CHILD_COUNT}
            swipeOptions={swipeOptions}
          >
            <div className="flex justify-center items-center w-full h-full min-h-0 max-h-full overflow-auto">
              <div
                ref={_handleRef(0)}
                className="relative min-w-full w-full h-full flex-shrink-0 overflow-auto min-h-0"
              >
                {activeIndex === 0 ? (
                  <DateTable
                    size={props.dateTableSize}
                    appIsStandalone={props.appIsStandalone}
                    selectDate={(date) => {
                      setCurrentDate(date);
                      return setTimeout(() => {
                        if (containerRef) {
                          containerRef.next();
                        }
                      }, 250);
                    }}
                    getSummary={props.getSummary}
                    appColors={props.appColors}
                    selectedDate={currentDate || new Date().toISOString().slice(0, 10)}
                    shouldNotScroll={activeIndex !== 0}
                    // preCreatedSummary={props.appSummary || undefined}
                    // shouldLoadSummary={false}
                  ></DateTable>
                ) : null}
              </div>
            </div>
            <div className="flex justify-center items-center w-full h-full min-h-0 max-h-full">
              <div ref={_handleRef(1)} className="min-w-full w-full h-full flex-shrink-0">
                <TimePicker
                  appColors={props.appColors}
                  selectTime={(time) => {
                    setCurrentTime((currTime) => {
                      if (currTime === undefined) {
                        setTimeout(() => {
                          if (containerRef) {
                            containerRef.next();
                          }
                        }, 250);
                      }
                      return time;
                    });
                  }}
                  selectedTime={currentTime}
                  relativeSized
                ></TimePicker>
                <div className="flex h-64 w-full justify-center items-center px-6">
                  <div className="inline-flex w-full h-36">
                    {props.appSummary && currentDate ? (
                      <DateTimeModule
                        isSelected={false}
                        summaryTableTime={props.appSummary[currentDate]}
                        dateOfDay={currentDate}
                        veranstaltung={currentVeranstaltung?.titel}
                      ></DateTimeModule>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center w-full h-full min-h-0 max-h-full">
              <div ref={_handleRef(2)} className="min-w-full w-full h-full flex-shrink-0">
                <GuestAmountPicker
                  appColors={props.appColors}
                  selectGuestAmount={(guest) => {
                    setCurrentGuestAmount((currGuest) => {
                      if (currGuest === undefined) {
                        setTimeout(() => {
                          if (containerRef) {
                            containerRef.next();
                          }
                        }, 250);
                      }
                      return guest;
                    });
                  }}
                  selectedGuestAmount={currentGuestAmount}
                ></GuestAmountPicker>
              </div>
            </div>
            <div className="flex justify-center items-center w-full h-full min-h-0 max-h-full">
              <div ref={_handleRef(3)} className="min-w-full w-full h-full flex-shrink-0">
                <NewReservationDetail
                  appColors={props.appColors}
                  active={activeIndex === 3}
                  selectDetails={_handleNewDetails}
                  handleContinue={_handleDetailsContinue}
                  {...props.incomingReservation}
                  requestChannel={props.incomingReservation?.cause?.type || props.incomingReservation?.requestChannel}
                  {...(isWalkIn ? { name: "Walk-In", requestChannel: "Walk-In" } : undefined)}
                ></NewReservationDetail>
              </div>
            </div>
            <div className="flex justify-start items-center w-full h-full min-h-0 max-h-full overflow-hidden">
              <div
                ref={setListRef}
                className="flex flex-col w-full h-full px-6 justify-start items-center overflow-auto min-h-0"
              >
                <SummaryBlock
                  hasError={errorFlags.some((c) => c === "datum")}
                  clickHandler={slideTo(0)}
                  appColors={props.appColors}
                  value={
                    currentDate
                      ? `${new Date(currentDate).toLocaleDateString("de-de", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          weekday: "long",
                        })}`
                      : ""
                  }
                  icon="CalendarIcon"
                />

                <SummaryBlock
                  hasError={errorFlags.some((c) => c === "uhrzeit")}
                  clickHandler={slideTo(1)}
                  appColors={props.appColors}
                  value={currentTime ? `${currentTime.slice(0, -3)} Uhr` : ""}
                  icon="ClockIcon"
                />

                <SummaryBlock
                  hasError={errorFlags.some((c) => c === "gaeste")}
                  clickHandler={slideTo(2)}
                  appColors={props.appColors}
                  value={
                    currentGuestAmount
                      ? `${currentGuestAmount.adultAmount} Erwachsen${
                          currentGuestAmount.adultAmount > 1 ? "e" : "er"
                        } ${
                          currentGuestAmount.childAmount > 0
                            ? `und ${currentGuestAmount.childAmount} Kind${
                                currentGuestAmount.childAmount > 1 ? "er" : ""
                              }`
                            : ""
                        }`
                      : ""
                  }
                  icon="UsersIcon"
                />

                <SummaryBlock
                  hasError={errorFlags.some((c) => c === "details")}
                  clickHandler={slideTo(3)}
                  appColors={props.appColors}
                  value={currentDetails && currentDetails.name ? currentDetails.name : ""}
                  icon="InformationCircleIcon"
                />

                <SummaryBlock
                  clickHandler={slideTo(3)}
                  appColors={props.appColors}
                  value={currentDetails && currentDetails.email ? currentDetails.email : ""}
                  icon="MailIcon"
                />

                <SummaryBlock
                  clickHandler={slideTo(3)}
                  appColors={props.appColors}
                  value={currentDetails && currentDetails.phoneNumber ? currentDetails.phoneNumber : ""}
                  icon="PhoneIncomingIcon"
                />

                <div
                  className={`flex flex-col justify-start items-start h-32 pl-2 flex-grow-0 flex-shrink-0 w-full rounded-lg shadow bg-white overflow-hidden mt-1 min-h-0`}
                  onClick={slideTo(3)}
                >
                  <div className="flex justify-center items-center h-32">
                    <DocumentTextIcon
                      className={`flex justify-start items-start flex-grow-0 pt-2 text-primary-700 w-6 h-full`}
                    />
                    <div
                      className={`flex flex-1 flex-wrap overflow-auto pl-2 pt-3 justify-start items-start text-primary-700 leading-tight max-w-full h-full`}
                    >
                      <span className="whitespace-pre-wrap text-left">{currentDetails ? currentDetails.note : ""}</span>
                    </div>
                  </div>
                </div>

                {isWalkIn ? null : (
                  <div className="inline-flex w-full backdrop-saturate-50 rounded-md shadow-md justify-between items-center px-3 py-2 mt-2 text-white backdrop-filter">
                    <span>Direkt bestätigen</span>
                    <Toggle idName="confirmToggle" onToggle={toggleConfirm} isToggled={shouldConfirm} />
                  </div>
                )}

                {!isWalkIn ? (
                  <>
                    <div
                      className={`inline-flex w-full backdrop-saturate-50 rounded-md shadow-md justify-between items-center px-3 py-2 mt-2 text-white backdrop-filter transition-opacity duration-200 ease-in-out ${
                        shouldConfirm ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
                      }`}
                    >
                      <span>Email bei Erstellung versenden</span>
                      <Toggle idName="emailToggle" onToggle={toggleSendEmail} isToggled={shouldSendEmail} />
                    </div>
                    <NewReservationEmail
                      appColors={props.appColors}
                      emailAdress={currentDetails?.email ?? null}
                      onSlideToEmail={slideTo(3)}
                      isVisible={shouldConfirm && shouldSendEmail}
                      setContent={setEmailContent}
                      setSubject={setEmailSubject}
                      content={emailContent}
                      subject={emailSubject}
                    />
                  </>
                ) : null}

                <div className="flex w-full h-20 flex-shrink-0 flex-grow-0 justify-center items-center py-4">
                  <button
                    className={`relative flex justify-center items-center w-full h-full rounded-lg bg-primary-500 disabled:bg-gray-300 disabled:text-gray-500 shadow-md font-semibold text-lg text-white cursor-pointer `}
                    onClick={_handleCreation}
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter") {
                      }
                    }}
                    tabIndex={0}
                    disabled={!!errorFlags.length}
                  >
                    <span className="px-1">{shouldSendEmail ? "Erstellen & Email Senden" : "Erstellen"}</span>
                    <CheckIcon className="absolute left-0 flex justify-start items-start flex-grow-0 w-12 h-6" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-start items-center w-full h-full min-h-0 max-h-full overflow-hidden">
              <div
                ref={_handleRef(4)}
                className="flex flex-col w-full h-full px-6 justify-start items-center overflow-auto min-h-0"
              >
                <div className="inline-grid grid-cols-3 gap-2 w-full min-h-0 p-2 bg-white rounded shadow-sm text-xs">
                  <span className="text-gray-600">Von</span>
                  <span className="col-span-2">{currentCause?.from}</span>
                  <span className="text-gray-600">Titel</span>
                  <span className="col-span-2">{currentCause?.subject}</span>
                  <span className="text-gray-600">Typ</span>
                  <span className="col-span-2">{currentCause?.type}</span>
                  <span className="text-gray-600">Zeitstempel</span>
                  <span className="col-span-2">
                    {currentCause?.timestamp || props.incomingReservation?.cause?.timestamp
                      ? new Date(props.incomingReservation?.cause?.timestamp!).toLocaleString("de-de")
                      : ""}
                  </span>
                  <span className="text-gray-600">Notiz</span>
                  <span className="col-span-2">
                    {currentCause?.note || props.note || props.incomingReservation?.note}
                  </span>
                  <span className="text-sm font-semibold tracking-wide col-span-3">Rohdaten</span>
                  <div className="inline-flex flex-col w-full gap-1 col-span-3">
                    {currentCause
                      ? Object.entries(currentCause)
                          .filter(([key]) => !(key === "reservationId" || key === " body"))
                          .map(([key, value]) => (
                            <React.Fragment key={key}>
                              <details className="w-full">
                                <summary className="py-1 w-full">
                                  <span className="text-gray-600">{key}</span>
                                </summary>

                                <span className="my-3 pl-3 whitespace-pre-wrap">
                                  {typeof value === "object" ? JSON.stringify(value, null, 2) : value + ""}
                                </span>
                              </details>
                            </React.Fragment>
                          ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </ReactSwipe>
        </div>
        <div className="flex flex-wrap justify-start items-start w-full z-10 h-0 flex-shrink-0 flex-grow-0"></div>
      </div>
    </div>
  );
};

type ButtonWithIconProps = { appColors: AppColors; isActive: boolean; onClick?: () => void; icon: Heroicon };

const ButtonWithIcon: React.FC<ButtonWithIconProps> = (props) => {
  const Icon = React.useMemo(() => Heroicons.Outline[props.icon], [props.icon]);

  const [isClicked, setIsClicked] = React.useState(false);
  React.useEffect(() => {
    setIsClicked(false);
  }, [props]);
  return (
    <div
      className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-10 h-10 cursor-pointer`}
      onClick={(ev) => {
        if (props.onClick) {
          props.onClick();
        }

        setIsClicked(true);
      }}
    >
      <div
        className={`flex flex-grow-0 flex-shrink-0 justify-center items-center rounded-lg p-2 ${
          props.isActive
            ? `bg-white text-primary-700 w-10 h-10 shadow-lg`
            : `bg-primary-500 text-white w-9 h-9 shadow-md`
        } ${isClicked ? "pulseOnce" : ""} `}
        style={{
          transitionDuration: "250ms",
        }}
      >
        <div className="flex flex-grow-0 flex-shrink-0 w-full h-full">
          <Icon className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};
export default ReservationCreation;
