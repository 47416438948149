import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect, useRef } from "react";
import { reactPlugin } from "../../AppInsights";

const useTrackUpdate = <T>(key: string, data: T, skipFirstRun?: boolean) => {
  const keyRef = useRef<string>(key);
  const trackUpdate = useTrackEvent<T>(reactPlugin, `${keyRef.current}_Update`, data, skipFirstRun);
  useEffect(() => {
    trackUpdate(typeof data === "object" ? data : ({ [key]: data } as any));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return trackUpdate;
};
export default useTrackUpdate;
