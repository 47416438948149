import * as React from "react";
import { AppColors } from "../models/General";
import { Heroicon, Heroicons } from "./Heroicon/Heroicon";

export const SummaryBlock: React.FC<{
  appColors: AppColors;
  value: string;
  clickHandler?: () => void;
  className?: string;
  icon: Heroicon;
  hasError?: boolean;
  hasNoMargin?: boolean;
}> = (props) => {
  const Icon = React.useMemo(() => Heroicons.Outline[props.icon], [props.icon]);

  return (
    <div
      className={`relative flex justify-center items-center h-12 pl-2 flex-grow-0 flex-shrink-0 w-full rounded-lg shadow-md overflow-hidden cursor-pointer ${
        props.hasNoMargin ? "" : "mt-1"
      } ${props.className} ${
        props.hasError ? "ring-1 ring-red-500 bg-red-50 text-red-600" : `text-primary-600 bg-white`
      }`}
      onClick={props.clickHandler}
    >
      <Icon className="w-5 h-5" />
      <div className={`flex flex-1 pl-2 justify-start items-center leading-tight`}>{props.value}</div>
      <Heroicons.Outline.ArrowRightIcon className={`absolute right-0 top-0 h-full w-5 mr-2 text-gray-400`} />
    </div>
  );
};
