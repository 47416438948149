import { writeStorage, useLocalStorage } from "@rehooks/local-storage";
import { useCallback } from "react";
import { TailwindBackgroundColors } from "../models/General";

export type AppColors = {
  backgroundcolor: TailwindBackgroundColors;
  textcoloroncolor: "gray-900" | "white";
  textdefaultcolor: "gray-900" | "white";
};

const defaultTheme: AppColors = {
  backgroundcolor: "blue",
  textcoloroncolor: "white",
  textdefaultcolor: "gray-900",
};

export const useTheme = () => {
  const [theme] = useLocalStorage<AppColors>(`Theme`, defaultTheme);

  const setTheme = useCallback((color: TailwindBackgroundColors) => {
    writeStorage(`Theme`, {
      backgroundcolor: color,
      textcoloroncolor: "white",
      textdefaultcolor: "gray-900",
    });
  }, []);

  return { theme, setTheme };
};
