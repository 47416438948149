import { createContext } from "react";
import Tastatur from "./Tastatur";

type Context = {
  internalText: string | null;
  mode: Tastatur.Mode;
  isShifted: boolean;
  focusedInputId: string | null;
  setFocusedInputId: (id: string | null) => void;
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  toggleShift: () => void;
  switchMode: () => void;
  onKeyPress: (key: Tastatur.Key | Tastatur.Key.Special) => void;
  art: Tastatur.Art;
  setArt: (art: Tastatur.Art) => void;
  setInternalText: (text: string | null) => void;
  isShowingSpecialKeys: boolean;
  setIsShowingSpecialKeys: (is: boolean) => void;
};

const TastaturContext = createContext<Context>({
  internalText: null,
  mode: Tastatur.Mode.Text,
  isShifted: false,
  focusedInputId: null,
  setFocusedInputId: (...args) => console.log("not implemented", args),
  isVisible: false,
  hide: (...args) => console.log("not implemented", args),
  show: (...args) => console.log("not implemented", args),
  toggleShift: (...args) => console.log("not implemented", args),
  switchMode: (...args) => console.log("not implemented", args),
  onKeyPress: (...args) => console.log("not implemented", args),
  art: 0,
  setArt: (...args) => console.log("not implemented", args),
  setInternalText: (...args) => console.log("not implemented", args),
  isShowingSpecialKeys: false,
  setIsShowingSpecialKeys: (...args) => console.log("not implemented", args),
});

export default TastaturContext;
