import { useMemo } from "react";
import useTrackUpdate from "../../clientApi/tracking/useTrackUpdate";
import { useProtocol } from "../../hooks/useProtocol";

type ProtocolTrackerProps = {};
const ProtocolTracker = (props: ProtocolTrackerProps) => {
  const { protocol } = useProtocol();
  const lastProtocolEntry = useMemo(() => protocol[0], [protocol]);

  useTrackUpdate("Protocol", lastProtocolEntry, false);

  return (
    <div
      id="Protocoltracker"
      className="hidden h-0 w-0 min-w-0 min-h-0 max-h-0 max-w-0 opacity-0 pointer-events-none"
    ></div>
  );
};
export default ProtocolTracker;
