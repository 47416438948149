import TastaturButton from "./TastaturButton";

type TastaturSpaceButtonProps = {
  onPress: () => void;
  className?: string;
};

const TastaturSpaceButton = ({ onPress, className }: TastaturSpaceButtonProps) => {
  return (
    <TastaturButton
      onPress={onPress}
      innerClassName={"h-full text-gray-700 bg-white"}
      className={`h-full ${className ?? "col-span-7"}`}
    >
      Leerzeichen
    </TastaturButton>
  );
};
export default TastaturSpaceButton;
