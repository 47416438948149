import * as React from "react";
import { AppColors } from "../models/General";
import { Heroicons } from "./Heroicon/Heroicon";

export const CloseArrow = (props: { close: () => void; appColors: AppColors; color?: string }) => {
  return (
    <div className="flex h-full w-10 flex-grow-0 justify-start items-center pl-1 transition-all duration-200 ease-in-out">
      <span className="underline cursor-pointer" onClick={props.close}>
        <Heroicons.Outline.ChevronLeftIcon
          className={`w-9 h-6 ${props.color ? `text-${props.color}` : "text-primary-700"}`}
        />
      </span>
    </div>
  );
};
