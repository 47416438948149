import { lazy, Suspense, useMemo } from "react";
import { useTheme } from "../../hooks/useTheme";

const views = {
  App: lazy(() => import("../../App")),
  AuthenticatedView: lazy(() => import("../Authenticated/AuthenticatedView")),
  ReservierungWeb: lazy(() => import("../ReservierungenWeb")),
  ReservierungMobile: lazy(() => import("../ReservierungMobile")),
  ReservierungTablet: lazy(() => import("../ReservierungTablet")),
  Nachrichten: lazy(() => import("../Nachrichten")),
  NachrichtenView: lazy(() => import("../Nachrichten/NachrichtenView")),
  Events: lazy(() => import("../Events")),
  FetchToy: lazy(() => import("../FetchToy/FetchToyView")),
  OrganisationPageView: lazy(() => import("../../pages/Organisation/OrganisationPageView")),
  Protokoll: lazy(() => import("../Protokoll")),
  ReservationCreation: lazy(() => import("../../components/ReservationCreation")),
  ReservationDetail: lazy(() => import("../../components/ReservationDetail")),
  DateTable: lazy(() => import("../../components/DateTable")),
  DateTableDrawer: lazy(() => import("../../components/DateSelectDrawer")),
  ReservierungenView: lazy(() => import("../Reservierungen/ReservierungenView")),
  TischeView: lazy(() => import("../Tische/TischeView")),
  VeranstaltungView: lazy(() => import("../Veranstaltung/VeranstaltungView")),
  VeranstaltungSimpleView: lazy(() => import("../VeranstaltungSimple/VeranstaltungSimpleView")),
  Information: lazy(() => import("../../Informationen/views/main/InformationMainView")),
};

export const ViewLoader = ({
  componentKey,
  ...props
}: {
  props?: any;
  componentKey: keyof typeof views;
  loadingComponent?: (...p: any) => JSX.Element;
}) => {
  const LoadingComponent = useMemo(
    () => (props.loadingComponent ? <props.loadingComponent /> : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.loadingComponent]
  );

  const Props = useMemo(() => (props.props ? props.props : undefined), [props.props]);

  const Component = useMemo(() => views[componentKey], [componentKey]);

  return (
    <Suspense fallback={LoadingComponent ?? <Loading />}>
      <Component {...Props} />
    </Suspense>
  );
};

const Loading = () => {
  const { theme } = useTheme();

  return (
    <div className="flex w-full h-full flex-col justify-center items-center">
      <div className="relative flex w-24 h-24 rounded-full bg-gray-300 shadow-inner p-2 overflow-hidden">
        <div className="absolute flex top-0 left-0 w-full h-full justify-center items-start animate-spin rounded-full shadow-inner overflow-hidden">
          <div className={`h-12 w-12 bg-${theme.backgroundcolor}-500 transform rounded-lg shadow-md`}></div>
        </div>
        <div className="relative flex justify-center items-center text-center leading-none w-full h-full bg-gray-100 rounded-full shadow-inner">
          <span className="text-gray-500 text-xxs uppercase font-semibold tracking-wide">Loading...</span>
        </div>
      </div>
    </div>
  );
};
