import { useCallback, useEffect } from "react";
import { useSettings } from "../clientApi/SettingsProvider";
import { addMessage } from "../components/ToasterHandler/useToasterHandler";

const useDeprecatedUrl = (Settings: ReturnType<typeof useSettings>) => {
  const checkDeprecatedUrl = useCallback(() => {
    try {
      const url = document.location.hostname;

      const isBackend = url.toLocaleLowerCase().includes("backend");
      const isKonzept = url.toLocaleLowerCase().includes("konzept");
      const isDeprecated = isBackend || isKonzept;
      Settings.set("isDepreactedUrl", isDeprecated);
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Settings.set]);

  useEffect(() => {
    checkDeprecatedUrl();
  }, [checkDeprecatedUrl]);

  useEffect(() => {
    if (Settings.isDepreactedUrl) {
      const goToAppsMkGk = () => {
        window.location.replace("https://www.mk-gk.de/apps.html");
      };

      const showMessage = () => {
        return addMessage({
          color: "error",
          icon: "ShieldExclamationIcon",
          title: "Nicht unterstützte URL",
          text: `Sie nutzen aktuell eine URL die nicht länger unterstützt wird\r\n\r\n${window.location.hostname} ist nicht Prod1 oder Prod2\r\n\r\nBitte rufen Sie mk-gk.de/apps.html auf und wählen Sie dort eine Version`,
          delay: 600000,
          onClick: goToAppsMkGk,
        });
      };
      setTimeout(() => {
        showMessage();
      }, 2500);
      const interval = setInterval(() => showMessage(), 1 * 60 * 1000);
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [Settings.isDepreactedUrl]);
  return;
};
export default useDeprecatedUrl;
