import { AIReactCustomEvent } from "@microsoft/applicationinsights-react-js/types/useTrackEvent";
import * as IDB from "idb-keyval";
import * as uuid from "uuid";
import Package from "../../package.json";
import { addMessage } from "../components/ToasterHandler/useToasterHandler";
import Information from "../Informationen/types/Information";
import { Cause } from "../models/Cause";
import { Email } from "../models/Email";
import { EMPTY_GUID } from "../models/General";
import { createFakeReservation, NewReservation, Reservation, walkedInConfirmation } from "../models/Reservation";
import { SummaryTable } from "../models/Summary";
import { Table } from "../models/Table";
import { WebSocketCall } from "../models/WebSocketCall";
import ServerApi from "../serverApi/ServerApi";
import Tools from "../Tools";
import CachedDataProvider from "./CachedDataProvider";
import CommandQueue from "./CommandQueue";
import EventProvider from "./EventProvider";
import { Anfrage } from "./models/Anfrage";
import { Gast } from "./models/Gast";
import { Nachricht } from "./models/Nachricht";
import VeranstaltungSimplified from "./models/VeranstaltungSimplified";
import PersRepo from "./PersistentStorageRepository";
import QueryQueue from "./QueryQueue";
import SettingsProvider, { AppSettings } from "./SettingsProvider";
import Action from "./tracking/Actions";

export let PersistentStorageRepository: ReturnType<typeof PersRepo> | null = null;

const ShouldLog: {
  [key in
    | Action.Variant.Empty
    | Action.Variant.CacheQuery
    | Action.Variant.ServerQuery
    | Action.Variant.Command
    | Action.Variant.ServerCommand]: boolean;
} = {
  [Action.Variant.Empty]: true,
  [Action.Variant.CacheQuery]: true,
  [Action.Variant.ServerQuery]: true,
  [Action.Variant.Command]: true,
  [Action.Variant.ServerCommand]: true,
};

export const Events = {
  MANDANTEN_UPDATED: "MANDANTEN_UPDATED",
  RESERVIERUNGEN_UPDATED: "RESERVIERUNGEN_UPDATED",
  TISCHE_UPDATED: "TISCHE_UPDATED",
  GAESTE_UPDATED: "GAESTE_UPDATED",
  ANFRAGEN_UPDATED: "ANFRAGEN_UPDATED",
  ZUSAMMENFASSUNGEN_UPDATED: "ZUSAMMENFASSUNGEN_UPDATED",
  NACHRICHTEN_UPDATED: "NACHRICHTEN_UPDATED",
  VERANSTALTUNGEN_UPDATED: "VERANSTALTUNGEN_UPDATED",
  INFORMATIONEN_UPDATED: "INFORMATIONEN_UPDATED",
  FOTOS_TAKEN: "FOTOS_TAKEN",
  FOTOS_CANCELED: "FOTOS_CANCELED",
  EINGEHENDER_ANRUF: "EINGEHENDER_ANRUF",
  WEBSOCKET_CONNECTED: "WEBSOCKET_CONNECTED",
  WEBSOCKET_DISCONNECTED: "WEBSOCKET_DISCONNECTED",
};

export const MandantChangeEvents = [
  Events.ANFRAGEN_UPDATED,
  Events.GAESTE_UPDATED,
  Events.NACHRICHTEN_UPDATED,
  Events.RESERVIERUNGEN_UPDATED,
  Events.TISCHE_UPDATED,
  Events.ZUSAMMENFASSUNGEN_UPDATED,
  Events.VERANSTALTUNGEN_UPDATED,
  Events.INFORMATIONEN_UPDATED,
];

export const SAVELOCATIONS = {
  mandanten: "mandanten",
  reservierungen: "reservierungen",
  tische: "tische",
  gaeste: "gaeste",
  anfragen: "anfragen",
  nachrichten: "nachrichten",
  zusammenfassungen: "zusammenfassungen",
  veranstaltungen: "veranstaltungen",
  informationen: "informationen",
};

const generateEvents = (savelocations: typeof SAVELOCATIONS) => {
  const possibleEventTypes = {
    added: "ADDED",
    removed: "REMOVED",
    updated: "UPDATED",
  };

  const events = Object.values(savelocations).reduce((obj, loc) => {
    const location = loc.toLocaleUpperCase();
    const possibleEvents = (Object.keys(possibleEventTypes) as (keyof typeof possibleEventTypes)[]).reduce(
      (obj, key) => {
        const temp = { ...obj, [key]: `${location}_${possibleEventTypes[key]}` };
        return temp;
      },
      {} as { [key in keyof typeof possibleEventTypes]: string }
    );
    const tempobj = { ...obj, [loc]: possibleEvents };
    return tempobj;
  }, {} as { [key in keyof typeof SAVELOCATIONS]: { [key in keyof typeof possibleEventTypes]: string } });

  return events;
};

export type Savelocation = (typeof SAVELOCATIONS)[keyof typeof SAVELOCATIONS];
export const _EVENTS = generateEvents(SAVELOCATIONS);

export const _dispatchEvent = <T>(event: string, detail?: T) => {
  if (detail) {
    const eventToDispatch = new CustomEvent<typeof detail>(event, { detail });
    return window.dispatchEvent(eventToDispatch);
  } else {
    const eventToDispatch = new Event(event);
    return window.dispatchEvent(eventToDispatch);
  }
};

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
type Tracker = AIReactCustomEvent<Action.Base<any>>;
const ClientApi = function ClientApi() {
  let trackEvent: Tracker | null = null;
  let user: string | null = null;

  const logToAppInsights =
    (type: Action.Variant, identifier?: string) =>
    <F extends (...args: any) => any>(cb: F) =>
    async (...data: Parameters<F>) => {
      const url = window?.location?.href;
      try {
        if (!(ShouldLog as any)[type]) return await cb(...data);
        if (trackEvent) {
          let now = new Date();
          trackEvent({
            actionData: data,
            actionName: `${identifier ?? "??"} - ClientApi`,
            type: type,
            progress: Action.Progress.Started,
            triggeredBy: "Code",
            information: {
              timestamp: now,
              timestampString: now.toISOString(),
              isError: false,
              version: Package.version,
              currentUrl: url,
              user: user ?? undefined,
            },
          });
          let resp: Awaited<ReturnType<F>>;
          resp = await cb(...data);
          now = new Date();
          trackEvent({
            actionData: data,
            actionName: `${identifier ?? "??"} - ClientApi`,
            type: type,
            progress: Action.Progress.Ended,
            triggeredBy: "Code",
            information: {
              timestamp: now,
              timestampString: now.toISOString(),
              isError: false,
              version: Package.version,
              currentUrl: url,
              user: user ?? undefined,
            },
          });
          return resp;
        } else throw new Error("AppInsights Tracker is undefined");
      } catch (error) {
        console.error(error);
        const now = new Date();
        if (trackEvent) {
          try {
            trackEvent({
              actionData: data,
              actionName: `${identifier ?? "??"} - ClientApi`,
              type: type,
              progress: Action.Progress.Error,
              triggeredBy: "Code",
              information: {
                timestamp: now,
                timestampString: now.toISOString(),
                isError: true,
                version: Package.version,
                currentUrl: url,
                user: user ?? undefined,
              },
            });
          } catch (error) {
            throw error;
          }
        }
        throw error;
      }
    };

  const logQuery = (identifier: string) => logToAppInsights(Action.Variant.CacheQuery, identifier);
  const logServerQuery = (identifier: string) => logToAppInsights(Action.Variant.ServerQuery, identifier);
  const logCommand = (identifier: string) => logToAppInsights(Action.Variant.Command, identifier);
  const logBackendCall = (identifier: string) => logToAppInsights(Action.Variant.ServerCommand, identifier);
  const logErrorWith = (identifier: string) => logToAppInsights(Action.Variant.Error, identifier);
  const logError = logErrorWith("Error")(addMessage);

  const Queries_Calls = {
    getCachedReservierungen: async (dateOfRes?: Date) => {
      try {
        if (!PersistentStorageRepository) throw new Error("PersistentStorageRepository is undefined");
        const MANDANT = SettingsProvider.get("mandant");
        const RESERVIERUNG_IDENTIFIER = "reserv";

        const storageKeys = await PersistentStorageRepository.keys();
        const keysAsStrings = storageKeys.map((key) => key.toString());

        const allResKeys = keysAsStrings.filter((key) => key.includes(RESERVIERUNG_IDENTIFIER));
        const keysOfMandant = MANDANT ? allResKeys.filter((key) => key.includes(MANDANT)) : [];

        let keys = dateOfRes ? keysOfMandant.filter((c) => c.includes(Tools.dateToIsoLike(dateOfRes))) : keysOfMandant;

        const requests = await Promise.allSettled(keys.map((key) => PersistentStorageRepository!.dangerouslyGet(key)));
        const fullfilledRequests = requests.filter(({ status }) => status === "fulfilled") as PromiseFulfilledResult<
          Reservation[]
        >[];
        const allRes = fullfilledRequests.flatMap(({ value }) => value);
        return allRes;
      } catch (error) {
        throw error;
      }
    },
    getCachedReservierungById: async (id: Reservation["id"], causeId?: Reservation["causeId"], dateOfRes?: Date) => {
      try {
        if (id === EMPTY_GUID) throw new Error("Id is invalid");
        const allRes = await Queries.getCachedReservierungen(dateOfRes);
        return allRes.filter((c) => {
          if (c.id && c.id !== EMPTY_GUID) return c.id === id;
          if (c.causeId && c.causeId !== EMPTY_GUID) return c.causeId === causeId;
          if (c.cause?.id && c.cause.id !== EMPTY_GUID) return c.cause.id === causeId;
          if (c.cause?.reservationId && c.cause?.reservationId !== EMPTY_GUID) return c.cause?.reservationId === id;
          return false;
        });
      } catch (error) {
        throw error;
      }
    },
    getReservierungById: async (id: Reservation["id"], freshCallBack: (res: Reservation | null) => any) => {
      try {
        const stale = await Queries.getCachedReservierungById(id);
        ServerApi.GetSingleReservation(id).then(async (res) => {
          freshCallBack(res);
          if (!PersistentStorageRepository) throw new Error("PersistentStorageRepository is undefined");
          PersistentStorageRepository.add(
            SAVELOCATIONS.reservierungen,
            typeof res.dateOfArrival === "string" ? new Date(res.dateOfArrival) : new Date(),
            res,
            true,
            "id",
            true,
            true
          );
        });
        return stale[0];
      } catch (error) {
        throw error;
      }
    },
    getMandanten: (fromServer?: boolean) => {
      const result = getItemsByDate<string>(SAVELOCATIONS.mandanten, "getMandanten", undefined, fromServer);
      return result;
    },
    getReservierungenByDate: (date: Date, fromServer?: boolean) => {
      console.log(date, fromServer);
      return getItemsByDate<Reservation>(SAVELOCATIONS.reservierungen, "getReservierungenByDate", date, fromServer);
    },
    getTische: (fromServer?: boolean) =>
      getItemsByDate<Table>(SAVELOCATIONS.tische, "getTische", undefined, fromServer),
    getGaesteByDate: (date: Date, fromServer?: boolean) =>
      getItemsByDate<Gast.Client>(SAVELOCATIONS.gaeste, "getGaesteByDate", date, fromServer),
    getAnfragenByDate: (date: Date, fromServer?: boolean) =>
      getItemsByDate<Anfrage.Client>(SAVELOCATIONS.anfragen, "getAnfragenByDate", date, fromServer),
    getZusammenfassungByDate: (date: Date, fromServer?: boolean) => {
      const tempDate = new Date(date);
      tempDate.setDate(1);
      return getItemsByDate<SummaryTable>(
        SAVELOCATIONS.zusammenfassungen,
        "getZusammenfassungByDate",
        tempDate,
        fromServer
      );
    },
    getNachrichtenBeforeDate: <T extends any>(date: Date, fromServer?: boolean) =>
      getItemsByDate<Nachricht.Client<T | any>>(
        SAVELOCATIONS.nachrichten,
        "getNachrichtenBeforeDate",
        date,
        fromServer
      ),
    getCallMessage: async (cause: Cause | Nachricht.Client<any>) => {
      try {
        const soundFile = await ServerApi.getMissedCallMessage(cause);
        return soundFile;
      } catch (error) {
        throw error;
      }
    },
    getCommandQueueTempIds: async () => {
      try {
        const resp = await CommandQueue.getCommandTempIds();
        return resp || [];
      } catch (error) {
        throw error;
      }
    },
    getCommandQueueIds: async () => {
      try {
        const ids = CommandQueue.getIds();
        return ids || [];
      } catch (error) {
        throw error;
      }
    },
    getVeranstaltungen: (date: Date, fromServer?: boolean) => {
      const tempDate = new Date(date);
      tempDate.setDate(1);
      return getItemsByDate<VeranstaltungSimplified.Client>(
        SAVELOCATIONS.veranstaltungen,
        "getVeranstaltungen",
        tempDate,
        fromServer
      );
    },
    getInformationen: (date: Date, fromServer?: boolean) => {
      const tempDate = new Date(date);
      tempDate.setDate(1);
      return getItemsByDate<Information>(SAVELOCATIONS.informationen, "getInformationen", tempDate, fromServer);
    },
  };

  const Queries: { [key in keyof typeof Queries_Calls]: (typeof Queries_Calls)[key] } = {
    getMandanten: logQuery("getMandanten")(Queries_Calls.getMandanten),
    getCachedReservierungen: logQuery("getCachedReservierungen")(Queries_Calls.getCachedReservierungen),
    getCachedReservierungById: logQuery("getCachedReservierungById")(Queries_Calls.getCachedReservierungById),
    getAnfragenByDate: logQuery("getAnfragenByDate")(Queries_Calls.getAnfragenByDate),
    getCallMessage: logQuery("getCallMessage")(Queries_Calls.getCallMessage),
    getCommandQueueIds: logQuery("getCommandQueueIds")(Queries_Calls.getCommandQueueIds),
    getCommandQueueTempIds: logQuery("getCommandQueueTempIds")(Queries_Calls.getCommandQueueTempIds),
    getGaesteByDate: logQuery("getGaesteByDate")(Queries_Calls.getGaesteByDate),
    getNachrichtenBeforeDate: logQuery("getNachrichtenBeforeDate")(Queries_Calls.getNachrichtenBeforeDate),
    getReservierungById: logQuery("getReservierungById")(Queries_Calls.getReservierungById),
    getReservierungenByDate: logQuery("getReservierungenByDate")(Queries_Calls.getReservierungenByDate),
    getTische: logQuery("getTische")(Queries_Calls.getTische),
    getZusammenfassungByDate: logQuery("getZusammenfassungByDate")(Queries_Calls.getZusammenfassungByDate),
    getVeranstaltungen: logQuery("getVeranstaltungen")(Queries_Calls.getVeranstaltungen),
    getInformationen: logQuery("getInformationen")(Queries_Calls.getInformationen),
  };

  const ServerQueries_Calls: { [key in keyof typeof Queries]: (typeof Queries)[key] } = {
    getMandanten: () => getItemsFromServer<string>(SAVELOCATIONS.mandanten, undefined),
    getCachedReservierungen: () => new Promise((r) => r([])),
    getCachedReservierungById: () => new Promise((r) => r([])),
    getReservierungById: () => new Promise((r) => r(null as any)),
    getReservierungenByDate: (date: Date) => getItemsFromServer<Reservation>(SAVELOCATIONS.reservierungen, date),
    getTische: () => getItemsFromServer<Table>(SAVELOCATIONS.tische, undefined),
    getAnfragenByDate: (date: Date) => getItemsFromServer<Anfrage.Client>(SAVELOCATIONS.anfragen, date),
    getGaesteByDate: (date: Date) => getItemsFromServer<Gast.Client>(SAVELOCATIONS.gaeste, date),
    getZusammenfassungByDate: (date: Date) => getItemsFromServer<SummaryTable>(SAVELOCATIONS.zusammenfassungen, date),
    getNachrichtenBeforeDate: (date: Date) =>
      getItemsFromServer<Nachricht.Client<any>>(SAVELOCATIONS.nachrichten, date),
    getCallMessage: Queries.getCallMessage,
    getCommandQueueIds: Queries.getCommandQueueIds,
    getCommandQueueTempIds: Queries.getCommandQueueTempIds,
    getVeranstaltungen: (date: Date) =>
      getItemsFromServer<VeranstaltungSimplified.Client>(SAVELOCATIONS.veranstaltungen, date),
    getInformationen: (date: Date) => getItemsFromServer<Information>(SAVELOCATIONS.informationen, date),
  };

  const ServerQueries: { [key in keyof typeof ServerQueries_Calls]: (typeof ServerQueries_Calls)[key] } = {
    getMandanten: logServerQuery("getMandanten")(() => getItemsFromServer<string>(SAVELOCATIONS.mandanten, undefined)),
    getCachedReservierungen: logServerQuery("getCachedReservierungen")(() => new Promise((r) => r([]))),
    getCachedReservierungById: logServerQuery("getCachedReservierungById")(() => new Promise((r) => r([]))),
    getReservierungById: logServerQuery("getReservierungById")(() => new Promise((r) => r(null as any))),
    getReservierungenByDate: logServerQuery("getReservierungenByDate")((date: Date) =>
      getItemsFromServer<Reservation>(SAVELOCATIONS.reservierungen, date)
    ),
    getTische: logServerQuery("getTische")(() => getItemsFromServer<Table>(SAVELOCATIONS.tische, undefined)),
    getAnfragenByDate: logServerQuery("getAnfragenByDate")((date: Date) =>
      getItemsFromServer<Anfrage.Client>(SAVELOCATIONS.anfragen, date)
    ),
    getGaesteByDate: logServerQuery("getGaesteByDate")((date: Date) =>
      getItemsFromServer<Gast.Client>(SAVELOCATIONS.gaeste, date)
    ),
    getZusammenfassungByDate: logServerQuery("getZusammenfassungByDate")((date: Date) =>
      getItemsFromServer<SummaryTable>(SAVELOCATIONS.zusammenfassungen, date)
    ),
    getNachrichtenBeforeDate: logServerQuery("getNachrichtenBeforeDate")((date: Date) =>
      getItemsFromServer<Nachricht.Client<any>>(SAVELOCATIONS.nachrichten, date)
    ),
    getCallMessage: logServerQuery("getCallMessage")(Queries.getCallMessage),
    getCommandQueueIds: logServerQuery("getCommandQueueIds")(Queries.getCommandQueueIds),
    getCommandQueueTempIds: logServerQuery("getCommandQueueTempIds")(Queries.getCommandQueueTempIds),
    getVeranstaltungen: logServerQuery("getVeranstaltungen")((date: Date) =>
      getItemsFromServer<VeranstaltungSimplified.Client>(SAVELOCATIONS.veranstaltungen, date)
    ),
    getInformationen: logServerQuery("getInformationen")((date: Date) =>
      getItemsFromServer<Information>(SAVELOCATIONS.informationen, date)
    ),
  };

  const BackendCalls_Calls = {
    putNewReservierung: async (
      reservierung: NewReservation,
      shouldConfirm?: boolean,
      email?: Email,
      createEmail?: boolean,
      cause?: Cause
    ) => {
      try {
        const resp: any = await ServerApi.addReservierungToDate(reservierung, shouldConfirm, email, createEmail);
        if (cause) {
          await Commands.processCause({ ...cause, reservation: resp, reservationId: resp.id }, true);
        }
        return resp;
      } catch (error) {
        throw error;
      }
    },
    walkInWithoutReservation: async (reservierung: NewReservation, tischIds: Table["id"][]) => {
      try {
        const resp = await ServerApi.WalkInWithoutReservation(reservierung, tischIds);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    walkInWithReservierung: async (reservierung: Reservation, walkedInConfirmation: walkedInConfirmation) => {
      try {
        const resp = await ServerApi.WalkInWithReservierung(reservierung, walkedInConfirmation);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    processCause: async (cause: Cause, requestReservation: boolean) => {
      try {
        const resp = await ServerApi.ProcessCause(cause, requestReservation);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    processNachricht: async (nachricht: Nachricht.Client<any>, requestReservation: boolean) => {
      try {
        const resp = await ServerApi.ProcessNachricht(nachricht, requestReservation);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    editNachrichtNote: async (nachricht: Nachricht.Client<any>, note: string) => {
      try {
        const resp = await ServerApi.EditNachrichtNote(nachricht, note);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    assignTischeToReservierung: async (reservierung: Reservation, tische: Table[]) => {
      try {
        const resp = await ServerApi.AssignTischeToReservierung(reservierung, tische);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    unassignTischeToReservierung: async (reservierung: Reservation) => {
      try {
        const resp = await ServerApi.UnassignTischeToReservierung(reservierung);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    cancelReservierung: async (reservierung: Reservation, reason: string, reasonPhrase: string) => {
      try {
        const resp = await ServerApi.CancelReservierung(reservierung, reason, reasonPhrase);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    confirmReservierung: async (reservierung: Reservation, createEmail: boolean, email?: Email) => {
      try {
        const resp = await ServerApi.ConfirmReservierung(reservierung, createEmail, email);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    leaveReservierung: async (reservierung: Reservation) => {
      try {
        const resp = await ServerApi.LeaveReservierung(reservierung);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    uploadTisch: (tisch: Table, eventId?: string, dateOverwrite?: Date) =>
      upload(tisch, "tische", "addTischToDate", eventId, dateOverwrite),
    uploadGast: (gast: Gast.Client, eventId?: string, dateOverwrite?: Date) =>
      upload(gast, "gaeste", "addGastToDate", eventId, dateOverwrite),
    uploadAnfrage: (anfrage: Anfrage.Client, eventId?: string, dateOverwrite?: Date) =>
      upload(anfrage, "anfragen", "addAnfrageToDate", eventId, dateOverwrite),
    addVeranstaltung: async (veranstaltung: Pick<VeranstaltungSimplified.Client, "titel" | "veranstaltungDatum">) => {
      try {
        const resp = await ServerApi.addVeranstaltung(veranstaltung);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    removeVeranstaltung: async (veranstaltung: VeranstaltungSimplified.Client) => {
      try {
        const { id, veranstaltungDatum } = veranstaltung;
        const firstOfDate = new Date(veranstaltungDatum);
        firstOfDate.setDate(1);
        const resp = await ServerApi.removeVeranstaltung(veranstaltung);
        await PersistentStorageRepository!.removeEntry<VeranstaltungSimplified.Client>(
          SAVELOCATIONS.veranstaltungen,
          (i) => i.id !== id,
          firstOfDate
        );
        return resp;
      } catch (error) {
        throw error;
      }
    },
    addInformation: async (information: Information) => {
      try {
        const resp = await ServerApi.addInformation(information);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    updateInformation: async (information: Information) => {
      try {
        const resp = await ServerApi.updateInformation(information);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    removeInformation: async (information: Information) => {
      try {
        const { id, bezugsDatum } = information;
        const firstOfDate = new Date(bezugsDatum.obj);
        firstOfDate.setDate(1);
        const resp = await ServerApi.removeInformation(information);
        await PersistentStorageRepository!.removeEntry<Information>(
          SAVELOCATIONS.informationen,
          (i) => i.id !== id,
          firstOfDate
        );
        return resp;
      } catch (error) {
        throw error;
      }
    },
  };

  const BackendCalls: { [key in keyof typeof BackendCalls_Calls]: (typeof BackendCalls_Calls)[key] } = {
    putNewReservierung: logBackendCall("putNewReservierung")(BackendCalls_Calls.putNewReservierung),
    walkInWithoutReservation: logBackendCall("walkInWithoutReservation")(BackendCalls_Calls.walkInWithoutReservation),
    walkInWithReservierung: logBackendCall("walkInWithReservierung")(BackendCalls_Calls.walkInWithReservierung),
    processCause: logBackendCall("processCause")(BackendCalls_Calls.processCause),
    processNachricht: logBackendCall("processNachricht")(BackendCalls_Calls.processNachricht),
    editNachrichtNote: logBackendCall("editNachrichtNote")(BackendCalls_Calls.editNachrichtNote),
    assignTischeToReservierung: logBackendCall("assignTischeToReservierung")(
      BackendCalls_Calls.assignTischeToReservierung
    ),
    unassignTischeToReservierung: logBackendCall("unassignTischeToReservierung")(
      BackendCalls_Calls.unassignTischeToReservierung
    ),
    cancelReservierung: logBackendCall("cancelReservierung")(BackendCalls_Calls.cancelReservierung),
    confirmReservierung: logBackendCall("confirmReservierung")(BackendCalls_Calls.confirmReservierung),
    leaveReservierung: logBackendCall("leaveReservierung")(BackendCalls_Calls.leaveReservierung),
    uploadTisch: logBackendCall("uploadTisch")(BackendCalls_Calls.uploadTisch),
    uploadGast: logBackendCall("uploadGast")(BackendCalls_Calls.uploadGast),
    uploadAnfrage: logBackendCall("uploadAnfrage")(BackendCalls_Calls.uploadAnfrage),
    addVeranstaltung: logBackendCall("addVeranstaltung")(BackendCalls_Calls.addVeranstaltung),
    removeVeranstaltung: logBackendCall("removeVeranstaltung")(BackendCalls_Calls.removeVeranstaltung),
    addInformation: logBackendCall("addInformation")(BackendCalls_Calls.addInformation),
    updateInformation: logBackendCall("updateInformation")(BackendCalls_Calls.updateInformation),
    removeInformation: logBackendCall("removeInformation")(BackendCalls_Calls.removeInformation),
  };

  const Commands_Calls = {
    clearReservierungen: async (date?: Date) => {
      try {
        if (!PersistentStorageRepository) throw new Error("PersistentStorageRepository is undefined");
        return await PersistentStorageRepository.write(SAVELOCATIONS.reservierungen, date, []);
      } catch (error) {
        throw error;
      }
    },
    // uploadReservierung: (reservierung: Reservation, eventId?: string, dateOverwrite?: Date) =>
    //   upload(reservierung, "reservierungen", "addReservierungToDate", eventId, dateOverwrite),

    addReservierung: async (
      newReservation: NewReservation,
      shouldConfirm?: boolean,
      email?: Email,
      createEmail?: boolean,
      cause?: Cause
    ) => {
      try {
        const dummyReservation = createFakeReservation(newReservation, uuid.v4(), shouldConfirm ? 1 : 0);
        const date = new Date(dummyReservation.dateOfArrival);
        await addItemToDate(dummyReservation, "reservierungen", undefined, date);
        await CommandQueue.addToCommandQueue({
          command: "putNewReservierung",
          props: [newReservation, shouldConfirm, email, createEmail, cause],
          id: dummyReservation.id + "",
          isCreation: true,
          reference: SAVELOCATIONS.reservierungen,
          date: date,
        });
      } catch (error) {
        throw error;
      }
    },
    walkInWithoutReservation: async (reservierung: NewReservation, tische: Table[] | null) => {
      try {
        const dummyReservation = createFakeReservation(reservierung, uuid.v4(), 4);
        const date = new Date(dummyReservation.dateOfArrival);
        await addItemToDate({ ...dummyReservation, tables: tische }, "reservierungen", undefined, date);
        await CommandQueue.addToCommandQueue({
          command: "walkInWithoutReservation",
          props: [reservierung, (tische || []).map((t) => t.id)],
          id: dummyReservation.id,
          isCreation: true,
          reference: SAVELOCATIONS.reservierungen,
          date: date,
        });
      } catch (error) {
        throw error;
      }
    },
    walkInWithReservierung: async (
      reservierung: Reservation,
      walkedInConfirmation: walkedInConfirmation,
      tables: Table[] | undefined
    ) => {
      try {
        const date = new Date(walkedInConfirmation.arrivalDateTime);
        await addItemToDate(
          {
            ...reservierung,
            tables,
            guestAmount: walkedInConfirmation.guestAmount,
            state: 3,
          },
          "reservierungen",
          "id",
          date
        );
        await CommandQueue.addToCommandQueue({
          command: "walkInWithReservierung",
          props: [reservierung, walkedInConfirmation],
          id: reservierung.id,
          isCreation: false,
          reference: SAVELOCATIONS.reservierungen,
          date: date,
        });
      } catch (error) {
        throw error;
      }
    },
    processCause: async (cause: Cause, requestReservation: boolean) => {
      try {
        const date = cause.reservation?.dateOfArrival ? new Date(cause.reservation.dateOfArrival) : undefined;
        const id = cause.reservation?.id || cause.id;
        await addItemToDate(cause, "nachrichten", "id", date);
        await CommandQueue.addToCommandQueue({
          command: "processCause",
          id: id,
          isCreation: false,
          props: [cause, requestReservation],
          reference: SAVELOCATIONS.nachrichten,
          date: date,
        });
      } catch (error) {
        throw error;
      }
    },
    processNachricht: async (nachricht: Nachricht.Client<any>, requestReservation: boolean, date?: Date) => {
      try {
        // const date = nachricht.reservation?.dateOfArrival ? new Date(nachricht.reservation.dateOfArrival) : undefined;
        // const id = nachricht.reservation?.id || nachricht.id;

        await addItemToDate(
          { ...nachricht, state: requestReservation ? 1 : 2 },
          "nachrichten",
          "id",
          date ?? new Date()
        );
        await CommandQueue.addToCommandQueue({
          command: "processNachricht",
          id: nachricht.id,
          isCreation: false,
          props: [nachricht, requestReservation],
          reference: SAVELOCATIONS.nachrichten,
          date: new Date(),
        });
      } catch (error) {
        throw error;
      }
    },
    editNachrichtNote: async (nachricht: Nachricht.Client<any>, note: string, date?: Date) => {
      try {
        await addItemToDate({ ...nachricht, state: 4 }, "nachrichten", "id", date ?? new Date());
        await CommandQueue.addToCommandQueue({
          command: "editNachrichtNote",
          id: nachricht.id,
          isCreation: false,
          props: [nachricht, note],
          reference: SAVELOCATIONS.nachrichten,
          date: new Date(),
        });
      } catch (error) {
        throw error;
      }
    },
    assignTischeToReservierung: async (reservierung: Reservation, tische: Table[]) => {
      try {
        const date = new Date(reservierung.dateOfArrival);
        await addItemToDate({ ...reservierung, state: 2, tables: tische }, "reservierungen", "id", date);
        await CommandQueue.addToCommandQueue({
          command: "assignTischeToReservierung",
          id: reservierung.id,
          isCreation: false,
          props: [reservierung, tische],
          reference: SAVELOCATIONS.reservierungen,
          date,
        });
      } catch (error) {
        throw error;
      }
    },
    unassignTischeToReservierung: async (reservierung: Reservation) => {
      try {
        const date = new Date(reservierung.dateOfArrival);
        await addItemToDate({ ...reservierung, state: 1, tables: [] }, "reservierungen", "id", date);
        await CommandQueue.addToCommandQueue({
          command: "unassignTischeToReservierung",
          id: reservierung.id,
          isCreation: false,
          props: [reservierung],
          reference: SAVELOCATIONS.reservierungen,
          date,
        });
      } catch (error) {
        throw error;
      }
    },
    cancelReservierung: async (reservierung: Reservation, reason: string, reasonPhrase: string) => {
      try {
        const date = new Date(reservierung.dateOfArrival);
        await addItemToDate({ ...reservierung, state: 5 }, "reservierungen", "id", date);
        await CommandQueue.addToCommandQueue({
          command: "cancelReservierung",
          id: reservierung.id,
          isCreation: false,
          props: [reservierung, reason, reasonPhrase],
          reference: SAVELOCATIONS.reservierungen,
          date,
        });
      } catch (error) {
        throw error;
      }
    },
    confirmReservierung: async (reservierung: Reservation, createEmail: boolean, email?: Email) => {
      try {
        const date = new Date(reservierung.dateOfArrival);
        await addItemToDate({ ...reservierung, state: 1 }, "reservierungen", "id", date);
        await CommandQueue.addToCommandQueue({
          command: "confirmReservierung",
          id: reservierung.id,
          isCreation: false,
          props: [reservierung, createEmail, email],
          reference: SAVELOCATIONS.reservierungen,
          date,
        });
      } catch (error) {
        throw error;
      }
    },
    leaveReservierung: async (
      reservierung: Reservation,
      leaveDateTime: string,
      leaveNotice: string,
      satisfaction: number
    ) => {
      try {
        const date = new Date(reservierung.dateOfArrival);
        await addItemToDate(
          { ...reservierung, state: 6, leaveDateTime, leaveNotice, satisfaction },
          "reservierungen",
          "id",
          date
        );
        await CommandQueue.addToCommandQueue({
          command: "leaveReservierung",
          id: reservierung.id,
          isCreation: false,
          props: [{ ...reservierung, leaveDateTime, leaveNotice, satisfaction }],
          reference: SAVELOCATIONS.reservierungen,
          date,
        });
      } catch (error) {
        throw error;
      }
    },
    addTisch: async (tisch: Table, date?: Date, toServer?: boolean) => {
      try {
        const { eventId } = await addItemToDate(tisch, "tische", undefined, date);
        if (toServer) {
          await BackendCalls.uploadTisch(tisch, eventId, date);
        }
      } catch (error) {
        throw error;
      }
    },
    addGast: async (gast: Gast.Client, date?: Date, toServer?: boolean) => {
      try {
        const { eventId } = await addItemToDate(gast, "gaeste", undefined, date);
        if (toServer) {
          await BackendCalls.uploadGast(gast, eventId, date);
        }
      } catch (error) {
        throw error;
      }
    },
    addAnfrage: async (anfrage: Anfrage.Client, date?: Date, toServer?: boolean) => {
      try {
        const { eventId } = await addItemToDate(anfrage, "gaeste", undefined, date);
        if (toServer) {
          await BackendCalls.uploadAnfrage(anfrage, eventId, date);
        }
      } catch (error) {
        throw error;
      }
    },
    takePhoto: () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.setAttribute("capture", "camera");

      let enteredPhotoHandler: { has: boolean; timeout: ReturnType<typeof setTimeout> | null } = {
        has: false,
        timeout: null,
      };

      const handlePhoto = (event: Event) => {
        enteredPhotoHandler = { ...enteredPhotoHandler, has: true };
        const { files } = (event as any).target;
        if (files) {
          _dispatchEvent<File[]>(Events.FOTOS_TAKEN, files);
        } else {
          console.error("No Files selected");
        }
        input.parentNode?.removeChild(input);
      };

      const handleWindowRegainedFocus = (ev: FocusEvent) => {
        if (enteredPhotoHandler.timeout) clearTimeout(enteredPhotoHandler.timeout);
        enteredPhotoHandler = { ...enteredPhotoHandler, timeout: setTimeout(testPhotoHandler, 200) };
      };

      const testPhotoHandler = () => {
        if (enteredPhotoHandler.has) {
          enteredPhotoHandler = { ...enteredPhotoHandler, has: false };
          window.removeEventListener("focus", handleWindowRegainedFocus);
          return;
        } else {
          enteredPhotoHandler = { ...enteredPhotoHandler, has: false };
          window.removeEventListener("focus", handleWindowRegainedFocus);
          _dispatchEvent(Events.FOTOS_CANCELED);
          return;
        }
      };

      input.classList.add("cursor-pointer");

      input.addEventListener("change", handlePhoto, { passive: true });
      window.addEventListener("focus", handleWindowRegainedFocus, { passive: true });

      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        composed: true,
      });

      document.body.appendChild(input);
      input.dispatchEvent(clickEvent);

      // input.click();
    },
  };

  const Commands: { [key in keyof typeof Commands_Calls]: (typeof Commands_Calls)[key] } = {
    clearReservierungen: logCommand("clearReservierungen")(Commands_Calls.clearReservierungen),
    addReservierung: logCommand("addReservierung")(Commands_Calls.addReservierung),
    walkInWithoutReservation: logCommand("walkInWithoutReservation")(Commands_Calls.walkInWithoutReservation),
    walkInWithReservierung: logCommand("walkInWithReservierung")(Commands_Calls.walkInWithReservierung),
    processCause: logCommand("processCause")(Commands_Calls.processCause),
    processNachricht: logCommand("processNachricht")(Commands_Calls.processNachricht),
    editNachrichtNote: logCommand("editNachrichtNote")(Commands_Calls.editNachrichtNote),
    assignTischeToReservierung: logCommand("assignTischeToReservierung")(Commands_Calls.assignTischeToReservierung),
    unassignTischeToReservierung: logCommand("unassignTischeToReservierung")(
      Commands_Calls.unassignTischeToReservierung
    ),
    cancelReservierung: logCommand("cancelReservierung")(Commands_Calls.cancelReservierung),
    confirmReservierung: logCommand("confirmReservierung")(Commands_Calls.confirmReservierung),
    leaveReservierung: logCommand("leaveReservierung")(Commands_Calls.leaveReservierung),
    addTisch: logCommand("addTisch")(Commands_Calls.addTisch),
    addGast: logCommand("addGast")(Commands_Calls.addGast),
    addAnfrage: logCommand("addAnfrage")(Commands_Calls.addAnfrage),
    takePhoto: logCommand("takePhoto")(Commands_Calls.takePhoto),
  };

  const logWithEvent = (text: string, styling?: string) => {
    console.log(text, styling);
    const event = new CustomEvent<string>("clientApiLog", { detail: text.startsWith("%c") ? text.slice(2) : text });
    window.dispatchEvent(event);
  };

  function handleServiceWorkerResponse(event: CustomEvent) {
    try {
      const { detail } = event;
      const { apiKey, params } = detail;
      getItemsFromServer(apiKey, params, true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  let isInitialising = false;

  const initialise = async (
    tracker: typeof trackEvent,
    userName: typeof user,
    store: IDB.UseStore | null,
    cb: () => any
  ) => {
    try {
      if (isInitialising) throw new Error("Already in Progress");
      isInitialising = true;
      const today = new Date();
      logWithEvent(
        `%cClientApi initialising - ${today.toLocaleDateString()}`,
        "background-color:green; padding:8px 24px; font-weight:600; color:white"
      );
      logWithEvent(`%cToday is ${today.toLocaleDateString("de-de")}`, "padding:3px 24px; font-weight:600;");
      logWithEvent(`%cConnecting to AppInsights`, "padding:3px 24px; color:yellow");
      trackEvent = tracker;
      logWithEvent(`%cConnected to AppInsights`, "padding:3px 24px; font-weight:600; color:green");
      logWithEvent(`%cConnecting to PersistentStorage`, "padding:3px 24px; color:yellow");
      PersistentStorageRepository = PersRepo(store ?? undefined, () =>
        logWithEvent(`%cCallback from Pers. Storage: Connected`, "padding:3px 24px; font-weight:600; color:green")
      );
      logWithEvent(`%cConnected to PersistentStorage`, "padding:3px 24px; font-weight:600; color:green");
      logWithEvent(`%cConnecting to CachedDataProvider`, "padding:3px 24px; color:yellow");
      CachedDataProvider.initialise(today);
      logWithEvent(`%cConnected to CachedDataProvider`, "padding:3px 24px; font-weight:600; color:green");
      user = userName;
      logWithEvent(`%cClearing Data in localstorage older than 7 and 14 days`, "padding:3px 24px; color:yellow");
      _clearLocalstorage(today, 7, 14);

      logWithEvent(`%cClearing Causes older than 3 days`, "padding:3px 24px; color:yellow");
      _clearCauses(today, 3);
      logWithEvent(`%cCleared Causes older than 3 days`, "padding:3px 24px; color:green; font-weight:600;");

      try {
        logWithEvent(`%cClearing Reservierungen older than 3 days`, "padding:3px 24px; color:yellow");
        await _clearReservierungen(3);
        logWithEvent(`%cCleared Reservierungen older than 3 days`, "padding:3px 24px; color:green; font-weight:600;");
      } catch (error) {
        logWithEvent(
          `%cFailed to Clear Reservierungen older than 3 days`,
          "padding:3px 24px; color:red; font-weight:600;"
        );
      }

      try {
        logWithEvent(`%cClearing Nachrichten older than 3 days`, "padding:3px 24px; color:yellow");
        await _clearNachrichten(3);
        logWithEvent(`%cCleared Nachrichten older than 3 days`, "padding:3px 24px; color:green; font-weight:600;");
      } catch (error) {
        logWithEvent(
          `%cFailed to clear Nachrichten older than 3 days`,
          "padding:3px 24px; color:red; font-weight:600;"
        );
      }

      logWithEvent(
        `%cAdding Eventlistener for Settings, CachedData, Eventprovider and ANRUF`,
        "padding:3px 24px; color:yellow"
      );
      window.addEventListener(SettingsProvider.Events.SETTINGS_UPDATED, _handleSettingsChange as any);
      window.addEventListener(CachedDataProvider!.Events.CACHE_UPDATED, _handleEvent as any);
      window.addEventListener(EventProvider.Events.EVENTS_VERSION, _handleEventVersionChange as any);
      window.addEventListener("ANRUF", _handleCall as any);
      window.addEventListener("RESPONSE", handleServiceWorkerResponse as any);
      logWithEvent(
        `%cAdded Eventlistener for Settings, CachedData, Eventprovider, ANRUF and RESPONSE`,
        "padding:3px 24px; color:green; font-weight:600;"
      );

      logWithEvent(`%cInitialising Queues`, "padding:3px 24px; color:yellow");
      await CommandQueue.initialise();
      logWithEvent(`%cInitialised Queues`, "padding:3px 24px; color:green; font-weight:600;");

      if (cb) cb();

      // logWithEvent(`%cInitialising WebSocket for calls`, "padding:3px 24px; color:yellow");
      // await listenToWebsocketCalls();
      // logWithEvent(`%cInitialised WebSocket for calls`, "padding:3px 24px; color:green; font-weight:600;");

      logWithEvent(`%cClientApi Initialised`, "padding:3px 24px; color:green; font-weight:600;");
    } catch (error: any) {
      logWithEvent(
        `%cERROR - ClientApi - ${"message" in error ? error.message : error}`,
        "padding:3px 24px; color:black; background-color: red; background-opacity: 0.8; font-weight:600;"
      );
      throw error;
    }
  };

  const handleWebSocketMessage = async (msg: MessageEvent) => {
    try {
      const parsed: WebSocketCall = JSON.parse(msg.data);
      console.error("handleWebSocketMessage", parsed);
      if (parsed && parsed.causeId && !(parsed as any).isDebug) {
        const date = new Date();
        const tempNachricht: Nachricht.Client<any> = {
          id: parsed.causeId,
          from: parsed.from,
          timestamp: date,
          body: null,
          modifiedBy: "Eingehender Anruf",
          note: "",
          type: "Call",
          subject: "",
          state: 0,
          reservationId: EMPTY_GUID,
          reservation: null,
        };
        _dispatchEvent(Events.EINGEHENDER_ANRUF, tempNachricht);
        await addItemToDate(tempNachricht, "nachrichten", "id", date);
      }
    } catch (error) {
      throw error;
    }
  };

  const listenToWebsocketCalls = async (pTimeout?: number) => {
    try {
      const timeout = pTimeout || 5000;

      const url = window.location.host.includes("localhost")
        ? `wss://localhost:44315/websocket/calls`
        : window.location.host.includes("192.168.")
        ? "ws://192.168.178.28:45456"
        : `wss://${window.location.host}/websocket/calls`;

      const NEW_WEBSOCKET = new WebSocket(url);
      NEW_WEBSOCKET.onopen = () => {
        _dispatchEvent(Events.WEBSOCKET_CONNECTED);
      };
      NEW_WEBSOCKET.addEventListener("close", (e) => {
        if (e.code === 1000) {
          return;
        } else {
          _dispatchEvent(Events.WEBSOCKET_DISCONNECTED);
          NEW_WEBSOCKET.close();
          if (!pTimeout === undefined || (pTimeout && pTimeout < 10000)) {
            setTimeout(() => listenToWebsocketCalls(timeout + 1000), timeout);
          }
        }
      });
      NEW_WEBSOCKET.addEventListener("error", (ev) => {
        try {
          const val = { ...ev };
          return NEW_WEBSOCKET.close();
        } catch (error) {
          console.error(error);
        }
      });
      NEW_WEBSOCKET.addEventListener("message", handleWebSocketMessage);
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const _clearIDBKeyByDays = (key: string) => async (beforeDaysAgo: number) => {
    try {
      const MANDANT = SettingsProvider.get("mandant");
      if (!PersistentStorageRepository) throw new Error("PersistentStorageRepository is undefined");
      const allKeys = (await PersistentStorageRepository.keys()).map((c) => c.toString());
      const mandantKeys = allKeys.filter((c) => c.includes(MANDANT));
      const filteredBySavelocationKeys = mandantKeys.filter((c) => c.includes(key));

      if (!filteredBySavelocationKeys.length) {
        return;
      }

      const today = new Date();
      const deadline = new Date(today);
      deadline.setDate(today.getDate() - beforeDaysAgo);
      deadline.setHours(0);
      deadline.setMinutes(0);
      deadline.setSeconds(0, 1);

      const deadlineString = Tools.dateToIsoLike(deadline);

      const keysToRemove = filteredBySavelocationKeys.filter((c) => {
        const splitResult = c.split("_");
        if (splitResult.length === 3) {
          const [mandant, dateString, key] = splitResult;
          if (dateString === deadlineString) return false;
          else {
            const tempDate = new Date(dateString);
            return deadline >= tempDate;
          }
        } else {
          return false;
        }
      });

      const removal = await Promise.allSettled(
        keysToRemove.map((key) => PersistentStorageRepository!.dangerouslyRemove(key))
      );

      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const _clearNachrichten = _clearIDBKeyByDays(SAVELOCATIONS.nachrichten);
  const _clearReservierungen = _clearIDBKeyByDays(SAVELOCATIONS.reservierungen);

  const _clearLocalstorage = (date: Date, daysAgo: number, daysFurther: number) => {
    const today = new Date(date);
    today.setHours(12);
    const keys = Object.keys(localStorage).filter((c) => !c.includes("b2c") && !c.includes("telemetry"));
    const daysBefore = Array(daysAgo)
      .fill(null)
      .map((_, idx) => {
        const temp = new Date(today);
        temp.setDate(today.getDate() - (idx + 1));
        return temp.toISOString().slice(0, 10);
      });

    const daysAfter = Array(daysFurther)
      .fill(null)
      .map((_, idx) => {
        const temp = new Date(today);
        temp.setDate(today.getDate() + (idx + 1));
        return temp.toISOString().slice(0, 10);
      });

    const yearMonthBefore = daysBefore[0]?.slice(0, 7);
    const yearMonthCurrent = today.toISOString().slice(0, 7);
    const yearMonthAfter = daysAfter[0]?.slice(0, 7);

    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const simpleDate = `_${currentYear}_${currentMonth}`;

    const todayIso = today.toISOString().slice(0, 10);

    const days = [...daysBefore, todayIso, ...daysAfter];

    const keeperKeys = [
      ...days,
      yearMonthCurrent + "_",
      yearMonthAfter + "_",
      yearMonthBefore + "_",
      "settings",
      "queue",
      "calllist",
      "adal",
      "theme",
      "@",
      "account",
      "b2c",
      "step_count",
      "api_token_tries",
      "loginHint",
      "getTables",
      simpleDate,
    ].map((c) => c.toLocaleLowerCase());

    console.log({ keeperKeys });

    const keysToDelete = keys.filter((key) => !keeperKeys.some((d) => key.toLocaleLowerCase().includes(d)));

    console.log({ keysToDelete });

    if (keysToDelete.length > 0) {
      let key = undefined;
      for (key of keysToDelete) {
        try {
          localStorage.removeItem(key);
        } catch (error) {
          console.error(error, key);
        }
      }
    }
  };

  const _clearCauses = (date: Date, daysAgo: number) => {
    const today = new Date(date);
    today.setHours(12);
    const keys = Object.keys(localStorage);
    const causeKeys = keys.filter((key) => key.includes("getCausesForDate"));

    const todayIso = today.toISOString().slice(0, 10);

    const days = [
      todayIso,
      ...Array(daysAgo)
        .fill(null)
        .map((_, idx) => {
          const dateDaysAgo = new Date(today);
          dateDaysAgo.setDate(today.getDate() - (idx + 1));
          return dateDaysAgo.toISOString().slice(0, 10);
        }),
    ];

    const keysToDelete = causeKeys.filter((k) => !days.some((day) => k.includes(day)));

    if (keysToDelete.length > 0) {
      let key = undefined;
      for (key of keysToDelete) {
        try {
          localStorage.removeItem(key);
        } catch (error) {
          console.error(error, key);
        }
      }
    }
  };

  const _handleCall = (ev: CustomEvent<WebSocketCall>) => {
    try {
      console.error("_handleCall", ev.detail);
      if (ev && "detail" in ev) {
        const { detail } = ev;
        if (detail) {
          const { from, causeId } = detail;
          const cause: Cause = {
            from,
            id: causeId || uuid.v4(),
            state: 0,
            timestamp: new Date().toISOString(),
            body: null,
            modifiedBy: "--",
            note: "",
            reservationId: EMPTY_GUID,
            subject: "",
            type: "Call",
            reservation: undefined,
          };
          _dispatchEvent(Events.EINGEHENDER_ANRUF, cause);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const _handleSettingsChange = (ev: CustomEvent<keyof AppSettings>) => {
    if (ev) {
      const { detail } = ev;
      if (detail.toUpperCase() === "MANDANT") {
        let event = "";
        for (event of MandantChangeEvents) {
          _dispatchEvent(event);
        }
      }
    }
  };

  const _handleEvent = (event: CustomEvent<keyof Cache>) => {
    const { detail } = event;
    if (detail) {
      const uppercaseDetail = detail.toUpperCase();
      if (uppercaseDetail === SAVELOCATIONS.reservierungen.toUpperCase()) {
        _dispatchEvent(Events.RESERVIERUNGEN_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.gaeste.toUpperCase()) {
        _dispatchEvent(Events.GAESTE_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.tische.toUpperCase()) {
        _dispatchEvent(Events.TISCHE_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.anfragen.toUpperCase()) {
        _dispatchEvent(Events.ANFRAGEN_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.zusammenfassungen.toUpperCase()) {
        _dispatchEvent(Events.ZUSAMMENFASSUNGEN_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.nachrichten.toUpperCase()) {
        _dispatchEvent(Events.NACHRICHTEN_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.veranstaltungen.toUpperCase()) {
        _dispatchEvent(Events.VERANSTALTUNGEN_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.informationen.toUpperCase()) {
        _dispatchEvent(Events.INFORMATIONEN_UPDATED);
      } else if (uppercaseDetail === SAVELOCATIONS.mandanten.toUpperCase()) {
        _dispatchEvent(Events.MANDANTEN_UPDATED);
      } else if (CommandQueue.Constants.Array.some((cmd) => uppercaseDetail === cmd)) {
        console.debug(detail);
      } else if (QueryQueue.Constants.Array.some((cmd) => uppercaseDetail === cmd)) {
        console.debug(detail);
      } else {
        console.error(detail);
      }
    }
  };

  const _handleClientEvent = (event?: Event) => {
    console.log(EventProvider.get("Events"), EventProvider.get("ClientVersion"));
  };

  const _handleEventVersionChange = (event?: CustomEvent<"CLIENT" | "SERVER">) => {
    if (event && event.detail) {
      if (event.detail === "SERVER") {
        SettingsProvider.set("serverVersion", EventProvider.get("ServerVersion"));
      } else if (event.detail === "CLIENT") {
        SettingsProvider.set("clientVersion", EventProvider.get("ClientVersion"));
      }
    }
  };

  const getID = (location: keyof typeof SAVELOCATIONS, date?: Date, idx?: number) =>
    `${SAVELOCATIONS[location]}_${date ? date.toISOString().slice(0, 10) : "default"}${
      idx !== undefined ? `_${idx}` : ""
    }`;

  const getItemsByDate = async <T>(
    location: Savelocation,
    query: keyof typeof ServerQueries,
    date?: Date,
    fromServer?: boolean
  ) => {
    try {
      console.log(location, query, date, fromServer);
      if (fromServer) {
        const result = await (ServerQueries[query] as any).apply(null, [date]);
        await PersistentStorageRepository!.write(location, date, result);
      }
      const loadedItems: T[] = (await CachedDataProvider.get(location, date)) || [];
      return loadedItems;
    } catch (error) {
      throw error;
    }
  };

  const getItemsFromServer = async <R>(location: Savelocation, date?: Date, stale?: boolean) => {
    try {
      console.log("getItemsFromServer", location, date);
      const resp: any = await getCommands[location](date || SettingsProvider.get("date"), stale);
      console.log("getItemsFromServer", resp, location, date);
      if (resp) {
        if (Array.isArray(resp)) {
          return resp as R[];
        } else return [];
      } else return [];
    } catch (error) {
      throw error;
    }
  };

  const getCommands = {
    [SAVELOCATIONS.mandanten]: ServerApi.getMandanten,
    [SAVELOCATIONS.reservierungen]: ServerApi.getReservierungenByDate,
    [SAVELOCATIONS.tische]: ServerApi.getTische,
    [SAVELOCATIONS.anfragen]: ServerApi.getAnfragenByDate,
    [SAVELOCATIONS.gaeste]: ServerApi.getGaesteByDate,
    [SAVELOCATIONS.nachrichten]: ServerApi.getNachrichtenBeforeDate,
    [SAVELOCATIONS.veranstaltungen]: ServerApi.getVeranstaltungen,
    [SAVELOCATIONS.informationen]: ServerApi.getInformationen,
  };

  const upload = async <T>(
    item: T,
    location: keyof typeof SAVELOCATIONS,
    call: keyof typeof ServerApi,
    eventId?: string,
    dateOverwrite?: Date
  ) => {
    try {
      const date = dateOverwrite ?? SettingsProvider.get("date");
      // const resp = await ServerApi[call](date as any, item as any);
      // const matchingEvent = await EventProvider.findEventForElement(getID(location, date));
      // if (resp && (matchingEvent || eventId)) {
      //   if ("eventId" in resp && "date" in resp && "eventVersion" in resp) {
      //     await EventProvider.modify(date, matchingEvent ? matchingEvent.id : eventId!, {
      //       serverDate: new Date(resp.date),
      //       serverId: resp.eventId,
      //       serverVersion: resp.eventVersion,
      //     });
      //   }
      // }
      // return resp;
    } catch (error) {
      throw error;
    }
  };

  const addItemToDate = async <T extends object & { id: string | number }, K extends keyof T>(
    item: T,
    location: keyof typeof SAVELOCATIONS,
    overwriteKey?: K,
    date?: Date
  ) => {
    try {
      if (!PersistentStorageRepository) throw new Error("PersistentStorageRepository is undefined");
      PersistentStorageRepository.add<T, Savelocation>(
        location,
        date || SettingsProvider.get("date"),
        item,
        true,
        overwriteKey || "id",
        true,
        true
      );
      const eventId = await EventProvider.add(_EVENTS[location].added, {
        id: item.id + "",
        storage: SAVELOCATIONS[location],
        date: SettingsProvider.get("date"),
        location: null,
      });
      return { eventId, item };
    } catch (error) {
      throw error;
    }
  };

  const getFromCache = (key: Savelocation) => {
    return CachedDataProvider.directlyGet(key);
  };

  const wait = (time: number) => new Promise((res) => setTimeout(res, time));

  const DANGEROUSLY_COMPLETLY_CLEAR = async (
    onSessionClear: (msg: string) => any,
    onLocalClear: (msg: string) => any,
    onIndexedDBClear: (msg: string) => any,
    onReloadSoon: (msg: string) => any
  ) => {
    try {
      const conf = window.confirm(
        "Hiermit werden ALLE Daten gelöscht\r\nDiese Aktion kann NICHT rückgängig gemacht werden\r\n\r\nTrotzdem fortfahren?"
      );
      if (conf) {
        if (!PersistentStorageRepository) throw new Error("PersistentStorageRepository is undefined");
        onSessionClear("SessionStorage wird gelöscht");
        sessionStorage.clear();
        await wait(1000);
        onSessionClear("SessionStorage erfoglreich gelöscht");
        onLocalClear("LocalStorage wird gelöscht");
        localStorage.clear();
        await wait(1000);
        onLocalClear("LocalStorage erfoglreich gelöscht");
        onIndexedDBClear("EVENT_DB wird gelöscht");
        await PersistentStorageRepository.dangerouslyClear(EventProvider.eventStore);
        await wait(1000);
        onIndexedDBClear("EVENT_DB wurde gelöscht");
        onIndexedDBClear("keyval-store wird gelöscht");
        // await clearDB("keyval-store");
        await PersistentStorageRepository.dangerouslyClear();
        await wait(1000);
        onIndexedDBClear("keyval-store wurde gelöscht");
        onReloadSoon("Seite wird in 5 Sekunden neugeladen...");
        await wait(5000);
        window.location.reload();
      }
    } catch (error) {
      logError({
        title: "Fehler bei Initialisierung",
        text: "Es ist ein Fehler aufgetreten - der Zustand der App kann nichtmehr garantiert werden - bitte die App manuell löschen, ggfs. Cache leeren und neuinstallieren",
        color: "error",
        icon: "ShieldExclamationIcon",
        delay: 15000,
      });
    }
  };
  const ClientApi = {
    initialise,
    Queries,
    ServerQueries,
    Commands,
    BackendCalls,
    Events,
    Cache: CachedDataProvider.CacheData,
    SAVELOCATIONS,
    getFromCache,
    DANGEROUSLY_COMPLETLY_CLEAR,
  };
  return ClientApi;
};

export default ClientApi;
