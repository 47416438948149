import { ButtonHTMLAttributes, DetailedHTMLProps, memo, ReactNode, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useTracker from "../AppInsights/useTracker";

type BasicButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  isLink?: boolean;
  isOutsideLink?: boolean;
  to?: string;
  isReplace?: boolean;
  identifier: string;
  children?: ReactNode | ReactNode[];
};

const BasicButton = memo(function BasicButton({
  isLink,
  isOutsideLink,
  to,
  isReplace,
  children,
  identifier,
  ...btnProps
}: BasicButtonProps) {
  const navigate = useNavigate();
  const onLink = useCallback(() => {
    if (isLink && to) {
      if (isOutsideLink) {
        window.location.replace(to);
      } else {
        if (isReplace) navigate(to, { replace: true });
        else navigate(to);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLink, isOutsideLink, isReplace, to]);

  const onClick = useMemo(() => (isLink ? onLink : btnProps.onClick), [btnProps.onClick, isLink, onLink]);

  const memoIdentifier = useMemo(() => identifier || "Generic BasicButton", [identifier]);
  const relayClick = useTracker(memoIdentifier, "Button Click");

  const relayedClick = useMemo(() => relayClick(onClick), [onClick, relayClick]);

  return (
    <button type="button" {...btnProps} onClick={relayedClick}>
      {children}
    </button>
  );
});
export default BasicButton;
