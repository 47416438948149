import { useCallback, useEffect, useRef, useState } from "react";
import useTrackUpdate from "../../clientApi/tracking/useTrackUpdate";
import { useEventListener } from "../../hooks/useEventlistener";
import { clientApi } from "../../views/Authenticated/AuthenticatedView";
import Information from "../types/Information";

const useInformationen = (date: Date, doNotPull?: boolean, doNotLoad?: boolean) => {
  const [informationen, setInformationen] = useState<Information[]>([]);
  const dateRef = useRef<Date>(date);

  useTrackUpdate("Informationen_Datum", dateRef.current);
  useTrackUpdate("Informationen", informationen);

  const [isPulling, setIsPulling] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pullInformationen = useCallback(async () => {
    setIsPulling(true);
    try {
      const resp = await clientApi.Queries.getInformationen(dateRef.current, true);
      setInformationen(resp);
    } catch (error) {
      throw error;
    } finally {
      setIsPulling(false);
    }
  }, []);

  const loadInformationen = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await clientApi.Queries.getInformationen(dateRef.current, false);
      setInformationen(resp);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEventListener(clientApi.Events.INFORMATIONEN_UPDATED, doNotLoad ? () => null : loadInformationen);

  useEffect(() => {
    if (!doNotPull) {
      loadInformationen();
      pullInformationen();
    } else if (!doNotLoad) {
      loadInformationen();
    } else {
    }
  }, [doNotLoad, doNotPull, loadInformationen, pullInformationen, date]);

  useEffect(() => {
    dateRef.current = date;
  }, [date]);

  return { informationen, isPulling, isLoading };
};
export default useInformationen;
