import { useCallback, useEffect, useState } from "react";
import { Heroicons } from "../../Heroicon/Heroicon";
import TastaturButton from "./TastaturButton";

type TastaturBackspaceButtonProps = {
  onPress: () => void;
};

const TastaturBackspaceButton = ({ onPress }: TastaturBackspaceButtonProps) => {
  const [isPressedDown, setIsPressedDown] = useState<boolean>(false);
  const [deletionMode, setDeletionMode] = useState<"single" | "quick">("single");

  const relayPress = useCallback(() => {
    onPress();
    setIsPressedDown(true);
  }, [onPress]);

  useEffect(() => {
    if (isPressedDown) {
      const timeout = setTimeout(() => {
        setDeletionMode("quick");
      }, 2000);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [isPressedDown]);

  useEffect(() => {
    if (isPressedDown && deletionMode) {
      const interval = setInterval(
        () => {
          onPress();
        },
        deletionMode === "single" ? 150 : 50
      );
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [deletionMode, isPressedDown, onPress]);

  const onTouchEnd = useCallback(() => {
    setIsPressedDown(() => {
      setDeletionMode("single");
      return false;
    });
  }, []);

  return (
    <TastaturButton
      onPress={relayPress}
      onTouchEnd={onTouchEnd}
      innerClassName={"h-full text-gray-700 bg-gray-400"}
      className="col-span-2 h-full"
    >
      <Heroicons.Outline.BackspaceIcon className="h-full w-6 mx-auto" />
    </TastaturButton>
  );
};
export default TastaturBackspaceButton;
