import * as IDB from "idb-keyval";
import { useCallback, useState } from "react";
import { useEventListener } from "../../../hooks/useEventlistener";
import { useToggle } from "../../../hooks/useToggle";
import Absprache from "../types/Absprache";

export const IDBKEY = "Absprache";

export const AbspracheEvents = {
  update: "UpdateAbsprache",
};

const useAbsprachen = () => {
  const { get } = IDB;
  const [isLoading, , startLoading, stopLoading] = useToggle();
  const [absprachen, setAbsprachen] = useState<Absprache.Client[]>([]);

  const loadFromIdb = useCallback(async () => {
    try {
      startLoading();
      const items = await get<Absprache.Client[]>(IDBKEY);
      if (items && Array.isArray(items)) {
        setAbsprachen(items);
      }
    } catch (error) {
      throw error;
    } finally {
      stopLoading();
    }
  }, [get, startLoading, stopLoading]);

  useEventListener(AbspracheEvents.update, loadFromIdb);

  return { absprachen, isLoading };
};
export default useAbsprachen;
