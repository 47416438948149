import * as React from "react";
import { AppColors, TailwindBackgroundColors } from "../models/General";
import { Heroicons } from "./Heroicon/Heroicon";

export const TimePicker = ({
  ...props
}: {
  appColors: AppColors;
  selectTime: (time: string) => void;
  selectedTime?: string;
  availableHours?: string[];
  height?: "12" | "10" | "8";
  textSize?: "xs" | "sm" | "lg" | "xl";
  textColor?: TailwindBackgroundColors;
  relativeSized?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const [hour, setHour] = React.useState<number | null>(props.selectedTime ? +props.selectedTime.slice(0, 2) : null);
  const [minute, setMinute] = React.useState<number | null>(
    props.selectedTime ? +props.selectedTime.slice(3, 5) : null
  );
  const hasLoadedTime = React.useRef(false);

  const { selectTime, selectedTime } = props;

  React.useEffect(() => {
    if (hasLoadedTime.current && hour !== null && minute !== null) {
      const timeString = `${("0" + hour).slice(-2)}:${("0" + minute).slice(-2)}:00`;
      if (selectedTime !== timeString) {
        selectTime(timeString);
      }
    }
  }, [hour, minute, selectTime, selectedTime]);

  React.useEffect(() => {
    hasLoadedTime.current = true;
  }, [hasLoadedTime]);

  React.useEffect(() => {
    if (selectedTime) {
      setHour((ch) => {
        setMinute((cm) => {
          if (+selectedTime.slice(3, 5) !== cm) {
            return +selectedTime.slice(3, 5);
          }
          return cm;
        });
        if (+selectedTime.slice(0, 2) !== ch) {
          return +selectedTime.slice(0, 2);
        }
        return ch;
      });
    }
  }, [selectedTime]);

  return (
    <div
      className={`${
        props.relativeSized ? "inline-flex" : "flex h-full"
      }flex-1 flex-col justify-start items-start w-full z-10 `}
    >
      <div
        className={`flex justify-center items-end flex-shrink-0 flex-grow-0 ${
          props.height ? `h-${props.height}` : "h-12"
        } w-full z-10 px-1`}
      >
        <div
          className={`flex justify-between items-center flex-shrink-0 flex-grow-0 ${
            props.height ? `h-${props.height}` : "h-12"
          } w-full`}
        >
          <div
            className={`flex flex-1 h-full justify-around items-center text-primary-700 px-3 bg-white shadow-md rounded-lg `}
          >
            <Heroicons.Outline.ArrowLeftIcon
              className="w-5 h-5 flex-grow-0 flex-shrink-0"
              onClick={() => setHour((hour) => (hour !== null ? (hour - 1 >= 0 ? hour - 1 : 23) : 0))}
            />
            <span
              className={`flex flex-1 justify-center items-center font-semibold leading-none ${
                props.textSize ? `text-${props.textSize}` : "text-2xl"
              }`}
            >
              {hour !== null ? ("0" + hour).slice(-2) : "--"}
            </span>
            <Heroicons.Outline.ArrowRightIcon
              className="w-5 h-5 flex-grow-0 flex-shrink-0"
              onClick={() => setHour((hour) => (hour !== null ? (hour + 1 <= 23 ? hour + 1 : 0) : 1))}
            />
          </div>
          <div
            className={`flex flex-grow-0 flex-shrink-0 justify-center items-center ${
              props.textSize ? `text-${props.textSize}` : "text-4xl"
            } w-5 h-full leading-none ${
              props.textColor ? "text-" + props.textColor + "-600" : "text-primary-100"
            } pb-2`}
          >
            :
          </div>
          <div
            className={`flex flex-1 h-full justify-around items-center text-primary-700 px-3 bg-white shadow-md rounded-lg `}
          >
            <Heroicons.Outline.ArrowLeftIcon
              className="w-5 h-5 flex-grow-0 flex-shrink-0"
              onClick={() => setMinute((minute) => (minute !== null ? (minute - 15 >= 0 ? minute - 15 : 0) : 0))}
            />
            <span
              className={`flex flex-1 justify-center items-center font-semibold leading-none ${
                props.textSize ? `text-${props.textSize}` : "text-2xl"
              }`}
            >
              {minute !== null ? ("0" + minute).slice(-2) : "--"}
            </span>
            <Heroicons.Outline.ArrowRightIcon
              className="w-5 h-5 flex-grow-0 flex-shrink-0"
              onClick={() => setMinute((minute) => (minute !== null ? (minute + 15 <= 45 ? minute + 15 : 0) : 15))}
            />
          </div>
        </div>
      </div>
      <div className={`relative flex flex-1 flex-col w-full px-1 pb-2 pt-1 min-h-0 max-h-full h-full`}>
        <div className="flex justify-center items-start w-full">
          <div className="flex flex-1 flex-wrap justify-center items-center p-1">
            {(props.availableHours || [...Array(24).fill(undefined)].map((_, index) => ("0" + index).slice(-2))).map(
              (time) => {
                return (
                  <TimeButton
                    key={time}
                    onClick={() => setHour(+time)}
                    appColors={props.appColors}
                    active={+time === hour}
                    textColor={props.textColor}
                  >
                    {time}
                  </TimeButton>
                );
              }
            )}
          </div>
          <div className="flex flex-1 flex-wrap justify-center items-center p-1">
            {[0, 15, 30, 45]
              .map((_, index) => ("0" + _).slice(-2))
              .map((time) => {
                return (
                  <TimeButton
                    key={time}
                    onClick={() => setMinute(+time)}
                    appColors={props.appColors}
                    active={+time === minute}
                    textColor={props.textColor}
                  >
                    {time}
                  </TimeButton>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const TimeButton: React.FC<{
  appColors: AppColors;
  active: boolean;
  onClick: () => void;
  textColor?: TailwindBackgroundColors;
  children?: React.ReactNode | React.ReactNode[];
}> = (props) => {
  return (
    <div
      className={`flex h-8 w-8 flex-grow-0 flex-shrink-0 text-base justify-center items-center rounded-lg border-white ${
        props.active
          ? `text-primary-500 bg-white shadow-md`
          : `text-${
              props.textColor ? props.textColor + "-600" : "white"
            } hover:bg-white hover:text-primary-500 shadow-none`
      } bg-transparent cursor-pointer transition-all duration-150 ease-in-out`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
