import DateFormatter from "../helper/DateFormatter";
import Datum from "../helper/Datum";
import Limit from "./subtype/Limit";
import Notiz from "./subtype/Notiz";

namespace Information {
  export enum Status {
    Aktiv = 0,
    Geloescht = 1,
  }
  export enum Typ {
    Notiz,
    Limit,
  }

  export type Server<T extends Mapper.Richtung, D = Typ> = {
    id: string;
    status: Status;
    erstelltAm: T extends Mapper.Richtung.Eingehend ? string : Date;
    erstelltVon: string;
    bearbeitungen: T extends Mapper.Richtung.Eingehend ? string[] : string[];
    bezugsDatum: T extends Mapper.Richtung.Eingehend ? string : Date;
    beschreibung: string;
    typ: T extends Mapper.Richtung.Eingehend ? number : D extends Typ ? D : Typ;
    data: D extends Typ ? SubData[D] : never;
  };

  export type Client<T = Typ> = {
    id: string;
    status: Status;
    erstelltAm: Datum;
    erstelltVon: string;
    bearbeitungen: [Datum, string][];
    bezugsDatum: Datum;
    beschreibung: string;
    typ: T;
    data: T extends Typ ? SubData[T] : never;
  };

  export const Key = "Information";
  export const Routes = {
    Root: "/Informationen/",
  };

  export namespace Mapper {
    export enum Richtung {
      Eingehend,
      Ausgehend,
    }

    export const clientToServer: (i: Information) => Information.Server<Richtung.Ausgehend, any> = (info) => {
      return {
        ...info,
        erstelltAm: info.erstelltAm.obj,
        bearbeitungen: info.bearbeitungen
          ? info.bearbeitungen.map(([datum, user]) => [datum.obj.toISOString(), user].join("&"))
          : [],
        bezugsDatum: info.bezugsDatum.obj,
        data: JSON.stringify(info.data),
      };
    };

    export const serverToClient: (i: Information.Server<Richtung.Eingehend, Typ>) => Information = (info) => {
      return {
        ...info,
        erstelltAm: DateFormatter.toDatum(info.erstelltAm),
        bearbeitungen: info.bearbeitungen
          ? info.bearbeitungen.map((str) => str.split("&")).map(([datum, user]) => [DateFormatter.toDatum(datum), user])
          : [],
        bezugsDatum: DateFormatter.toDatum(info.bezugsDatum),
        data: JSON.parse(info.data as any),
      };
    };
  }

  export namespace SubData {
    export const Key = "SubData";
  }

  export type SubData = {
    [Typ.Notiz]: Notiz;
    [Typ.Limit]: Limit;
  };
}

type Information<T = Information.Typ> = Information.Client<T>;

export default Information;
