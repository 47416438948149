import * as React from "react";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { useSettings } from "../../clientApi/SettingsProvider";
import { BaseInput } from "../../components/baseinput/BaseInput";
import { ReservationButton } from "../../components/ReservationButton";
import { useInput } from "../../hooks/useInput";
import { useTheme } from "../../hooks/useTheme";

const NachrichtenNotizBearbeiten = (props: {
  nachricht?: Nachricht.Client<any>;
  note?: string;
  otherNotes?: string[];
  subTitle?: string;
  onConfirm?: (nachricht: Nachricht.Client<any>, note: string) => void;
}) => {
  const { theme } = useTheme();
  const [noteText, setNoteText, resetNoteText] = useInput(props.nachricht?.note);

  const handleOnConfirm = React.useCallback(() => {
    if (props.onConfirm && props.nachricht) props.onConfirm(props.nachricht, noteText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteText, props.onConfirm, props.nachricht]);

  const { isUsingVirtualKeyboard } = useSettings();

  return (
    <div className="flex flex-1 flex-col bg-gray-200 shadow-inner rounded-lg p-2 min-h-0 overflow-hidden space-y-2">
      <BaseInput
        icon="DocumentTextIcon"
        height="h-full"
        value={noteText}
        onChange={setNoteText}
        label={"Notiz"}
        isVirtualKeyboard={isUsingVirtualKeyboard}
        isTextArea
      />

      <div className="flex h-14 flex-col w-full pt-2 pb-2">
        <ReservationButton
          unlockedHeight
          color="blue"
          isLocked={false}
          onClickHandler={handleOnConfirm}
          additionalClasses={`rounded-lg transition-all duration-200 ${
            props.onConfirm ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          Bestätigen
        </ReservationButton>
      </div>
    </div>
  );
};

export default NachrichtenNotizBearbeiten;
