import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import packageJson from "../package.json";
import "./App.css";
import { AppShell } from "./AppShell";
import ClientSideNavigation from "./auth/ClientSideNavigation";
import AuthConfig from "./AuthConfig";

async function registerSW() {
  const env = process.env.NODE_ENV;
  if (!window.location.origin.includes("localhost") && navigator && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    console.log(publicUrl.origin, window.location.origin);
    if (publicUrl.origin !== window.location.origin) {
      return;
    } else {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      navigator.serviceWorker.register(swUrl);
    }
  }
}

const PCA = new PublicClientApplication(AuthConfig.base);
PCA.initialize().then(() => {
  if (!PCA.getActiveAccount() && PCA.getAllAccounts().length > 0) {
    PCA.setActiveAccount(PCA.getAllAccounts()[0]);
  }

  PCA.enableAccountStorageEvents();

  PCA.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      PCA.setActiveAccount(account);
    }
  });

  function AppProvider() {
    return (
      <React.StrictMode>
        <MsalProvider instance={PCA}>
          <BrowserRouter>
            <ClientSideNavigation pca={PCA}>
              <AppShell />
            </ClientSideNavigation>
          </BrowserRouter>
        </MsalProvider>
        <span className="fixed bottom-3 left-4 px-1 text-white/80 mix-blend-difference font-semibold tracking-wide text-xs z-[100] select-none pointer-events-none">
          v{packageJson.version ?? "??"}
        </span>
      </React.StrictMode>
    );
  }

  const container = window?.document?.getElementById("root");
  if (!container) throw new Error("Konnte root nicht finden");
  const root = createRoot(container);
  root.render(<AppProvider />);
});

registerSW();
