import { XIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppFunctions, AppState } from "../App";
import { _dispatchEvent } from "../clientApi/EventProvider";
import { WebSocketCall } from "../models/WebSocketCall";
import { Heroicon, Heroicons } from "./Heroicon/Heroicon";

type NotificationBarProps = {
  shouldHide: boolean;
  appWebSocket: AppState["appWebSocket"];
  appRestaurantId: AppState["appRestaurantId"];
  appIsCached: AppState["appIsCached"];
  appIsUpdateAvailable: AppState["appIsUpdateAvailable"];
  appDisplayMode: AppState["appDisplayMode"];
  appShowNotification: AppFunctions["appShowNotification"];
  setNewReservationPrimer: AppFunctions["setNewReservationPrimer"];
};

type NotificationType = "alert" | "warning" | "success" | "update";

export const NotificationBar = (props: NotificationBarProps) => {
  const {
    appWebSocket,
    shouldHide,
    appRestaurantId,
    setNewReservationPrimer,
    appIsCached,
    appIsUpdateAvailable,
    appShowNotification,
    appDisplayMode,
  } = props;

  const [isVisible, setIsVisible] = React.useState(false);
  const [currentMessage, setCurrentMessage] = React.useState<string | null>(null);
  const [type, setType] = React.useState<NotificationType>("warning");

  const [currentIncomingCall, setCurrentIncomingCall] = React.useState<WebSocketCall | null>(null);
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  const [hasClosedUpdate, setHasClosedUpdate] = React.useState(false);

  const [, setConnectionHasFailed] = React.useState<boolean | undefined>(undefined);

  const hasDisplayedCache = React.useRef<boolean>(false);

  const [, setDisplayingCall] = React.useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();

  const _handleMessageDisplay = React.useCallback((typeToSet: NotificationType, messageToSet: string | null) => {
    setIsVisible((curr) => {
      setType(() => {
        setCurrentMessage(messageToSet);
        return typeToSet;
      });
      return true;
    });
  }, []);

  React.useEffect(() => {
    if (appIsUpdateAvailable && !hasClosedUpdate) {
      _handleMessageDisplay("update", "Neue Version verfügbar - Klicken zum Aktualisieren");
    }
  }, [_handleMessageDisplay, appIsUpdateAvailable, hasClosedUpdate, isVisible]);

  React.useEffect(() => {
    if (appIsCached && !hasDisplayedCache.current) {
      _handleMessageDisplay("success", "Website ist nun Offline verfügbar");
      hasDisplayedCache.current = true;
    }
  }, [_handleMessageDisplay, appIsCached]);

  React.useEffect(() => {
    if (isVisible && type) {
      const timeOut = setTimeout(
        () => {
          setIsVisible(false);
        },
        type === "update"
          ? 60000
          : type === "warning"
          ? 30000
          : type === "alert"
          ? 5000
          : type === "success"
          ? 2000
          : 5000
      );
      return () => {
        if (timeOut) clearTimeout(timeOut);
      };
    }
  }, [isVisible, type]);

  React.useEffect(() => {
    if (shouldRedirect && navigate) {
      if (location.pathname.toLocaleLowerCase() !== "/Reservierung".toLocaleLowerCase()) {
        navigate("/Reservierung");
      } else {
      }
      setShouldRedirect(() => {
        setIsVisible(false);
        return false;
      });
    }
  }, [shouldRedirect, navigate, location.pathname]);

  // const _handleCreateReservationFromCall = React.useCallback(async () => {
  //   try {
  //     if (!shouldRedirect && currentIncomingCall && currentIncomingCall.causeId) {
  //       const cause = await getCause(appRestaurantId, currentIncomingCall.causeId);
  //       await setNewReservationPrimer({ phoneNumber: currentIncomingCall.from, causeId: cause.id, cause: cause }).then(
  //         () => {
  //           setShouldRedirect(true);
  //         }
  //       );
  //     } else {
  //       setShouldRedirect(false);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }, [appRestaurantId, currentIncomingCall, setNewReservationPrimer, shouldRedirect]);

  const _handleRedirectToNachrichten = React.useCallback(async () => {
    try {
      if (currentIncomingCall && appDisplayMode !== "Tablet") {
        navigate(`/Nachrichten/${currentIncomingCall.causeId || ""}`);
      } else if (currentIncomingCall && appDisplayMode === "Tablet") {
        navigate(`/Reservierung/2/${currentIncomingCall.causeId || ""}`);
      }
    } catch (error) {
      throw error;
    }
  }, [appDisplayMode, currentIncomingCall, navigate]);

  const _handleCloseNotification = React.useCallback(() => {
    if (type === "update") setHasClosedUpdate(true);
    return setIsVisible(false);
  }, [type]);

  const _handleClick = React.useCallback(() => {
    if (type === "warning" || type === "alert") _handleRedirectToNachrichten();
  }, [_handleRedirectToNachrichten, type]);

  const _style = React.useMemo(
    () => ({
      backgroundColor:
        type === "update"
          ? "rgba(56, 178, 172, 0.85)"
          : type === "warning"
          ? "rgba(246, 224, 94, 0.85)"
          : type === "alert"
          ? "rgba(245, 101, 101, 0.85)"
          : type === "success"
          ? "rgba(72, 187, 120, 0.85)"
          : "white",
    }),
    [type]
  );

  const _className = React.useMemo(
    () =>
      `absolute top-0 left-0 inline-flex justify-center items-center h-8 flex-shrink-0 flex-grow-0 pl-9 pr-8 z-30 shadow-md rounded-full mt-2 ml-2 leading-tight ${
        type === "update"
          ? "text-teal-100 cursor-pointer"
          : type === "warning"
          ? "text-yellow-900 cursor-pointer"
          : type === "alert"
          ? "text-red-100 cursor-pointer"
          : type === "success"
          ? "text-green-100"
          : "text-black"
      } text-xs transition-all transform ease-in-out duration-300 ${
        shouldHide ? (isVisible ? "translate-y-10" : "-translate-y-full") : "-translate-y-full"
      }`,
    [isVisible, shouldHide, type]
  );

  const icons: { [key in NotificationType]: Heroicon } = React.useMemo(
    () =>
      ({
        alert: "ExclamationIcon",
        success: "CheckIcon",
        update: "InformationCircleIcon",
        warning: "ExclamationIcon",
      } as { [key in NotificationType]: Heroicon }),
    []
  );

  const Icon = React.useMemo(() => Heroicons.Solid[icons[type]] || null, [icons, type]);

  return (
    <div className={_className} style={_style}>
      <Icon className="absolute top-0 left-0 w-4 h-full ml-3" />
      <span onClick={_handleClick}>{currentMessage}</span>
      <span
        onClick={_handleCloseNotification}
        className="absolute flex justify-center items-center right-0 h-full w-4 z-10 mr-3 mt-px"
      >
        <XIcon className="flex flex-grow-0 flex-shrink-0 w-4 h-4" />
      </span>
    </div>
  );
};
