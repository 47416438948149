import { useCallback } from "react";
import Tools from "../../../Tools";
import Absprache from "../types/Absprache";
import * as IDB from "idb-keyval";
import * as AbsprachenHook from "./useAbsprachen";
import { useToggle } from "../../../hooks/useToggle";
import SettingsProvider from "../../../clientApi/SettingsProvider";
import { Reservation } from "../../../models/Reservation";

const baseUrl = window.location.hostname === "localhost" ? "https://localhost:44315" : window.location.origin;

const useAbsprachenApi = () => {
  const { set } = IDB;

  const [isPulling, , startPulling, stopPulling] = useToggle();
  const [isCreating, , startCreating, stopCreating] = useToggle();

  const updateIdb = useCallback(
    async (items: Absprache.Client[]) => {
      try {
        await set(AbsprachenHook.IDBKEY, items);
        const ev = new CustomEvent(AbsprachenHook.AbspracheEvents.update);
        window.dispatchEvent(ev);
      } catch (error) {
        throw error;
      }
    },
    [set]
  );

  const baseFetch = useCallback(
    async (...[input, init, params]: [...Parameters<typeof fetch>, { [key: string]: string }?]) => {
      try {
        const paramString = new URLSearchParams(
          Object.entries({ restaurantId: SettingsProvider.get("mandant"), ...params })
        ).toString();
        const resp = await fetch(`${baseUrl}/api/${input}?${paramString}`, init);
        if (resp.ok) {
          return resp;
        } else throw new Error("Response did not indicate Success");
      } catch (error) {
        throw error;
      }
    },
    []
  );

  const getAbspracheLinkForDate = useCallback(
    async (date: Date) => {
      try {
        const resp = await baseFetch(`notes/${date}`, { method: "GET" });
        if (resp.ok) {
          try {
            const json: { url: string } = await resp.json();
            if (typeof json === "object" && "url" in json) {
              return json.url;
            } else {
              throw new Error("Json was of wrong type");
            }
          } catch (error) {
            throw new Error("Was unable to parse response");
          }
        } else {
          throw new Error("Response did not indicate success");
        }
      } catch (error) {
        throw error;
      }
    },
    [baseFetch]
  );

  const createAbspracheOnDate = useCallback(
    async (date: Date) => {
      try {
        startCreating();
        const resp = await baseFetch(`notes/${Tools.dateToIsoLike(date)}`, { method: "POST" });
        return resp.ok;
      } catch (error) {
        throw error;
      } finally {
        stopCreating();
      }
    },
    [baseFetch, startCreating, stopCreating]
  );

  const addReservierungToAbspracheOnDate = useCallback(
    async (res: Reservation, date: Date) => {
      try {
        startCreating();
        const resp = await baseFetch(
          `notes/${Tools.dateToIsoLike(date)}/${res.id}`,
          { method: "POST" },
          { reservationName: res.name }
        );
        return resp.ok;
      } catch (error) {
        throw error;
      } finally {
        stopCreating();
      }
    },
    [baseFetch, startCreating, stopCreating]
  );

  const getAbsprachen = useCallback(async () => {
    try {
      startPulling();
      const resp = await baseFetch("notes", { method: "GET" });
      const json: Absprache.Server[] = await resp.json();
      if (json && Array.isArray(json)) {
        const mapped = json.map((c) => ({ ...c, tag: new Date(c.tag), tagString: c.tag } as Absprache.Client));
        await updateIdb(mapped);
        return mapped;
      } else throw new Error("response was of wrong type");
    } catch (error) {
      throw error;
    } finally {
      stopPulling();
    }
  }, [baseFetch, startPulling, stopPulling, updateIdb]);

  return {
    getAbsprachen,
    createAbspracheOnDate,
    getAbspracheLinkForDate,
    isPulling,
    isCreating,
    addReservierungToAbspracheOnDate,
  };
};
export default useAbsprachenApi;
