import { useCallback, useState } from "react";

export const useToggle = (start?: boolean) => {
  const [current, setCurrent] = useState(start ?? false);

  const on = useCallback(() => setCurrent(true), []);
  const off = useCallback(() => setCurrent(false), []);
  const toggle = useCallback(() => setCurrent((c) => !c), []);

  return [current, toggle, on, off] as [boolean, typeof toggle, typeof on, typeof off];
};
