import { useCallback, useMemo } from "react";
import { Events } from "./ToasterHandler";
import * as Heroicons from "@heroicons/react/solid";

export const addMessage = (detail: {
  title: string;
  text: string;
  icon: keyof typeof Heroicons;
  color: string;
  delay?: number;
  onClick?: () => any;
}) => {
  const event = new CustomEvent<{
    title: string;
    text: string;
    icon: keyof typeof Heroicons;
    color: string;
    delay?: number;
    onClick?: () => any;
  }>(Events.TOASTER_ADD, { detail });
  window.dispatchEvent(event);
};

export const useToasterHandler = () => {
  const _addMessage = useCallback(
    (detail: {
      title: string;
      text: string;
      icon: keyof typeof Heroicons;
      color: string;
      delay?: number;
      onClick?: () => any;
    }) => addMessage(detail),
    []
  );

  return { addMessage: _addMessage };
};
