import { useEffect, useLayoutEffect } from "react";
import SettingsProvider, { useSettings } from "../clientApi/SettingsProvider";
import { Device, useDevice } from "./useDevice";

const useDisplayCorrection = (device: ReturnType<typeof useDevice>, Settings: ReturnType<typeof useSettings>) => {
  useEffect(() => {
    const currentDisplay = SettingsProvider.get("displayMode");
    console.log({ currentDisplay, device });
    if (device === Device.Mobile && currentDisplay !== "Mobile") {
      SettingsProvider.set("displayMode", "Mobile");
    } else if (device === Device.Tablet && currentDisplay !== "Tablet") {
      SettingsProvider.set("displayMode", "Tablet");
    } else if (device === Device.Desktop && currentDisplay !== "Web") {
      SettingsProvider.set("displayMode", "Web");
    }
  }, [device]);

  useEffect(() => {
    const currentDisplay = Settings.displayMode;
    console.log({ currentDisplay, device });
    if (device === Device.Mobile && currentDisplay !== "Mobile") {
      Settings.set("displayMode", "Mobile");
    } else if (device === Device.Tablet && currentDisplay !== "Tablet") {
      Settings.set("displayMode", "Tablet");
    } else if (device === Device.Desktop && currentDisplay !== "Web") {
      Settings.set("displayMode", "Web");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Settings.set, Settings.displayMode, device]);

  useLayoutEffect(() => {
    const fixHeight = () => {
      if ("resizeTo" in window && typeof window.resizeTo === "function") {
        window.resizeTo(window.screen.availWidth, window.screen.availHeight);
      }
    };
    const observer = new MutationObserver(fixHeight);
    const timeout = setTimeout(() => {
      observer.observe(document.documentElement, { subtree: true, attributes: true });
    }, 1e3);
    return () => {
      if (timeout) clearTimeout(timeout);
      observer.disconnect();
    };
  }, []);

  return;
};
export default useDisplayCorrection;
