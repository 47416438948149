namespace Action {
  export enum Trigger {
    User = "User",
    Code = "Code",
  }
  export enum Variant {
    Empty = "Empty",
    CacheQuery = "CacheQuery",
    ServerQuery = "ServerQuery",
    ServerCommand = "ServerCommand",
    Command = "Command",
    Interaction = "Interaction",
    Hook = "Hook",
    Authentication = "Authentication",
    Error = "Error",
  }
  export enum Progress {
    Started = "Started",
    Ended = "Ended",
    Error = "Error",
    Once = "Once",
  }
  type GeneralInformation = {
    currentUrl?: string;
    version?: string;
    environment?: string;
    user?: string;
    timestamp: Date;
    timestampString: string;
    isError: boolean;
  };
  export type Base<K extends Variant, P = keyof typeof Progress> = {
    type: K;
    actionName: string;
    actionData?: any;
    additionalData?: any;
    triggeredBy: keyof typeof Trigger;
    progress: P;
    information: GeneralInformation;
  } & (K extends Variant.CacheQuery
    ? { storageKey: string }
    : K extends Variant.ServerQuery
    ? {
        apiUrl: string;
        params: string;
      }
    : K extends Variant.Command
    ? {
        commandKey: string;
      }
    : K extends Variant.Interaction
    ? {
        interactionName: string;
        interactionTarget: string;
      }
    : K extends Variant.Hook
    ? {
        hookName: string;
      }
    : {});
}

export default Action;
