import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useEventListener } from "./useEventlistener";

export enum Device {
  Mobile,
  Tablet,
  Desktop,
}

export const useDevice = (shouldWait?: number) => {
  const [device, setDevice] = useState<Device>(Device.Desktop);
  const createTestElement = useCallback(() => {
    const element = document.getElementById("deviceTester") || document.createElement("div");
    element.setAttribute("id", "deviceTester");
    element.classList.add(..."opacity-0 fixed top-0 left-0 z-10 h-0 lg:h-px xl:w-px pointer-events-none".split(" "));
    document.body.appendChild(element);
    return element;
  }, []);

  const testElement = useRef<any | null>(null);

  const checkDevice = useCallback(() => {
    console.log("checking");
    const element = createTestElement();
    testElement.current = element;
    setDevice(() => {
      if (testElement.current.clientWidth > 0 && testElement.current.clientHeight > 0) return Device.Desktop;
      else if (testElement.current.clientHeight > 0) return Device.Tablet;
      else return Device.Mobile;
    });
    return element;
  }, [createTestElement]);

  useLayoutEffect(() => {
    let element: HTMLElement | null = null;
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (shouldWait) {
      timeout = setTimeout(() => {
        element = checkDevice();
      }, shouldWait);
    } else {
      element = checkDevice();
    }
    return () => {
      if (timeout) clearTimeout(timeout);
      if (element) element.remove();
    };
  }, [checkDevice, shouldWait]);

  useEventListener("resize", checkDevice);

  return device;
};
