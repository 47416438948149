import { useCallback, useEffect, useState } from "react";
import { useSettings } from "../../clientApi/SettingsProvider";
import { useFilterByTerm } from "../../hooks/useFilterByTerm";
import { Reservation } from "../../models/Reservation";
import { ReservierungenViewModelTypes } from "./ReservierungenViewModelTypes";

export const useReservierungenViewModel = (props: ReservierungenViewModelTypes.Props) => {
  const Settings = useSettings();

  const [reservierungen, setReservierungen] = useState<Reservation[]>(props.reservations);
  const [loadingReservierungen, setLoadingReservierungen] = useState<Reservation[]>([]);

  const { filteredData, setSearchTerm } = useFilterByTerm(
    reservierungen,
    ["createdBy", "name", "phoneNumber", "email", "note"],
    200
  );

  const selectReservierung = useCallback(
    (res: Reservation | null) => (props.onReservierungClick ? props.onReservierungClick.call(undefined, res) : null),
    [props.onReservierungClick]
  );

  useEffect(() => {
    setSearchTerm(props.filterTerm ?? null);
  }, [props.filterTerm, setSearchTerm]);

  useEffect(() => {
    setLoadingReservierungen(props.loadingReservations || []);
  }, [props.loadingReservations]);

  useEffect(() => {
    setReservierungen(props.reservations);
  }, [props.reservations]);

  return {
    reservierungen: filteredData,
    loadingReservierungen,
    selectReservierung,
  };
};
