enum DebugCommand {
  PingAll,
  Ping,
  RespondToPing,
  Logout,
  EmergencyBrake,
  StorageInformation,
  RespondToStorageInformation,
}

export default DebugCommand;
