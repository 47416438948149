import { createContext } from "react";
import DebugConnection from "./DebugConnection";
import useDebugConnections from "./useDebugConnections";

type Context = {} & ReturnType<typeof useDebugConnections>;

const DebugContext = createContext<Context>({
  connections: [] as DebugConnection[],
  SESSION_ID: "--",
  isConnected: false,
  isConnecting: false,
  connect: () => console.log("connect is undefined"),
  greet: (a: string) =>
    new Promise((r) => {
      console.log("greet is undefined");
      r();
    }),
  greetAll: () =>
    new Promise((r) => {
      console.log("greetAll is undefined");
      r();
    }),
  sendMessage: () =>
    new Promise((r) => {
      console.log("sendMessage is undefined");
      r();
    }),
  remoteLogout: (id: string) => console.log("remoteLogout is undefined"),
  remoteBrake: (id: string) => console.log("remoteBrake is undefined"),
  resetConnections: () => console.log("resetConnections is undefined"),
  requestStorageInformation: (id: string) =>
    new Promise((r) => {
      console.log("requestStorageInformation is undefined");
      r();
    }),
});

export default DebugContext;
