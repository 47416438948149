import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Anfrage } from "../clientApi/models/Anfrage";
import { Gast } from "../clientApi/models/Gast";
import { Nachricht } from "../clientApi/models/Nachricht";
import VeranstaltungSimplified from "../clientApi/models/VeranstaltungSimplified";
import SettingsProvider from "../clientApi/SettingsProvider";
import { addMessage } from "../components/ToasterHandler/useToasterHandler";
import { addProtocol } from "../hooks/useProtocol";
import Information from "../Informationen/types/Information";
import { Cause } from "../models/Cause";
import { Email } from "../models/Email";
import { EMPTY_GUID } from "../models/General";
import { NewReservation, Reservation, walkedInConfirmation } from "../models/Reservation";
import { SummaryTable } from "../models/Summary";
import { Table } from "../models/Table";
import Tools from "../Tools";

let isBlocked = false;

export const getDateString = (date?: Date) => {
  let tempDate = date ? new Date(date) : new Date();
  const dateString = tempDate.toLocaleDateString("de-de", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  console.log(dateString);
  const [day, month, year] = [dateString.slice(0, 2), dateString.slice(3, 5), dateString.slice(6, 10)];
  return `${year}-${month}-${day}`;
};

const propsHeader = "properties-for-request";
const onlyStaleHeader = "only-stale";

type ServerResponse = { date: string; eventId: string; eventVersion: number };

type RequestOption = RequestInit & { API: string };
const DefaultOptions = {
  POST: {
    method: "POST",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
  } as RequestInit,
  PUT: {
    method: "PUT",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
  } as RequestInit,
  PATCH: {
    method: "PATCH",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
  } as RequestInit,
  DELETE: {
    method: "DELETE",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
  } as RequestInit,
  GET: { method: "GET", mode: "cors" } as RequestInit,
};

const getOption: (opt: RequestOption | keyof typeof DefaultOptions, APIkey?: keyof typeof APIS) => RequestOption = (
  opt,
  APIKey
) => {
  if (typeof opt === "string") {
    if (APIKey) {
      const API = APIS[APIKey];
      if (opt === "GET") {
        return { API, APIKey, ...DefaultOptions["GET"] };
      } else if (opt === "POST") {
        return { API, APIKey, ...DefaultOptions["POST"] };
      } else if (opt === "PATCH") {
        return { API, APIKey, ...DefaultOptions["PATCH"] };
      } else if (opt === "PUT") {
        return { API, APIKey, ...DefaultOptions["PUT"] };
      } else if (opt === "DELETE") {
        return { API, APIKey, ...DefaultOptions["DELETE"] };
      } else return { API, APIKey, ...DefaultOptions["GET"] };
    } else return { API: APIS.reservierungen, ...DefaultOptions["GET"] };
  } else {
    return opt;
  }
};

const APIS = {
  reservierungen: "api/reservations",
  nachrichten: "api/causes",
  gaeste: "api/guests",
  tische: "api/tables",
  anfragen: "api/requests",
  veranstaltungen: "api/veranstaltung",
  informationen: "api/informationen",
  mandanten: "api/mandant",
};

const Options = {
  GetMandanten: getOption("GET", "mandanten"),
  GetSingleReservation: getOption("GET", "reservierungen"),
  GetReservierungen: getOption("GET", "reservierungen"),
  GetTische: getOption("GET", "tische"),
  GetGaeste: getOption("GET", "gaeste"),
  GetAnfragen: getOption("GET", "anfragen"),
  GetZusammenfassung: getOption("GET", "reservierungen"),
  GetNachrichtenByDateSpan: getOption("GET", "nachrichten"),
  GetMissedCallMessage: getOption("GET", "nachrichten"),
  AddReservierung: getOption("PUT", "reservierungen"),
  WalkInWithoutReservation: getOption("POST", "reservierungen"),
  WalkInWithReservierung: getOption("POST", "reservierungen"),
  ProcessCause: getOption("POST", "nachrichten"),
  AssignTischeToReservierung: getOption("POST", "reservierungen"),
  UnassignTischeToReservierung: getOption("POST", "reservierungen"),
  CancelReservierung: getOption("DELETE", "reservierungen"),
  ConfirmReservierung: getOption("POST", "reservierungen"),
  LeaveReservierung: getOption("POST", "reservierungen"),
  AddTische: getOption("POST", "tische"),
  AddGaeste: getOption("POST", "gaeste"),
  AddAnfragen: getOption("POST", "anfragen"),
  EditNote: getOption("POST", "nachrichten"),
  GetVeranstaltungen: getOption("GET", "veranstaltungen"),
  AddVeranstaltung: getOption("POST", "veranstaltungen"),
  RemoveVeranstaltung: getOption("DELETE", "veranstaltungen"),
  GetInformationen: getOption("GET", "informationen"),
  AddInformation: getOption("POST", "informationen"),
  UpdateInformation: getOption("PATCH", "informationen"),
  RemoveInformation: getOption("DELETE", "informationen"),
};

const Queries = {
  getMandanten: async (date?: Date, stale?: boolean) => {
    try {
      const tische = await getItems<undefined, string[], string[]>(
        undefined,
        "GetMandanten",
        undefined,
        undefined,
        undefined,
        undefined,
        stale
      );
      return tische;
    } catch (error) {
      throw error;
    }
  },
  GetSingleReservation: async (id: Reservation["id"]) => {
    try {
      const resp: Reservation = await getItem({}, "GetSingleReservation", true, id, undefined, "json");
      return resp;
    } catch (error) {
      throw error;
    }
  },
  getReservierungenByDate: async (date: Date, stale?: boolean) => {
    try {
      const reservierungen = await getItems<{ date: string }, Reservation, Reservation>(
        {
          date: getDateString(date),
        },
        "GetReservierungen",
        undefined,
        undefined,
        undefined,
        (res: Reservation) => ({ ...res, tables: res.tables?.sort((a, b) => +a.name - +b.name) }),
        stale
      );
      return reservierungen;
    } catch (error) {
      throw error;
    }
  },
  getTische: async (date?: Date, stale?: boolean) => {
    try {
      const tische = await getItems<undefined, Table, Table>(
        undefined,
        "GetTische",
        undefined,
        undefined,
        undefined,
        undefined,
        stale
      );
      return tische;
    } catch (error) {
      throw error;
    }
  },
  getGaesteByDate: async (date: Date) => {
    try {
      const gaeste = await getItems<{ date: string }, Gast.Server, Gast.Client>(
        {
          date: getDateString(date),
        },
        "GetGaeste"
      );
      return gaeste;
    } catch (error) {
      throw error;
    }
  },
  getAnfragenByDate: async (date: Date, stale?: boolean) => {
    try {
      const anfragen = await getItems<{ date: string }, Anfrage.Server, Anfrage.Client>(
        {
          date: getDateString(date),
        },
        "GetAnfragen",
        undefined,
        undefined,
        undefined,
        undefined,
        stale
      );
      return anfragen;
    } catch (error) {
      throw error;
    }
  },
  getZusammenfassungByDate: async (date: Date, stale?: boolean) => {
    try {
      const zusammenfassung = await getItems<{ year: string; month: string }, SummaryTable, SummaryTable>(
        { year: date.getFullYear() + "", month: date.getMonth() + 1 + "" },
        "GetZusammenfassung",
        true,
        "GetReservationSummaryForMonth",
        undefined,
        undefined,
        stale
      );
      return zusammenfassung;
    } catch (error) {
      throw error;
    }
  },
  getMissedCallMessage: async (cause: Cause | Nachricht.Client<any>) => {
    try {
      const soundFile: File = await getItem<{}, File>({}, "GetMissedCallMessage", true, undefined, cause.id, "blob");
      return soundFile;
    } catch (error) {
      throw error;
    }
  },
  getNachrichtenByDateSpan: async (start: Date, end: Date) => {
    try {
      const nachrichten = await getItems<
        { startDate: string; endDate: string },
        Nachricht.Server,
        Nachricht.Client<any>
      >(
        {
          startDate: (typeof start === "string" ? new Date(start) : start).toISOString().slice(0, 10),
          endDate: (typeof end === "string" ? new Date(end) : end).toISOString().slice(0, 10),
        },
        "GetNachrichtenByDateSpan",
        false,
        undefined,
        undefined,
        (item) => {
          try {
            let parsed = item.body && typeof item.body === "string" ? JSON.parse(item.body) : item.body;
            return {
              ...item,
              body: parsed,
              timestamp: new Date(item.timestamp),
              modified: item.modified ? new Date(item.modified) : undefined,
            };
          } catch (error) {
            return {
              ...item,
              body: item.body || null,
              timestamp: new Date(item.timestamp),
              modified: item.modified ? new Date(item.modified) : undefined,
            };
          }
        }
      );
      return nachrichten.sort((a: any, b: any) => +b.timestamp - +a.timestamp);
    } catch (error) {
      throw error;
    }
  },
  getNachrichtenBeforeDate: async (start: Date, stale?: boolean) => {
    try {
      // const daySpan = SettingsProvider.get("nachrichtenDaySpan");
      // const tempEndDate = new Date(start);
      // tempEndDate.setDate(tempEndDate.getDate() - 1);

      const nachrichten = await Queries.getNachrichtenByDateSpan(start, start);
      return nachrichten;
    } catch (error) {
      throw error;
    }
  },
  getVeranstaltungen: async (date: Date, stale?: boolean) => {
    try {
      const veranstaltungen = await getItems<any, VeranstaltungSimplified.Server, VeranstaltungSimplified.Client>(
        { zeitraum: Tools.dateToIsoLike(date).slice(0, 7) },
        "GetVeranstaltungen",
        false,
        undefined,
        undefined,
        (item) => ({
          ...item,
          veranstaltungDatum: new Date(item.veranstaltungDatum),
          veranstaltungDatumIso: Tools.dateToIsoLike(new Date(item.veranstaltungDatum)),
        })
      );
      return veranstaltungen;
    } catch (error) {
      throw error;
    }
  },
  getInformationen: async (date: Date, stale?: boolean) => {
    try {
      const informationen = await getItems<any, Information.Server<Information.Mapper.Richtung.Eingehend>, Information>(
        { zeitraum: Tools.dateToIsoLike(date).slice(0, 7) },
        "GetInformationen",
        false,
        undefined,
        undefined,
        Information.Mapper.serverToClient
      );
      return informationen;
    } catch (error) {
      throw error;
    }
  },
};

const Commands = {
  addReservierungToDate: async (
    reservierung: NewReservation,
    shouldConfirm?: boolean,
    email?: Email,
    createEmail?: boolean
  ) => {
    try {
      const resp = await addItem(
        { reservation: reservierung, email: email },
        { confirm: shouldConfirm, createEmail: Boolean(createEmail) },
        "AddReservierung"
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  WalkInWithoutReservation: async (reservierung: NewReservation, tischIds: Table["id"][]) => {
    try {
      const resp = await addItem(
        { newReservation: reservierung, tableIds: tischIds },
        {},
        "WalkInWithoutReservation",
        true
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  WalkInWithReservierung: async (reservierung: Reservation, walkedInConfirmation: walkedInConfirmation) => {
    try {
      const resp = await addItem(
        walkedInConfirmation,
        {},
        "WalkInWithReservierung",
        true,
        "WalkInWithReservation",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  ProcessCause: async (cause: Cause, requestReservation: boolean) => {
    try {
      const resp = await addItem(cause, { requestReservation }, "ProcessCause", true, cause.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  ProcessNachricht: async (nachricht: Nachricht.Client<any>, requestReservation: boolean) => {
    try {
      const resp = await addItem(nachricht, { requestReservation }, "ProcessCause", true, nachricht.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  EditNachrichtNote: async (nachricht: Nachricht.Client<any>, note: string) => {
    try {
      const resp = await addItem({ ...nachricht, note }, {}, "EditNote", true, `${nachricht.id}/note`);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  AssignTischeToReservierung: async (reservierung: Reservation, tische: Table[]) => {
    try {
      const resp = await addItem(
        tische.map((t) => t.id),
        {},
        "AssignTischeToReservierung",
        true,
        "AssignTables",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  UnassignTischeToReservierung: async (reservierung: Reservation) => {
    try {
      const resp = await addItem(
        undefined,
        {},
        "UnassignTischeToReservierung",
        true,
        "UnassignTables",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  CancelReservierung: async (reservierung: Reservation, reason: string, reasonPhrase: string) => {
    try {
      const resp = await addItem({ reason, reasonPhrase }, {}, "CancelReservierung", false, undefined, reservierung.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  ConfirmReservierung: async (reservierung: Reservation, createEmail: boolean, email?: Email) => {
    try {
      const resp = await addItem(
        { reservation: reservierung, email },
        { createEmail: Boolean(createEmail) },
        "ConfirmReservierung",
        true,
        "ConfirmReservation",
        reservierung.id
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  LeaveReservierung: async (reservierung: Reservation) => {
    try {
      const resp = await addItem(reservierung, {}, "LeaveReservierung", true, "leave", reservierung.id);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addTischToDate: async (date: Date, tisch: Table) => {
    try {
      const resp = await addItem(tisch, { date }, "AddTische");
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addGastToDate: async (date: Date, gast: Gast.Client) => {
    try {
      const resp = await addItem(gast, { date }, "AddGaeste");
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addAnfrageToDate: async (date: Date, anfrage: Anfrage.Client) => {
    try {
      const resp = await addItem(anfrage, { date }, "AddAnfragen");
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addVeranstaltung: async (veranstaltung: Pick<VeranstaltungSimplified.Client, "titel" | "veranstaltungDatum">) => {
    try {
      const resp = await addItem(
        { ...veranstaltung, id: EMPTY_GUID } as VeranstaltungSimplified.Client,
        {},
        "AddVeranstaltung",
        false
      );
      return resp;
    } catch (error) {
      throw error;
    }
  },
  removeVeranstaltung: async (veranstaltung: VeranstaltungSimplified.Client) => {
    try {
      const resp = await addItem(veranstaltung, {}, "RemoveVeranstaltung", false);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  addInformation: async (information: Information) => {
    try {
      const resp = await addItem(Information.Mapper.clientToServer(information), {}, "AddInformation", false);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  updateInformation: async (information: Information) => {
    try {
      const resp = await addItem(Information.Mapper.clientToServer(information), {}, "UpdateInformation", false);
      return resp;
    } catch (error) {
      throw error;
    }
  },
  removeInformation: async (information: Information) => {
    try {
      const resp = await addItem(Information.Mapper.clientToServer(information), {}, "RemoveInformation", false);
      return resp;
    } catch (error) {
      throw error;
    }
  },
};

const addItem = async <P, T, K extends keyof typeof Options>(
  item: T,
  params: P,
  optionKey: K,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string
) => {
  try {
    const resp = await sendToApi(
      optionKey,
      params,
      {
        body: item ? JSON.stringify(item) : item,
      } as (typeof Options)[typeof optionKey],
      includeKey,
      overWriteKey,
      preKey
    );
    try {
      const json: ServerResponse = await resp.json();
      return json;
    } catch (error) {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

const getItems = async <P, T, R>(
  params: P,
  optionKey: keyof typeof Options,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string,
  mapper?: (item: T) => R,
  stale?: boolean
) => {
  try {
    const resp = await sendToApi(optionKey, params, undefined, includeKey, overWriteKey, preKey, stale);
    const json: T[] = await resp.json();
    if (mapper && Array.isArray(json)) return json.map(mapper);
    return json;
  } catch (error) {
    throw error;
  }
};

const getItem = async <P, T>(
  params: P,
  optionKey: keyof typeof Options,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string,
  respFunc?: keyof Pick<Response, "arrayBuffer" | "blob" | "json" | "formData">
) => {
  try {
    const resp = await sendToApi(optionKey, params, undefined, includeKey, overWriteKey, preKey);
    if (resp && respFunc && respFunc in resp && typeof resp[respFunc] === "function") {
      const result: T = await resp[respFunc]();
      return result;
    } else {
      if (resp === undefined || resp === null) throw new Error("Response was null or undefined");
      else if (respFunc === undefined) throw new Error("Chosen Function was undefined");
      else if (!(respFunc in resp)) throw new Error("Chosen Function does not exist in response");
      else if (!(typeof resp[respFunc] === "function"))
        throw new Error("Given property for response function was not of type function");
      else throw new Error("Could not determine what went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const sendToApi = async <P, T extends keyof typeof Options, O extends Partial<(typeof Options)[T]>>(
  optionKey: T,
  params: P,
  option?: O,
  includeKey?: boolean,
  overWriteKey?: string,
  preKey?: string,
  stale?: boolean
) => {
  try {
    const tempOpts: RequestOption = { ...Options[optionKey], ...option } || Options[optionKey];
    const apiKey = (tempOpts as any)["APIKey"] ?? null;
    const props = JSON.stringify({ apiKey, params });

    const mandant = SettingsProvider.get("mandant");
    const baseUrl = window.location.hostname === "localhost" ? "https://localhost:44315" : window.location.origin;
    const paramsString = paramsToString({ restaurantId: mandant, ...params });

    isBlocked = false;
    // let token = authProvider.getToken();
    // const token = await getToken();
    const accessToken = sessionStorage.getItem("token_fallback");

    const opts: RequestOption = {
      ...tempOpts,
      headers: {
        ...(tempOpts.headers || {}),
        Authorization: "Bearer " + accessToken,
        [propsHeader]: props,
        ...(stale ? { [onlyStaleHeader]: onlyStaleHeader } : {}),
      },
    };

    const url = `${baseUrl}/${opts.API}${preKey ? `/${preKey}` : ""}${
      includeKey ? `/${overWriteKey || optionKey}` : ""
    }${paramsString}`;

    const wrappedFetch = new Promise<Response>((resolve, reject) => fetch(url, opts).then(resolve).catch(reject));

    const resp: Response = await wrappedFetch;
    if (resp.ok) {
      return resp;
    } else {
      try {
        addProtocol({
          data: option ? JSON.stringify(option, null, 2) : "--",
          desc: `Fehler - ${opts.method ?? "??"} - ${url}`,
          type: "API",
          date: new Date(),
        });
      } catch (error) {
        console.error("couldn't add to protocoll", error);
      }

      if (resp.status === 401) {
        window.dispatchEvent(new CustomEvent("TOKEN_REFRESH"));
      }

      throw new Error(`${resp.statusText || "Status did not indicate Success"} - ${resp.status} - ${optionKey}`);
    }
  } catch (error) {
    if (
      error &&
      (error as any).message &&
      !(error as any).message.includes("no_tokens_found") &&
      error instanceof InteractionRequiredAuthError
    ) {
      // if (timeout) clearTimeout(timeout);
      // counter.increase();
      // timeout = setTimeout(() => {
      //   counter.reset();
      // }, 2500);
      // const desc = `${typeof error === "string" ? error : error && "name" in error ? error.name : "--"} \r\n\r\n ${
      //   error && "message" in error ? (error as any).message : "--"
      // } \r\n\r\n ${error && "stack" in error ? error.stack : "--"} \r\n\r\n ${error.errorCode ?? "--"} \r\n\r\n ${
      //   error.errorMessage ?? "--"
      // }`;
      // addProtocol({
      //   data: `${
      //     option ? JSON.stringify(option, null, 2) : "--"
      //   } \r\n\r\n ${desc} \r\n\r\n Sollte Blockiert werden: ${isBlocked}`,
      //   desc: `Fehler - Silent Token Renewal Fehlgeschlagen`,
      //   type: "API",
      //   date: new Date(),
      // });
      // if (!isBlocked) {
      //   isBlocked = true;
      //   const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();
      //   acquireToken(InteractionType.Redirect, {
      //     ...AuthConfig.apiRequest.reservierungen,
      //     account: savedAccount ?? undefined,
      //   });
      // }
    } else if (error instanceof InteractionRequiredAuthError) {
      // const desc = `${typeof error === "string" ? error : error && "name" in error ? error.name : "--"} \r\n\r\n ${
      //   error && "message" in error ? (error as any).message : "--"
      // } \r\n\r\n ${error && "stack" in error ? error.stack : "--"}`;
      // addProtocol({
      //   data: desc,
      //   desc: `InteractionRequiredAuthError - ServerApi.ts - ${option ? JSON.stringify(option, null, 2) : "--"}`,
      //   type: "API",
      //   date: new Date(),
      // });
      // if (!isBlocked) {
      //   isBlocked = true;
      //   PCA.loginRedirect(AuthConfig.loginRequest);
      // }
    } else {
      const desc = `${
        typeof error === "string" ? error : error && "name" in (error as any) ? (error as any).name : "--"
      } \r\n\r\n ${error && "message" in (error as any) ? (error as any).message : "--"} \r\n\r\n ${
        error && "stack" in (error as any) ? (error as any).stack : "--"
      }`;
      addProtocol({
        data: desc,
        desc: `Error - ServerApi.ts - ${option ? JSON.stringify(option, null, 2) : "--"}`,
        type: "API",
        date: new Date(),
      });
    }
    if (SettingsProvider.get("showServerApiError")) {
      addMessage({
        title: "showServerApiError",
        text: error instanceof Error ? (error as any).message : JSON.stringify(error),
        color: "red",
        icon: "ExclamationCircleIcon",
        delay: 6e4,
      });
    }
    console.error("serverapi", error);
    throw error;
  }
};

const paramsToString = <P extends object>(params: P) => {
  return (Object.keys(params) as (keyof typeof params)[]).reduce((baseString, prop, idx) => {
    if (idx !== 0) {
      baseString = baseString + `&${prop.toString()}=${params[prop]}`;
    } else {
      baseString = baseString + `?${prop.toString()}=${params[prop]}`;
    }
    return baseString;
  }, "");
};

const ServerApi = {
  ...Queries,
  ...Commands,
  APIS,
};

export default ServerApi;
