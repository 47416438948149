import * as React from "react";
import { AppColors } from "../models/General";
import BaseButton from "./Button/BaseButton";

export const InformationButton: React.FC<{
  appColors: AppColors;
  color?: string;
  additionalClasses?: string;
  spanClass?: string;
  big?: boolean;
  handleClick: () => any;
  children?: React.ReactNode | React.ReactNode[];
}> = (props) => {
  return (
    <BaseButton
      identifier={`Info_btn ${props.children}`}
      onClick={props.handleClick}
      className={`text-xs outline-none focus:outline-none whitespace-pre-wrap ${props.additionalClasses ?? ""}`}
      paddingOverwrite="px-1 py-2 !pr-1"
    >
      {props.children}
    </BaseButton>
  );
};
