import * as React from "react";
import { AppState } from "../App";
import { MessageBox } from "./MessageBox";

export const InstallPrompt = (props: {
  shouldDisplay: boolean;
  logo: any;
  neverShowAgain: () => any;
  afterInstallation?: (installed: boolean) => any;
  appColors: AppState["appColors"];
}) => {
  const { shouldDisplay } = props;
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const [installEvent, setInstallEvent] = React.useState<Event | undefined>(undefined);

  const _handleInstallation = () => {
    setIsDisplaying(() => {
      if (installEvent && (installEvent as any).prompt && (installEvent as any).userChoice) {
        (installEvent as any).prompt();
        (installEvent as any).userChoice.then((choice: boolean) => {
          setIsDisplaying(() => {
            return !choice;
          });
          if (props.afterInstallation) {
            props.afterInstallation(choice);
          }
        });
      }
      return false;
    });
  };

  React.useEffect(() => {
    if (window && shouldDisplay) {
      const handlePrompt = (e: Event) => {
        e.preventDefault();
        if ((e as any).persist) {
          (e as any).persist();
        }
        setIsDisplaying(() => {
          setInstallEvent(e);
          return true;
        });
      };
      window.addEventListener("beforeinstallprompt", handlePrompt);
      return () => {
        window.removeEventListener("beforeinstallprompt", handlePrompt);
      };
    }
  }, [shouldDisplay]);

  return (
    <MessageBox
      appColors={props.appColors}
      clickText={"Hier klicken zum installieren"}
      handleClick={_handleInstallation}
      handleClose={() => {
        setIsDisplaying(false);
      }}
      handleNeverAgain={() => {
        setIsDisplaying(() => {
          props.neverShowAgain();
          return false;
        });
      }}
      isDisplaying={isDisplaying}
      text={"Diese Seite kann installiert werden"}
      icon="SparklesIcon"
    ></MessageBox>
  );
};
