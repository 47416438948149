import { BellIcon, LogoutIcon } from "@heroicons/react/outline";
import { FolderOpenIcon, RefreshIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useEffect } from "react";
import DebugContext from "../../hooks/RemoteDebug/DebugContext";
import { useToggle } from "../../hooks/useToggle";

type DebugConnectionViewProps = {};

const DebugConnectionView = (props: DebugConnectionViewProps) => {
  const {
    connections,
    greet,
    greetAll,
    remoteLogout,
    remoteBrake,
    isConnected,
    isConnecting,
    resetConnections,
    requestStorageInformation,
    SESSION_ID,
    connect,
  } = React.useContext(DebugContext);

  const [isRefreshing, , refreshing, refreshed] = useToggle(false);

  useEffect(() => {
    if (isRefreshing && connections.length <= 0) {
      const timeout = setTimeout(() => {
        refreshed();
      }, 3500);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    } else if (isRefreshing) {
      refreshed();
    }
  }, [connections.length, isRefreshing, refreshed]);

  return (
    <div className="inline-flex flex-col w-full h-full min-h-0 justify-start items-start content-start px-0.5 gap-2 pt-2">
      <span className="inline-flex gap-3">
        <span className="inline-flex flex-col text-sm font-semibold tracking-wide min-h-0">
          <span className="font-normal">SessionID: </span>
          <span>{SESSION_ID}</span>{" "}
        </span>
        <span
          className={`inline-flex flex-col content-start justify-center items-center ${
            isConnecting
              ? "bg-yellow text-gray-700 pointer-events-none"
              : isConnected
              ? "bg-green-500 text-white px-2 pointer-events-none"
              : "bg-white-500 text-green-500 px-2 pointer-events-auto"
          }`}
          onClick={connect}
        >
          <span>{isConnecting ? "Verbindet..." : isConnected ? "Verbunden" : "Neu Verbinden"}</span>
        </span>
        <button
          onClick={() => {
            refreshing();
            resetConnections();
            greetAll();
          }}
          className="p-2 bg-primary-500 text-white disabled:bg-gray-300 disabled:text-gray-600"
          disabled={isRefreshing}
        >
          {isRefreshing ? "Lädt..." : "Refresh"}
        </button>
      </span>

      <span>Gefundene Verbindungen: {connections.length}</span>
      <div className="inline-grid grid-cols-1 w-full min-h-0 overflow-y-auto overflow-x-hidden justify-start items-start content-start">
        {connections.length ? (
          connections.map(({ sessionId, lastContact, accountName, userAgent, version }, id) => (
            <span
              className="inline-flex flex-col text-sm gap-3 min-h-0 justify-start items-start content-start ring-1 rounded-md ring-gray-300 px-3 py-3 bg-white"
              key={id}
            >
              <span className="inline-flex flex-col gap-1 flex-1 text-xs">
                <span className="inline-flex gap-2 items-center">
                  <span className="bg-primary-50 text-primary-500 font-semibold py-0.5 px-1.5 rounded-md -ml-1.5">
                    {getDeviceType(userAgent)}
                  </span>
                  <span>{accountName}</span>
                  <span className="font-semibold tabular-nums">v{version ?? "??"}</span>
                </span>
                <span className="inline-flex gap-1 content-start items-center justify-start">
                  <span>Letzter Kontakt:</span>
                  <span className="tabular-nums font-semibold tracking-wide px-1.5 py-0.5 bg-green-50 text-green-600 rounded-md">
                    {lastContact.toLocaleDateString("de-de", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </span>
                </span>
                <span>{sessionId}</span>
              </span>
              <span className="inline-flex flex-wrap gap-1 flex-shrink-0 content-start justify-end items-center ">
                <button
                  className="inline-flex justify-center items-center content-start px-2 py-1 gap-2 bg-green-50 text-green-500 rounded"
                  onClick={() => greet(sessionId)}
                >
                  <BellIcon className="w-5 h-5 " />
                  <span>Ping</span>
                </button>
                <button
                  className="inline-flex justify-center items-center content-start px-2 py-1 gap-2 bg-red-50 text-red-500 rounded"
                  onClick={() => remoteLogout(sessionId)}
                >
                  <LogoutIcon className="w-5 h-5 " />
                  <span>Ausloggen</span>
                </button>
                <button
                  className="inline-flex justify-center items-center content-start px-2 py-1 gap-2 bg-red-500 text-white rounded"
                  onClick={() => {
                    const conf = window.confirm(`ID ${sessionId} Neustarten?`);
                    if (conf) {
                      remoteBrake(sessionId);
                    }
                  }}
                >
                  <RefreshIcon className="w-5 h-5" />
                  <span>Neustart</span>
                </button>
                <button
                  className="inline-flex justify-center items-center content-start px-2 py-1 gap-2 bg-primary-500 text-white rounded"
                  onClick={() => {
                    const conf = window.confirm(`ID ${sessionId} Storage anfordern?`);
                    if (conf) {
                      requestStorageInformation(sessionId);
                    }
                  }}
                >
                  <FolderOpenIcon className="w-5 h-5" />
                  <span>Storage anfordern</span>
                </button>
              </span>
            </span>
          ))
        ) : (
          <span className="w-full h-12 text-center text-xs mt-12">Keine Verbindungen gefunden</span>
        )}
      </div>
    </div>
  );
};
export default DebugConnectionView;

const getDeviceType = (userAgent: string) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  if (isMobile) {
    const deviceTypes = ["Android", "webOS", "iPhone", "iPad", "iPod", "BlackBerry", "IEMobile", "Opera Mini"];
    return deviceTypes.filter((c) => userAgent.includes(c)).join(" - ");
  } else {
    return "Desktop";
  }
};
