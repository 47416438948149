import { useCallback, useEffect, useRef, useState } from "react";
import { addProtocol } from "../useProtocol";
import { useToggle } from "../useToggle";

const URL = window.location.host.includes("localhost")
  ? `wss://localhost:44315/websocket/calls`
  : window.location.host.includes("192.168.")
  ? "ws://192.168.178.28:45456"
  : `wss://${window.location.host}/websocket/calls`;

const useDebugWebsocket = (messageCB: (msg: MessageEvent["data"]) => void) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const cbRef = useRef<typeof messageCB>(messageCB);
  const [isConnected, , connected, disconnected] = useToggle(false);
  const [isConnecting, , connecting, notConnecting] = useToggle(false);

  const connect = useCallback(() => {
    connecting();
    try {
      const ws = new WebSocket(URL);
      setWs(ws);
    } catch (error) {
      throw new Error(error as any);
    } finally {
      notConnecting();
    }
  }, [connecting, notConnecting]);

  const onConnection = useCallback(async () => {
    connected();
    try {
      await addProtocol({ desc: "Debug Websocket connected!", data: URL, type: "API" });
    } catch (error) {
      console.error(error as any);
      await addProtocol({ desc: "Debug Websocket - Error!", data: (error as any).message, type: "API" });
    }
  }, [connected]);

  const onClose = useCallback(
    async (e: CloseEvent) => {
      disconnected();
      console.log({ closeEvent: e });
      try {
        if (e.code === 1000) {
          return;
        } else if (e.code === 1006) {
          connect();
        } else {
          if (ws) ws.close();
          await addProtocol({ desc: "Debug Websocket disconnected", data: URL, type: "API" });
        }
      } catch (error) {
        await addProtocol({ desc: "Debug Websocket onClose - Error", data: (error as any).message, type: "API" });
        console.error(error as any);
        throw error;
      }
    },
    [connect, disconnected, ws]
  );

  const onError = useCallback(
    async (ev: Event) => {
      try {
        console.log({ ev });
        if (ws) ws.close();
        const val = { ...ev };
        await addProtocol({ desc: `Websocket Error - ${URL}`, data: JSON.stringify(val, null, 2), type: "API" });
      } catch (error) {
        console.error(error as any);
        await addProtocol({ desc: `Websocket Error - Error - ${URL}`, data: (error as any).message, type: "API" });
      }
    },
    [ws]
  );

  const onMessage = useCallback(async (msg: MessageEvent) => {
    try {
      console.log({ msg });
      const message = JSON.parse(msg.data);
      if (message.isDebug) {
        if (cbRef.current) cbRef.current(message);
      } else return;
    } catch (error) {
      console.error(error as any);
      await addProtocol({ desc: `Websocket Message Error - ${URL}`, data: (error as any).message, type: "API" });
    }
  }, []);

  useEffect(() => {
    if (!isConnected && !isConnecting) {
      connect();
    }
  }, [connect, isConnected, isConnecting]);

  useEffect(() => {
    if (isConnected && ws) {
      const temp = ws;
      return () => {
        temp.close();
      };
    }
  }, [isConnected, ws]);

  useEffect(() => {
    if (ws) {
      console.log("started listening to debug commands");
      ws.addEventListener("open", onConnection);
      ws.addEventListener("close", onClose);
      ws.addEventListener("error", onError);
      ws.addEventListener("message", onMessage);

      return () => {
        console.log("STOPPED listening to debug commands");
        ws.removeEventListener("open", onConnection);
        ws.removeEventListener("close", onClose);
        ws.removeEventListener("error", onError);
        ws.removeEventListener("message", onMessage);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws]);

  return { isConnected, websocket: ws, isConnecting, connect };
};
export default useDebugWebsocket;
