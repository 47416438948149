import * as IDB from "idb-keyval";
import { addProtocol } from "../hooks/useProtocol";
import SettingsProvider from "./SettingsProvider";

const WRITE_TO_PROTOCOL: boolean = false;
const WRITE_ERRORS_TO_PROTOCOL: boolean = true;
const conditionallyAddToProtocol = (isError: boolean, ...args: Parameters<typeof addProtocol>) => {
  try {
    const [value, flush] = args;
    if ((isError && WRITE_ERRORS_TO_PROTOCOL) || WRITE_TO_PROTOCOL) {
      return addProtocol(value, flush);
    }
  } catch (error) {
    throw error;
  }
};
let isBlocked = false;

const toggleBlock = (ev: CustomEvent<boolean | null | undefined>) => {
  if (typeof ev.detail === "boolean") {
    isBlocked = ev.detail;
  } else {
    isBlocked = !isBlocked;
  }
};

const PERSISTANT_STORE = "keyval";
const PERSISTANT_DB = "keyval-store";

const Events = {
  STORAGE_UPDATED: "STORAGE_UPDATED",
  STORAGE_BLOCK: "STORAGE_BLOCK",
};

window.addEventListener(Events.STORAGE_BLOCK, toggleBlock as any);

const PersistentStorageRepository = (idbStore?: IDB.UseStore, cb?: () => any) => {
  let fails = 0;
  let interval: ReturnType<typeof setTimeout> | null = null;
  if (idbStore === undefined || idbStore === null) {
    idbStore = IDB.createStore(PERSISTANT_DB, PERSISTANT_STORE);
  }

  try {
    const createStore = () => {
      try {
        addProtocol({
          data: "Laedt",
          desc: "Status der IndexedDB-Erstellung",
          type: "CACHE",
          date: new Date(),
        });
        console.log("lädt indexedDB");
        if (idbStore === undefined || idbStore === null) {
          idbStore = IDB.createStore(PERSISTANT_DB, PERSISTANT_STORE);
        }
        addProtocol({
          data: "Erstellt",
          desc: "Status der IndexedDB-Erstellung",
          type: "CACHE",
          date: new Date(),
        });
        return true;
      } catch (error) {
        const err: any = error;
        addProtocol({
          data: `Fehler - ${err.message ?? "--"} - ${err.name ?? "--"} - ${JSON.stringify(
            error,
            null,
            2
          )} - Versuche: ${fails}`,
          desc: "Status der IndexedDB-Erstellung",
          type: "CACHE",
          date: new Date(),
        });
        console.error("Fehler beim laden der indexedDB");
        console.error(error);
        if (fails >= 15) {
          addProtocol({
            data: `Laedt Seite Neu`,
            desc: "Status der IndexedDB-Erstellung",
            type: "CACHE",
            date: new Date(),
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          fails += 1;
          return false;
        }
      }
    };

    let storeCreated = createStore();
    if (!storeCreated) {
      interval = setInterval(() => {
        if (idbStore === undefined || idbStore === null) {
          createStore();
        } else {
          if (interval) clearInterval(interval);
        }
      }, 1500);
    } else {
      if (cb) cb();
    }
  } catch (error) {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  const dispatchChangeEvent = (key: string) => {
    const storageUpdatedEvent = new CustomEvent<string>(Events.STORAGE_UPDATED, {
      detail: key.toUpperCase(),
    });
    return window.dispatchEvent(storageUpdatedEvent);
  };

  const write = async <T extends string>(key: T, date: Date | undefined, value: any) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      conditionallyAddToProtocol(false, {
        desc: `IDB - Write - ${key} - ${mapLocation(key, date)} - ${typeof value}`,
        data:
          typeof value === "string" || typeof value === "number" || typeof value === "boolean"
            ? value + ""
            : typeof value === "object"
            ? JSON.stringify(value, null, 2)
            : "--",
        type: "CACHE",
        date: new Date(),
      });
      await IDB.set(mapLocation(key, date), value, idbStore);
      return dispatchChangeEvent(key.toUpperCase());
    } catch (error) {
      const err: any = error;
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - Write - ${key} - ${date} - ${typeof value}`,
        data:
          (typeof value === "string" || typeof value === "number" || typeof value === "boolean"
            ? value + ""
            : typeof value === "object"
            ? JSON.stringify(value, null, 2)
            : "--") +
          "## ERROR ##" +
          err?.message,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const has = async (key: string | string[], date: Date) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      const keys = await IDB.keys(idbStore);
      if (Array.isArray(key)) {
        return key.filter((key) => !keys.includes(mapLocation(key, date)));
      } else {
        return keys.includes(mapLocation(key, date));
      }
    } catch (error) {
      throw error;
    }
  };

  const keys = async () => {
    try {
      const keys = await IDB.keys(idbStore);
      if (keys && Array.isArray(keys)) {
        return keys;
      }
      if (typeof keys === "string") {
        return [keys];
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  };

  const add = async <T extends object, B extends string>(
    key: B,
    date: Date | undefined,
    value: T,
    overwriteExisting?: boolean,
    overwriteByKey?: keyof T,
    createIfNotExist?: boolean,
    shouldBeArray?: boolean,
    overwriteWhenValue?: T[keyof T]
  ) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      conditionallyAddToProtocol(false, {
        desc: `IDB - Add - ${key} - ${mapLocation(key, date)} - ${typeof value}`,
        data:
          typeof value === "string" || typeof value === "number" || typeof value === "boolean"
            ? value + ""
            : typeof value === "object"
            ? JSON.stringify(value, null, 2)
            : "--" +
              JSON.stringify(
                {
                  key,
                  date,
                  overwriteExisting,
                  overwriteByKey,
                  createIfNotExist,
                  shouldBeArray,
                  overwriteWhenValue,
                },
                null,
                2
              ),
        type: "CACHE",
        date: new Date(),
      });
      const currentItem = await IDB.get<T | T[]>(mapLocation(key, date), idbStore);
      if (currentItem) {
        if (Array.isArray(currentItem)) {
          let index = 0;
          if (overwriteExisting && currentItem.length > 0) {
            const overwriteKey = overwriteByKey || "id";
            if (
              overwriteByKey &&
              value.hasOwnProperty(overwriteKey) &&
              currentItem.some(
                (e) => e[overwriteByKey] === value[overwriteByKey] || e[overwriteByKey] === overwriteWhenValue
              )
            ) {
              await write(
                key,
                date,
                [...currentItem].map((e, idx) => {
                  if (overwriteWhenValue) {
                    if (e[overwriteByKey] === overwriteWhenValue) {
                      index = idx;
                      return value;
                    } else {
                      return e;
                    }
                  } else {
                    if (e[overwriteByKey] === value[overwriteByKey]) {
                      index = idx;
                      return value;
                    } else {
                      return e;
                    }
                  }
                })
              );
            } else if (createIfNotExist) {
              index = currentItem.length;
              await write(key, date, [...currentItem, value]);
            } else {
              window.alert("Failed to overwrite - date does not have either id or overwrite key");
            }
          } else {
            index = currentItem.length;
            await write(key, date, [...currentItem, value]);
          }
          return index;
        } else if (currentItem instanceof Object) {
          if (overwriteExisting) {
            const overwriteKey = overwriteByKey || "id";
            if (overwriteByKey && value.hasOwnProperty(overwriteKey)) {
              await write(key, date, { ...currentItem, [overwriteKey]: value });
            } else {
              await write(key, date, { ...currentItem, ...value });
            }
          } else {
            await write(key, date, value);
          }
        } else {
          await write(key, date, currentItem + value);
        }
      } else if (createIfNotExist) {
        if (shouldBeArray) {
          await write(key, date, [value]);
        } else {
          await write(key, date, value);
        }
      }
      return null;
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - Add - ${key} - ${mapLocation(key, date)} - ${typeof value}`,
        data:
          typeof value === "string" || typeof value === "number" || typeof value === "boolean"
            ? value + ""
            : typeof value === "object"
            ? JSON.stringify(value, null, 2)
            : "--" +
              JSON.stringify(
                {
                  key,
                  date,
                  overwriteExisting,
                  overwriteByKey,
                  createIfNotExist,
                  shouldBeArray,
                  overwriteWhenValue,
                },
                null,
                2
              ) +
              "\r\n\r\n ## ERROR ##" +
              error,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const removeEntry = async <T>(key: string, filter: (item: T, idx: number) => boolean, date?: Date) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      const loaded = await get<T[]>(key, date);
      conditionallyAddToProtocol(false, {
        desc: `IDB - removeEntry - ${key} - ${mapLocation(key, date)}`,
        data: JSON.stringify(loaded, null, 2),
        type: "CACHE",
        date: new Date(),
      });
      if (loaded) {
        return await dangerouslySet(mapLocation(key, date), loaded.filter(filter));
      }
      return dispatchChangeEvent(key.toUpperCase());
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - removeEntry - ${key} - ${mapLocation(key, date)}`,
        data: (error as any)?.message,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const remove = async (key: string, date?: Date) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      conditionallyAddToProtocol(false, {
        desc: `IDB - remove - ${key} - ${mapLocation(key, date)}`,
        data: "--",
        type: "CACHE",
        date: new Date(),
      });
      await IDB.del(mapLocation(key, date), idbStore);
      return dispatchChangeEvent(key.toUpperCase());
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - remove - ${key} - ${mapLocation(key, date)}`,
        data: (error as any)?.message,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const dangerouslyRemove = async (key: string, keyToDispatch?: string) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      conditionallyAddToProtocol(false, {
        desc: `IDB - dangerouslyRemove - ${key}`,
        data: "--",
        type: "CACHE",
        date: new Date(),
      });
      await IDB.del(key, idbStore);
      return keyToDispatch ? dispatchChangeEvent(keyToDispatch.toUpperCase()) : null;
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - dangerouslyRemove - ${key}`,
        data: "--",
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const get = async <T>(key: string, date?: Date): Promise<T | null> => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      const location = mapLocation(key, date);
      conditionallyAddToProtocol(false, {
        desc: `IDB - get - ${key} - ${location}`,
        data: "--",
        type: "CACHE",
        date: new Date(),
      });
      const keys = await IDB.keys(idbStore);
      if (keys.includes(location)) {
        const loaded = await IDB.get(location);
        let response: any | null = null;
        if (loaded) {
          response = loaded;
        }
        return response;
      } else {
        return null;
      }
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - get - ${key}`,
        data: (error as any)?.message,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const dangerouslyGet = async (location: string) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      conditionallyAddToProtocol(false, {
        desc: `IDB - dangerouslyGet - ${location}`,
        data: "--",
        type: "CACHE",
        date: new Date(),
      });
      const keys = await IDB.keys(idbStore);
      if (keys.includes(location)) {
        const loaded = await IDB.get(location, idbStore);
        let response: any | null = null;
        if (loaded) {
          response = loaded;
        }
        return response;
      } else {
        return null;
      }
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - dangerouslyGet - ${location}`,
        data: (error as any)?.message,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const dangerouslySet = async (location: string, value: any) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      conditionallyAddToProtocol(false, {
        desc: `IDB - dangerouslyGet - ${location} - ${typeof value}`,
        data: JSON.stringify(value),
        type: "CACHE",
        date: new Date(),
      });
      await IDB.set(location, value, idbStore);
      return;
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - dangerouslyGet - ${location} - ${typeof value}`,
        data: (error as any)?.message,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const dangerouslyClear = async (cidbStore?: IDB.UseStore) => {
    try {
      if (isBlocked) throw new Error("IDB is blocked");
      conditionallyAddToProtocol(false, {
        desc: `IDB - dangerouslyClear`,
        data: "--",
        type: "CACHE",
        date: new Date(),
      });
      await IDB.clear(cidbStore ?? idbStore);
      return;
    } catch (error) {
      conditionallyAddToProtocol(true, {
        desc: `IDB - ERROR - dangerouslyClear`,
        data: (error as any)?.message,
        type: "CACHE",
        date: new Date(),
      });
      throw error;
    }
  };

  const mapLocation = (key: string, date?: Date) => {
    const identifier = date ? date.toISOString().slice(0, 10) : "default";
    const mandant = SettingsProvider.get("mandant");
    return `${mandant}_${identifier}_${key}`;
  };

  return {
    write,
    add,
    has,
    remove,
    get,
    dangerouslyGet,
    dangerouslySet,
    Events,
    keys,
    removeEntry,
    dangerouslyClear,
    dangerouslyRemove,
  };
};

export default PersistentStorageRepository;
