import { useState, useCallback, useEffect } from "react";
import { addProtocol } from "./useProtocol";

const tryReach = async (serverUrl: string, date: Date) => {
  try {
    const wrappedResp = new Promise<Response>((resolve, reject) =>
      fetch(`${serverUrl}?checkTime=${+new Date()}`, {
        method: "HEAD",
        mode: "no-cors",
        cache: "no-store",
        headers: {
          "Cache-Control": "no-store",
          pragma: "no-cache",
        },
      })
        .then(resolve)
        .catch(reject)
    );
    const resp = await wrappedResp;
    if (resp && (resp.ok || resp.type === "opaque")) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const useConnected = () => {
  const [isOnline, setIsOnline] = useState<boolean | null>(false);

  const _handleConnection = useCallback(async () => {
    try {
      if (navigator && typeof navigator === "object" && "onLine" in navigator) {
        if (navigator.onLine) return setIsOnline(true);
        const isReachable = await tryReach(window.location.origin, new Date());
        addProtocol({
          data: isReachable ? "Online" : "Offline",
          desc: "useConnected Hook",
          type: "API",
          date: new Date(),
        });
        return setIsOnline(isReachable);
      }
      const isReachable = await tryReach(window.location.origin, new Date());
      return setIsOnline(isReachable);
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    let connectionHandler = _handleConnection;
    connectionHandler();
    window.addEventListener("online", connectionHandler);
    window.addEventListener("offline", connectionHandler);
    return () => {
      window.removeEventListener("online", connectionHandler);
      window.removeEventListener("offline", connectionHandler);
    };
  }, [_handleConnection]);

  return isOnline;
};

export default useConnected;
