import { useCallback } from "react";
import { PersistentStorageRepository } from "../../../clientApi/ClientApi";
import StorageOverviewViewTypes from "./StorageOverviewViewTypes";

export const useStorageOverviewViewModel = (props: StorageOverviewViewTypes.Props) => {
  const relayRemoveStorage = useCallback(
    (key: string | null) => async () => {
      try {
        const confirm = window.confirm(`${key} wirklich löschen?`);
        if (confirm && key) {
          const resp = await PersistentStorageRepository!.dangerouslyRemove(key);
          await props.refresh();
        }
      } catch (error) {
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.refresh]
  );

  const relayRemoveLocalStorage = useCallback(
    (key: string | null) => async () => {
      try {
        const confirm = window.confirm(`${key} wirklich löschen?`);
        if (confirm && key) {
          localStorage.removeItem(key);
          await props.refresh();
        }
      } catch (error) {
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.refresh]
  );

  return { relayRemoveStorage, relayRemoveLocalStorage };
};
