import { useCallback, useLayoutEffect, useState, useTransition } from "react";

const useIsRendered = () => {
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [, startTransition] = useTransition();

  const hasRendered = useCallback(() => {
    startTransition(() => {
      setIsRendered(true);
    });
  }, []);

  useLayoutEffect(() => {
    const request = window.requestAnimationFrame(hasRendered);
    return () => {
      window.cancelAnimationFrame(request);
    };
  }, [hasRendered]);

  return isRendered;
};
export default useIsRendered;
