import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

const useHistory = () => {
  const navigate = useNavigate();

  const push = useCallback(
    (url: string, options?: NavigateOptions) => {
      return navigate(url, options);
    },
    [navigate]
  );

  const replace = useCallback(
    (url: string, options?: NavigateOptions) => {
      return navigate(url, { replace: true, ...options });
    },
    [navigate]
  );

  const goBack = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  return { push, replace, goBack };
};
export default useHistory;
