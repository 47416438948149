import * as React from "react";
import BasicButton from "./Button/BasicButton";

export const RoundButton: React.FC<{
  isVisible: boolean;
  onClick: (ev?: any) => void;
  identifier: string;
  withEvent?: boolean;
  isOpaque?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  className?: string;
}> = ({ className, isVisible, isOpaque, onClick, withEvent, children, identifier, disabled }) => {
  const onClickRef = React.useRef(onClick);
  React.useEffect(() => {
    onClickRef.current = onClick;
  });

  const _handleClick = React.useCallback(
    (ev: any) => {
      if (isVisible) {
        if (withEvent) {
          onClickRef.current(ev);
        } else {
          onClickRef.current();
        }
      }
    },
    [isVisible, withEvent]
  );

  const classname = `flex flex-grow-0 flex-shrink-0 justify-center items-center w-9 h-9 rounded-full bg-primary-500 shadow-md p-1 hover:bg-primary-600 ${
    isVisible ? (isOpaque ? "opacity-30" : "opacity-100 ") : "opacity-0 pointer-events-none"
  } transition-all duration-150 ease-in-out ${className ?? ""}`;

  return (
    <BasicButton disabled={disabled} identifier={identifier} className={classname} onClick={_handleClick}>
      {children}
    </BasicButton>
  );
};
