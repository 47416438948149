import { useCallback } from "react";
import { useSettings } from "../../clientApi/SettingsProvider";
import { Reservation } from "../../models/Reservation";
import { Table } from "../../models/Table";
import { clientApi } from "../Authenticated/AuthenticatedView";
import { TischeViewModel } from "./TischeViewType";

export const useTischeViewModel = (props: TischeViewModel.Props) => {
  const Settings = useSettings();

  const selectReservierung = useCallback(
    (res: Reservation | null) => (props.onReservationSelect ? props.onReservationSelect(res) : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onReservationSelect]
  );

  const deselectTisch = useCallback(
    (table: Table) => {
      console.log(props.selectedTables);
      if (props.onTableSelect && props.selectedTables)
        props.onTableSelect(props.selectedTables.filter((c) => c.id === table.id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onTableSelect, props.selectedTables]
  );

  const selectTisch = useCallback(
    (table: Table) => {
      if (props.selectedReservation || props.canSelectTablesWithoutReservation) {
        if (props.onTableSelect && props.selectedTables) {
          if (props.selectedTables.some((c) => c.id === table.id)) {
            props.onTableSelect(props.selectedTables.filter((c) => c.id !== table.id));
          } else {
            props.onTableSelect([...props.selectedTables, table]);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canSelectTablesWithoutReservation, props.onTableSelect, props.selectedReservation, props.selectedTables]
  );

  const deselectAllTische = useCallback(() => {
    if (props.onTableSelect && props.selectedTables) {
      props.onTableSelect([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onTableSelect]);

  const confirmSelectedTables = useCallback(async () => {
    try {
      if (props.selectedReservation) {
        if (props.selectedTables && props.selectedTables.length > 0) {
          await clientApi.Commands.assignTischeToReservierung(props.selectedReservation, props.selectedTables);
        } else {
          await clientApi.Commands.unassignTischeToReservierung(props.selectedReservation);
        }
        props.onTableConfirm?.call(undefined, {
          ...props.selectedReservation,
          tables: props.selectedTables,
          state: props.selectedTables?.length ? 2 : 1,
        });
      }
    } catch (error) {
      throw error;
    }
  }, [props.onTableConfirm, props.selectedReservation, props.selectedTables]);

  return {
    deselectTisch,
    selectTisch,
    deselectAllTische,
    spaceOnBottom: props.spaceOnBottom,
    confirmSelectedTables,
    selectReservierung,
  };
};
