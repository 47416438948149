import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { AppColors } from "../models/General";

export interface AppLinkProps extends Partial<AppColors>, NavLinkProps {}

export const AppLink: React.FunctionComponent<AppLinkProps> = (props) => (
  <NavLink
    style={({ isActive }) =>
      isActive ? { color: props.textcoloroncolor, transition: "all 250ms" } : { transition: "all 250ms" }
    }
    className={({ isActive }) =>
      `inline-flex px-4 justify-center items-center no-underline tracking-normal bg-transparent text-xl md:text-xs text-primary-100 md:text-primary-200 hover:text-white h-full overflow-hidden ${
        isActive ? "relative bg-primary-600 tracking-widest md:tracking-normal" : ""
      }`
    }
    {...props}
  >
    {typeof props.children === "string" ? <span className="truncate">{props.children}</span> : props.children}
  </NavLink>
);
