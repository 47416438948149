import { FC, HTMLProps, TouchEvent, useCallback, useEffect, useRef, useState } from "react";

type TastaturButtonProps = {
  onPress: () => void;
  className?: string;
  innerClassName?: string;
} & HTMLProps<HTMLButtonElement>;

const TastaturButton: FC<TastaturButtonProps> = ({ onPress, className, children, innerClassName, ...btnProps }) => {
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const [isPressed, setIsPressed] = useState<boolean>(false);
  const [isHighlighting, setisHighlighting] = useState<boolean>(false);

  const relayPress = useCallback(
    (ev: any) => {
      if ("preventDefault" in ev) ev.preventDefault();
      onPress();
      setisHighlighting(() => {
        setIsPressed(true);
        return true;
      });
    },
    [onPress]
  );

  const relayRelease = useCallback((ev: any) => {
    if ("preventDefault" in ev) ev.preventDefault();
    setIsPressed(() => {
      setisHighlighting(false);
      return false;
    });
  }, []);

  useEffect(() => {
    if (isHighlighting && !isPressed) {
      const timeout = setTimeout(() => {
        setisHighlighting(false);
      }, 100);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [isHighlighting, isPressed]);

  useEffect(() => {
    if (btnRef.current) {
      const btn = btnRef.current;
      const handlerStart = relayPress;
      const handlerEnd = relayRelease;
      btn.addEventListener("touchstart", handlerStart, { passive: false });
      btn.addEventListener("touchend", handlerEnd, { passive: false });
      return () => {
        btn.removeEventListener("touchstart", handlerStart);
        btn.removeEventListener("touchend", handlerEnd);
      };
    }
  }, [relayPress, relayRelease]);

  return (
    <button
      {...btnProps}
      ref={btnRef}
      className={`inline-flex justify-center items-center content-start select-none appearance-none flex-1 ${
        className ?? " h-full"
      } p-0.5 py-1`}
      type="button"
    >
      <span
        className={`inline-flex justify-center items-center content-start select-none rounded drop-shadow border-b border-gray-400 w-full h-full min-h-0 transition-all duration-100 ease-in-out pointer-events-none text-lg ${
          innerClassName ?? "bg-white"
        } ${isHighlighting ? "!bg-gray-400" : ""}`}
      >
        {children}
      </span>
    </button>
  );
};
export default TastaturButton;
