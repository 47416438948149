export interface Tenant {
  name: string;
}

export const loadTendant = () =>
  new Promise((resolve, reject) => {
    try {
      return resolve({ tendant: { name: "Meta-Objects" } });
    } catch (error) {
      return reject({ caller: "loadTendant", error: error });
    }
  }) as Promise<{ tendant: Tenant }>;
