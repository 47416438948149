import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { addProtocol } from "../hooks/useProtocol";

let isBlocked = false;

const getBaseUrl = () => {
  const localServerPort = "44315";
  const conveyorPort = "45456";
  const base = "https://";
  const { host, hostname, origin } = window.location;
  if (host.includes("localhost")) {
    return `${base}localhost:${localServerPort}`;
  } else if (host.includes("192.168.")) {
    return `http://192.168.178.28:${conveyorPort}`;
  } else return origin;
};

const baseUrl = getBaseUrl();

let abortControllers: Map<string, AbortController | null> = new Map();

type ApiCall = <T>(
  apiUrl: string,
  method: "POST" | "GET" | "PUT" | "DELETE",
  body?: string,
  mapper?: (response: any) => T,
  defaultReturn?: any
) => Promise<T>;
export const ApiCall: ApiCall = async (apiUrl, method, body, mapper, defaultReturn) => {
  try {
    let headers: any = {};
    if (method !== "GET") {
      headers["Content-Type"] = "application/json";
    }
    let abortController: AbortController | null | undefined = abortControllers.get(apiUrl);
    if (method === "GET" && abortController && !abortController.signal.aborted) {
      abortController.abort();
    }
    const url = apiUrl.includes("https://") ? apiUrl : `${baseUrl}/api/${apiUrl}`;
    const shortenUrl = url.slice((url.length - window.location.origin.length) * -1);

    addProtocol({
      data: body || "--",
      desc: `Calling - ${method} - ${shortenUrl}`,
      type: "API",
      date: new Date(),
    });

    isBlocked = false;

    abortController = new AbortController();
    if (method === "GET") {
      abortControllers.set(apiUrl, abortController);
    }

    const accessToken = sessionStorage.getItem("token_fallback");

    const promiseWrapper: Promise<Response> = new Promise((resolve, reject) =>
      fetch(url, {
        method,
        headers: { ...headers, Authorization: "Bearer " + accessToken },
        body: body,
        signal: abortController?.signal,
      })
        .then((resp) => {
          abortControllers.set(apiUrl, null);
          return resolve(resp);
        })
        .catch((error) => reject(error))
    );

    const fetching = await promiseWrapper;
    if (fetching === undefined) throw new Error("Request did not return solvable promise");
    const resp: Response = fetching.clone();
    let textResponse = null;
    if (resp.ok) {
      addProtocol({
        data: body || "--",
        desc: `OK - ${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
        type: "API",
        date: new Date(),
      });
      let response = null;
      try {
        if (resp.status === 204) {
          response = null;
        } else {
          response = await resp.json();
        }
      } catch (error) {
        try {
          textResponse = await resp.text();
        } catch (error) {
          console.error("Unable to parse response text");
          textResponse = null;
        }
        response = null;
      }
      if (mapper && response) {
        let text = null;
        try {
          text = JSON.stringify(response, null, 2) + body ? JSON.stringify(body, null, 2) : null;
        } catch (error) {
          text = body ? JSON.stringify(body, null, 2) : null;
        }
        addProtocol({
          data: text || "--",
          desc: `${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
          type: "API",
          date: new Date(),
        });
        return mapper(response);
      } else if (response) {
        let text = null;
        try {
          text = JSON.stringify(response, null, 2) + body ? JSON.stringify(body, null, 2) : null;
        } catch (error) {
          text = body ? JSON.stringify(body, null, 2) : null;
        }
        addProtocol({
          data: text || "--",
          desc: `${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
          type: "API",
          date: new Date(),
        });
        return response;
      } else {
        addProtocol({
          data: "Unable to parse",
          desc: `${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
          type: "API",
          date: new Date(),
        });
      }
      if (defaultReturn) {
        return defaultReturn;
      } else return response;
    } else {
      console.error("Controller", resp.status, resp.clone());
      if (resp.status === 401) {
        window.dispatchEvent(new CustomEvent("TOKEN_REFRESH"));
      }
      if (resp.status === 403 || resp.status.toString() === "403") {
        throw new Error("Controller 403");
      }

      try {
        textResponse = await resp.text();
      } catch (error) {
        console.error("Unable to parse response text");
        textResponse = null;
      }
      addProtocol({
        data: body ? JSON.stringify(JSON.parse(body), null, 2) : textResponse ?? "--",
        desc: `${resp.status} - ${resp.statusText ?? "--"} - ${method} - ${shortenUrl}`,
        type: "API",
        date: new Date(),
      });
      if (textResponse)
        throw new Error(`${resp.status} - ${resp.statusText} - Status did not indicate success\r\n\r\n${textResponse}`);
      throw new Error(`${resp.status} - ${resp.statusText} - Status did not indicate success`);
    }
  } catch (error) {
    const message = (error as Error).message;
    if (
      error &&
      (error as any).message &&
      !(error as any).message.includes("no_tokens_found") &&
      error instanceof InteractionRequiredAuthError
    ) {
      // const desc = `${
      //   typeof error === "string" ? error : error && "name" in (error as any) ? (error as any).name : "--"
      // } \r\n\r\n ${error && "message" in (error as any) ? (error as any).message : "--"} \r\n\r\n ${
      //   error && "stack" in (error as any) ? (error as any).stack : "--"
      // } \r\n\r\n ${error.errorCode ?? "--"} \r\n\r\n ${error.errorMessage ?? "--"}`;
      // addProtocol({
      //   data: desc,
      //   desc: `Fehler - Controller.ts - Silent Token Renewal Fehlgeschlagen - sollte blockiert werden: ${isBlocked}`,
      //   type: "API",
      //   date: new Date(),
      // });
      // if (timeout) clearTimeout(timeout);
      // counter.increase();
      // timeout = setTimeout(() => {
      //   counter.reset();
      // }, 2500);
      // if (!isBlocked) {
      //   isBlocked = true;
      //   const savedAccount = PCA.getActiveAccount() || PCA.getAllAccounts().find((a) => a) || getSavedAccount();
      //   acquireToken(InteractionType.Redirect, {
      //     ...AuthConfig.apiRequest.reservierungen,
      //     account: savedAccount ?? undefined,
      //   });
      // }
    } else if (error instanceof InteractionRequiredAuthError) {
      const desc = `${
        typeof error === "string" ? error : error && "name" in (error as any) ? (error as any).name : "--"
      } \r\n\r\n ${error && "message" in (error as any) ? (error as any).message : "--"} \r\n\r\n ${
        error && "stack" in (error as any) ? (error as any).stack : "--"
      }`;
      addProtocol({
        data: desc,
        desc: `InteractionRequiredAuthError - Controller.ts - Silent Token Renewal Fehlgeschlagen - sollte blockiert werden: ${isBlocked}`,
        type: "API",
        date: new Date(),
      });
    } else if (
      error &&
      ((error as Error).message?.includes("AbortError") ||
        (error as Error).name?.includes("AbortError") ||
        (error as Error).message?.includes("user aborted"))
    ) {
      addProtocol({
        data: "AbortError",
        desc: `Cancelled Request - Controller.ts - ${method} - ${apiUrl}`,
        type: "API",
        date: new Date(),
      });
      if (defaultReturn) return defaultReturn;
      else throw error;
    } else if (message.includes("403")) {
      const err = new Error("Controller, ApiCall, Error, 403", { cause: error });
      console.error(err);
      throw err;
    } else {
      const desc = `${
        typeof error === "string" ? error : error && "name" in (error as any) ? (error as any).name : "--"
      } \r\n\r\n ${error && "message" in (error as any) ? (error as any).message : "--"} \r\n\r\n ${
        error && "stack" in (error as any) ? (error as any).stack : "--"
      } \r\n\r\n ## BODY ## \r\n\r\n ${body ? JSON.stringify(JSON.parse(body ?? "{}"), null, 2) : "--"}`;
      addProtocol({
        data: desc,
        desc: `Error - Controller.ts - ${method} - ${apiUrl}`,
        type: "API",
        date: new Date(),
      });
    }

    throw error;
  }
};
