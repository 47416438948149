import { useCallback } from "react";
import { Heroicons } from "../../../components/Heroicon/Heroicon";
import useHistory from "../../../hooks/useHistory";
import { useStorageDetailViewModel } from "./StorageDetailViewModel";
import StorageDetailViewTypes from "./StorageDetailViewTypes";

const StorageDetailView = (props: StorageDetailViewTypes.Props) => {
  const viewModel = useStorageDetailViewModel(props);

  const { goBack } = useHistory();

  const relayRemoval = useCallback(
    (item: any | null, idx: number) => () => {
      const confirm = window.confirm(`element ${idx} wirklich löschen?`);
      if (confirm) {
        viewModel.removeElement(item, idx);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewModel.removeElement]
  );

  return (
    <section className="inline-flex flex-col w-full h-full justify-start items-start content-start">
      <span
        onClick={goBack}
        className="inline-flex w-full px-3 py-1 text-sm font-semibold tracking-wide gap-2 justify-start items-center content-start my-1 select-none"
      >
        <Heroicons.Outline.ChevronLeftIcon className="h-6 w-6 text-primary-500" />
        <span>{viewModel.params.key}</span>
      </span>

      <div className="inline-flex flex-col justify-start items-start content-start min-h-0 w-full gap-1 divide-y px-2 py-2 border bg-white overflow-auto">
        {Array.isArray(viewModel.items) &&
          viewModel.items.map((item, idx) => {
            return (
              <div
                key={item.id ?? idx}
                className="inline-flex flex-col w-full max-w-full min-h-0 flex-shrink-0 justify-start items-start content-start"
              >
                <span className="font-semibold tracking-wide tabular-nums w-full text-left flex-shrink-0"># {idx}</span>
                <span className="inline-flex gap-1 w-full min-h-0 flex-shrink-0 justify-start content-start items-start">
                  <span className="text-left text-xs whitespace-pre-wrap truncate">
                    {item ? JSON.stringify(item, null, 2) : null}
                  </span>
                </span>
                <div className="inline-flex w-full h-10 p-1 justify-end items-center flex-shrink-0">
                  <button
                    type="button"
                    className="px-3 py-1 bg-red-500 text-red-50 rounded shadow-red-500/50 shadow"
                    onClick={relayRemoval(item, idx)}
                  >
                    Löschen
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      <details className="inline-flex w-full px-3 text-left justify-start items-start content-start min-h-0 flex-shrink-0 max-h-full overflow-auto pb-24">
        <summary>JSON</summary>
        <div className="inline-flex w-full min-h-0 overflow-auto justify-start items-start content-start flex-shrink-0">
          <code className="bg-gray-200 text-xs whitespace-pre-wrap w-full min-h-0 border rounded text-left p-2 flex-shrink-0">
            {JSON.stringify(viewModel.selectedItem, null, 2)}
          </code>
        </div>
      </details>
    </section>
  );
};
export default StorageDetailView;
