import React, { useCallback, useMemo } from "react";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { Heroicon, Heroicons } from "../../components/Heroicon/Heroicon";
import { RoundButton } from "../../components/RoundButton";
import { ViewModelType } from "./NachrichtenView";

export const SearchInput = (props: {
  filteredTypes: ViewModelType["filteredTypesAndStates"];
  onFilterTypeClick: ViewModelType["filterToggleTypeOrState"];
  searchTerm: ViewModelType["searchTerm"];
  resetSearch: ViewModelType["resetSearch"];
  setSearchTerm: ViewModelType["setSearchTerm"];
  causeTypeToIcon: Map<Nachricht.Type, Heroicon>;
  causeStateToIconAndColor: Map<keyof Nachricht.State, [Heroicon, string]>;
}) => {
  const [areFilterVisible, setAreFilterVisible] = React.useState(false);
  const filterMoreIconHighlighted = React.useMemo(() => props.filteredTypes.length, [props.filteredTypes.length]);

  const filterTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleSearch = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (ev) {
        const val = ev.target.value;
        props.setSearchTerm.call(undefined, val);
      }
    },
    [props.setSearchTerm]
  );

  const toggleFilterVisibility = React.useCallback(
    (set?: boolean) => (ev?: any) => {
      setAreFilterVisible((is) => (set !== undefined ? set : !is));
    },
    []
  );

  React.useEffect(() => {
    if (areFilterVisible && props.filteredTypes) {
      filterTimeout.current = setTimeout(() => {
        setAreFilterVisible(false);
      }, 3000);
      return () => {
        if (filterTimeout.current) clearTimeout(filterTimeout.current);
      };
    }
  }, [areFilterVisible, props.filteredTypes]);

  const currentSearchTerm = useMemo(() => props.searchTerm || "", [props.searchTerm]);
  const isResetIconVisible = useMemo(() => currentSearchTerm !== "", [currentSearchTerm]);

  const causeStateKeys = useMemo(
    () => Array.from(props.causeStateToIconAndColor.keys()),
    [props.causeStateToIconAndColor]
  );
  const causeTypeKeys = useMemo(() => Array.from(props.causeTypeToIcon.keys()), [props.causeTypeToIcon]);

  const stateFilterButtons = React.useMemo(
    () =>
      causeStateKeys.map((state, idx) => {
        const isActive = props.filteredTypes.includes(state);
        const [StateIconName, color] = props.causeStateToIconAndColor.get(+state as keyof Nachricht.State) || [
          "QuestionMarkCircleIcon",
          "red-500",
        ];

        const Icon = Heroicons.Outline[StateIconName];

        return (
          <div
            key={state}
            className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-10 h-full ${
              areFilterVisible ? "pointer-event-auto" : "pointer-events-none"
            }`}
          >
            <RoundButton
              identifier={`SearchInput_btn_${state}`}
              isOpaque={!isActive}
              isVisible={areFilterVisible}
              onClick={props.onFilterTypeClick.call(undefined, state)}
            >
              <Icon className={`w-full h-full text-primary-100`} />
            </RoundButton>
          </div>
        );
      }),
    [areFilterVisible, causeStateKeys, props.causeStateToIconAndColor, props.filteredTypes, props.onFilterTypeClick]
  );

  const typeFilterButtons = React.useMemo(
    () =>
      causeTypeKeys.map((type, idx) => {
        const isActive = props.filteredTypes.includes(type);
        const TypeIcon =
          Heroicons.Outline[props.causeTypeToIcon.get(type as Nachricht.Type) || "QuestionMarkCircleIcon"];
        return (
          <div
            key={type}
            className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-10 h-full p-1 ${
              areFilterVisible ? "pointer-event-auto" : "pointer-events-none"
            }`}
          >
            <RoundButton
              identifier={`SearchInput_btn_${type}`}
              isOpaque={!isActive}
              isVisible={areFilterVisible}
              onClick={props.onFilterTypeClick.call(undefined, type)}
            >
              <TypeIcon className={`w-full h-full text-primary-100`} />
            </RoundButton>
          </div>
        );
      }),
    [areFilterVisible, causeTypeKeys, props.causeTypeToIcon, props.filteredTypes, props.onFilterTypeClick]
  );

  return (
    <div
      className={`relative flex flex-shrink-0 flex-grow-0 h-10 w-full justify-start items-center pr-8 bg-white rounded-lg shadow-md z-10`}
    >
      <div className={`flex flex-grow-0 flex-shrink-0 justify-center items-center p-1 w-8 text-primary-500`}>
        <Heroicons.Outline.SearchIcon className="w-5 h-5 flex-shrink-0 flex-grow-0" />
      </div>
      <div className="relative flex flex-1 justify-start items-center h-full outline-none border-0 w-full">
        <input
          value={currentSearchTerm}
          onChange={handleSearch}
          onClick={toggleFilterVisibility(false)}
          className="flex flex-1 justify-start items-center h-full outline-none border-0 w-full"
          placeholder="Suche..."
        ></input>
        <div className="flex flex-grow-0 flex-shrink-0 justify-end items-center pr-3 pl-1 w-8 h-full mr-1">
          <Heroicons.Outline.XIcon
            className={`flex flex-grow-0 flex-shrink-0 w-5 h-5 text-primary-400 transition-opacity duration-200 ease-in-out ${
              isResetIconVisible ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            onClick={props.resetSearch}
          />
        </div>
      </div>
      <div
        className={`absolute top-0 right-0 h-full inline-flex ${
          areFilterVisible ? "pointer-events-auto" : "pointer-events-none"
        }`}
      >
        {stateFilterButtons}
        {typeFilterButtons}
        <div
          className={`absolute top-0 inline-flex h-full justify-center items-center right-0 mr-3 ${
            areFilterVisible ? "opacity-0 pointer-events-none" : "opacity-100 pointer-events-auto"
          } transition-opacity duration-150 ease-in-out`}
          onClick={toggleFilterVisibility()}
        >
          <Heroicons.Outline.DotsHorizontalIcon
            className={`w-7 h-7 p-1 rounded-full ${
              filterMoreIconHighlighted
                ? `text-primary-100 bg-primary-500 shadow-md`
                : `text-primary-500 bg-primary-100`
            } transition-colors ease-in-out duration-150`}
          />
        </div>
      </div>
    </div>
  );
};
