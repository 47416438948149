import { SearchIcon, XIcon } from "@heroicons/react/outline";
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  // eslint-disable-next-line comma-dangle
  useRef,
} from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { useInput } from "../../hooks/useInput";
import { useToggle } from "../../hooks/useToggle";
import * as uuid from "uuid";
import { KeyListener, useKeyEvents } from "../../hooks/useKeyEvents";
import { useEventListener } from "../../hooks/useEventlistener";
import useTastaturContext from "../tastatur/useTastaturContext";
import { Heroicon, Heroicons } from "../Heroicon/Heroicon";

type BaseInputProps = {
  onRef?: (ref: (HTMLElement & HTMLInputElement & HTMLTextAreaElement) | null) => any;
  value: string | null;
  onChange: (value: string | null) => any;
  onClick?: () => void;
  deleteDefaultValue?: string | null;
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  height?: string;
  icon?: Heroicon;
  iconType?: keyof typeof Heroicons;
  label?: string;
  readOnly?: boolean;
  onFocus?: () => any;
  onBlur?: () => any;
  isTextArea?: boolean;
  delay?: number;
  className?: string;
  onEnterPress?: () => void;
  shouldFocus?: boolean;
  focusOnEvent?: string;
  isVirtualKeyboard?: boolean;
};

export const BaseInput = (props: BaseInputProps) => {
  const internalId = useMemo(() => uuid.v4(), []);
  const interalRef = useRef<(HTMLElement & HTMLInputElement & HTMLTextAreaElement) | null>(null);
  const [value, onInput, resetValue] = useInput(
    typeof props.value === "string" ? props.value : undefined,
    props.deleteDefaultValue || undefined
  );
  const debounced = useDebounce(value, props.delay ?? 350);

  const [isFocused, , startFocus, stopFocus] = useToggle();

  const { setFocusedInputId, focusedInputId, internalText } = useTastaturContext();

  useEffect(() => {
    if (props.isVirtualKeyboard && isFocused) {
      setFocusedInputId(internalId);
      return () => {
        setFocusedInputId(null);
      };
    }
  }, [internalId, isFocused, props.isVirtualKeyboard, setFocusedInputId]);

  useEffect(() => {
    if (focusedInputId === internalId) {
      onInput(internalText ?? "");
    }
  }, [focusedInputId, internalId, internalText, onInput]);

  const Icon = useMemo(
    () => Heroicons[props.iconType || "Outline"][props.icon || "PencilIcon"],
    [props.icon, props.iconType]
  );

  useEffect(() => {
    props.onChange(debounced || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced]);

  useLayoutEffect(() => {
    if (props.onRef && interalRef.current) props.onRef(interalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onRef]);

  useEffect(() => {
    if (isFocused) {
      if (props.onFocus) props.onFocus();
      return () => {
        if (props.onBlur) props.onBlur();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, props.onBlur, props.onFocus]);

  const isLabelOnTop = useMemo(() => isFocused || !!value, [isFocused, value]);

  const focusInput = useCallback(() => {
    if (interalRef.current) {
      interalRef.current.focus();
    }
  }, []);

  useLayoutEffect(() => {
    if (props.shouldFocus) {
      focusInput();
    }
  }, [focusInput, props.shouldFocus]);

  const onEnterPress = useCallback(() => {
    if (interalRef.current) interalRef.current.blur();
    if (props.onEnterPress) props.onEnterPress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onEnterPress]);

  const listener = useMemo(() => [["Enter", onEnterPress, [], false]] as KeyListener[], [onEnterPress]);

  useKeyEvents(listener, "keypress", interalRef.current);
  useEventListener(props.focusOnEvent, focusInput);

  return (
    <label
      className={`relative inline-flex ${props.className ?? ""} ${
        props.isTextArea ? "items-start justify-start content-start" : "items-center justify-start "
      } ring-1 focus-within:ring-2 focus-within:read-only:ring-1 ring-transparent ${
        props.readOnly
          ? value
            ? "bg-white ring-1 ring-gray-200 dark:bg-gray-800 dark:ring-gray-700"
            : "bg-gray-100 dark:bg-gray-900 dark:ring-gray-800"
          : value
          ? "bg-white ring-primary-300 focus-within:ring-primary-500 focus-within:bg-white dark:bg-gray-800 dark:ring-primary-600 dark:focus-within:bg-gray-700 dark:focus-within:ring-primary-500"
          : "bg-gray-100 focus-within:ring-gray-300 focus-within:bg-white focus-within:read-only:bg-gray-100 dark:bg-gray-800 dark:ring-gray-800 dark:focus-within:ring-gray-600 dark:focus-within:read-only:bg-gray-700 dark:focus-within:bg-gray-700"
      }  rounded-md min-h-0 ${
        props.height || "h-10"
      } group  text-gray-500 focus-within:text-gray-600 dark:text-gray-400 dark:focus-within:text-gray-200 transition-all duration-200 ease-in-out w-full focus:z-10`}
      htmlFor={internalId}
    >
      <span
        className={`absolute inline-flex ${
          props.isTextArea ? "top-0 left-1" : "left-1 inset-0 bottom-0"
        } pointer-events-none ml-10 transform h-full transition-transform duration-200 ease-in-out  ${
          isLabelOnTop ? "-translate-y-3" : "translate-y-0"
        } select-none`}
      >
        <label
          className={`origin-top text-center min-h-0 ${
            props.isTextArea ? (value ? "mt-[1.5em]" : "mt-3.5 group-focus-within:mt-[1.5em]") : "my-auto"
          } leading-none overflow-visible transition-all duration-200 ease-in-out ${
            isLabelOnTop ? "text-xs font-semibold tracking-wide text-primary-400" : "text-base"
          } select-none`}
          htmlFor={internalId}
        >
          {props.label}
        </label>
      </span>

      <Icon
        className={`absolute left-3 top-0 bottom-0 my-auto w-5 h-5 ${props.isTextArea ? "top-3 bottom-auto" : ""}`}
      />
      {props.isTextArea ? (
        <textarea
          ref={interalRef}
          id={internalId}
          name={internalId}
          onClick={props.onClick as any}
          placeholder={!props.label ? props.inputProps?.placeholder : undefined}
          className={`w-full min-w-0 h-full bg-transparent outline-none focus:outline-none pl-11 pr-9 ${
            value
              ? "text-gray-800 dark:text-gray-200"
              : "text-gray-600 focus:text-gray-800 dark:text-gray-300 dark:focus:text-gray-200"
          } ${props.label ? "pt-5" : ""} resize-none`}
          value={value || ""}
          onChange={onInput as any}
          onFocus={props.readOnly ? undefined : startFocus}
          onBlur={props.readOnly ? undefined : stopFocus}
          readOnly={props.readOnly || props.isVirtualKeyboard}
        />
      ) : (
        <input
          ref={interalRef}
          id={internalId}
          name={internalId}
          onClick={props.onClick}
          {...props.inputProps}
          placeholder={!props.label ? props.inputProps?.placeholder : undefined}
          className={`w-full min-w-0 h-full bg-transparent outline-none focus:outline-none pl-11 pr-9 ${
            value
              ? "text-gray-800 dark:text-gray-200"
              : "text-gray-600 focus:text-gray-800 dark:text-gray-300 dark:focus:text-gray-200"
          } ${props.label ? "pt-3.5" : ""}`}
          type="text"
          value={value || ""}
          onChange={onInput}
          onFocus={props.readOnly ? undefined : startFocus}
          onBlur={props.readOnly ? undefined : stopFocus}
          readOnly={props.readOnly || props.isVirtualKeyboard}
        />
      )}

      {props.readOnly ? null : (
        <XIcon
          onClick={resetValue}
          className={`absolute right-0 p-2 left-0 ml-auto w-9 h-9 transition-all duration-200 ease-in-out cursor-pointer hover:text-red-500 select-none z-10 ${
            value ? "opacity-100 text-gray-500" : "opacity-0 pointer-events-none"
          } ${props.isTextArea ? "mt-1.5" : ""}`}
        />
      )}
    </label>
  );
};
