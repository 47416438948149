import {
  cancelReservationCall,
  confirmReservationCall,
  deleteReservationCall,
  guestLeftReservationCall,
  putNewReservationCall,
  rescheduleReservationCall,
  reservationCommands as ReservationCommands,
  updateGuestAmountOnReservationCall,
  updateReservationCall,
  walkedInWithReservationCall,
  sendEmailCall,
} from "./ReservierungDataController";
import {
  putNewTableCall,
  TableCommands,
  unassignTablesToReservationCall,
  updateTableCall,
  assignTablesToReservationCall,
} from "./TableController";
import { processCauseCall, CauseCommands } from "./CauseController";

export type commandProps<B, P> = { restaurantId: string; body: B } & P;

export const commands: { [key in ReservationCommands & TableCommands & CauseCommands]: () => any } = {
  putNewReservation: () => putNewReservationCall,
  cancelReservation: () => cancelReservationCall,
  confirmReservation: () => confirmReservationCall,
  deleteReservation: () => deleteReservationCall,
  guestLeftReservation: () => guestLeftReservationCall,
  rescheduleReservation: () => rescheduleReservationCall,
  updateReservation: () => updateReservationCall,
  updateGuestAmountOnReservation: () => updateGuestAmountOnReservationCall,
  walkedInWithReservation: () => walkedInWithReservationCall,
  putNewTable: () => putNewTableCall,
  updateTable: () => updateTableCall,
  assignTablesToReservation: () => assignTablesToReservationCall,
  unassignTablesToReservation: () => unassignTablesToReservationCall,
  processCause: () => processCauseCall,
  sendEmail: () => sendEmailCall,
};
