import { ChangeEvent, useCallback, useEffect, useState } from "react";

export const useInput = (currentValue?: string, defaultValue?: string, overwriteIfNull?: boolean) => {
  const [value, setValue] = useState<string>(currentValue ?? defaultValue ?? "");

  const resetInput = useCallback(() => setValue(defaultValue ?? ""), [defaultValue]);
  const onInput = useCallback(
    (ev: ChangeEvent<HTMLInputElement> | string | null) =>
      ev === null ? setValue("") : typeof ev === "string" ? setValue(ev) : setValue(ev.target.value),
    []
  );

  useEffect(() => {
    if (currentValue !== null && typeof currentValue === "string") {
      setValue(currentValue);
    } else {
      setValue("");
    }
  }, [currentValue]);

  return [value, onInput, resetInput] as [string, typeof onInput, typeof resetInput];
};
