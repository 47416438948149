import { useMemo } from "react";
import { Heroicons } from "../../../components/Heroicon/Heroicon";
import { useBlobUrl } from "../../../hooks/useBlobUrl";
import { useTheme } from "../../../hooks/useTheme";
import Tools from "../../../Tools";
import { useExportDayViewModel } from "./ExportDayViewModel";
import { ExportDayViewTypes } from "./ExportDayViewTypes";

export const ExportDayView = (props: ExportDayViewTypes.Props) => {
  const viewModel = useExportDayViewModel(props);
  const {
    theme: { backgroundcolor },
  } = useTheme();

  const currentDateLocaleString = useMemo(
    () => viewModel.currentDate.toLocaleDateString("de-de", { month: "2-digit", day: "2-digit", year: "numeric" }),
    [viewModel.currentDate]
  );

  const blobUrl = useBlobUrl(viewModel.file);

  const isDownloading = useMemo(
    () => viewModel.hasRequestedFile && viewModel.isLoading,
    [viewModel.hasRequestedFile, viewModel.isLoading]
  );

  const downloadName = useMemo(() => {
    return `${Tools.dateToIsoLike(viewModel.currentDate)}_reservierungen.xlsx`;
  }, [viewModel.currentDate]);

  return (
    <section
      className={`inline-flex flex-col w-full flex-1 max-h-full min-h-0 justify-start items-start content-start overflow-hidden bg-primary-800`}
    >
      <div
        className={`inline-flex flex-col w-full flex-1 max-h-full min-h-0 justify-start items-start content-start max-w-screen-xs mx-auto bg-primary-700`}
      >
        <h2 className="text-xl font-bold tracking-wider mt-6 mx-3 text-gray-50">
          Export für den {currentDateLocaleString}
        </h2>
        <div className="inline-flex flex-col w-full flex-1 px-1 mt-6">
          <a
            href={blobUrl ?? "#"}
            download={downloadName}
            className={`inline-flex w-full pl-3 py-5 bg-white shadow-md rounded-lg ${
              viewModel.file ? "opacity-100 pointer-events-auto scale-100" : "opacity-0 pointer-events-none scale-75"
            } transition-all duration-300 ease-in-out transform`}
          >
            <div className="inline-flex flex-2 flex-col h-full">
              <span className={`text-sm tracking-wide text-gray-800`}>{downloadName}</span>
              <span className={`text-xs font-semibold tracking-wider text-primary-800`}>
                {viewModel.file?.size ? Tools.formatBytes(viewModel.file?.size) : null}
              </span>
              <span className="text-xs text-gray-400 tracking-wide">{viewModel.file?.type}</span>
            </div>
            <div className={`inline-flex flex-col h-full flex-1 justify-center items-center text-primary-600`}>
              <Heroicons.Outline.DownloadIcon className="h-8 w-8" />
            </div>
          </a>
        </div>
        <button
          className={`mb-24 mt-auto mx-auto px-6 rounded-md py-2 transition-all duration-150 ease-in-out ${
            viewModel.file ? "opacity-0 pointer-events-none" : "opacity-100"
          } ${
            isDownloading
              ? `bg-transparent hover:bg-transparent text-primary-50 animate-pulse pointer-events-none`
              : `bg-primary-500 hover:bg-primary-600 text-primary-50 shadow-md`
          }`}
          onClick={viewModel.downloadFileForToday}
          type="button"
          disabled={viewModel.hasRequestedFile}
        >
          {viewModel.isLoading ? "Lädt..." : "Datei anfordern"}
        </button>
      </div>
    </section>
  );
};
