import { useCallback, useEffect, useRef, useState } from "react";

export const useTimeout = (duration?: number) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isTimeoutactive, setIsTimeoutactive] = useState(false);

  const startTimeout = useCallback(() => {
    setIsTimeoutactive(true);
  }, []);

  const stopTimeout = useCallback(() => {
    setIsTimeoutactive(false);
  }, []);

  useEffect(() => {
    if (isTimeoutactive) {
      if (timer.current) clearTimeout(timer.current);
      const newTimer = setTimeout(() => {
        setIsTimeoutactive(false);
      }, duration || 2500);
      timer.current = newTimer;
      return () => {
        if (newTimer) clearTimeout(newTimer);
      };
    }
  }, [duration, isTimeoutactive]);

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return { isTimeoutactive, startTimeout, stopTimeout };
};
