import { useCallback, useEffect, useMemo, useState } from "react";
import { PersistentStorageRepository, _EVENTS } from "../../../clientApi/ClientApi";
import { useEventListener } from "../../../hooks/useEventlistener";
import StorageMainViewTypes from "./StorageMainViewTypes";

export const useStorageMainViewModel = (props: StorageMainViewTypes.Props) => {
  const [persistentStorageKeys, setPersistentStorageKeys] = useState<IDBValidKey[]>([]);
  const [localstorageKeys, setLocalstorageKeys] = useState<string[]>([]);

  const loadLocalstorageKeys = useCallback(async () => {
    try {
      const keys = Object.keys(localStorage);
      setLocalstorageKeys(keys);
      return keys;
    } catch (error) {
      throw error;
    }
  }, []);

  const loadPersistentStorageKeys = useCallback(async () => {
    try {
      const keys = await PersistentStorageRepository!.keys();
      setPersistentStorageKeys(keys);
      return keys;
    } catch (error) {
      throw error;
    }
  }, []);

  const refresh = useCallback(async () => {
    try {
      const persKeys = await loadPersistentStorageKeys();
      const localKeys = await loadLocalstorageKeys();
      setPersistentStorageKeys(() => {
        setLocalstorageKeys(localKeys);
        return persKeys;
      });
    } catch (error) {
      throw error;
    }
  }, [loadLocalstorageKeys, loadPersistentStorageKeys]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const events = useMemo(() => Object.entries(_EVENTS).map(([key, value]) => value.updated), []);
  useEventListener(events, loadPersistentStorageKeys);

  return { persistentStorageKeys, localstorageKeys, refresh };
};
