import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, useTransition } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CommandQueue, { CommandQueueItem } from "../../clientApi/CommandQueue";
import { Nachricht } from "../../clientApi/models/Nachricht";
import QueryQueue from "../../clientApi/QueryQueue";
import { QueueElement } from "../../clientApi/QueueProvider";
import { useSettings } from "../../clientApi/SettingsProvider";
import { useCurrentDate } from "../../hooks/useCurrentDate";
import { useEventListener } from "../../hooks/useEventlistener";
import { useFilterByTerm } from "../../hooks/useFilterByTerm";
import { Reservation } from "../../models/Reservation";
import Tools from "../../Tools";
import { clientApi } from "../Authenticated/AuthenticatedView";
import { NachrichtenTypes } from "./NachrichtenTypes";
import useNachrichtenHelper from "./useNachrichtenHelper";

export const NachrichtenViewEvent = {
  refreshNachrichte: "NACHRICHTEN_VIEW_REFRESH_NACHRICHTEN",
};

export const useNachrichtenViewModel = (props: NachrichtenTypes.props) => {
  const navigate = useNavigate();
  const Settings = useSettings();

  const displayMode = useMemo(() => Settings.displayMode, [Settings.displayMode]);

  const [nachrichtenUpdateTransition, startNachrichtenUpdateTransition] = useTransition();

  const [, , , frozDate, refreshTime] = useCurrentDate(true, 100);
  const isoLikeFrozen = useMemo(() => Tools.dateToIsoLike(frozDate), [frozDate]);
  const frozenDate = useMemo(() => new Date(isoLikeFrozen), [isoLikeFrozen]);

  const params = useParams<NachrichtenTypes.params>();
  const preselectedId = useMemo(() => {
    const pre = params.preselected || props.preselected;
    if (pre) {
      return pre;
    } else return null;
  }, [params.preselected, props.preselected]);

  const [selectedNachricht, setSelectedNachricht] = useState<Nachricht.Client<any> | null>(null);
  const [nachrichten, setNachrichten] = useState<Nachricht.Client<any>[]>([]);
  const { filteredData, resetSearch, searchTerm, setSearchTerm } = useFilterByTerm(
    nachrichten,
    ["reservationId", "from", "body", "note", "subject", "timestamp", "type"],
    400
  );
  const [isLoadingNachrichten, setIsLoadingNachrichten] = useState(true);
  const [isPullingNachrichten, setIsPullingNachrichten] = useState(true);

  const [daysInPast, setDaysInPast] = useState<number>(3);

  const deadlineDate = useMemo(() => {
    const temp = new Date(frozenDate);
    temp.setDate(frozenDate.getDate() - daysInPast);
    return temp;
  }, [daysInPast, frozenDate]);

  const loadedDatesRef = useRef<Date[]>([]);
  const loadedDates = useMemo(() => {
    const today = new Date(frozenDate);
    const additionalDays = Array(daysInPast)
      .fill(null)
      .map((_, idx) => {
        let day = idx + 1;
        const tempDate = new Date(today);
        tempDate.setDate(tempDate.getDate() - day);
        return tempDate;
      });
    const dates = [frozenDate, ...additionalDays];
    if (loadedDatesRef.current) loadedDatesRef.current = dates;
    return dates;
  }, [daysInPast, frozenDate]);

  const { getAmountsPerTypesByFrom, getFilteredByTypes, checkGUID } = useNachrichtenHelper();
  const amountPerCall = useMemo(
    () => getAmountsPerTypesByFrom(nachrichten, ["Call", "MissedCall"]),
    [getAmountsPerTypesByFrom, nachrichten]
  );

  const [queue, setQueue] = useState<QueueElement<CommandQueueItem<any>>[]>([]);
  const processingNachrichten = useMemo<Nachricht.Client<any>["id"][]>(() => queue.map((c) => c.reference), [queue]);

  const [filteredTypesAndStates, setFilteredTypesAndStates] = useState<(Nachricht.Type | keyof Nachricht.State)[]>([]);
  const filterToggleTypeOrState = useCallback(
    (type: Nachricht.Type | keyof Nachricht.State) => () =>
      setFilteredTypesAndStates((c) => (c.includes(type) ? c.filter((c) => c !== type) : [...c, type])),
    []
  );
  const filterForTypesAndStates = useCallback(
    (nachricht: Nachricht.Client<any>) =>
      filteredTypesAndStates.includes(nachricht.type) || filteredTypesAndStates.includes(nachricht.state),
    [filteredTypesAndStates]
  );

  const displayedNachrichten = useMemo(() => {
    const tempNachrichten = filteredTypesAndStates.length ? filteredData.filter(filterForTypesAndStates) : filteredData;
    return tempNachrichten.sort((b, a) => +a.timestamp - +b.timestamp);
  }, [filteredTypesAndStates.length, filteredData, filterForTypesAndStates]);

  const nachrichtenFilteredByType = useMemo(() => {
    return getFilteredByTypes(nachrichten, ["Call", "Email", "Form", "MissedCall", "MissedCallMessage"]);
  }, [getFilteredByTypes, nachrichten]);

  const [, startPreselectedTransition] = useTransition();
  useEffect(() => {
    startPreselectedTransition(() => {
      setSelectedNachricht((c) => {
        let selected = c || null;
        if (preselectedId) {
          const matches = nachrichten.filter((n) => n.id === preselectedId);
          if (matches.length === 0) {
            selected = null;
          } else if (matches.length === 1) {
            selected = matches[0];
          } else {
            selected = matches.sort((a, b) => +b.timestamp - +a.timestamp)[0];
          }
        }
        return selected;
      });
    });
  }, [nachrichten, preselectedId]);

  const toggleNachrichtSelection = useCallback((nachricht: Nachricht.Client<any>) => {
    setSelectedNachricht((c) => {
      if (c === null) return nachricht;
      if (c.id === nachricht.id) {
        return null;
      } else return nachricht;
    });
  }, []);

  const handleReservationCreation = useCallback(
    async (nachricht: Nachricht.Client<any>) => {
      try {
        let tempReservation: Reservation | null = nachricht.reservation ? { ...nachricht.reservation } : null;
        if (tempReservation?.dateOfArrival.includes("0001")) {
          tempReservation = null;
        }

        await props.setNewReservationPrimer.call(undefined, {
          phoneNumber: nachricht.type === "Call" ? nachricht.from : undefined,
          email:
            nachricht.type === "Email" && !nachricht.from.toLocaleLowerCase().includes("@wix")
              ? nachricht.from
              : undefined,
          ...tempReservation,
          cause: {
            ...nachricht,
            reservation: tempReservation || undefined,
            timestamp: nachricht.timestamp.toISOString() as any,
          },
        });
        if (displayMode === "Tablet") {
          return;
        } else {
          return navigate({ pathname: `/Reservierung/` });
        }
      } catch (error) {
        throw error;
      }
    },
    [displayMode, navigate, props.setNewReservationPrimer]
  );

  useEffect(() => {
    if (displayMode === "Tablet") {
      navigate({ pathname: `/Reservierung/2` });
    } else {
      navigate({ pathname: `/Nachrichten/${preselectedId || ""}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMode, params.preselected, preselectedId]);

  const moveDateBack = useCallback(() => {
    try {
      setDaysInPast((c) => c + 1);
    } catch (error) {
      console.error("Datum konnte nicht zurückgeschoben werden");
    } finally {
      return;
    }
  }, []);

  const getNachrichtenByDate = useCallback(async (date: Date, fromServer?: boolean) => {
    try {
      const oldNachrichten = await clientApi.Queries.getNachrichtenBeforeDate<any>(date, fromServer ?? true);
      return oldNachrichten;
    } catch (error) {
      throw error;
    }
  }, []);

  const getNachrichtenByDates = useCallback(
    async (dates: Date[], fromServer?: boolean) => {
      try {
        if (fromServer) {
          const cacheRequests = dates.map((d) => getNachrichtenByDate(new Date(d), false));
          const cachedResponses = await Promise.allSettled(cacheRequests);
          const cacheFulfilled = cachedResponses.filter(
            ({ status }) => status === "fulfilled"
          ) as PromiseFulfilledResult<Nachricht.Client<any>[]>[];
          const cacheNachrichten = cacheFulfilled.flatMap(({ value }) => value);
          console.log("test");
          startNachrichtenUpdateTransition(() => {
            setNachrichten(cacheNachrichten);
          });
        }
        const requests = dates.map((d) => getNachrichtenByDate(new Date(d), fromServer ?? true));
        const responses = await Promise.allSettled(requests);
        const fulfilled = responses.filter(({ status }) => status === "fulfilled") as PromiseFulfilledResult<
          Nachricht.Client<any>[]
        >[];
        const oldNachrichten = fulfilled.flatMap(({ value }) => value);
        console.log("test2");
        return oldNachrichten;
      } catch (error) {
        throw error;
      }
    },
    [getNachrichtenByDate]
  );

  const loadMoreNachrichten = useCallback(async () => {
    try {
      setDaysInPast((c) => c + 1);
    } catch (error) {
      throw error;
    }
  }, []);

  const refreshNachrichten = useCallback(
    async (date?: Date | Date[]) => {
      try {
        setIsPullingNachrichten(true);
        if (!Array.isArray(date) && typeof date === "object" && date instanceof Date && "toISOString" in date) {
          const res = await getNachrichtenByDate(date, true);
          return res;
        } else if (Array.isArray(date)) {
          return await getNachrichtenByDates(date, true);
        }
      } catch (error) {
        return [];
      } finally {
        setIsPullingNachrichten(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNachrichtenByDate, getNachrichtenByDates]
  );

  const [hasLoadedInitial, setHasLoadedInitial] = useState<boolean>(false);

  useLayoutEffect(() => {
    setHasLoadedInitial(true);
    const timeout = setTimeout(() => {
      refreshNachrichten(loadedDates);
    }, 100);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasLoadedInitial) {
      refreshNachrichten(deadlineDate);
    }
  }, [deadlineDate, hasLoadedInitial, refreshNachrichten]);

  const updateQueue = useCallback(async () => {
    try {
      const que = await CommandQueue.getQueue();
      setQueue((c) => {
        const missingDates = c
          .filter((c) => que.every((q) => q.reference !== c.reference))
          .map((c) => c.value.props[0] as Nachricht.Client<any>)
          .map((c) => c?.timestamp ?? null)
          .filter((c) => c);

        missingDates.forEach((c) => (c && typeof c === "object" && "toISOString" in c ? refreshNachrichten(c) : null));

        return que;
      });
    } catch (error) {
      throw error;
    }
  }, [refreshNachrichten]);

  useEventListener(CommandQueue.Events.COMMAND_QUEUE_UPDATE, updateQueue);

  const processNachricht = useCallback(async (nachricht: Nachricht.Client<any>, note: string | null, date?: Date) => {
    try {
      await clientApi.Commands.processNachricht({ ...nachricht, note: note || "" }, false, nachricht.timestamp);
    } catch (error) {
      throw error;
    }
  }, []);

  const cancelNachricht = useCallback(
    async (nachricht: Nachricht.Client<any>, note: string | null) => {
      try {
        // await clientApi.Commands.processNachricht({ ...nachricht, note: note || "" }, false);
        await refreshNachrichten(nachricht.timestamp);
      } catch (error) {
        throw error;
      }
    },
    [refreshNachrichten]
  );

  const editNote = useCallback(async (nachricht: Nachricht.Client<any>, note: string) => {
    try {
      await clientApi.Commands.editNachrichtNote(nachricht, note, nachricht.timestamp);
      return;
    } catch (error) {
      throw error;
    }
  }, []);

  const loadedReservierungenRef = useRef<string[]>([]);
  const [reservierungen, setReservierungen] = useState<Reservation[]>([]);
  const reservierungenByNachrichtId = useMemo<Map<Nachricht.Client<any>["id"], Reservation | null | false>>(() => {
    return new Map(
      nachrichten
        .filter((c) => c)
        .map((nachricht) => [
          nachricht.id,
          reservierungen.filter((c) => c).find((c) => c.id === nachricht.reservationId) ?? false,
        ])
    );
  }, [nachrichten, reservierungen]);

  const loadingReservationsRef = useRef<string[]>([]);
  const [loadingReservations, setLoadingReservations] = useState<Reservation["id"][]>([]);
  const [reservationTransition, startReservationTransition] = useTransition();

  const loadCachedReservierungen = useCallback(async () => {
    try {
      const allRes = await clientApi.Queries.getCachedReservierungen();
      loadedReservierungenRef.current = Array.from(
        new Set([...loadedReservierungenRef.current, ...allRes.filter((c) => c).map((c) => c.id)])
      );
      startReservationTransition(() => {
        setReservierungen(allRes);
        setLoadingReservations(loadingReservationsRef.current);
      });
    } catch (error) {
      throw error;
    }
  }, []);

  const loadFreshReservierung = useCallback(async (id: string) => {
    try {
      loadingReservationsRef.current = [...loadingReservationsRef.current, id];
      startReservationTransition(() => {
        setLoadingReservations(loadingReservationsRef.current);
      });
      clientApi.Queries.getReservierungById(id, (freshRes) => {
        loadingReservationsRef.current = loadingReservationsRef.current.filter((c) => c !== id);
        startReservationTransition(() => {
          setLoadingReservations(loadingReservationsRef.current);
        });
      });
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    loadCachedReservierungen();
  }, [loadCachedReservierungen]);

  useEffect(() => {
    if (selectedNachricht?.reservationId && checkGUID(selectedNachricht.reservationId))
      loadFreshReservierung(selectedNachricht.reservationId);
  }, [checkGUID, loadFreshReservierung, selectedNachricht?.reservationId]);

  const loadMissingReservations = useCallback(async () => {
    try {
      if (loadedReservierungenRef.current && nachrichten?.length) {
        const missing = nachrichten
          .filter((n) => n)
          .filter((c) => checkGUID(c.reservationId))
          .filter(
            (c) =>
              c.state === 1 &&
              c.reservationId &&
              loadingReservationsRef.current.every((id) => id !== c.reservationId) &&
              loadedReservierungenRef.current
                .filter((r) => r)
                .filter((r) => checkGUID(r))
                .every((r) => r !== c.reservationId)
          );
        missing.forEach((miss) => loadFreshReservierung(miss.reservationId));
      }
    } catch (error) {
      throw error;
    }
  }, [checkGUID, loadFreshReservierung, nachrichten]);

  useEffect(() => {
    loadMissingReservations();
  }, [loadMissingReservations]);

  const handleNachrichtenUpdate = useCallback(async () => {
    try {
      setIsLoadingNachrichten(true);
      try {
        const requests = loadedDates.map((d) => {
          return getNachrichtenByDate(new Date(d), false);
        });
        const fullfilledRequests = (await Promise.allSettled(requests)).filter(
          (c) => c.status === "fulfilled"
        ) as PromiseFulfilledResult<Nachricht.Client<any>[]>[];
        const cachedNachrichten = fullfilledRequests.flatMap((c) => c.value);
        startNachrichtenUpdateTransition(() => {
          setNachrichten(cachedNachrichten);
        });
      } catch (error) {
        throw error;
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingNachrichten(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNachrichtenByDate, loadedDates, props.setAppState]);

  const refreshAllNachrichten = useCallback(async () => {
    try {
      console.log("refresh all");
      const resp = await refreshNachrichten(loadedDatesRef.current);
    } catch (error) {
      return [];
    }
  }, [refreshNachrichten]);

  useEffect(() => {
    if (loadingReservationsRef.current.length === 0 && processingNachrichten.length === 0) props.appLoadCauses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appLoadCauses, nachrichten.length, processingNachrichten.length]);

  const handleQueryQueueUpdate = useCallback(async (ev: any) => {
    try {
      const queue = await QueryQueue.getQueue();
      setIsPullingNachrichten(queue.some((c) => c.reference.includes("getNachrichten")));
    } catch (error) {
      throw error;
    }
  }, []);

  useEventListener(QueryQueue.Events.QUERY_QUEUE_UPDATE, handleQueryQueueUpdate);
  useEventListener(clientApi.Events.NACHRICHTEN_UPDATED, handleNachrichtenUpdate);
  useEventListener(NachrichtenViewEvent.refreshNachrichte, refreshAllNachrichten);
  useEventListener(clientApi.Events.RESERVIERUNGEN_UPDATED, loadCachedReservierungen);

  return {
    nachrichtenFilteredByType,
    filterToggleTypeOrState,
    filteredTypesAndStates,
    resetSearch,
    searchTerm,
    setSearchTerm,
    isLoadingNachrichten,
    isPullingNachrichten,
    refreshNachrichten,
    selectedNachricht,
    processingNachrichten,
    toggleNachrichtSelection,
    displayedNachrichten,
    processNachricht,
    handleReservationCreation,
    reservierungenByNachrichtId,
    amountPerCall,
    cancelNachricht,
    moveDateBack,
    deadlineDate,
    getNachrichtenByDate,
    loadMoreNachrichten,
    loadedDates,
    frozenDate,
    refreshTime,
    loadingReservations,
    editNote,
  };
};
