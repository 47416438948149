import { useCallback, useMemo } from "react";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { EMPTY_GUID } from "../../models/General";

const useNachrichtenHelper = () => {
  const cleanUpFrom = useCallback((from: Nachricht.Client<any>["from"]) => from.replaceAll("-", ""), []);

  const checkGUID = useCallback(
    (id: string | undefined | null) => id !== null && id !== undefined && id !== EMPTY_GUID,
    []
  );

  const filterForType = useCallback(
    (type: Nachricht.Type) => (nachricht: Nachricht.Client<any>) => nachricht.type === type,
    []
  );

  const getFilteredByTypes = useCallback(
    (nachrichten: Nachricht.Client<any>[], types: Nachricht.Type[]) => {
      return new Map<Nachricht.Type, Nachricht.Client<any>[]>(
        types.map((type) => [type, nachrichten.filter(filterForType(type))])
      );
    },
    [filterForType]
  );

  const getAmountsByType = useCallback(
    (nachrichten: Nachricht.Client<any>[], filteredByType: Map<Nachricht.Type, Nachricht.Client<any>[]>) => {
      try {
        const types = Array.from(filteredByType.keys());
        const filtered: [Nachricht.Type, [number, number, number]][] = types.map((type) => {
          const nachrichtenForType = filteredByType.get(type);
          if (nachrichtenForType && nachrichtenForType.length) {
            const [finished, unfinished] = nachrichtenForType.reduce(
              (amounts, n) => {
                const temp = [...amounts];
                if (n.state === 1 || n.state === 2) {
                  temp[0] = temp[0] + 1;
                } else {
                  temp[1] = temp[1] + 1;
                }
                return temp;
              },
              [0, 0] as number[]
            );
            return [type, [nachrichtenForType.length, finished, unfinished]];
          } else return [type, [0, 0, 0]];
        });
        return new Map<Nachricht.Type, [number, number, number]>(filtered);
      } catch (error) {
        throw error;
      }
    },
    []
  );

  const getAmountsPerTypesByFrom = useCallback(
    (nachrichten: Nachricht.Client<any>[], types: Nachricht.Client<any>["type"][]) => {
      try {
        const mapped = new Map();
        nachrichten
          .filter((nachricht) => types.some((type) => type === nachricht.type))
          .forEach((nachricht) => {
            const { from } = nachricht;
            let cleanedFrom = cleanUpFrom(from);
            if (mapped.has(cleanedFrom)) {
              const temp = mapped.get(cleanedFrom);
              if (temp !== null) {
                mapped.set(cleanedFrom, temp + 1);
              } else {
                mapped.set(cleanedFrom, 1);
              }
            } else {
              mapped.set(cleanedFrom, 1);
            }
          });
        return mapped;
      } catch (error) {
        throw error;
      }
    },
    [cleanUpFrom]
  );

  const getPhoneNumber = useCallback((n: Nachricht.Client<any>) => {
    const phoneNumber = n.reservation?.phoneNumber;
    const regex = n.body?.match(/Telefon:[\s](\+[0-9]*)/);
    let tempPhone = regex ? (phoneNumber && phoneNumber !== "" ? phoneNumber : regex[1] ? regex[1] : null) : null;
    if (tempPhone === null && !n.from.includes("@") && n.from.includes("+")) {
      const match = n.from.replaceAll("-", "").match(/[\d+]+/g);
      tempPhone = match ? match.join("") : null;
    }
    return tempPhone;
  }, []);

  return { getAmountsPerTypesByFrom, getAmountsByType, getFilteredByTypes, checkGUID, cleanUpFrom, getPhoneNumber };
};
export default useNachrichtenHelper;
