import * as React from "react";
import { EventTypesLocalization, GastroEvent } from "../models/GastroEvent";
import { AppColors } from "../models/General";
import { Heroicons } from "./Heroicon/Heroicon";

export const EventBlock = (props: {
  appColors: AppColors;
  icon: JSX.Element;
  event: GastroEvent;
  setRef: (ref: HTMLDivElement | null) => any;
}) => {
  const { event } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const [eventData, setEventData] = React.useState<any>();

  React.useEffect(() => {
    setEventData(() => {
      return JSON.parse(event.eventData);
    });
  }, [event.eventData]);

  const detailRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={`relative inline-flex flex-shrink-0 min-h-0 w-full p-1 z-10 cursor-pointer`}
      style={{
        transition: "200ms height",
      }}
    >
      <div
        ref={(ref) => props.setRef(ref)}
        className="absolute min-w-0 w-0"
        style={{
          height: "150%",
          top: "-25%",
        }}
      ></div>
      <div className="flex flex-1 flex-shrink-0 flex-col min-h-0 h-full justify-start items-center w-full rounded-lg overflow-hidden shadow-md">
        <h3
          className={`rounded-lg bg-white flex flex-1 h-14 w-full flex-shrink-0 flex-row justify-start items-center text-primary-800 p-2 border-b z-20`}
        >
          <div
            className={`flex flex-grow-0 w-10 h-10 bg-primary-500 rounded-lg p-1 shadow-md justify-center items-center overflow-hidden`}
          >
            {props.icon}
          </div>
          <div className="flex flex-5 justify-center items-start flex-col h-full pt-1 overflow-hidden">
            <span className={`px-3 text-xs leading-tight font-bold truncate`}>
              {EventTypesLocalization[props.event.event]}
            </span>
            <span className="px-3 text-xs leading-tight">{props.event.createdBy}</span>
          </div>
          <div className="flex flex-4 justify-center items-start flex-col h-full pt-1">
            <span className="px-3 text-xs leading-tight font-bold truncate max-w-full">
              {new Date(props.event.timestamp)
                .toLocaleDateString("de-de", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })
                .slice(-8, -3)}
            </span>
            <span className="px-3 text-xs leading-tight">
              {new Date(props.event.timestamp).toLocaleDateString("de-de", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                weekday: "short",
              })}
            </span>
          </div>
          {eventData ? (
            <div
              className={`flex justify-end items-center absolute w-full h-14 h-10 px-5 text-primary-500 top-0 left-0 mt-1`}
              onClick={() => {
                setIsOpen((curr) => !curr);
              }}
            >
              <Heroicons.Solid.ChevronLeftIcon
                className={`w-6 h-6 transform ${
                  isOpen ? "-rotate-90" : "rotate-0"
                } transition-transform duration-200 ease-in-out`}
              />
            </div>
          ) : null}
        </h3>

        <div className={`inline-flex flex-1 flex-col justify-start items-start w-full px-1 -mt-px`}>
          <div
            ref={(ref) => (detailRef.current = ref)}
            className={`relative flex flex-col w-full ${
              isOpen ? "h-full p-2" : "h-0 px-2"
            } justify-start items-start bg-white rounded-b-lg overflow-hidden`}
            style={{
              zIndex: 5,
              top: "0",
              transform: `translate(0px, ${isOpen ? "0%" : "-100%"})`,
              transitionDuration: "200ms",
              transitionTimingFunction: "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
            }}
          >
            {eventData && typeof eventData === "object"
              ? (Object.keys(eventData) as (keyof typeof eventData)[]).map((key, idx) => {
                  return (
                    <div className={`flex w-full h-8 justify-between items-center px-1`} key={idx}>
                      {isOpen ? <span className="text-xxs font-semibold">{key.toString()}</span> : null}
                      {isOpen ? (
                        <span className={`text-xs p-1 rounded border bg-gray-100`}>
                          {key.toString().toLocaleLowerCase().includes("date")
                            ? new Date(eventData[key]).toLocaleDateString("de-de", {
                                hour: "2-digit",
                                minute: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                day: "2-digit",
                                second: "2-digit",
                              })
                            : typeof eventData[key] === "object"
                            ? JSON.stringify(eventData[key])
                            : eventData[key]}
                        </span>
                      ) : null}
                    </div>
                  );
                })
              : eventData}
          </div>
        </div>
      </div>
    </div>
  );
};
