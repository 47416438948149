import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import SettingsProvider from "../../../clientApi/SettingsProvider";
import Tools from "../../../Tools";
import AbspracheDetailViewTypes from "./AbspracheDetailViewTypes";

export const useAbspracheDetailViewModel = (props: AbspracheDetailViewTypes.Props) => {
  const { date } = useParams<AbspracheDetailViewTypes.Params>();
  const currentDate = useMemo(() => (date ? new Date(date) : new Date()), [date]);

  const match = useMemo(
    () => props.absprachen.find((c) => Tools.dateToIsoLike(c.tag) === date),
    [date, props.absprachen]
  );

  const [isFileExisting, setIsFileExisting] = useState(false);
  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    if (match && match.url) {
      setIsFileExisting(() => {
        setLink(match.url);
        return true;
      });
      return () => {
        setIsFileExisting(() => {
          setLink(null);
          return false;
        });
      };
    }
  }, [match]);

  const directLink = useMemo(() => (link ? `${link}?web=1&action=edit` : "#"), [link]);
  const hasMatch = useMemo(() => match !== undefined && match !== null, [match]);

  const fileIds = useMemo(() => {
    if (!match) return [];
    return match.attachments.map(({ fileName }) => {
      const [_, name, date, id] = fileName.slice(0, fileName.lastIndexOf(".")).split("_");
      return id;
    });
  }, [match]);

  const mappedFileUrls = useMemo(() => {
    if (!match) return new Map();
    const matches = fileIds.map((id) => {
      const found = match.attachments.find(({ fileName }) => fileName.includes(id));
      return found ? { ...found, fileName: id } : null;
    });
    const filtered = matches.filter((c) => c) as { fileName: string; serverRelativeUrl: string }[];
    const entries = filtered.map((c) => Object.values(c) as [string, string]);
    return new Map(entries);
  }, [fileIds, match]);

  useEffect(() => {
    console.log({ mappedFileUrls });
  }, [mappedFileUrls]);

  const convertLink = useCallback(
    (link: string) => {
      if (!match) return link;
      return `${match.url}${link}?web=1&action=edit`;
    },
    [match]
  );

  useEffect(() => {
    if (date) {
      props.setDate(date);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, props.setDate]);

  return { currentDate, dateString: date, isFileExisting, directLink, hasMatch, fileIds, mappedFileUrls, convertLink };
};
