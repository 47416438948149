import * as React from "react";
import { AppColors } from "../models/General";
import { Heroicon, Heroicons } from "./Heroicon/Heroicon";

export const DetailLine: React.FC<{
  appColors: AppColors;
  value: string;
  icon: Heroicon;
  desc?: string;
  handleOnClick?: () => any;
  notStaticSized?: boolean;
  className?: string;
}> = React.memo((props) => {
  const Icon = React.useMemo(() => Heroicons.Outline[props.icon], [props.icon]);

  return (
    <div
      className={`relative flex w-full p-1 text-primary-800 bg-white rounded-lg shadow mb-1 ${
        props.handleOnClick ? "cursor-pointer" : ""
      } ${props.className}`}
      onClick={props.handleOnClick}
      style={
        props.notStaticSized
          ? {}
          : {
              maxHeight: "100%",
              height: "2.75rem",
              minHeight: "2.75rem",
            }
      }
    >
      <div className={`flex flex-col w-10 h-full justify-center items-start text-primary-500`}>
        <Icon className="flex w-7 h-full px-1 justify-center items-center" />
        {props.desc ? (
          <span
            className={`flex h-2 w-5 mx-1 justify-center items-center text-xxs font-semibold leading-none text-primary-400`}
          >
            {props.desc}
          </span>
        ) : null}
      </div>
      <div className="relative flex justify-start items-center w-full py-2 ml-1 text-sm">
        <span className="text-left">{props.value}</span>
        {props.handleOnClick ? (
          <Heroicons.Outline.DotsHorizontalIcon className="absolute right-0 top-0 w-4 h-full flex justify-center items-center text-gray-400 mr-1" />
        ) : null}
      </div>
    </div>
  );
});
