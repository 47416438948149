import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode, useMemo } from "react";
import { Heroicon, Heroicons } from "../Heroicon/Heroicon";
import BasicButton from "./BasicButton";
import ButtonColors, { ButtonColor } from "./ButtonColors";

type BaseButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  isLink?: boolean;
  isOutsideLink?: boolean;
  to?: string;
  isReplace?: boolean;
  inverted?: boolean;
  color?: ButtonColor;
  icon?: Heroicon;
  iconType?: keyof typeof Heroicons;
  iconClass?: string;
  iconSizeOverwrite?: string;
  paddingOverwrite?: string;
  identifier: string;
  children?: ReactNode | ReactNode[];
  ref?: any;
};

const BaseButton = ({
  icon,
  iconType,
  iconClass,
  iconSizeOverwrite,
  paddingOverwrite,
  color,
  inverted,
  children,
  ...props
}: BaseButtonProps) => {
  const Icon = useMemo(() => (icon ? Heroicons[iconType || "Outline"][icon] : () => null), [icon, iconType]);

  return (
    <BasicButton
      {...props}
      className={`${props.className ?? ""} ${
        paddingOverwrite ?? "px-4 py-3"
      } outline-none inline-flex justify-center items-center content-center min-w-0  hoverFix:hover:underline font-semibold tracking-wide rounded-md ${
        ButtonColors[inverted ? "Inverted" : "Basic"][color ?? "primary"]
      } transition-all ease-in-out duration-200 focus:outline-1 focus:outline-gray-700 gap-2 ${
        children ? "pr-[1.875rem]" : ""
      }`}
    >
      {icon ? (
        <>
          <Icon className={`${iconClass ?? ""} ${iconSizeOverwrite ?? "w-[1.875rem] h-[1.875rem]"} flex-shrink-0`} />
          {children ? <span className="text-center truncate flex-shrink-0">{children}</span> : null}
        </>
      ) : (
        <span className="text-center truncate">{children}</span>
      )}
    </BasicButton>
  );
};
export default BaseButton;
