const IS_DEV = process.env.REACT_APP_IS_DEV === "true";
const AUTHORITY = IS_DEV ? "devp907gas" : "prodp907gas";
const AUTHORITY_DOMAIN = `${AUTHORITY}.b2clogin.com`;
const AUTHORITY_SUB_DOMAIN = `${AUTHORITY}.onmicrosoft.com`;

type B2C_POLICY = "signIn" | "signUpSignIn" | "profileEdit" | "resetPassword";

const names: { [key in B2C_POLICY]: string } = {
  signIn: "B2C_1_signin",
  signUpSignIn: "B2C_1_signup_signin_mo",
  profileEdit: "B2C_1_profileedit",
  resetPassword: "B2C_1_passwordreset",
};

type policyCollection = {
  authorities: { [key in B2C_POLICY]: string };
} & { authorityDomain: typeof AUTHORITY_DOMAIN };

function generatePolicies() {
  const keys = Object.keys(names) as (B2C_POLICY & "clean")[];
  const rest: B2C_POLICY[] = keys.filter((c) => c !== "clean");
  const createAuthority: (key: B2C_POLICY) => string = (key) => {
    return `https://${AUTHORITY_DOMAIN}/${AUTHORITY_SUB_DOMAIN}/${names[key]}`;
  };
  const base = rest.reduce(
    (obj, key) => ({ ...obj, [key]: createAuthority(key) }),
    {} as { [key in B2C_POLICY]: string }
  );
  const policy: policyCollection = { authorities: base, authorityDomain: AUTHORITY_DOMAIN };
  return policy;
}

const policies: policyCollection = generatePolicies();

const B2C = {
  isDev: IS_DEV,
  authority: AUTHORITY,
  authorityDomain: AUTHORITY_DOMAIN,
  authoritySubDomain: AUTHORITY_SUB_DOMAIN,
  policies,
};

export default B2C;
