import * as React from "react";
import { AppColors } from "../models/General";
import { Heroicon, Heroicons } from "./Heroicon/Heroicon";

export const InformationBaseClass = "rounded text-xs leading-tight";

export const InformationDetail: React.FC<{
  appColors: AppColors;
  additionalClasses?: string;
  icon: Heroicon;
  iconColorIntensity?: string;
  size?: "big";
  children?: React.ReactNode | React.ReactNode[];
}> = (props) => {
  const Icon = React.useMemo(() => Heroicons.Solid[props.icon], [props.icon]);

  return (
    <div className={`flex flex-grow-0 justify-center items-center h-full ${props.additionalClasses}`}>
      <span className={`${InformationBaseClass} border-primary-300 text-primary-800 font-semibold`}>
        <div className={`flex justify-center items-center text-primary-100 space-x-1`}>
          <Icon
            className={`flex justify-center items-center ${props.size === "big" ? "w-4" : "w-3"} ${
              props.iconColorIntensity ? `text-primary-${props.iconColorIntensity}` : `text-primary-100`
            } h-full flex-shrink-0`}
          />
          <span className={`${props.size === "big" ? "text-base" : "text-xs"} whitespace-no-wrap`}>
            {props.children}
          </span>
        </div>
      </span>
    </div>
  );
};
