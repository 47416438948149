import { NavLink } from "react-router-dom";
import { useFadeInComponent } from "../../../hooks/useFadeInComponent";

export const CancelledLoginView = () => {
  const { opacityClasses } = useFadeInComponent();
  return (
    <div
      className={`inline-flex flex-col w-full h-screen justify-center items-center min-w-0 min-h-0 px-2 ${opacityClasses}`}
    >
      <span className="mt-4 font-bold text-2xl tracking-wide text-gray-700">Login abgebrochen</span>
      <NavLink className="mt-6 text-lg font-semibold text-blue-600 underline" to="/login">
        Erneut versuchen
      </NavLink>
    </div>
  );
};
