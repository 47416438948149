import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { AIReactCustomEvent } from "@microsoft/applicationinsights-react-js/types/useTrackEvent";
import { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { reactPlugin } from "./AppInsights";
import AppInsightsWrapper from "./appInsights/AppInsightsWrapper";
import SettingsProvider, { useSettings } from "./clientApi/SettingsProvider";
import Action from "./clientApi/tracking/Actions";
import ErrorBoundary from "./components/ErrorBoundary";
import { MessageBox } from "./components/MessageBox";
import { useToasterHandler } from "./components/ToasterHandler/useToasterHandler";
import { Device, useDevice } from "./hooks/useDevice";
import { useToggle } from "./hooks/useToggle";
import { UnauthenticatedMainView } from "./views/Unauthenticated/main/UnauthenticatedMainView";

import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  SilentRequest,
} from "@azure/msal-browser";
import AuthConfig from "./AuthConfig";
import DelayedNavigate from "./components/DelayedNavigate";
import AuthenticatedView from "./views/Authenticated/AuthenticatedView";
import useDeprecatedUrl from "./hooks/useDeprecatedUrl";
import useDisplayCorrection from "./hooks/useDisplayCorrection";

const generateEmptyClientApiAction = () => {
  const now = new Date();
  const action: Action.Base<Action.Variant.Empty> = {
    type: Action.Variant[Action.Variant.Empty],
    progress: Action.Progress[Action.Progress.Started] as keyof typeof Action.Progress,
    actionName: "generateEmptyClientApiAction",
    triggeredBy: "Code",
    actionData: "Leerlauf",
    information: {
      currentUrl: window?.location?.href ?? "",
      timestamp: now,
      timestampString: now.toISOString(),
      isError: false,
    },
  };
  return action;
};

export const TrackContext = createContext<AIReactCustomEvent<Action.Base<any>>>(undefined as any);

export const AppShell = () => {
  const {
    instance: { logout, handleRedirectPromise },
    inProgress,
    accounts,
  } = useMsal();
  const activeAccount = useAccount();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState<string | null>(null);

  const account = useMemo(() => {
    let account = activeAccount;
    account ||= accounts[0];
    account ||= null;
    return account;
  }, [accounts, activeAccount]);

  const config: SilentRequest = useMemo(() => {
    let account = activeAccount;
    account ||= accounts[0];
    account ||= null;

    return {
      ...AuthConfig.loginRequest,
      account: account ?? undefined,
      loginHint: account?.username ?? account?.name ?? undefined,
    } as SilentRequest;
  }, [accounts, activeAccount]);

  const { acquireToken, error, login, result } = useMsalAuthentication(InteractionType.Silent, config);

  useEffect(() => {
    if (error) {
      const { message, errorMessage, errorCode } = error;
      if (
        message.includes("AADB2C90080") ||
        errorCode.includes("AADB2C90080") ||
        errorMessage.includes("AADB2C90080")
      ) {
        logout(config);
      } else if (error instanceof InteractionRequiredAuthError) {
        console.error("Login called useEffect - error", error);
        login(InteractionType.Redirect, config);
      }
    }
  }, [accounts, config, error, inProgress, login, logout]);

  const saveToken = useCallback((token: string) => {
    console.log("Trage Token in sessionStorage ein");
    setToken(token);
    sessionStorage.setItem("token_fallback", token);
    const payload = { type: "TOKEN", token: token };
    if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
      const { serviceWorker } = navigator;
      serviceWorker.controller?.postMessage(payload);
    }
  }, []);

  useEffect(() => {
    if (result?.accessToken) {
      saveToken(result.accessToken);
    }
  }, [result, saveToken]);

  const sendTokenToServiceWorker = useCallback(
    async (conf: typeof config) => {
      try {
        if (inProgress === InteractionStatus.None) {
          console.log("Prüfe ob Token an SW gesendet werden soll");
          const { account } = conf;
          if (inProgress !== InteractionStatus.None) {
            throw new Error("Kein Token angefordert - es wird noch eine Interaction durchgeführt");
          }
          if (account === null || account === undefined) {
            throw new Error("Kein Token angefordert - Account scheint nicht gesetzt zu sein");
          }
          const response = await acquireToken(InteractionType.Silent, conf);
          if (!response?.accessToken) {
            throw new Error("Token Response war schlecht");
          } else {
            saveToken(response.accessToken);
          }
        }
      } catch (error) {
        const message = (error as Error).message ?? "";
        console.error("Konnte Token nicht an ServiceWorker senden", error);
        if (message.includes("AADB2C90080")) {
          logout(config);
        } else if (message.startsWith("Kein Token angefordert")) {
          return;
        } else if (error instanceof InteractionRequiredAuthError) {
          await acquireToken(InteractionType.Redirect, config);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts, inProgress, acquireToken, saveToken]
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      accounts[0] &&
      navigator &&
      "serviceWorker" in navigator &&
      navigator.serviceWorker &&
      inProgress === InteractionStatus.None
    ) {
      const { serviceWorker } = navigator;
      const messageHandler = (event: MessageEvent) => {
        if (event.data.type) {
          const { type, data } = event.data;
          if (type === "TOKEN_REFRESH") {
            console.log("SW fordert neuen Token");
            sendTokenToServiceWorker(config);
          } else if (type === "RESPONSE") {
            _dispatchEvent(type, data);
          } else if (type === "REFRESH") {
            console.log("refresh");
            window.location.reload();
          }
        }
      };
      serviceWorker.addEventListener("message", messageHandler);
      return () => {
        serviceWorker.removeEventListener("message", messageHandler);
      };
    }
  }, [accounts, config, inProgress, isAuthenticated, sendTokenToServiceWorker]);

  useEffect(() => {
    if (isAuthenticated && accounts[0] && inProgress === InteractionStatus.None) {
      const refreshHandler = () => {
        console.log("Window fordert neuen Token");
        sendTokenToServiceWorker(config);
      };
      window.addEventListener("TOKEN_REFRESH", refreshHandler);
      return () => {
        window.removeEventListener("TOKEN_REFRESH", refreshHandler);
      };
    }
  }, [accounts, config, inProgress, isAuthenticated, sendTokenToServiceWorker]);

  useLayoutEffect(() => {
    if (isAuthenticated && accounts[0] && inProgress === InteractionStatus.None) {
      console.log("Frage Initialen Token ab");
      sendTokenToServiceWorker(config);
      console.log("Starte Interval");
      const interval = setInterval(() => {
        console.log("Rufe neuen Token ab oder behalte den Alten");
        sendTokenToServiceWorker(config);
      }, 6e4 * 15);
      return () => {
        if (interval) {
          console.log("Breche Interval ab");
          clearInterval(interval);
        }
      };
    }
  }, [accounts, config, inProgress, isAuthenticated, sendTokenToServiceWorker]);

  const actionRef = useRef<Action.Base<Action.Variant.Empty>>(generateEmptyClientApiAction());
  const trackGenericEvent = useTrackEvent<Action.Base<any>>(reactPlugin, "Client_Action", actionRef.current, false);
  const trackClientApiEvent = useTrackEvent<Action.Base<any>>(reactPlugin, "Client_API", actionRef.current, false);

  const [isUpdateAvailable, , showUpdateMsg, hideUpdateMsg] = useToggle();

  const update = useCallback(async () => {
    try {
      if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        const waitingRegistration = registrations.find((c) => c.waiting !== null);
        if (waitingRegistration?.waiting) {
          waitingRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
        } else {
          window.location.reload();
        }
      }
    } catch (error) {}
  }, []);

  const checkForUpdate = useCallback(async () => {
    if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
      const reg = await navigator.serviceWorker.ready;
      if (reg) await reg.update();
      if (reg.waiting) {
        showUpdateMsg();
      }
    }
  }, [showUpdateMsg]);

  useLayoutEffect(() => {
    if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
      const interval = setInterval(checkForUpdate, 6e4);
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [checkForUpdate]);

  const device = useDevice(1e3);
  const Settings = useSettings();
  const { addMessage } = useToasterHandler();

  useDisplayCorrection(device, Settings);
  useDeprecatedUrl(Settings);

  return (
    <>
      <MessageBox
        clickText="Hier klicken um das Update durchzuführen"
        isDisplaying={isUpdateAvailable}
        text="Neues Update verfügbar"
        icon="LightBulbIcon"
        handleClick={update}
        handleClose={hideUpdateMsg}
      />
      <ErrorBoundary>
        <UnauthenticatedTemplate>
          <Routes>
            <Route index element={<UnauthenticatedMainView />} />
            <Route path="/*" element={<UnauthenticatedMainView />} />
          </Routes>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AppInsightsWrapper>
            <TrackContext.Provider value={trackGenericEvent}>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <div className="inline-flex w-full h-full bg-white justify-center items-center content-start gap-1.5">
                      <h3 className="text-lg text-gray-800">Login abgeschlossen</h3>
                      <span className="animate-pulse text-sm text-gray-600">Sie werden weitergeleitet...</span>
                      <DelayedNavigate to="/Reservierung" />
                    </div>
                  }
                />
                <Route
                  path="/*"
                  element={
                    accounts[0] && isAuthenticated && inProgress === InteractionStatus.None && token ? (
                      <AuthenticatedView device={device} trackEvent={trackClientApiEvent} />
                    ) : (
                      <div className="inline-grid grid-cols-1 p-2 justify-start items-start gap-2">
                        <span>
                          {account?.username ?? "Kein Account"} - {inProgress}
                        </span>
                        <span className="text-xs text-gray-500">accounts:</span>
                        {accounts.map((account) => (
                          <span className="text-xs text-gray-500" key={account.localAccountId}>
                            {account.username}
                          </span>
                        ))}
                      </div>
                    )
                  }
                />
                <Route index element={<DelayedNavigate to="/Reservierung" />} />
              </Routes>
            </TrackContext.Provider>
          </AppInsightsWrapper>
        </AuthenticatedTemplate>
      </ErrorBoundary>
    </>
  );
};

function _dispatchEvent<T>(event: string, detail?: T) {
  if (detail) {
    const eventToDispatch = new CustomEvent<typeof detail>(event, { detail });
    return window.dispatchEvent(eventToDispatch);
  } else {
    const eventToDispatch = new Event(event);
    return window.dispatchEvent(eventToDispatch);
  }
}
