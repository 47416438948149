import * as React from "react";
interface InputFieldProps {
  handleInputChange: (ev: React.ChangeEvent<HTMLInputElement>) => any;
  value: string;
  placeholder: string;
  className?: string;
  style?: React.CSSProperties;
  type?: "text" | "number" | "date";
}

export const InputField: React.FC<InputFieldProps> = props => {
  // const _handleFilter: <T>(keys: (keyof T)[], filters: ((keys: keyof T) => boolean)[]) => (keyof T)[] = (
  //   keys,
  //   filters
  // ) => filters.reduce((values, filter) => [...values.filter(filter)], keys);
  return (
    <input
      type={props.type || "text"}
      className={`flex pl-2 ${props.className}`}
      placeholder={props.placeholder}
      onChange={props.handleInputChange}
      value={props.value}
      style={props.style}
    />
  );
};
