import { useEffect } from "react";
import { useSettings } from "../clientApi/SettingsProvider";
import { useInput } from "../hooks/useInput";
import { AppColors } from "../hooks/useTheme";
import { BaseInput } from "./baseinput/BaseInput";
import { SummaryBlock } from "./SummaryBlock";

type NewReservationEmailProps = {
  isVisible?: boolean;
  appColors: AppColors;
  onSlideToEmail: () => void;
  emailAdress: string | null;
  content: string | null;
  subject: string | null;
  setSubject: (str: string) => void;
  setContent: (str: string) => void;
  isFullHeight?: boolean;
  className?: string;
};

const NewReservationEmail = (props: NewReservationEmailProps) => {
  const [emailSubject, setEmailSubject, resetEmailSubject] = useInput(props.subject || undefined);
  const [emailContent, setEmailContent, resetEmailContent] = useInput(props.content || undefined);

  useEffect(() => {
    props.setContent(emailContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setContent, emailContent]);

  useEffect(() => {
    props.setSubject(emailSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setSubject, emailSubject]);

  const { isUsingVirtualKeyboard } = useSettings();

  return (
    <>
      <div
        className={`relative inline-flex flex-col justify-start items-start content-start bg-gray-100 rounded-lg w-full shadow flex-shrink-0 p-2 transition-opacity duration-200 ease-in-out ${
          props.isVisible
            ? `opacity-100 ${props.isFullHeight ? "flex-1" : "h-auto mt-2"} pointer-events-auto`
            : "opacity-0 max-h-0 h-0 min-h-0 pointer-events-none mt-0"
        } ${props.className ?? ""}`}
        // style={{
        //   minHeight: "8rem",
        //   height: "8rem",
        //   maxHeight: "8rem",
        // }}
      >
        {/* <span className="flex w-full h-full justify-center items-center text-red-500">
        Not Implemented
      </span> */}
        <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1`}>
          Empfänger
        </span>
        <SummaryBlock
          clickHandler={props.onSlideToEmail}
          appColors={props.appColors}
          value={props.emailAdress || ""}
          icon="MailIcon"
        />
        <span className={`text-xxs uppercase font-semibold tracking-wide text-left w-full text-gray-500 px-1 mt-2`}>
          Email
        </span>
        <BaseInput
          value={emailSubject}
          onChange={setEmailSubject}
          label="Betreff"
          className="shadow-md flex-shrink-0"
          isVirtualKeyboard={isUsingVirtualKeyboard}
        />
        <BaseInput
          icon="DocumentTextIcon"
          value={emailContent}
          onChange={setEmailContent}
          label="Nachricht"
          className={`mt-1 shadow-md h-64 flex-shrink-0 ${props.isFullHeight ? "flex-1" : "w-full"}`}
          height="h-full"
          isTextArea
          isVirtualKeyboard={isUsingVirtualKeyboard}
        />
      </div>
    </>
  );
};
export default NewReservationEmail;
