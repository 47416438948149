import * as uuid from "uuid";
import { ApiCall } from "../models/Controller";
import * as Queue from "../models/Queue";
import { Reservation } from "../models/Reservation";
import { Table } from "../models/Table";
import { commandProps } from "./Commands";

export const getAllTables = async (restaurantId: string) => {
  try {
    const handledResponse = await ApiCall<{ tables: Table[] }>(
      `tables?restaurantId=${restaurantId}`,
      "GET",
      undefined,
      (response: Table[]) => {
        return { tables: response || [] };
      }
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

type putNewTableCall = commandProps<Table, { tab: Table }>;

export const putNewTableCall = async (props: putNewTableCall) =>
  await ApiCall<Table>(`tables?restaurantId=${props.restaurantId}`, "PUT", JSON.stringify(props.body));

export const putNewTable = async (restaurantId: string, tab: Table) => {
  Queue.addToQueue<putNewTableCall>({
    guid: uuid.v4(),
    command: "putNewTable",
    data: { restaurantId, body: tab, tab: tab },
  });
  return tab;
};

type updateTableCall = commandProps<Table, { tab: Table }>;

export const updateTableCall = async (props: updateTableCall) =>
  await ApiCall<Table>(`tables?restaurantId=${props.restaurantId}`, "POST", JSON.stringify(props.body));

export const updateTable = async (restaurantId: string, tab: Table) => {
  Queue.addToQueue<updateTableCall>({
    guid: uuid.v4(),
    command: "updateTable",
    data: { restaurantId, body: tab, tab: tab },
  });
  return tab;
};

type assignTableToReservationCall = commandProps<Table["id"][], { tabs: Table[]; res: Reservation }>;

export const assignTablesToReservationCall = async (props: assignTableToReservationCall) =>
  await ApiCall<Reservation>(
    `reservations/${props.res.id}/AssignTables?restaurantId=${props.restaurantId}`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { ...props.res, tables: props.tabs, state: 2 } as Reservation
  );

export const assignTablesToReservation = async (restaurantId: string, tabs: Table[], res: Reservation) => {
  Queue.addToQueue<assignTableToReservationCall>({
    guid: uuid.v4(),
    command: "assignTablesToReservation",
    data: { restaurantId, body: tabs.map((t) => t.id), tabs: tabs, res: res },
  });
  return { ...res, tables: tabs, state: 2 } as Reservation;
};

type unassignTableToReservationCall = commandProps<undefined, { res: Reservation }>;

export const unassignTablesToReservationCall = async (props: unassignTableToReservationCall) =>
  await ApiCall<Reservation>(
    `reservations/${props.res.id}/UnassignTables?restaurantId=${props.restaurantId}`,
    "POST",
    undefined,
    undefined,
    { ...props.res, tables: undefined, state: 1 } as Reservation
  );

export const unassignTableToReservation = async (restaurantId: string, res: Reservation) => {
  Queue.addToQueue<unassignTableToReservationCall>({
    guid: uuid.v4(),
    command: "unassignTablesToReservation",
    data: { restaurantId, body: undefined, res: res },
  });
  return { ...res, tables: undefined, state: 1 } as Reservation;
};

// const checkQueueForTables = (tables: Table[], date: string) => {
//   const queue = Queue.getQueue();
//   const queueMessages = queue
//     .filter(
//       message =>
//         tableCommands.some(key => key === message.command) &&
//         message.data &&
//         (message.data as commandProps<any, any>).body &&
//         (message.data as commandProps<any, any>).body.dateOfArrival &&
//         (message.data as commandProps<any, any>).body.dateOfArrival.startsWith(date)
//     )
//     .map(res => res.data.body);
//   console.log(queueMessages);
//   return [...tables, ...queueMessages];
// };

export const getTablesForRestaurant = ({ restaurantId }: { restaurantId: string }, cb: any) => {
  const name = "getTables";
  const storageLocation = `${restaurantId}_${name}`;

  const getTables = async () => {
    try {
      const res = await getAllTables(restaurantId);
      window.localStorage.setItem(storageLocation, JSON.stringify(res.tables));
      return cb(res.tables, true);
    } catch (error) {
      if (
        error &&
        ((error as Error).message?.includes("AbortError") ||
          (error as Error).name?.includes("AbortError") ||
          (error as Error).message?.includes("user aborted"))
      ) {
        const reservations = window.localStorage.getItem(storageLocation);
        if (reservations !== null) {
          const parsed = JSON.parse(reservations) as Reservation[];
          return cb(parsed, false);
        } else {
          return cb([], false);
        }
      } else if ((error as Error).message.includes("403")) {
        return;
      } else {
        setTimeout(() => {
          getTables();
        }, 5e3);
      }
    }
  };

  try {
    if (window && window.localStorage && window.localStorage.getItem) {
      const reservations = window.localStorage.getItem(storageLocation);
      if (reservations !== null) {
        const parsed = JSON.parse(reservations) as Reservation[];
        cb(parsed, false);
      } else {
        cb([], false);
      }
      getTables();
    }
  } catch (error) {
    if (
      error &&
      ((error as Error).message?.includes("AbortError") ||
        (error as Error).name?.includes("AbortError") ||
        (error as Error).message?.includes("user aborted"))
    ) {
      return;
    } else throw error;
  }
};

export type TableCommands = "putNewTable" | "updateTable" | "assignTablesToReservation" | "unassignTablesToReservation";

export const tableCommands: TableCommands[] = [
  "putNewTable",
  "updateTable",
  "assignTablesToReservation",
  "unassignTablesToReservation",
];
