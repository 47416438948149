import * as React from "react";
import { AppColors } from "../models/General";
import { SummaryTable } from "../models/Summary";
import { TimeColumn } from "./TimeColumn";

const defaultMaximum = 20;

export const TimeDiagram = ({ Times, ...props }: { Times: SummaryTable[0]; isSelected: boolean; date?: string }) => {
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (containerRef !== null) {
      containerRef.addEventListener("touchmove", (e) => e.stopPropagation(), { passive: false });
      return containerRef.removeEventListener("touchmove", (e) => e.stopPropagation());
    }
  }, [containerRef]);

  const keys = React.useMemo(() => {
    const keysToFilter = ["reservationTotal", "guestTotal", "reservationsByState", "reservationsGuestsByState"];
    return Times
      ? (Object.keys(Times) as (keyof SummaryTable)[]).filter((key) => keysToFilter.every((c) => c !== key))
      : [];
  }, [Times]);

  const maximum: number = React.useMemo(() => {
    const calculatedMaxiumum = keys.reduce((maximum: number, time) => {
      if (Times[time]) {
        return +Times[time].guestTotal > maximum ? +Times[time].guestTotal : +maximum;
      }
      return +maximum;
    }, 0);
    if (calculatedMaxiumum < defaultMaximum) return defaultMaximum;
    else return calculatedMaxiumum;
  }, [Times, keys]);

  const _renderTimeColums = React.useMemo(() => {
    if (Times) {
      return keys.map((time, idx) => {
        if (Times && Times[time]) {
        } else return null;
        if (time === "reservationTotal" || time === "guestTotal") return null;
        return (
          <TimeColumn
            key={time}
            TableTime={Times[time]}
            time={time + ""}
            total={+Times["guestTotal"]}
            maximum={maximum}
          ></TimeColumn>
        );
      });
    }
  }, [Times, keys, maximum]);

  return (
    <div
      ref={setContainerRef}
      className={`inline-grid grid-cols-1 flex-1 flex-shrink-0 w-full overflow-auto min-h-0 h-full`}
    >
      <div className={`relative flex flex-1 flex-shrink-0 h-full overflow-auto py-1 snap-x snap-proximity`}>
        {_renderTimeColums}
      </div>
    </div>
  );
};
