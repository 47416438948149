import * as React from "react";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { useSettings } from "../../clientApi/SettingsProvider";
import { BaseInput } from "../../components/baseinput/BaseInput";
import { ReservationButton } from "../../components/ReservationButton";
import { useTheme } from "../../hooks/useTheme";

export const NachrichtenProcessing = (props: {
  nachricht: Nachricht.Client<any>;
  canConfirm?: boolean;
  onConfirm?: (nachricht: Nachricht.Client<any>, note: string) => void;
}) => {
  const { theme } = useTheme();

  const [noteText, setNoteText] = React.useState<string | null>(null);

  const resetNoteText = React.useCallback(() => {
    return setNoteText(null);
  }, []);

  const currentNoteText = React.useMemo(() => noteText || "", [noteText]);

  const handleOnConfirm = React.useCallback(() => {
    if (props.onConfirm && typeof props.onConfirm === "function") {
      return props.onConfirm.call(undefined, props.nachricht, currentNoteText);
    }
  }, [currentNoteText, props.nachricht, props.onConfirm]);

  const { isUsingVirtualKeyboard } = useSettings();

  return (
    <div className="flex flex-1 flex-col min-h-0 overflow-hidden">
      <div className="flex flex-1 bg-gray-200 shadow-inner rounded-lg p-2">
        <BaseInput
          height="h-full"
          value={currentNoteText}
          onChange={setNoteText}
          label={"Notiz"}
          isTextArea
          icon="DocumentTextIcon"
          isVirtualKeyboard={isUsingVirtualKeyboard}
        />
      </div>
      <div className="flex h-14 flex-col w-full pt-2 pb-2">
        <ReservationButton
          unlockedHeight
          color={"blue"}
          isLocked={false}
          onClickHandler={handleOnConfirm}
          additionalClasses={`rounded-lg transition-all duration-200 ${
            props.canConfirm ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          Bestätigen
        </ReservationButton>
      </div>
    </div>
  );
};
