import { AuthError, RedirectRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect } from "react";
import AuthConfig from "../../../AuthConfig";
import B2C from "../../../b2cpolicies";
import useAuthRedirectHandler from "../../../hooks/useAuthRedirectHandler";

const config: RedirectRequest = {
  ...AuthConfig.loginRequest,
  authority: B2C.policies.authorities.resetPassword,
};

export const ForgotPasswordView = () => {
  const { instance } = useMsal();

  const continueOnForgotPasswordError = useCallback((error: AuthError | null) => {
    if (error === null) return true;
    if (
      error.message.includes("AADB2C90118") ||
      error.errorCode.includes("AADB2C90118") ||
      error.errorMessage.includes("AADB2C90118")
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const { error, result } = useAuthRedirectHandler({
    config,
    invokeRequestOnLoad: true,
    continueOnError: continueOnForgotPasswordError,
  });

  useEffect(() => {
    try {
      if (error) {
        if (
          error.message.includes("AADB2C90118") ||
          error.errorCode.includes("AADB2C90118") ||
          error.errorMessage.includes("AADB2C90118")
        ) {
          console.log("Forgot PASSWORD");
        } else {
          instance.logout({
            postLogoutRedirectUri: `${window.location.origin}`,
          });
        }
      } else if (result) {
        if (result.account === null) {
          instance.setActiveAccount(null);
          instance.logout({
            postLogoutRedirectUri: `${window.location.origin}`,
          });
        }
      }
    } catch (error) {
      throw new Error(`${error}`);
    }
  }, [error, instance, result]);

  return (
    <section className="fixed inset-0 inline-flex flex-col w-full h-full min-h-0 jusitfy-center items-center content-start">
      <p className="text-gray-50 font-semibold tracking-wide animate-pulse mx-auto my-auto">Weiterleitung...</p>
    </section>
  );
};
