function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function compareISOStrings(a: string, b: string, inverted?: boolean) {
  const options = ["de-de", { numeric: true }] as [string | string[], Intl.CollatorOptions | undefined];
  if (inverted) {
    return a.localeCompare(b, ...options);
  } else {
    return b.localeCompare(a, ...options);
  }
}

function sliceArrayIntoParts<T>(array: T[], chunkSize: number) {
  if (array.length <= chunkSize) return [1, [array]] as [number, T[][]];
  const chunkAmount = Math.ceil(array.length / chunkSize);
  const chunks: T[][] = Array(chunkAmount)
    .fill(null)
    .map((_, idx) => {
      const offset = idx * chunkSize;
      return array.slice(offset, offset + chunkSize);
    });
  return [chunkAmount, chunks] as [number, T[][]];
}

const iOSFocus = (el: HTMLInputElement) => {
  const fakeInput = document.createElement("input");
  fakeInput.setAttribute("readonly", "true");
  fakeInput.style.position = "absolute";
  fakeInput.style.opacity = "0";
  fakeInput.style.height = "0";
  fakeInput.style.fontSize = "16px";
  document.body.prepend(fakeInput);
  fakeInput.focus();

  return setTimeout(() => {
    el.focus();
    fakeInput.remove();
  }, 300);
};

function isoLikeToDate(isoLike: string) {
  try {
    return new Date(isoLike);
  } catch (error) {
    throw error;
  }
}

function dateToIsoLike(date: Date) {
  return [...date.toLocaleDateString("de-de", { month: "2-digit", day: "2-digit", year: "numeric" }).split(".")]
    .reverse()
    .join("-");
}

const Tools = {
  formatBytes,
  compareISOStrings,
  sliceArrayIntoParts,
  iOSFocus,
  dateToIsoLike,
};

export default Tools;
