import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import * as IDB from "idb-keyval";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import App from "../../App";
import ClientApi from "../../clientApi/ClientApi";
import ProtocolTracker from "../../components/AppInsights/ProtocolTracker";
import BaseButton from "../../components/Button/BaseButton";
import TastaturContextProvider from "../../components/tastatur/TastaturContextProvider";
import ToasterHandler from "../../components/ToasterHandler/ToasterHandler";
import DebugContext from "../../hooks/RemoteDebug/DebugContext";
import useDebugConnections from "../../hooks/RemoteDebug/useDebugConnections";
import useClientApiLog from "../../hooks/useClientApiLog";
import useEmergencyBrake from "../../hooks/useEmergencyBrake";
import { useToggle } from "../../hooks/useToggle";
import { useAuthenticatedViewModel } from "./AuthenticatedViewModel";
import { AuthenticatedViewTypes } from "./AuthenticatedViewTypes";

let store: IDB.UseStore | null = null;

export const clientApi = ClientApi();

const AuthenticatedView = (props: AuthenticatedViewTypes.Props) => {
  const viewModel = useAuthenticatedViewModel(props);

  const clientApiRef = useRef(clientApi);

  const { inProgress, accounts } = useMsal();
  const account = useMemo(() => accounts[0] ?? null, [accounts]);
  const isAuthenticated = useIsAuthenticated();

  const [isInitialized, , initialized] = useToggle();
  const isInitialising = useRef(false);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (
      clientApiRef.current &&
      isAuthenticated &&
      props.trackEvent !== undefined &&
      props.trackEvent !== null &&
      isInitialising.current === false &&
      inProgress === InteractionStatus.None
    ) {
      isInitialising.current = true;
      const timeout = setTimeout(() => {
        clientApiRef.current.initialise(props.trackEvent, account?.username ?? null, store, initialized);
      }, 250);
      return () => {
        isInitialising.current = false;
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress, props.trackEvent]);

  const debugConnections = useDebugConnections(account?.name ?? account?.username, isInitialized);

  const [hasProblem, setHasProblem] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasProblem(true);
    }, 1500);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  const [brake, isBraking] = useEmergencyBrake();
  const onBrake = useCallback(() => {
    try {
      const ev = new CustomEvent("STORAGE_BLOCK", { detail: true });
      window.dispatchEvent(ev);
    } catch (error) {
      console.error(error);
    }
    brake();
  }, [brake]);

  const [texts, lastText] = useClientApiLog();

  return (
    <>
      <ToasterHandler />
      <ProtocolTracker />
      <TastaturContextProvider>
        <DebugContext.Provider value={debugConnections}>
          {isAuthenticated && isInitialized ? (
            <App navigate={navigate} account={account} device={props.device} />
          ) : (
            <>
              <div className="fixed top-0 left-0 inline-flex flex-col max-h-screen overflow-hidden justify-start items-start content-start text-xs font-mono font-semibold tracking-wider leading-snug px-1 z-0">
                {texts.slice(0, -1).map((text, idx) => (
                  <span key={idx} className="text-gray-500/50">
                    {text ?? "--"}
                  </span>
                ))}
                <span className="text-white/50">{lastText ?? "--"}</span>
              </div>
              <span className="fixed top-0 left-0 inset-0 w-screen h-screen flex justify-center items-center content-start flex-col gap-1 drop-shadow z-10">
                {!isAuthenticated ? (
                  <span className="bg-gray-500/50 text-gray-50 px-3 py-1 rounded">Nicht Authentifiziert</span>
                ) : null}
                {!isInitialized ? (
                  <>
                    <span className="bg-gray-500/50 text-gray-50 px-3 py-1 rounded">ClientApi nicht Initialisiert</span>
                    {isInitialising ? (
                      <span className="bg-gray-500/50 text-gray-50 px-3 py-1 rounded animate-pulse">
                        Wird initialisiert...
                      </span>
                    ) : (
                      <span className="bg-red-500/50 text-red-50 px-3 py-1 rounded">Wird nicht initialisiert</span>
                    )}
                  </>
                ) : null}

                {isInitialized && isAuthenticated ? (
                  <span className="bg-gray-500/50 text-gray-50 px-3 py-1 rounded">Es sollte bald weitergehen...</span>
                ) : null}

                <BaseButton
                  identifier="Restart_App"
                  onClick={onBrake}
                  disabled={isBraking}
                  className={`w-64 h-10 ${hasProblem ? "opacity-100" : "opacity-10"}`}
                  iconSizeOverwrite="w-6 h-6"
                  color="error"
                >
                  {isBraking ? "Startet neu..." : "Neustart"}
                </BaseButton>
              </span>
            </>
          )}
        </DebugContext.Provider>
      </TastaturContextProvider>
    </>
  );
};

export default AuthenticatedView;
