import * as React from "react";
import { Reservation } from "../models/Reservation";
import { AppColors } from "../models/General";
import { Heroicons } from "./Heroicon/Heroicon";

export const GuestAmountEdit = React.memo(function GuestAmountEdit(props: {
  appColors: AppColors;
  guestAmount: Reservation["guestAmount"];
  handleGuestAmountMinus: (type: keyof Reservation["guestAmount"]) => void;
  handleGuestAmountAdd: (type: keyof Reservation["guestAmount"]) => void;
}) {
  return (
    <div className={`flex flex-2 w-full justify-center items-start py-2`}>
      <div className="flex flex-1 flex-col justify-center items-center p-1">
        <span
          className={`flex text-xxs uppercase font-semibold tracking-wide text-left w-full text-primary-400 px-1 pb-1`}
        >
          Erwachsene
        </span>
        <div className="flex flex-1 w-full bg-white rounded-lg shadow-md">
          <div
            className={`flex flex-1 items-center justify-center p-2 text-primary-600`}
            onClick={() => props.handleGuestAmountAdd("adultAmount")}
          >
            <Heroicons.Outline.PlusIcon className="w-6 h-6" />
          </div>
          <div className="flex flex-col flex-3 p-2 items-stretch">
            <div className={`flex w-full h-full justify-center items-center text-primary-700 text-2xl font-semibold`}>
              {props.guestAmount.adultAmount}
            </div>
          </div>
          <div
            className={`flex flex-1 items-center justify-center p-2 text-primary-600`}
            onClick={() => props.handleGuestAmountMinus("adultAmount")}
          >
            <Heroicons.Outline.MinusIcon className="w-6 h-6" />
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col justify-center items-center p-1">
        <span
          className={`flex text-xxs uppercase font-semibold tracking-wide text-left w-full text-primary-400 px-1 pb-1`}
        >
          Kinder
        </span>
        <div className="flex flex-1 w-full bg-white rounded-lg shadow-md">
          <div
            className={`flex flex-1 items-center justify-center p-2 text-primary-600`}
            onClick={() => props.handleGuestAmountAdd("childAmount")}
          >
            <Heroicons.Outline.PlusIcon className="w-6 h-6" />
          </div>
          <div className="flex flex-col flex-3 p-2 items-stretch">
            <div className={`flex w-full h-full justify-center items-center text-primary-700 text-2xl font-semibold`}>
              {props.guestAmount.childAmount}
            </div>
          </div>
          <div
            className={`flex flex-1 items-center justify-center p-2 text-primary-600`}
            onClick={() => props.handleGuestAmountMinus("childAmount")}
          >
            <Heroicons.Outline.MinusIcon className="w-6 h-6" />
          </div>
        </div>
      </div>
    </div>
  );
});
