import { useCallback, useEffect, useMemo, useState } from "react";
import Tools from "../Tools";
import useDayAmount from "./useDayAmount";

const formatDate = (date?: Date, ignoreTime?: boolean) => {
  if (ignoreTime) {
    const temp = date ? new Date(date) : new Date();
    temp.setHours(0);
    temp.setMinutes(0);
    temp.setSeconds(0);
    temp.setMilliseconds(0);
    return temp;
  } else {
    return date ? new Date(date) : new Date();
  }
};

export const useCurrentDate = (ignoreTime?: boolean, intervalInSec?: number) => {
  const [currentDate, setCurrentDate] = useState<number>(+formatDate(new Date(), ignoreTime));

  const updateTime = useCallback(() => {
    setCurrentDate(+formatDate(new Date(), ignoreTime));
  }, [ignoreTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime();
    }, 1000 * (intervalInSec ?? 60));
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [ignoreTime, intervalInSec, updateTime]);

  const currentDateDate = useMemo(() => new Date(currentDate), [currentDate]);

  const dayAmount = useDayAmount(currentDateDate);

  const formattedDate = useMemo(() => {
    const formatted = currentDateDate.toLocaleDateString("de-de", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    return { display: formatted, isoLike: Tools.dateToIsoLike(currentDateDate) };
  }, [currentDateDate]);

  const isoLike = useMemo(() => Tools.dateToIsoLike(currentDateDate), [currentDateDate]);
  const frozenDate = useMemo(() => new Date(isoLike), [isoLike]);

  return [currentDateDate, dayAmount, formattedDate, frozenDate, updateTime] as [
    Date,
    number,
    { display: string; isoLike: string },
    Date,
    typeof updateTime
  ];
};
