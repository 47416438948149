import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Tools from "../../../Tools";
import AbspracheDetailView from "../detail/AbspracheDetailView";
import AbspracheOverviewView from "../overview/AbspracheOverviewView";
import { useAbspracheMainViewModel } from "./AbspracheMainViewModel";
import AbspracheMainViewTypes from "./AbspracheMainViewTypes";

const AbspracheMainView = (props: AbspracheMainViewTypes.Props) => {
  const viewModel = useAbspracheMainViewModel(props);

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/absprache/${Tools.dateToIsoLike(props.date)}`);
  }, [navigate, props.date]);

  return (
    <Routes>
      <Route path="/" element={<AbspracheOverviewView {...viewModel} reservierungen={props.reservierungen} />} />
      <Route
        path="/:date"
        element={<AbspracheDetailView {...viewModel} reservierungen={props.reservierungen} setDate={props.setDate} />}
      />
    </Routes>
  );
};
export default AbspracheMainView;
