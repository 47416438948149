/* eslint-disable quotes */
import { memo, useCallback, useMemo } from "react";
import TastaturBackspaceButton from "./buttons/TastaturBackspaceButton";
import TastaturButton from "./buttons/TastaturButton";
import TastaturEnterButton from "./buttons/TastaturEnterButton";
import TastaturShiftButton from "./buttons/TastaturShiftButton";
import TastaturSpaceButton from "./buttons/TastaturSpaceButton";
import TastaturSpecialCharButton from "./buttons/TastaturSpecialCharButton";
import TastaturSwitchButton from "./buttons/TastaturSwitchButton";
import Tastatur from "./Tastatur";
import useTastaturContext from "./useTastaturContext";

type TastaturTextProps = {
  onKeyPress: (key: Tastatur.Key | Tastatur.Key.Special) => void;
};

const TastaturText = ({ onKeyPress, ...props }: TastaturTextProps) => {
  const { isShifted, mode, isShowingSpecialKeys } = useTastaturContext();

  const firstRowKeys: (Tastatur.Key | Tastatur.Key.Special)[] = useMemo(
    () =>
      mode === Tastatur.Mode.Text
        ? ["q", "w", "e", "r", "t", "z", "u", "i", "o", "p", "ü"]
        : isShowingSpecialKeys
        ? ["[", "]", "{", "}", "#", "%", "^", "*", "+", "="]
        : Array(10)
            .fill(null)
            .map((_, c) => (c < 9 ? c + 1 + "" : "0")),
    [isShowingSpecialKeys, mode]
  );

  const secondRowKeys: (Tastatur.Key | Tastatur.Key.Special)[] = useMemo(
    () =>
      mode === Tastatur.Mode.Text
        ? ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ö", "ä"]
        : isShowingSpecialKeys
        ? ["_", "\\", "|", "~", "<", ">", "$", "£", "¥", "•"]
        : ["-", "/", ":", ";", "(", ")", "€", "&", "@", '"'],
    [isShowingSpecialKeys, mode]
  );

  const thirdRowKeys: (Tastatur.Key | Tastatur.Key.Special)[] = useMemo(
    () =>
      mode === Tastatur.Mode.Text
        ? [Tastatur.Key.Special.Shift, "y", "x", "c", "v", "b", "n", "m", Tastatur.Key.Special.Backspace]
        : [Tastatur.Key.Special.SpecialChars, ".", ",", "?", "!", "´", Tastatur.Key.Special.Backspace],
    [mode]
  );

  const fourthRowKeys: (Tastatur.Key | Tastatur.Key.Special)[] = useMemo(
    () => [Tastatur.Key.Special.Switch, Tastatur.Key.Special.Space, Tastatur.Key.Special.Enter],
    []
  );

  const keyboardKeys: (Tastatur.Key | Tastatur.Key.Special)[] = useMemo(
    () => [...firstRowKeys, ...secondRowKeys, ...thirdRowKeys, ...fourthRowKeys],
    [firstRowKeys, fourthRowKeys, secondRowKeys, thirdRowKeys]
  );

  const relayKeyPress = useCallback(
    (key: Tastatur.Key | Tastatur.Key.Special) => () => {
      onKeyPress(key);
    },
    [onKeyPress]
  );

  const keyButtons = useMemo(
    () =>
      keyboardKeys.map((key, idx) => {
        const keyHandler = relayKeyPress(key);
        return key === Tastatur.Key.Special.Shift ? (
          <TastaturShiftButton key="Shift" onPress={keyHandler} isShifted={isShifted} />
        ) : key === Tastatur.Key.Special.Backspace ? (
          <TastaturBackspaceButton key="Backspace" onPress={keyHandler} />
        ) : key === Tastatur.Key.Special.Space ? (
          <TastaturSpaceButton
            key="Space"
            onPress={keyHandler}
            className={mode === Tastatur.Mode.Text ? "col-span-7" : "col-span-6"}
          />
        ) : key === Tastatur.Key.Special.Switch ? (
          <TastaturSwitchButton key="Switch" onPress={keyHandler}>
            {mode === Tastatur.Mode.Text ? "123" : "ABC"}
          </TastaturSwitchButton>
        ) : key === Tastatur.Key.Special.Enter ? (
          <TastaturEnterButton key="Enter" onPress={keyHandler} />
        ) : key === Tastatur.Key.Special.SpecialChars ? (
          <TastaturSpecialCharButton key="SpecialChar" onPress={keyHandler}>
            {isShowingSpecialKeys ? "123" : "#+="}
          </TastaturSpecialCharButton>
        ) : (
          <TastaturButton
            className={mode === Tastatur.Mode.Signs && key === "?" ? "col-span-2 h-full" : undefined}
            key={key}
            onPress={keyHandler}
          >
            {!isShifted ? key : key.toString().toLocaleUpperCase()}
          </TastaturButton>
        );
      }),
    [isShifted, isShowingSpecialKeys, keyboardKeys, mode, relayKeyPress]
  );
  return (
    <div
      className={`inline-grid justify-center items-stretch min-h-0 ${
        mode === Tastatur.Mode.Text ? "grid-cols-11" : "grid-cols-10"
      } w-full flex-1`}
    >
      {keyButtons}
    </div>
  );
};
export default memo(TastaturText);
