import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { ToasterHandlerProps } from "./ToasterHandler";
import { useToggle } from "../../hooks/useToggle";

const positionClasses: { [key in Required<ToasterHandlerProps>["position"]]: string[] } = {
  "top-left": ["opacity-100", "opacity-0 -translate-y-full", "opacity-0 -translate-x-full"],
  "bottom-left": ["opacity-100", "opacity-0 translate-y-full", "opacity-0 -translate-x-full"],
  "top-right": ["opacity-100", "opacity-0 -translate-y-full", "opacity-0 translate-x-full"],
  "bottom-right": ["opacity-100", "opacity-0 translate-y-full", "opacity-0 translate-x-full"],
};

export const ToasterMessage = (props: {
  title: string;
  text: string;
  Icon: (p: any) => JSX.Element;
  color: string;
  position: Required<ToasterHandlerProps>["position"];
  delay?: number;
  id: number | string;
  onRemove: (id: number | string) => void;
  onClick?: () => any;
}) => {
  const [isRunning, , startRunning, stopRunning] = useToggle(false);
  const [isVisible, setIsVisible] = useState<boolean | null>(null);

  const onRemove = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (isRunning) {
      const timeout = setTimeout(() => {
        onRemove();
      }, props.delay || 15000);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [isRunning, onRemove, props.delay]);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (isVisible) {
      startRunning();
      return () => {
        stopRunning();
      };
    }
  }, [isVisible, startRunning, stopRunning]);

  useEffect(() => {
    if (isVisible === false) {
      const timeout = setTimeout(() => {
        props.onRemove(props.id);
      }, 250);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, props.id, props.onRemove]);

  const delayStyle: React.CSSProperties = useMemo(
    () => ({ transitionDuration: `${props.delay || 15000}ms` }),
    [props.delay]
  );

  return (
    <div
      title={props.title}
      className={`relative inline-flex ${
        isVisible === true
          ? positionClasses[props.position][0]
          : isVisible === null
          ? positionClasses[props.position][1]
          : positionClasses[props.position][2]
      } transform transition-all duration-200 ease-in-out`}
    >
      <div
        className={`relative inline-flex justify-start items-start w-full bg-${props.color}-500 bg-opacity-90 text-${props.color}-100 rounded-md shadow-lg overflow-hidden`}
      >
        <div
          style={delayStyle}
          className={`absolute bottom-0 left-0 w-full bg-${
            props.color
          }-300 h-1 transition-transform transform ease-linear ${isRunning ? "-translate-x-full" : "translate-x-0"}`}
        ></div>
        <div
          className={`absolute top-0 right-0 mr-1 mt-1 text-${props.color}-100 cursor-pointer leading-none pointer-events-auto`}
          id={props.title}
          onClick={onRemove}
        >
          <XIcon className="inline-flex w-5 h-5" />
        </div>
        <div className="flex h-full w-12 justify-center items-center flex-shrink-0">
          <props.Icon className={`flex justify-center items-center w-5 h-5 text-${props.color}-100`} />
        </div>
        <div
          className={`flex w-64 px-2 h-full bg-${props.color}-500 text-${props.color}-100 text-sm flex-col shadow-xl pointer-events-auto`}
        >
          <span className="inline-flex w-full text-xs tracking-wide font-semibold leading-loose pt-1">
            {props.title}
          </span>
          <span className="inline-flex justify-start items-start text-left pb-3 whitespace-pre-wrap">{props.text}</span>

          {props.onClick ? (
            <div className="inline-flex w-full justify-end mb-2">
              <button
                className={`inline-flex items-center justify-center h-full outline-none focus:outline-none bg-${props.color}-700 hover:bg-${props.color}-600 px-3 text-xs text-${props.color}-50 rounded tracking-wide leading-loose py-1`}
                onClick={props.onClick}
              >
                <span>Hier klicken</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
