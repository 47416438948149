import { useCallback, useEffect } from "react";
import WebsocketDebug from "../../models/WebsocketDebug";
import useDebugWebsocket from "./useDebugWebsocket";
import * as uuid from "uuid";
import DebugCommand from "./DebugCommand";
import { PersistentStorageRepository } from "../../clientApi/ClientApi";
import packageJson from "../../../package.json";

const SESSION_ID = uuid.v4();

const useDebugConnection = (
  divertMessage?: (msg: WebsocketDebug<any>) => void,
  accountName?: string,
  perStorageInit?: boolean
) => {
  const sendMessage = useCallback(
    async <T>(data: T, type: DebugCommand, adressing: string | null) => {
      try {
        const message: WebsocketDebug<T> = {
          id: uuid.v4(),
          isDebug: true,
          data,
          timestamp: new Date(),
          sessionId: SESSION_ID,
          type,
          addressing: adressing,
          userAgent: navigator?.userAgent ?? "--",
          accountName: accountName ?? "--",
          version: packageJson.version,
        };
        const messageAsJson = JSON.stringify(message);

        const baseUrl = window.location.hostname === "localhost" ? "https://localhost:44315" : window.location.origin;
        const resp: Response = await new Promise((resolve, reject) =>
          fetch(`${baseUrl}/api/debug`, {
            method: "POST",
            body: JSON.stringify({ jsonObject: messageAsJson }),
            headers: { "Content-Type": "application/json" },
          })
            .then(resolve)
            .catch(reject)
        );
        if (resp.ok) {
          return;
        } else throw new Error("Response did not indicate success");
      } catch (error) {
        throw error;
      }
    },
    [accountName]
  );

  const greetAll = useCallback(async () => {
    try {
      console.log("Greeting all");
      await sendMessage(SESSION_ID, DebugCommand.PingAll, null);
    } catch (error) {
      throw error;
    }
  }, [sendMessage]);

  const greet = useCallback(
    async (addressing: string) => {
      try {
        console.log("Greeting " + addressing);
        await sendMessage(SESSION_ID, DebugCommand.Ping, addressing);
      } catch (error) {
        throw error;
      }
    },
    [sendMessage]
  );

  const respondToPing = useCallback(
    async (addressing: string) => {
      try {
        console.log("Responding to " + addressing);
        await sendMessage(SESSION_ID, DebugCommand.RespondToPing, addressing);
      } catch (error) {
        throw error;
      }
    },
    [sendMessage]
  );

  const requestStorageInformation = useCallback(
    async (addressing: string) => {
      try {
        console.log("Requesting StorageInformation from " + addressing);
        await sendMessage(SESSION_ID, DebugCommand.StorageInformation, addressing);
      } catch (error) {
        throw error;
      }
    },
    [sendMessage]
  );

  const respondToStorageInformation = useCallback(
    async (addressing: string) => {
      try {
        console.log("Responding with StorageInformation to " + addressing);

        const localStorageKeys = Object.entries(localStorage).map(([key, value]) => [key, value.length]);
        const sessionStorageKeys = Object.entries(sessionStorage).map(([key, value]) => [key, value.length]);
        const storageKeys = perStorageInit ? await PersistentStorageRepository!.keys() : [];

        const keyObject = { localStorageKeys, sessionStorageKeys, storageKeys };

        await sendMessage(keyObject, DebugCommand.RespondToStorageInformation, addressing);
      } catch (error) {
        throw error;
      }
    },
    [perStorageInit, sendMessage]
  );

  const handleMessage = useCallback(
    (msg: WebsocketDebug<any>) => {
      if (msg.sessionId !== SESSION_ID) {
        if (msg.addressing === null) {
          console.log(msg);
          if (msg.type === DebugCommand.PingAll) respondToPing(msg.sessionId);
        } else if (msg.addressing === SESSION_ID) {
          console.log(msg);
          if (msg.type === DebugCommand.Ping) respondToPing(msg.sessionId);
          else if (msg.type === DebugCommand.StorageInformation) respondToStorageInformation(msg.sessionId);
          else if (msg.type === DebugCommand.RespondToStorageInformation) console.log(msg);
        }
        if (divertMessage && (msg.addressing === SESSION_ID || msg.addressing === null)) {
          return divertMessage(msg);
        }
      } else {
        console.log("ignoring own message");
      }
    },
    [divertMessage, respondToPing, respondToStorageInformation]
  );

  const ws = useDebugWebsocket(handleMessage);

  return {
    sendMessage,
    greetAll,
    greet,
    requestStorageInformation,
    SESSION_ID,
    isConnected: ws.isConnected,
    isConnecting: ws.isConnecting,
    connect: ws.connect,
  };
};
export default useDebugConnection;
