import { useLoginViewModel } from "./LoginViewModel";
import { LoginViewTypes } from "./LoginViewTypes";

export const LoginView = (props: LoginViewTypes.Props) => {
  const viewModel = useLoginViewModel(props);

  return (
    <section className="fixed inset-0 inline-flex flex-col w-full h-full min-h-0 jusitfy-center items-center content-start">
      <p className="text-gray-50 font-semibold tracking-wide animate-pulse mx-auto my-auto">Sie werden angemeldet...</p>
    </section>
  );
};
