import { useEffect, useState } from "react";

const getURLCreator = () =>
  URL && URL.createObjectURL && typeof URL.createObjectURL === "function"
    ? URL
    : false || (window.URL && window.URL.createObjectURL && typeof window.URL.createObjectURL === "function")
    ? window.URL
    : false;

export const useBlobUrl = (blob: Blob | File | null | undefined) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (blob) {
      const beforeCreator = getURLCreator();
      if (beforeCreator) {
        const tempUrl = beforeCreator.createObjectURL(blob);
        setUrl(tempUrl);
        return () => {
          const afterCreator = getURLCreator();
          if (afterCreator) {
            afterCreator.revokeObjectURL(tempUrl);
          }
        };
      }
    }
  }, [blob]);

  return url;
};
