import { ApiCall } from "../models/Controller";
import { GastroEvent } from "../models/GastroEvent";
import { Reservation } from "../models/Reservation";

export const getAllEventsForDate = async (restaurantId: string, date: string) => {
  try {
    const handledResponse = await ApiCall<{ events: GastroEvent[] }>(
      `Reservations/events?restaurantId=${restaurantId}&date=${date}`,
      "GET",
      undefined,
      (response: GastroEvent[]) => {
        return { events: response || [] };
      }
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

export const getEventsForReservation = async (restaurantId: string, { id }: Reservation) => {
  try {
    const handledResponse = await ApiCall<{ events: GastroEvent[] }>(
      `Reservations/${id}/events?restaurantId=${restaurantId}`,
      "GET",
      undefined,
      (response: GastroEvent[]) => {
        return { events: response || [] };
      }
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

export const getEventsStorageLocation = ({ restaurantId, date }: { restaurantId: string; date: string }) =>
  `${restaurantId}_getEventsForDate_${date}`;

export const saveEventsInStorage = ({
  restaurantId,
  date,
  events,
}: {
  restaurantId: string;
  date: string;
  events: GastroEvent[];
}) => {
  const storageLocation = getEventsStorageLocation({ restaurantId: restaurantId, date: date });
  window.localStorage.setItem(storageLocation, JSON.stringify(events));
  return storageLocation;
};

export const getEventsForDate = (
  { restaurantId, date }: { restaurantId: string; date: string },
  cb: (events: GastroEvent[], date: string, isFresh: boolean) => any
) => {
  const getEvents = async () => {
    try {
      const resp = await getAllEventsForDate(restaurantId, date);
      saveEventsInStorage({ restaurantId: restaurantId, date: date, events: resp.events });
      return cb(resp.events, date, true);
    } catch (error) {
      if (error instanceof DOMException && error.name === "AbortError") {
        return;
      } else if ((error as Error).message.includes("403")) {
        return;
      } else
        setTimeout(() => {
          getEvents();
        }, 1500);
    }
  };

  const storageLocation = getEventsStorageLocation({ restaurantId: restaurantId, date: date });

  try {
    if (window && window.localStorage && window.localStorage.getItem) {
      const reservations = window.localStorage.getItem(storageLocation);
      if (reservations !== null) {
        const parsed = JSON.parse(reservations) as GastroEvent[];
        cb(parsed, date, false);
      } else {
        cb([], date, false);
      }
      getEvents();
    }
  } catch (error) {
    if (error instanceof DOMException && error.name === "AbortError") {
      return;
    } else throw error;
  }
};
