import { useMemo } from "react";

type ToggleProps = {
  isToggled?: boolean;
  onToggle: () => void;
  idName: string;
};

const Toggle = ({ isToggled, onToggle, idName }: ToggleProps) => {
  const style = useMemo(() => ({ padding: "2px" }), []);

  return (
    <label
      className="isolate relative inline-flex justify-start items-start content-start h-7 w-12 overscroll-none p-[2px] cursor-pointer flex-shrink-0 min-w-0 min-h-0 pointer-events-auto"
      htmlFor={idName}
      style={style}
    >
      <input
        className={`absolute inset-0 w-full h-full filter peer appearance-none shadow-inner rounded-full ${
          isToggled ? "bg-gradient-to-tr saturate-100 opacity-100" : "bg-gradient-to-tl saturate-0 opacity-30"
        } transition-all duration-300 ease-in-out from-green-500 to-green-300 pointer-events-auto transform`}
        type="checkbox"
        checked={isToggled}
        onChange={onToggle}
        name={idName}
        id={idName}
      />
      <div
        className={`inline-flex w-6 h-full transition-transform duration-300 ease-in-out  bg-white border-gray-200 rounded-full z-10 origin-right transform ${
          isToggled ? "translate-x-5" : "translate-x-0"
        } drop-shadow pointer-events-none`}
      ></div>
    </label>
  );
};
export default Toggle;
