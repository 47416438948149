import { NavLink } from "react-router-dom";
import { Heroicons } from "../../../../components/Heroicon/Heroicon";
import StorageRoutes from "../../constants/StorageRoutes";

type OverviewEntryProps = {
  internalKey: string;
  onDelete: () => void;
};

const OverviewEntry = (props: OverviewEntryProps) => {
  return (
    <div className="inline-flex w-full gap-1 justify-start items-center content-start py-1" key={props.internalKey}>
      <NavLink to={StorageRoutes.root + "/" + props.internalKey} className="text-left py-2 px-1 truncate text-xs">
        {props.internalKey}
      </NavLink>
      <button
        type="button"
        className="p-1 bg-red-500 text-red-50 rounded shadow-red-500/50 shadow ml-auto"
        onClick={props.onDelete}
      >
        <Heroicons.Outline.TrashIcon className="w-6 h-6" />
      </button>
    </div>
  );
};
export default OverviewEntry;
