import * as React from "react";
import { Heroicons } from "../components/Heroicon/Heroicon";
import { StorageTableBase } from "./StorageTableBase";

export type GastroEvent = {
  id: string;
  event: keyof EventType;
  version: number;
  aggregateType: string;
  aggregateId: string;
  eventDescription: string;
  eventData: string;
  createdBy: string;
} & StorageTableBase;

type EventType = {
  0: "Requested";
  1: "Confirmed";
  2: "TableAssigned";
  3: "TableUnassigned";
  4: "Updated";
  5: "GuestAmountUpdated";
  6: "WalkedInWithReservation";
  7: "WalkedInWithoutReservation";
  8: "Cancelled";
  9: "NewEmail";
  10: "IncomingCall";
  11: "ReservationCreated";
  12: "NotAReservation";
  13: "IncomingConversation";
  14: "Left";
  15: "Rescheduled";
  16: "Hidden";
  18: "SentEmail";
  19: "MissedCallMessage";
  20: "Unknown";
  21: "MissedCall";
  22: "Commented";
  23: "Bankett";
};

export const EventTypes: EventType = {
  0: "Requested",
  1: "Confirmed",
  2: "TableAssigned",
  3: "TableUnassigned",
  4: "Updated",
  5: "GuestAmountUpdated",
  6: "WalkedInWithReservation",
  7: "WalkedInWithoutReservation",
  8: "Cancelled",
  9: "NewEmail",
  10: "IncomingCall",
  11: "ReservationCreated",
  12: "NotAReservation",
  13: "IncomingConversation",
  14: "Left",
  15: "Rescheduled",
  16: "Hidden",
  18: "SentEmail",
  19: "MissedCallMessage",
  20: "Unknown",
  21: "MissedCall",
  22: "Commented",
  23: "Bankett",
};

export const EventTypesLocalization: { [key in keyof EventType]: string } = {
  0: "Angefragt",
  1: "Bestätigt",
  2: "Tisch zugewiesen",
  3: "Zuweisung aufgehoben",
  4: "Aktualisiert",
  5: "Anzahl angepasst",
  6: "Angekommen",
  7: "Walk-In",
  8: "Abgesagt",
  9: "Neue Email",
  10: "Eingehender Anruf",
  11: "Reservierung angelegt",
  12: "Keine Reservierung",
  13: "Eingehende Konversation",
  14: "Verlassen",
  15: "Verschoben",
  16: "Verst. Res.",
  18: "Email gesendet",
  19: "MissedCallMessage",
  20: "Unknown",
  21: "MissedCall",
  22: "Commented",
  23: "Bankett",
};

export const getIconForEvent: (props: { height?: string; width?: string; color?: string }) => {
  [key in keyof EventType]: JSX.Element;
} = (props) => ({
  0: <Heroicons.Outline.QuestionMarkCircleIcon {...props} />,
  1: <Heroicons.Outline.CheckIcon {...props} />,
  2: <Heroicons.Outline.DownloadIcon {...props} />,
  3: <Heroicons.Outline.ArrowRightIcon {...props} />,
  4: <Heroicons.Outline.RefreshIcon {...props} />,
  5: <Heroicons.Outline.UserAddIcon {...props} />,
  6: <Heroicons.Outline.HomeIcon {...props} />,
  7: <Heroicons.Outline.DocumentTextIcon {...props} />,
  8: <Heroicons.Outline.BanIcon {...props} />,
  9: <Heroicons.Outline.DocumentIcon {...props} />,
  10: <Heroicons.Outline.InformationCircleIcon {...props} />,
  11: <Heroicons.Outline.InformationCircleIcon {...props} />,
  12: <Heroicons.Outline.InformationCircleIcon {...props} />,
  13: <Heroicons.Outline.ChatIcon {...props} />,
  14: <Heroicons.Outline.LogoutIcon {...props} />,
  15: <Heroicons.Outline.ClockIcon {...props} />,
  16: <Heroicons.Outline.EyeOffIcon {...props} />,
  18: <Heroicons.Outline.MailIcon {...props} />,
  19: <Heroicons.Outline.PhoneMissedCallIcon {...props} />,
  20: <Heroicons.Outline.ExclamationIcon {...props} />,
  21: <Heroicons.Outline.PhoneMissedCallIcon {...props} />,
  22: <Heroicons.Outline.ClipboardIcon {...props} />,
  23: <Heroicons.Outline.ViewGridAddIcon {...props} />,
});
