import * as React from "react";
import { AppColors } from "../models/General";
import { Table } from "../models/Table";
import { AppState } from "../App";
import { TableSelectDrawer } from "./TableSelectDrawer";
import { ViewHeader } from "./ViewHeader";
import { CloseArrow } from "./CloseArrow";
import { ViewTopSpace } from "./ViewTopSpace";
import { InformationDetail } from "./InformationDetail";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Heroicons } from "./Heroicon/Heroicon";
import { useToggle } from "../hooks/useToggle";

export const Overview: React.FC<{
  appColors: AppColors;
  transition: React.CSSProperties;
  handleClose: () => Promise<void>;
  date: string;
  day: string;
  reservationAmount: string | number;
  personAmount: string | number;
  tableList: Table[];
  appIsStandalone?: AppState["appIsStandalone"];
  appReservations?: AppState["appReservations"];
  children?: React.ReactNode | React.ReactNode[];
}> = React.memo((props) => {
  const [areTablesVisible, toggleTablesVisibility, showTables, hideTables] = useToggle(false);
  const [listRef, setListRef] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (listRef) {
      disableBodyScroll(listRef);
      return () => {
        enableBodyScroll(listRef);
      };
    }
  }, [listRef]);

  return (
    <div className="flex flex-1 flex-col w-full h-full overflow-hidden z-10">
      <TableSelectDrawer
        appColors={props.appColors}
        handleClose={hideTables}
        handleSelection={() => null}
        isDrawerVisible={areTablesVisible}
        isLocked={false}
        reservation={undefined}
        tableList={props.tableList}
        transition={props.transition}
        appReservations={props.appReservations}
        spaceOnBottom={props.appIsStandalone || false}
      ></TableSelectDrawer>
      <ViewHeader appColors={props.appColors}>
        <span className="flex flex-1 h-full justify-start items-center" onClick={props.handleClose}>
          <CloseArrow color="white" appColors={props.appColors} close={() => null}></CloseArrow>
          <span>Zurück</span>
        </span>
      </ViewHeader>
      <ViewTopSpace
        appColors={props.appColors}
        height="16"
        backgroundIcon="CalendarIcon"
        backgroundIconProps={{ width: "50%", height: "50%" }}
        sideButton={{ label: "Tische", onClick: showTables }}
      >
        <div className="flex flex-col w-full h-full justify-center items-start">
          <div className={`inline-flex flex-row justify-start items-center flex-grow-0 flex-shrink-0 h-7 w-full pl-3`}>
            <Heroicons.Outline.CalendarIcon className={`h-5 w-5 flex-grow-0 flex-flex-shrink-0 text-primary-100`} />
            <span
              className={`flex flex-1 justify-start items-center h-full text-white text-base font-semibold leading-none pl-2`}
            >
              {props.date}
            </span>

            <span
              className={`flex flex-2 justify-start items-center h-full text-white text-sm font-semibold leading-none pl-1 `}
            >
              {props.day}
            </span>
          </div>
          <div className="flex justify-start items-center space-x-3 pl-3">
            <InformationDetail appColors={props.appColors} icon="BookmarkIcon">
              {props.reservationAmount}
            </InformationDetail>
            <InformationDetail appColors={props.appColors} icon="UsersIcon">
              {props.personAmount}
            </InformationDetail>
          </div>
        </div>
      </ViewTopSpace>
      <div className={`flex flex-1 w-full overflow-auto bg-primary-700`}>
        <div ref={setListRef} className="relative flex flex-col w-full h-full overflow-hidden">
          {props.children}
        </div>
      </div>
    </div>
  );
});
