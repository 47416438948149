import * as React from "react";
import { AppColors } from "../models/General";
import { CloseArrow } from "./CloseArrow";
import { Reservation } from "../models/Reservation";
import { InformationDetail } from "./InformationDetail";
import { InformationButton } from "./InformationButton";
import { Table } from "../models/Table";
import { TableSelect, SelectableTable } from "./TableSelect";
import { ViewHeader } from "./ViewHeader";
import { ReservationButton } from "./ReservationButton";
import { AppState } from "../App";
import { Heroicons } from "./Heroicon/Heroicon";
import { XIcon } from "@heroicons/react/outline";

type TableSelectDrawerProps = {
  isDrawerVisible: boolean;
  appColors: AppColors;
  transition: React.CSSProperties;
  handleClose: () => void;
  handleSelection?: (tables: Table[] | null) => void;
  reservation: Reservation | undefined;
  isLocked: boolean;
  tableList: Table[];
  appReservations?: AppState["appReservations"];
  preSelectedTables?: Table[];
  spaceOnBottom: boolean;
};

export const TableSelectDrawer = ({
  isDrawerVisible,
  transition,
  handleClose,
  reservation,
  handleSelection,
  appColors,
  isLocked,
  tableList,
  appReservations,
  preSelectedTables,
  spaceOnBottom,
}: TableSelectDrawerProps) => {
  const { backgroundcolor } = appColors;
  const [selectedTables, setSelectedTables] = React.useState<Table[]>(preSelectedTables || []);
  const [isMultiTableVisible, setIsMultiTableVisible] = React.useState(false);
  const [lastTableRef, setLastTableRef] = React.useState<SVGSVGElement | null>(null);

  const handleSelectionRef = React.useRef<typeof handleSelection | null>(handleSelection);

  React.useEffect(() => {
    handleSelectionRef.current = handleSelection ?? null;
  }, [handleSelection]);

  React.useEffect(() => {
    if (selectedTables.length > 0) {
      setIsMultiTableVisible(true);
    } else {
      setIsMultiTableVisible(false);
    }
  }, [selectedTables]);

  React.useEffect(() => {
    if (lastTableRef) {
      // lastTableRef.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
  }, [lastTableRef]);

  const acceptSelection = React.useCallback(() => {
    if (handleSelectionRef.current) {
      handleSelectionRef.current(selectedTables);
    }
  }, [selectedTables]);

  const _handleTableSelection = React.useCallback((table: Table | null) => {
    if (table) {
      setSelectedTables((currentList) => {
        if (currentList.some((tab) => tab.id === table.id)) {
          return currentList.filter((tab) => tab.id !== table.id);
        } else return [...currentList, table];
      });
    } else {
      if (handleSelectionRef.current) handleSelectionRef.current(null);
    }
  }, []);

  const _handleTableDeselection = React.useCallback((table: Table | null) => {
    if (table) {
      setSelectedTables((currentList) => currentList.filter((tab) => tab.id !== table.id));
    } else {
      if (handleSelectionRef.current) handleSelectionRef.current(null);
    }
  }, []);

  const _handleDeselectAll = React.useCallback(() => setSelectedTables([]), []);

  return (
    <div
      className={`absolute right-0 flex flex-col flex-1 min-h-0 w-full h-full top-0 shadow-md z-40 transition-transform transform duration-150 ease-in-out bg-opacity-80 bg-white ${
        isDrawerVisible ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <ViewHeader appColors={appColors}>
        <span className="flex flex-1 h-full justify-start items-center" onClick={handleClose}>
          <CloseArrow color="white" appColors={appColors} close={() => null}></CloseArrow>
          <span>{reservation ? reservation.name : "Datepicker"}</span>
        </span>
      </ViewHeader>

      <div className={`flex flex-1 flex-col w-full max-h-full min-h-0 h-full bg-primary-700`}>
        <div
          className={`relative flex flex-shrink-0 flex-grow-0 w-full ${
            reservation ? "h-20" : "h-10"
          } bg-primary-700 text-white px-2 justify-center items-start flex-col py-2 overflow-hidden`}
        >
          <div
            className="absolute"
            style={{
              transform: "rotate(0deg)",
              bottom: "-3.5rem",
              left: "0",
            }}
          >
            <Heroicons.Outline.UsersIcon width="55%" height="60%" className={`text-primary-600`} />
          </div>
          {reservation ? (
            <div className="flex flex-1 flex-col z-10">
              <div className="flex flex-row space-x-3 pl-3">
                <InformationDetail appColors={appColors} icon="CalendarIcon">
                  {new Date(reservation.dateOfArrival).toLocaleString("de-de", {
                    month: "numeric",
                    weekday: "short",
                    day: "2-digit",
                  })}
                </InformationDetail>
                <InformationDetail appColors={appColors} icon="ClockIcon">
                  {
                    new Date(reservation.dateOfArrival)
                      .toLocaleString("de-de", {
                        month: "numeric",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                      .split(",")[1]
                  }
                </InformationDetail>
                {reservation.guestAmount &&
                reservation.guestAmount.adultAmount <= 0 &&
                (typeof reservation.guestAmount === "string" || typeof reservation.guestAmount === "number") ? (
                  <InformationDetail appColors={appColors} icon="UsersIcon">
                    {reservation.guestAmount}
                  </InformationDetail>
                ) : null}
                {(reservation.guestAmount.adultAmount && reservation.guestAmount.adultAmount > 0) ||
                (reservation.guestAmount.childAmount && reservation.guestAmount.childAmount > 0) ? (
                  <InformationDetail appColors={appColors} icon="UsersIcon">
                    {reservation.guestAmount.childAmount
                      ? `${reservation.guestAmount.adultAmount} + ${reservation.guestAmount.childAmount}`
                      : reservation.guestAmount.adultAmount}
                  </InformationDetail>
                ) : null}
              </div>
            </div>
          ) : null}
          {reservation ? (
            <div className="flex flex-2 z-20 w-full justify-between items-center">
              {reservation.tables ? (
                <>
                  <span className="flex justify-start items-center text-2xl font-semibold px-3">
                    {reservation.tables && reservation.tables.length >= 1
                      ? reservation.tables.length === 1
                        ? "Tisch " + reservation.tables[0].name
                        : reservation.tables.length + " Tische"
                      : "Kein Tisch"}
                  </span>
                  <InformationButton
                    big
                    appColors={appColors}
                    color={backgroundcolor + "-500"}
                    additionalClasses={"ml-auto"}
                    spanClass={"shadow-md"}
                    handleClick={_handleDeselectAll}
                  >
                    Zuweisung aufheben
                  </InformationButton>
                </>
              ) : (
                <span className="flex justify-start items-center text-2xl font-semibold px-3">
                  Kein Tisch zugewiesen
                </span>
              )}
            </div>
          ) : (
            <div className="flex flex-2 z-20 w-full justify-between items-center">
              <span className="flex justify-start items-center text-2xl font-semibold px-3">Tische</span>
            </div>
          )}
        </div>
        <div className={`flex h-2 flex-grow-0 flex-shrink-0 w-full px-1`}>
          <div className="flex w-full h-full bg-white justify-around items-center shadow-inner rounded-t-lg"></div>
        </div>
        <div className={`flex flex-col w-full h-full max-h-full min-h-0 z-20 px-1 bg-primary-700`}>
          <div
            className={`bg-white h-14`}
            style={{
              transitionDuration: "100ms",
              transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
            }}
          >
            {isMultiTableVisible ? (
              <>
                <div
                  className="flex flex-grow-0 flex-shrink-0 w-full h-14 bg-transparent top-0 flex-no-wrap overflow-auto justify-start items-center px-1"
                  style={{
                    transitionDuration: "250ms",
                    transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
                  }}
                >
                  {selectedTables.map((tab, index) => (
                    <div key={index} className="relative flex flex-shrink-0 flex-grow-0 w-20 px-1 h-full">
                      <XIcon
                        ref={(ref) => {
                          if (index + 1 === selectedTables.length) {
                            if (ref !== null) {
                              setLastTableRef(ref);
                            }
                          }
                        }}
                        className={`absolute flex flex-grow-0 flex-shrink-0 justify-center items center w-4 h-4 p-1  rounded-full bg-${appColors.backgroundcolor}-600 top-0 right-0 shadow z-10`}
                        onClick={() => _handleTableDeselection(tab)}
                      />
                      <SelectableTable
                        unfixedSize
                        appColors={appColors}
                        isGrayed={false}
                        table={tab}
                        onClick={() => null}
                        onReservationClick={() => null}
                      ></SelectableTable>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <TableSelect
            data={tableList}
            appColors={appColors}
            onTableSelection={_handleTableSelection}
            currentTables={undefined}
            selectedTables={selectedTables}
            reservations={appReservations}
          ></TableSelect>
          {reservation ? (
            <div
              className={`flex justify-start  flex-grow-0 flex-shrink-0 ${
                spaceOnBottom ? "h-16 items-start" : "h-14 items-center"
              }  w-full px-1 py-1 bg-white border-t shadow-md`}
            >
              {isMultiTableVisible ? (
                <div className={`flex justify-start items-center flex-grow-0 flex-shrink-0 h-10 w-full px-2 py-1`}>
                  <ReservationButton
                    color={"red"}
                    isLocked={false}
                    unlockedHeight
                    onClickHandler={_handleDeselectAll}
                    additionalClasses={"rounded-lg"}
                  >
                    Auswahl aufheben
                  </ReservationButton>
                  <div className="w-2"></div>
                  <ReservationButton
                    color={"green"}
                    isLocked={false}
                    unlockedHeight
                    onClickHandler={acceptSelection}
                    additionalClasses={"rounded-lg"}
                  >
                    Bestätigen
                  </ReservationButton>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="flex w-full h-2 bg-white justify-around items-center shadow-inner shadow-md"></div>
          )}
        </div>
      </div>
    </div>
  );
};
