import { useCallback, useEffect, useMemo, useRef } from "react";

type Specialkey = "altKey" | "ctrlKey" | "shiftKey";
export type KeyListener = [string, () => void, Specialkey[], boolean?];

export const useKeyEvents = (
  events: KeyListener[],
  eventType: "keydown" | "keyup" | "keypress",
  element: HTMLElement | null,
  logger?: (key: string) => void
) => {
  const currentListener = useRef<((ev: KeyboardEvent) => void) | null>(null);
  const currentType = useRef<typeof eventType | null>(null);

  const getMatchingEvent = useCallback((events: KeyListener[], key: string, special: Specialkey[]) => {
    const filtered = events.filter(([e, , s]) => e === key && special.every((sk) => s.some((sks) => sk === sks)));
    if (filtered.length > 0) {
      // Mehrere Elemente, mindestens 1
      if (filtered.length > 1) {
        // Mehrere Elemente, möglicherweise alle keine Specialkeys
        if (special.length <= 0) {
          //Wenn keine Specialkeys angegeben sind, merge alle defaults
          const defaults = filtered.filter(([, , s]) => s.length === 0);
          const shouldPreventDefault = filtered.some(([, , , prevent]) => prevent);
          const merged = defaults.reduce((arr, [, f]) => {
            return [...arr, f];
          }, [] as (() => void)[]);
          const executeMerged = () => merged.forEach((f) => f());
          return [key, executeMerged, [], shouldPreventDefault] as KeyListener;
        } else {
          //Wenn Specialkeys angegeben sind, finde passendes
          const matching = filtered.filter(
            ([, , s]) => s.length >= special.length && s.every((sk) => special.some((k) => sk === k))
          );
          const shouldPreventDefault = matching.some(([, , , prevent]) => prevent);
          if (matching.length > 0) {
            // Merge zutreffende
            const mergedSpecialKeys = matching.flatMap(([, , s]) => s);
            const merged = matching.reduce((arr, [, f]) => {
              return [...arr, f];
            }, [] as (() => void)[]);
            const executeMerged = () => merged.forEach((f) => f());
            return [key, executeMerged, mergedSpecialKeys, shouldPreventDefault] as KeyListener;
          } else {
            return null;
          }
        }
      } else {
        // Nur 1 Element;
        return filtered[0];
      }
    } else return null;
  }, []);

  const getSpecialKeys = useCallback((ev: KeyboardEvent) => {
    const specialkeys = [] as Specialkey[];
    if (ev.shiftKey) specialkeys.push("shiftKey");
    if (ev.altKey) specialkeys.push("altKey");
    if (ev.ctrlKey) specialkeys.push("ctrlKey");
    return specialkeys;
  }, []);

  const logText = useCallback(
    (text: string) => {
      try {
        if (logger && typeof logger === "function") {
          logger(text);
        }
      } catch (error) {
        console.error(`useKeyEvent - logText => logger - ${error}`);
      }
    },
    [logger]
  );

  const listenToKey = useCallback(
    (ev: KeyboardEvent) => {
      logText(ev.key);
      const specialkeys = getSpecialKeys(ev);
      logText(specialkeys.join(","));
      const match = getMatchingEvent(events, ev.key, specialkeys);
      if (match) {
        const [, func, specialkeys, shouldPreventDefault] = match;
        if (shouldPreventDefault) ev.preventDefault();
        if (func && typeof func === "function") {
          if (specialkeys.every((key) => ev[key])) {
            return func();
          } else return;
        } else if (func === undefined || func === null) {
          throw new Error("Eventlistener is undefined or null");
        } else if (typeof func !== "function") {
          throw new Error("Eventlistener is not of type function");
        } else throw new Error("Could not determine what went wrong");
      }
    },
    [events, getMatchingEvent, getSpecialKeys, logText]
  );

  useEffect(() => {
    if (events && element) {
      currentListener.current = listenToKey;
      currentType.current = eventType;
      element.addEventListener(currentType.current, currentListener.current);
      return () => {
        if (currentListener.current && currentType.current) {
          element.removeEventListener(currentType.current, currentListener.current);
        }
      };
    }
  }, [element, eventType, events, listenToKey]);

  return;
};
