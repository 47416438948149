import { useAccount } from "@azure/msal-react";
import { useCallback, useContext, useEffect, useRef } from "react";
import Package from "../../../package.json";
import { TrackContext } from "../../AppShell";
import Action from "../../clientApi/tracking/Actions";

const useTracker = (identifier: string, data: string, type?: Action.Variant) => {
  const account = useAccount(undefined);

  const trackContext = useContext(TrackContext);

  const identifierRef = useRef<string>(identifier);
  const dataRef = useRef<string>(data);
  const accountNameRef = useRef<string>(account?.username ?? "???");

  const encapsulateAction = useCallback(
    (cb?: (ev?: any) => any) =>
      cb === undefined || cb === null
        ? undefined
        : (ev?: any) => {
            try {
              const now = new Date();
              trackContext({
                actionData: `${dataRef.current}`,
                actionName: `${identifierRef.current || "--"}`,
                progress: Action.Progress.Started,
                information: {
                  timestamp: now,
                  timestampString: now.toISOString(),
                  currentUrl: window?.location?.href,
                  version: Package.version,
                  isError: false,
                  user: accountNameRef.current,
                },
                type: Action.Variant.Interaction,
                triggeredBy: Action.Trigger.User,
                interactionName: identifierRef.current,
              });
              cb(ev);
            } catch (error) {
              throw new Error("Konnte Aktion nicht tracken");
            } finally {
              const now = new Date();
              trackContext({
                actionData: `${dataRef.current}`,
                actionName: `${identifierRef.current || "--"}`,
                progress: Action.Progress.Ended,
                information: {
                  timestamp: now,
                  timestampString: now.toISOString(),
                  currentUrl: window?.location?.href,
                  version: Package.version,
                  isError: false,
                  user: accountNameRef.current,
                },
                type: Action.Variant.Interaction,
                triggeredBy: Action.Trigger.User,
                interactionName: identifierRef.current,
              });
            }
          },
    [trackContext]
  );

  useEffect(() => {
    identifierRef.current = identifier;
  }, [identifier]);

  useEffect(() => {
    if (typeof data !== "string") {
      dataRef.current = JSON.stringify(data, null, 2);
    } else {
      dataRef.current = data;
    }
  }, [data]);

  useEffect(() => {
    if (account?.username) {
      accountNameRef.current = account.username;
    } else {
      accountNameRef.current = "???";
    }
  }, [account?.username]);

  return encapsulateAction;
};
export default useTracker;
