export type ButtonColor = "primary" | "secondary" | "tertiary" | "warning" | "error" | "success";

const Base = "ring-1";

const disabled = {
  Basic: "disabled:bg-gray-300 disabled:text-gray-600 disabled:shadow-none shadow",
  Inverted: "disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-300 disabled:shadow-none",
};

const Basic: { [key in ButtonColor]: string } = {
  primary: `${Base} bg-primary-500 hover:bg-primary-600 text-white ring-transparent shadow-primary-500/40 ${disabled.Basic}`,
  secondary: `${Base} bg-secondary-500 hover:bg-secondary-600 text-white ring-transparent shadow-secondary-500/40 ${disabled.Basic}`,
  tertiary: `${Base} bg-tertiary-500 hover:bg-tertiary-600 text-white ring-transparent shadow-tertiary-500/40 ${disabled.Basic}`,
  warning: `${Base} bg-warning-500 hover:bg-warning-600 text-white ring-transparent shadow-warning-500/40 ${disabled.Basic}`,
  error: `${Base} bg-error-500 hover:bg-error-600 text-white ring-transparent shadow-error-500/40 ${disabled.Basic}`,
  success: `${Base} bg-success-500 hover:bg-success-600 text-white ring-transparent shadow-success-500/40 ${disabled.Basic}`,
};

const Inverted: { [key in ButtonColor]: string } = {
  primary: `${Base} bg-white hover:bg-primary-50 text-primary-500 ring-primary-500 shadow-primary-500/40 ${disabled.Inverted}`,
  secondary: `${Base} bg-white hover:bg-secondary-50 text-secondary-500 ring-secondary-500 shadow-primary-500/40 ${disabled.Inverted}`,
  tertiary: `${Base} bg-white hover:bg-tertiary-50 text-tertiary-500 ring-tertiary-500 shadow-tertiary-500/40 ${disabled.Inverted}`,
  warning: `${Base} bg-white hover:bg-warning-50 text-warning-500 ring-warning-500 shadow-warning-500/40 ${disabled.Inverted}`,
  error: `${Base} bg-white hover:bg-error-50 text-error-500 ring-error-500 shadow-error-500/40 ${disabled.Inverted}`,
  success: `${Base} bg-white hover:bg-success-50 text-success-500 ring-success-500 shadow-success-500/40 ${disabled.Inverted}`,
};

const ButtonColors = {
  Basic,
  Inverted,
};

export default ButtonColors;
