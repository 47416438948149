import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Heroicons } from "../../../components/Heroicon/Heroicon";
import { useInput } from "../../../hooks/useInput";
import { useToggle } from "../../../hooks/useToggle";
import StorageRoutes from "../constants/StorageRoutes";
import OverviewEntry from "./components/OverviewEntry";
import { useStorageOverviewViewModel } from "./StorageOverviewViewModel";
import StorageOverviewViewTypes from "./StorageOverviewViewTypes";

const StorageOverviewView = (props: StorageOverviewViewTypes.Props) => {
  const viewModel = useStorageOverviewViewModel(props);

  const [isShowingPersistent, togglePersistent] = useToggle(true);
  const [isShowingLocal, toggleLocal] = useToggle(false);

  const [persSearch, onPersSearch] = useInput();
  const [localSearch, onLocalSearch] = useInput();

  const displayedPers = useMemo(
    () =>
      persSearch
        ? props.persistentStorageKeys
            .map((c) => c.toString())
            .filter((c) => c.toLocaleLowerCase().includes(persSearch.toLocaleLowerCase()))
        : props.persistentStorageKeys.map((c) => c.toString()),
    [persSearch, props.persistentStorageKeys]
  );

  const displayedLocal = useMemo(
    () =>
      localSearch
        ? props.localstorageKeys.filter((c) => c.toLocaleLowerCase().includes(localSearch.toLocaleLowerCase()))
        : props.localstorageKeys,
    [localSearch, props.localstorageKeys]
  );

  return (
    <section className="inline-flex flex-col w-full min-h-0 justify-start items-start content-start py-2">
      <button
        type="button"
        onClick={togglePersistent}
        className="inline-flex text-2xl font-semibold tracking-wide px-3 my-1 justify-start w-full items-center select-none"
      >
        <span>Persistent Storage</span>
        <small className="ml-auto mr-2 text-base font-normal tabular-nums text-gray-600">
          ({props.persistentStorageKeys.length})
        </small>
        <span
          className={`h-6 w-6 text-primary-500 transition-all duration-200 ease-in-out ${
            isShowingPersistent ? "rotate-0" : "rotate-90"
          }`}
        >
          <Heroicons.Outline.ChevronDownIcon className="w-full h-full" />
        </span>
      </button>
      <div className="inline-flex min-h-0 w-full p-2 flex-shrink-0">
        <input
          type="text"
          value={persSearch}
          onChange={onPersSearch}
          className={`inline-flex justify-start items-center w-full min-h-0 ring-1 focus:ring-2 rounded p-2 outline-none focus:outline-none ${
            persSearch ? "ring-blue-500" : "ring-gray-300"
          }`}
          placeholder="Suche..."
        />
      </div>
      <div
        className={`inline-grid grid-cols-1 justify-start items-start content-start min-h-0 w-full divide-y px-2 border bg-white overflow-auto ${
          isShowingPersistent ? "pb-24" : ""
        }`}
      >
        {isShowingPersistent &&
          displayedPers.map((key) => (
            <OverviewEntry key={key} internalKey={key} onDelete={viewModel.relayRemoveStorage(key)} />
          ))}
      </div>
      <button
        type="button"
        onClick={toggleLocal}
        className="inline-flex text-2xl font-semibold tracking-wide px-3 my-1 justify-between w-full items-center mt-3 select-none"
      >
        <span>Localstorage</span>
        <small className="ml-auto mr-2 text-base font-normal tabular-nums text-gray-600">
          ({props.localstorageKeys.length})
        </small>
        <span
          className={`h-6 w-6 text-primary-500 transition-all duration-200 ease-in-out ${
            isShowingLocal ? "rotate-0" : "rotate-90"
          }`}
        >
          <Heroicons.Outline.ChevronDownIcon className="w-full h-full" />
        </span>
      </button>
      <div className="inline-flex min-h-0 w-full p-2 flex-shrink-0">
        <input
          type="text"
          value={localSearch}
          onChange={onLocalSearch}
          className={`inline-flex justify-start items-center w-full min-h-0 ring-1 focus:ring-2 rounded p-2 outline-none focus:outline-none ${
            localSearch ? "ring-blue-500" : "ring-gray-300"
          }`}
          placeholder="Suche..."
        />
      </div>
      <div
        className={`inline-grid grid-cols-1 justify-start items-start content-start min-h-0 w-full divide-y px-2 border bg-white overflow-auto ${
          isShowingLocal ? "pb-24" : ""
        }`}
      >
        {isShowingLocal &&
          displayedLocal.map((key) => {
            const json = localStorage.getItem(key);
            const parsed = json?.startsWith("{") || json?.startsWith("[") ? JSON.parse(json) : "--";
            const isArray = Array.isArray(parsed);

            return (
              <span
                key={key.toString()}
                className="inline-flex w-full gap-1 justify-start items-center content-start py-1"
              >
                <NavLink to={StorageRoutes.root + "/" + key} className="text-left py-2 px-1 truncate text-xs">
                  {key.toString()}
                </NavLink>
                {isArray ? <small className="text-xs text-gray-600">({parsed.length})</small> : null}
                <button
                  type="button"
                  className="p-1 bg-red-500 text-red-50 rounded shadow-red-500/50 shadow ml-auto"
                  onClick={viewModel.relayRemoveLocalStorage(key)}
                >
                  <Heroicons.Outline.TrashIcon className="w-6 h-6" />
                </button>
              </span>
            );
          })}
      </div>
    </section>
  );
};
export default StorageOverviewView;
