/* eslint-disable */

export type Size = "small" | "base" | "big" | "bigger" | "biggest" | "large" | "larger" | "largest"

const getTextSizes = (modifier: number) => {
  return Object.entries({
    body: {"font-size": 1, "line-height": 1},
    "text-xs": {"font-size": 0.75, "line-height": 1},
    "text-sm" : {"font-size": 0.875, "line-height": 1},
    "text-base" : {"font-size": 1, "line-height": 1},
    "text-lg" : {"font-size": 1.125, "line-height": 1},
    "text-xl" : {"font-size": 1.25, "line-height": 1},
    "text-2xl" : {"font-size": 1.5, "line-height": 1},
    "text-3xl" : {"font-size": 1.875, "line-height": 1},
    "text-4xl" : {"font-size": 2.25, "line-height": 1},
    "text-5xl" : {"font-size": 3, "line-height": 1},
    "text-6xl" : {"font-size": 3.75, "line-height": 1},
    "text-7xl" : {"font-size": 4.5, "line-height": 1},
    "text-8xl" : {"font-size": 6, "line-height": 1},
    "text-9xl" : {"font-size": 8, "line-height": 1},
    "text-xxs" : {"font-size": 0.65, "line-height": 1},
    "text-xxxs" : {"font-size": 0.6, "line-height": 1},
  }).map(([key, value], idx) => {
    const modifiedFontSize = value["font-size"] + modifier;
    return `${idx ? "." : ""}${key} { font-size: ${modifiedFontSize}rem; line-height: ${modifiedFontSize + 0.5}rem; }`
  })
}

const getHeights = (modifier: number) => {
  const heightClasses = [
    "0.5",
     1,
      "1.5",
    2,
    "2.5",
    3,
    "3.5",
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    20,
    21,
    24,
    28,
    29,
    30,
    32,
    36,
    40,
    44,
    48,
    52,
    56,
    60,
    64,
    72,
    80,
    96,]
  const mappedHeight = heightClasses.map((height) => {
    if(typeof height === "string") {
      const heightAsNumber = +height + modifier;
      const identifier = height.split(".").join("\\.");
      return `.h-${identifier} { height: ${(heightAsNumber / 4).toLocaleString("en-us", { maximumFractionDigits: 3 })}rem; }`;
    } else {
      return `.h-${height} { height: ${((height / 4) + modifier).toLocaleString("en-us", { maximumFractionDigits: 3 })}rem; }`;
    }
    
  });

  const heights = [ 
    ".h-0 { height: 0px; }",
    ".h-auto { height: auto; }",
    ".h-px { height: 1px; }",
    ".h-1\\/2 { height: 50%; }",
    ".h-1\\/3 { height: 33.3333%; }",
    ".h-2\\/3 { height: 66.6667%; }",
    ".h-1\\/4 { height: 25%; }",
    ".h-2\\/4 { height: 50%; }",
    ".h-3\\/4 { height: 75%; }",
    ".h-1\\/5 { height: 20%; }",
    ".h-2\\/5 { height: 40%; }",
    ".h-3\\/5 { height: 60%; }",
    ".h-4\\/5 { height: 80%; }",
    ".h-1\\/6 { height: 16.6667%; }",
    ".h-2\\/6 { height: 33.3333%; }",
    ".h-3\\/6 { height: 50%; }",
    ".h-4\\/6 { height: 66.6667%; }",
    ".h-5\\/6 { height: 83.3333%; }",
    ".h-full { height: 100%; }",
    ".h-screen { height: 100vh; }",
   ...mappedHeight];

   return heights;
}

 const modifiers = [-0.1, ...Array(5).fill(null).map((_,idx) => idx * 0.1)];

 const Sizes = modifiers.map((modifier) => [...getTextSizes(modifier), ...getHeights(modifier)]);

export default Sizes;
