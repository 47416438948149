import { Configuration, LogLevel, NavigationClient, SilentRequest } from "@azure/msal-browser";
import B2C from "./b2cpolicies";

const LOG_EVERYTHING = false;
const CLIENT_ID = B2C.isDev ? "80113c2b-08c3-4fe2-9abc-7569d4043c9a" : "33832427-3e24-4ca4-a310-c0808ed5db97";

const navigationClient = new NavigationClient();
const navigationTimeoutDuration = 500;

const publicUrl = process.env.PUBLIC_URL ?? window.location.origin;

const base: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: B2C.policies.authorities.signIn,
    knownAuthorities: [B2C.authorityDomain, B2C.authoritySubDomain],
    redirectUri: publicUrl,
    postLogoutRedirectUri: publicUrl,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) =>
        B2C.isDev ? console.info(level, message, containsPii) : containsPii ? null : console.info(level, message),
      logLevel: B2C.isDev && LOG_EVERYTHING ? LogLevel.Trace : LogLevel.Error,
    },
  },
};

const loginRequest = {
  scopes: ["openid", "offline_access", `https://${B2C.authoritySubDomain}/ReservierungsApp/Reservierungen.ReadWrite`],
  redirectUri: window.location.origin,
};

const apiRequest = {
  reservierungen: {
    scopes: [],
  },
};

const graphRequest = {
  scopes: [],
};

const AuthConfig = { base, loginRequest, graphRequest, apiRequest };

export default AuthConfig;
