import { ReactNode, useDeferredValue, useMemo } from "react";
import useInformationen from "../api/useInformationen";
import DateFormatter from "../helper/DateFormatter";
import Information from "../types/Information";

type NotizHintProps = {
  date: Date | string;
  informationen?: Information<Information.Typ>[];
  className?: string;
  children?: ReactNode | ReactNode[];
};

const NotizHint = (props: NotizHintProps) => {
  const date = useMemo(() => DateFormatter.toDatum(props.date), [props.date]);
  const shouldLoad = useDeferredValue(props.informationen !== undefined);
  const { informationen } = useInformationen(date.obj, shouldLoad, shouldLoad);

  const matchingInformationen: number = useMemo(
    () =>
      (
        (props.informationen ?? informationen).filter(
          (c) => c.typ == Information.Typ.Notiz && c.bezugsDatum.isoLike === date.isoLike
        ) as Information<Information.Typ.Notiz>[]
      ).reduce((info, cur) => info + cur.data.eintraege.length, 0),
    [date.isoLike, informationen, props.informationen]
  );

  return (
    <>
      {matchingInformationen ? props.children : null}
      {matchingInformationen ? <span className={props.className}>{matchingInformationen}</span> : null}
    </>
  );
};
export default NotizHint;
