import * as React from "react";
import { SubNav, SubNavButton } from "./SubNav";
import { AppColors } from "../models/General";

export interface DetailViewLayoutProps {
  subNavButtons?: { description: string; onClickFunc: () => void; isActive?: boolean }[];
  colors: AppColors;
  maxWidth?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const flexBasis = { flexBasis: "100%" };

export const DetailViewLayout: React.FC<DetailViewLayoutProps> = (props) => {
  const subnav = React.useMemo(
    () =>
      props.subNavButtons && props.subNavButtons.length > 0 ? (
        <SubNav colors={props.colors}>
          {props.subNavButtons.map(({ description, onClickFunc, isActive }, idx) => (
            <SubNavButton key={idx} onClick={onClickFunc} isActive={isActive}>
              {description}
            </SubNavButton>
          ))}
        </SubNav>
      ) : null,
    [props.colors, props.subNavButtons]
  );

  return (
    <div
      className="flex flex-col flex-1 flex-grow-0 w-full h-full max-h-full justify-center items-center"
      style={flexBasis}
    >
      {subnav}

      <div
        className="flex flex-col flex-1 flex-grow-0 bg-gray-100 py-2 md:py-3 xl:pl-8 xl:pr-8 lg:px-3 justify-center w-full max-h-full min-h-0"
        style={flexBasis}
      >
        {props.children}
      </div>
    </div>
  );
};

interface DetailViewHeadLineProps {
  title: string;
  additionalMenu?: DetailViewHeadLineButtonItem[] | undefined;
}

export const DetailViewHeadLine: React.FC<DetailViewHeadLineProps> = (props) => (
  <div className="flex flex-grow-0 w-full h-12">
    <span className="flex font-light text-4xl items-end pb-2" style={{ flex: 2 }}>
      {props.title}
    </span>
    {props.additionalMenu && (
      <div className="flex flex-1 h-full ml-auto bg-white shadow justify-end content-start items-center">
        {props.additionalMenu.map((item, idx) => (
          <DetailViewHeadLineButton key={idx} onClickFunc={(prop) => item.onClickFunc && item.onClickFunc(prop)}>
            {item.description}
          </DetailViewHeadLineButton>
        ))}
      </div>
    )}
  </div>
);

interface DetailViewHeadLineButtonItem extends DetailViewHeadLineButtonProps {
  description: string;
}

interface DetailViewHeadLineButtonProps {
  onClickFunc?: (prop: any) => any;
  children?: React.ReactNode | React.ReactNode[];
}

const DetailViewHeadLineButton: React.FC<DetailViewHeadLineButtonProps> = (props) => (
  <div
    className="flex flex-grow-0 h-full justify-center items-center cursor-pointer"
    style={{ width: "20%" }}
    onClick={props.onClickFunc}
  >
    <div className="flex flex-grow-0 h-8 w-8 justify-center items-center border-b-2 border-transparent hover:border-blue-200 text-sm hover:text-blue-300">
      {props.children}
    </div>
  </div>
);
