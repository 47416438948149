import { useLayoutEffect, useState } from "react";
import { useWelcomeViewModel } from "./WelcomeViewModel";
import { WelcomeViewTypes } from "./WelcomeViewTypes";

export const WelcomeView = (props: WelcomeViewTypes.Props) => {
  const viewModel = useWelcomeViewModel(props);

  const [isShowingLoginButton, setIsShowingLoginButton] = useState<boolean>(false);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setIsShowingLoginButton(true);
    }, 1e3);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  return (
    <section className="inline-flex flex-col w-full h-full min-h-0 justify-start items-start content-start">
      <h3 className="relative text-4xl w-full text-center mt-12 font-black text-white tracking-widest leading-tight">
        Herzlich Willkommen
      </h3>
      <button
        className={`fixed bottom-24 left-0 right-0 bg-primary-600 px-6 py-3 font-semibold tracking-wide duration-500 ease-in ${
          isShowingLoginButton ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => window.location.reload()}
      >
        Login
      </button>
    </section>
  );
};
