import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const origin = (window?.location?.origin || process.env.PUBLIC_URL || "error").toLocaleLowerCase();
if (origin === "error") throw new Error("Origin is error");
const isDevUrl = origin.includes("gastrodev");
const isProd1Url = origin.includes("prod1");
const isProd2Url = origin.includes("prod2");
const isBeta1Url = origin.includes("beta1");
const isBeta2Url = origin.includes("beta2");

const instrumentationKeys: [string, boolean][] = [
  ["cdae06de-fa47-44e0-8bdd-bc32ac55f984", isDevUrl],
  ["7002bb43-6d60-4e28-88f2-adc001a6eb79", isProd1Url],
  ["aff3b19e-d49b-444c-822f-8f08cbee9259", isProd2Url],
  ["7165427c-d3e2-4304-b073-597ef7fb21ff", isBeta1Url],
  ["5cc8be5d-6e97-4bf9-ae4a-9cc7353453eb", isBeta2Url],
];

const instrumentationKey = (instrumentationKeys.find(([, isActive]) => isActive) || [
  "cdae06de-fa47-44e0-8bdd-bc32ac55f984",
  true,
])[0];

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
