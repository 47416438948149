import * as React from "react";
import { AppFunctions, AppState } from "../App";
import { DateButton } from "../components/DateButton";
import DateTable from "../components/DateTable";
import { DetailViewLayout } from "../components/DetailViewLayout";
import { LoadingBar } from "../components/LoadingBar";
import ReservationCreation from "../components/ReservationCreation";
import ReservationDetail from "../components/ReservationDetail";
import { header, ReservationTableView } from "../components/ReservationTableView";
import { InputField } from "../components/SearchBar";
import { TableButton } from "../components/TableView";
import { Whitebox } from "../components/Whitebox";
import { causeCommands, processCause } from "../controller/CauseController";
import { getEventsForReservation } from "../controller/EventController";
import {
  cancelReservation,
  confirmReservation,
  deleteReservation,
  getSummariesForMonth,
  guestLeftReservation,
  putNewReservation,
  rescheduleReservation,
  updateGuestAmountOnReservation,
  updateReservation,
  walkedInWithoutReservation,
  walkedInWithReservation,
  getEmail,
  sendEmail,
  reservationCommands,
} from "../controller/ReservierungDataController";
import { assignTablesToReservation, tableCommands, unassignTableToReservation } from "../controller/TableController";
import { Cause } from "../models/Cause";
import { handledProcessCommand } from "../models/Queue";
import { NewReservation, Reservation, ReservationStates, walkedInConfirmation } from "../models/Reservation";
import { Table } from "../models/Table";
import { ReservierungMobileState } from "./ReservierungMobile";
import { Email } from "../models/Email";
import ReservierungenView from "./Reservierungen/ReservierungenView";
import { PlusIcon, RefreshIcon } from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/outline";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import DailyInformation from "../Informationen/components/DailyInformation";
import { addMessage } from "../components/ToasterHandler/useToasterHandler";

export interface ReservierungWebProps extends AppState, AppFunctions {}

export const localization: {
  [key in keyof (Reservation & Reservation["guestAmount"])]: string;
} = {
  dateOfArrival: "Datum",
  durationOfStay: "Dauer",
  email: "Email",
  guestAmount: "Personen",
  adultAmount: "Erwachsene",
  childAmount: "Kinder",
  id: "ID",
  name: "Name",
  note: "Notiz",
  phoneNumber: "Tel. Nr.",
  state: "Status",
  tables: "Tisch",
  causeId: "Grund ID",
  createdBy: "Angelegt von",
  modifiedBy: "Geändert von",
  leaveDateTime: "Gegangen am",
  leaveNotice: "Verlassensbemerkung",
  satisfaction: "Zufriedenheit",
  requestChannel: "Kanal",
  listWordDocUrl: "Word Urls",
};

export interface ReservierungWebState {
  activeTab: string;
  selectedReservation: Reservation | undefined;
  currentSearchTerm: string;
  isDisplaying_CreateReservation: boolean;
  isDisplaying_ReservationDetail: boolean;
  isDisplaying_DateSelect: boolean;
  isCreatingReservation: boolean;
  isUpdatingReservation: boolean;
  isLoadingDate: boolean;
  isDrawerVisible: boolean;
  isReservationDetailLocked: boolean;
  tempId: string;
  loadingItems: Reservation[];
  incomingReservation: AppState["appShouldCreateReservation"];
  incomingReservationDetail: AppState["appShouldShowReservationDetail"];
}

class ReservierungWeb extends React.Component<ReservierungWebProps, ReservierungWebState> {
  timeout: ReturnType<typeof setTimeout> | null;
  constructor(props: ReservierungWebProps) {
    super(props);
    this.state = {
      activeTab: this.props.appRestaurantId,
      selectedReservation: undefined,
      currentSearchTerm: "",
      isDisplaying_CreateReservation: this.props.appShouldCreateReservation ? true : false,
      isDisplaying_ReservationDetail: false,
      isDisplaying_DateSelect: this.props.appShouldCreateReservation ? false : true,
      isCreatingReservation: false,
      isLoadingDate: false,
      isDrawerVisible: false,
      isReservationDetailLocked: false,
      tempId: "",
      isUpdatingReservation: false,
      loadingItems: [],
      incomingReservation: this.props.appShouldCreateReservation,
      incomingReservationDetail: this.props.appShouldShowReservationDetail,
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.props.setAppState("appHeadTitle", undefined);
    if (this.props.appShouldCreateReservation) {
      this.setState(
        {
          isDrawerVisible: true,
          isDisplaying_CreateReservation: true,
          isDisplaying_ReservationDetail: false,
          isDisplaying_DateSelect: false,
          incomingReservation: this.props.appShouldCreateReservation,
        },
        this.props.releaseNewReservationPrimer
      );
    }

    if (this.props.appShouldShowReservationDetail) {
      this._handleReservationDetail(this.props.appShouldShowReservationDetail);
    }
    this._subscribeToQueue();
    // if (this.props.appWebSocket) {
    //   this.props.appWebSocket.addEventListener("message", this._handleIncomingCall);
    // }
    this.timeout = setTimeout(() => {
      this.reloadReservations(this.props.appReservationDate);
    }, 1);
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
    // if (this.props.appWebSocket) {
    //   this.props.appWebSocket.removeEventListener("message", this._handleIncomingCall);
    // }
  }

  componentDidUpdate(prevprops: ReservierungWebProps, prevState: ReservierungWebState) {
    if (this.props.appShouldCreateReservation !== null) {
      console.log("test");
      this.setState(
        {
          isDrawerVisible: true,
          isDisplaying_CreateReservation: true,
          isDisplaying_ReservationDetail: false,
          isDisplaying_DateSelect: false,
          incomingReservation: this.props.appShouldCreateReservation,
        },
        this.props.releaseNewReservationPrimer
      );
    }
    if (prevprops.appReservations !== this.props.appReservations) {
      if (this.state.selectedReservation) {
        this.setState((cs) => ({
          selectedReservation: cs.selectedReservation
            ? this.props.appReservations.find((item) => item.id === cs.selectedReservation!.id) ||
              cs.selectedReservation
            : undefined,
        }));
      }
    }
  }

  _handleReservationDetail = async (res: Reservation) => {
    try {
      await this.props.setAppState("appReservationDate", new Date(res.dateOfArrival));
      this.setState(
        {
          selectedReservation: res,
          isDisplaying_ReservationDetail: true,
          isDisplaying_CreateReservation: false,
        },
        () => {
          this._handleDrawerToggle(true);
        }
      );
      this.props.releaseReservationDetailPrimer();
    } catch (error) {
      throw error;
    }
  };

  _subscribeToQueue = () => {
    reservationCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp) => {
          if (resp) {
            try {
              const res =
                resp && resp.id
                  ? this.props.appReservations.find((res) => res.id === resp.id + "")
                  : this.props.appReservations.find((res) => res.id === resp + "");
              if (res) {
                return this._setItemLoading(res, false).then(() => {});
              }
              return;
            } catch (error) {
              console.error(error);
            }
          }
        })
    );
    tableCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp) => {
          if (resp) {
            try {
              const res =
                resp && resp.id
                  ? this.props.appReservations.find((res) => res.id === resp.id + "")
                  : this.props.appReservations.find((res) => res.id === resp + "");
              if (res) {
                return this._setItemLoading(res, false).then(() => {});
              }
              return;
            } catch (error) {
              console.error(error);
            }
          }
        })
    );
    causeCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp: Cause) => {
          try {
            this.props.appLoadCauses();
            const res =
              resp && resp.id && resp.reservationId
                ? this.props.appReservations.find((res) => res.id === resp.reservationId + "")
                : this.props.appReservations.find((res) => res.id === resp + "");
            if (res) {
              return this._setItemLoading(res, false).then(() => {});
            }
            return;
          } catch (error) {
            console.error(error);
          }
        })
    );
  };

  _mergeReservation = (reservation: Reservation) => {
    return new Promise<void>((resolve, reject) => {
      let oldReservations = this.props.appReservations || [];
      if (oldReservations.length > 0) {
      }
      this.props
        .setAppState("appReservations", [...oldReservations, reservation])
        .then(resolve)
        .catch((error) => {
          console.error("_mergeReservation", error);
          resolve();
        });
    });
  };

  _updateInList = (reservation: Reservation | undefined, remove?: boolean) => {
    if (reservation !== undefined && reservation !== null) {
      return new Promise<void>((resolve, reject) => {
        let oldReservations = this.props.appReservations || [];
        if (oldReservations.length > 0 && !remove) {
          oldReservations = oldReservations.map((item) => (item.id === reservation.id ? reservation : item));
        } else {
          oldReservations = oldReservations.filter((item) => item.id === reservation.id);
        }
        this.props
          .setAppState("appReservations", oldReservations)
          .then(resolve)
          .catch((error) => {
            console.error(error);
            resolve();
          });

        this.setState((cs) => ({
          selectedReservation: cs.selectedReservation
            ? oldReservations.find((res) => cs.selectedReservation!.id === res.id)
            : undefined,
        }));
      });
    } else return null;
  };

  reloadReservations = async (date?: Date) => {
    try {
      this.props.appLoadReservations(date);
      this.props.appLoadTables();
      return;
    } catch (error) {
      throw error;
    }
  };

  _recreateReservation = async (oldReservation: Reservation) => {
    try {
      const tempReservation = { ...oldReservation };
      await deleteReservation(this.props.appRestaurantId, tempReservation);
      const newRes = await putNewReservation(this.props.appRestaurantId, tempReservation);
      return newRes;
    } catch (error) {
      throw error;
    }
  };

  _rescheduleReservation = async (oldReservation: Reservation, isoDate: string) => {
    try {
      const tempReservation = { ...oldReservation };
      const mock = await rescheduleReservation(this.props.appRestaurantId, tempReservation, isoDate);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _updateGuestAmountOfReservation = async (oldReservation: Reservation, guestAmount: Reservation["guestAmount"]) => {
    try {
      const tempReservation = { ...oldReservation };
      const mock = await updateGuestAmountOnReservation(this.props.appRestaurantId, tempReservation, guestAmount);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _leaveReservation = async (
    oldReservation: Reservation,
    leaveOptions: {
      satisfaction: Reservation["satisfaction"];
      leaveNotice: Reservation["leaveNotice"];
      leaveDateTime: Reservation["leaveDateTime"];
    }
  ) => {
    try {
      const tempReservation = { ...oldReservation, ...leaveOptions };
      const mock = await guestLeftReservation(this.props.appRestaurantId, tempReservation);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _setIsReservationDetailLocked = (bool: boolean) =>
    new Promise<void>((resolve, reject) => {
      try {
        this.setState(
          {
            isReservationDetailLocked: bool,
          },
          resolve
        );
      } catch (error) {
        throw reject(error);
      }
    });

  _handleDrawerToggle = (bool?: boolean) =>
    new Promise<void>((resolve, reject) => {
      try {
        this.setState(
          (currentState) => ({
            isDrawerVisible: bool !== undefined ? bool : !currentState.isDrawerVisible,
          }),
          () => {
            setTimeout(resolve, 300);
          }
        );
      } catch (error) {
        throw reject(error);
      }
    });

  _setItemLoading = (reservation: Reservation, isLoading: boolean) =>
    new Promise<void>(async (resolve, reject) => {
      try {
        this.setState(
          (cs) => {
            let tempItems: ReservierungMobileState["loadingItems"] = [];
            if (isLoading) {
              tempItems = [...cs.loadingItems, reservation];
            } else {
              tempItems = [...cs.loadingItems].filter((item) => item.id !== reservation.id);
            }
            return {
              loadingItems: tempItems,
              selectedReservation:
                cs.selectedReservation !== undefined
                  ? this.props.appReservations.find((item) => item.id === cs.selectedReservation!.id)
                  : undefined,
            };
          },
          () => {
            if (!isLoading) {
              try {
                // console.log("itemloading", "reloading reservations");
                // this.reloadReservations(this.props.appReservationDate, undefined);
                return resolve();
              } catch (error) {
                throw error;
              }
            } else {
              return resolve();
            }
          }
        );
        if (isLoading) {
          await this._handleCloseDrawer();
          return resolve();
        }
      } catch (error) {
        throw reject(error);
      }
    });

  _handleTableAssignment = async (reservation: Reservation, tables: Table[]) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await assignTablesToReservation(this.props.appRestaurantId, tables, reservation);
      return this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleTableUnassignment = async (reservation: Reservation) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await unassignTableToReservation(this.props.appRestaurantId, reservation);
      return this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleCancelReservation = async (reservation: Reservation, reason: string, reasonPhrase: string) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await cancelReservation(this.props.appRestaurantId, reservation, reason, reasonPhrase);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleConfirmReservation = async (reservation: Reservation, shouldSendEmail: boolean, email?: Email) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await confirmReservation(this.props.appRestaurantId, reservation, shouldSendEmail, email);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleWalkedInWithReservation = async (
    reservation: Reservation,
    walkedInConfirmation: walkedInConfirmation,
    tables: Table[]
  ) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await walkedInWithReservation(this.props.appRestaurantId, reservation, walkedInConfirmation, tables);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleUpdateReservation = async (reservation: Reservation) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await updateReservation(this.props.appRestaurantId, reservation);
      this._updateInList(mock);
      return;
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleRescheduleReservation = async (reservation: Reservation, isoDate: string) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._rescheduleReservation(reservation, isoDate);
      const isDifferentDate = isoDate.slice(0, 10) !== this.props.appReservationDate.toISOString().slice(0, 10);
      await this._updateInList(mock, isDifferentDate);
      if (isDifferentDate) {
        this.props.setAppState("appReservationDate", new Date(isoDate)).then(() => {
          this._setItemLoading(reservation, false);
        });
      }
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleGuestAmountUpdateOfReservation = async (reservation: Reservation, guestAmount: Reservation["guestAmount"]) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._updateGuestAmountOfReservation(reservation, guestAmount);
      this._updateInList(mock);
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleLeave = async (
    reservation: Reservation,
    leaveOptions: {
      satisfaction: Reservation["satisfaction"];
      leaveNotice: Reservation["leaveNotice"];
      leaveDateTime: Reservation["leaveDateTime"];
    }
  ) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._leaveReservation(reservation, leaveOptions);
      this._updateInList(mock);
      return;
    } catch (error) {
      throw error;
    }
  };

  _checkIsReservationDetailLocked = () =>
    this.state.loadingItems.some((entry) => {
      if (this.state.selectedReservation) {
        return entry.id === this.state.selectedReservation.id;
      } else return false;
    });

  _handleCloseDrawer = async () => {
    try {
      this.setState({
        isDisplaying_CreateReservation: false,
        isDisplaying_ReservationDetail: false,
        incomingReservation: null,
        selectedReservation: undefined,
      });
      await this._handleDrawerToggle(false);
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleReservationCreation = async (
    newReservation: NewReservation,
    cause?: Cause,
    date?: Date,
    shouldConfirm?: boolean,
    isWalkIn?: boolean,
    createEmail?: boolean,
    email?: Email
  ) => {
    try {
      try {
        const response = isWalkIn
          ? await walkedInWithoutReservation(this.props.appRestaurantId, newReservation)
          : await putNewReservation(this.props.appRestaurantId, newReservation, shouldConfirm, email, createEmail);
        const causeResponse = cause
          ? await processCause(this.props.appRestaurantId, { ...cause, reservationId: response.id + "" }, true)
          : null;

        if (cause) {
          this.props.appLoadCauses();
        }

        if (date) {
          this.props.appLoadSummary(date.getFullYear(), date.getMonth() + 1);
          await this.props.appSetDate(date.toISOString().slice(0, 10));
          await this.reloadReservations(date);
        } else {
          this.props.appLoadSummary(
            this.props.appReservationDate.getFullYear(),
            this.props.appReservationDate.getMonth() + 1
          );
          await this.reloadReservations(this.props.appReservationDate);
        }
        this.setState({
          isCreatingReservation: false,
        });
        const resp = {
          reservation: response,
          cause: causeResponse,
        };
        return resp;
      } catch (error) {
        const errorObj = { caller: "handleCreateClick", error: error };
        addMessage({
          title: "Erstellung",
          text: `Fehler\r\nErstellung Fehlgeschlagen\r\n\r\n\r\n${
            "message" in (error as any) ? (error as any).message : (error as any)
          }\r\n\r\n${"stack" in (error as any) ? (error as any).stack : ""}`,
          color: "red",
          icon: "ExclamationCircleIcon",
          delay: 6e4,
        });
        throw errorObj;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  _handleDrawerCreationCreate = async (
    res: NewReservation,
    cause: Cause | undefined,
    date: Date | undefined,
    shouldConfirm: boolean | undefined,
    isWalkIn: boolean | undefined,
    createEmail?: boolean,
    email?: Email
  ) => {
    await this._handleCloseDrawer();
    this.setState({
      isCreatingReservation: true,
    });
    await this._handleReservationCreation(res, cause, date, shouldConfirm, isWalkIn, createEmail, email);
  };

  _getDrawerCreationChannel = (inc: ReservierungMobileState["incomingReservation"]) =>
    inc ? (inc ? (inc.email ? "Email" : inc.phoneNumber ? "Telephone" : "Direct") : "Direct") : undefined;

  _getDrawerStyle = (isVisible: boolean) => ({
    transitionDuration: "150ms",
    transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
    right: "0%",
    transform: `translate(${isVisible ? "0px" : "101vw"}, 0px)`,
    backgroundColor: "rgba(255,255,255,0.85)",
  });

  _getAmounts = (res: AppState["appReservations"]) =>
    res.reduce(
      (a, b) => {
        if (!["0", "5"].some((numb) => +b.state === +numb)) {
          const { adultAmount, childAmount } = b.guestAmount;
          return [a[0] + adultAmount, a[1] + childAmount];
        } else return a;
      },
      [0, 0]
    );

  _handleReservationTableClick = (res: Reservation | null) => {
    if (res)
      this.setState(
        {
          selectedReservation: this.props.appReservations.find((reservations) => reservations.id === res.id),
          isDisplaying_ReservationDetail: true,
          isDisplaying_CreateReservation: false,
        },
        () => {
          this._handleDrawerToggle(true);
        }
      );
  };

  _displayReservationCreation = () => {
    this.setState(
      {
        isDisplaying_ReservationDetail: false,
        isDisplaying_CreateReservation: true,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  _handleGetEventsForDetail = async (reservation: Reservation) => {
    try {
      const events = await getEventsForReservation(this.props.appRestaurantId, reservation);
      if (events.events && this.props.appEvents && events.events[0] && events.events[0].id) {
        if (this.props.appEvents.filter((ev) => ev.id === events.events[0].id).length !== events.events.length) {
          this.props.appLoadEvents();
          return events;
        } else {
          return events;
        }
      } else return events;
    } catch (error) {
      throw error;
    }
  };

  _handleAddButtonClick = () => {
    this.setState(
      {
        isDisplaying_ReservationDetail: false,
        isDisplaying_CreateReservation: true,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  _handleSearchBarChange = (term: string) =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        {
          currentSearchTerm: term,
        },
        resolve
      );
    });

  _changeDateTo = async (newDate: Date) => {
    try {
      await this.props.appSetDate(newDate.toISOString().slice(0, 10));
    } catch (error) {
      throw error;
    }
  };

  _handleShowDateSelection = () => {
    this.setState((cs) => ({
      isDisplaying_DateSelect: !cs.isDisplaying_DateSelect,
    }));
  };

  _handleDateForward = async () => {
    const currentDate = this.props.appReservationDate;
    const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1));
    this._changeDateTo(tomorrow);
  };

  _handleDateBack = async () => {
    const currentDate = this.props.appReservationDate;
    const yesterday = new Date(currentDate.setDate(currentDate.getDate() - 1));
    this._changeDateTo(yesterday);
  };

  _tableHeader = [
    {
      label: "Zeit",
      keyOfValue: "dateOfArrival",
      alignment: "center",
      formatFunc: (res) =>
        `${new Date(res.dateOfArrival).toLocaleString("de-de", {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
      flex: 1,
    },
    {
      label: "Tisch",
      keyOfValue: "tables",
      alignment: "center",
      formatFunc: (res) =>
        `${res.tables && res.tables.length >= 1 ? res.tables.map((tab) => tab.name).join(", ") : "--"}`,
      flex: 1,
    },
    {
      label: "Name",
      keyOfValue: "name",
      alignment: "start",
      formatFunc: (res) => res.name,
      flex: 2,
    },
    {
      label: "Notiz",
      keyOfValue: "note",
      alignment: "start",
      formatFunc: (res) => res.note,
      flex: 3,
    },
    {
      label: "Pers.",
      keyOfValue: "guestAmount",
      alignment: "center",
      formatFunc: (res) =>
        res.guestAmount.childAmount > 0
          ? `${res.guestAmount.adultAmount} + ${res.guestAmount.childAmount}`
          : res.guestAmount.adultAmount + "",
      flex: 1,
    },
    {
      label: "Status",
      keyOfValue: "state",
      alignment: "end",
      formatFunc: (res) => ReservationStates[res.state],
      flex: 1,
    },
  ] as header[];

  render() {
    return (
      <DetailViewLayout colors={this.props.appColors} maxWidth="100%">
        {/* <DetailViewHeadLine title={"Reservierung"} /> */}
        <Whitebox className={"relative"}>
          <div className="flex flex-1 w-full h-full min-h-0">
            <div className="relative flex flex-2 flex-col border-r max-w-full h-full min-h-0 overflow-auto flex-shrink-0">
              <div
                className={`absolute top-1 right-64 mr-3 bg-blue-500 rounded text-sm font-semibold tracking-wide z-50 text-blue-50 px-2 pointer-events-none select-none transition-opacity ease-in-out duration-150 ${
                  this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]
                    ? "opacity-100"
                    : "opacity-0"
                }`}
              >
                {this.props.appIsPulling["Reservations"] && this.props.appIsLoading["Reservations"]
                  ? "Beides"
                  : this.props.appIsPulling["Reservations"]
                  ? "Server"
                  : this.props.appIsLoading["Reservations"]
                  ? "Datenbank"
                  : "??"}
              </div>
              <div className="flex items-center flex-grow-0 flex-shrink-0 w-full h-8 border-l-4 divide-x border-b overflow-hidden">
                <InputField
                  value={this.state.currentSearchTerm}
                  handleInputChange={(ev) => this._handleSearchBarChange(ev.target.value)}
                  placeholder={"Suche..."}
                  className="h-8 text-sm flex outline-none w-full"
                />
                <DailyInformation date={this.props.appReservationDate} />
                <TableButton
                  colors={this.props.appColors}
                  onClick={() => {
                    this.setState(
                      {
                        isLoadingDate: true,
                      },
                      () => {
                        this.reloadReservations();
                      }
                    );
                  }}
                  size={12}
                >
                  <div
                    className={`h-6 w-6 ${
                      this.props.appIsPulling["Reservations"] || this.props.appIsLoading["Reservations"] ? "spin" : ""
                    }`}
                  >
                    <RefreshIcon width="100%" height="100%" color="primary-700" />
                  </div>
                </TableButton>
                <TableButton colors={this.props.appColors} onClick={this._displayReservationCreation} size={12}>
                  <div className="flex justify-center items-center h-6 w-full cursor-pointer">
                    <PlusIcon height="100%" width="100%" color="primary-700" />
                  </div>
                </TableButton>
                <div
                  className={`flex flex-grow-0 justify-center items-center w-40 h-full cursor-pointer ${
                    this.state.isDisplaying_DateSelect ? `bg-white text-primary-600` : `bg-white text-primary-800`
                  }`}
                >
                  <DateButton
                    desktopView
                    appColors={this.props.appColors}
                    currentDate={this.props.appReservationDate}
                    handleDateBack={this._handleDateBack}
                    handleDateForward={this._handleDateForward}
                    onButtonClick={this._handleShowDateSelection}
                  ></DateButton>
                </div>
              </div>
              <ReservierungenView
                reservations={this.props.appReservations}
                loadingReservations={this.state.loadingItems}
                emptyClick={this._displayReservationCreation}
                onReservierungClick={this._handleReservationTableClick}
                selectedReservation={this.state.selectedReservation}
                isOpaque={this.props.appIsPulling.Reservations}
                filterTerm={this.state.currentSearchTerm}
              ></ReservierungenView>
              {/* <ReservationTableView
                headerToShow={this._tableHeader}
                colors={this.props.appColors}
                isPulsing={this.props.appIsPulling["Reservations"]}
                handleClick={this._handleReservationTableClick}
                loadingReservations={this.state.loadingItems}
                reservations={this.props.appReservations}
                emptyMessage={undefined}
                textSize="sm"
                rowHeight={10}
                loading={this.props.appIsLoading["Reservations"]}
                emptyClick={this._displayReservationCreation}
              ></ReservationTableView> */}
            </div>
            <div
              className="relative inline-flex flex-1 flex-col"
              style={{
                maxWidth: "33%",
              }}
            >
              <div
                className={`absolute flex flex-1 flex-col flex-shrink-0 w-full h-full z-40 shadow-md top-0 left-0 overflow-hidden`}
                style={this._getDrawerStyle(this.state.isDrawerVisible)}
              >
                {this.state.isDisplaying_CreateReservation ? (
                  <ReservationCreation
                    dateTableSize={this.props.appDisplayMode === "Web" ? "dense" : "full"}
                    appIsStandalone={this.props.appIsStandalone}
                    close={this._handleCloseDrawer}
                    appColors={this.props.appColors}
                    create={this._handleDrawerCreationCreate}
                    getSummary={this.props.appGetSummary}
                    isCreating={this.state.isCreatingReservation}
                    restaurantId={this.props.appRestaurantId}
                    email={this.state.incomingReservation ? this.state.incomingReservation.email : undefined}
                    phoneNumber={
                      this.state.incomingReservation ? this.state.incomingReservation.phoneNumber : undefined
                    }
                    requestChannel={this._getDrawerCreationChannel(this.state.incomingReservation)}
                    incomingReservation={this.state.incomingReservation || undefined}
                    appSummary={this.props.appSummary}
                    currentDate={this.props.appReservationDate}
                    appGetSummary={this.props.appGetSummary}
                    setAppState={this.props.setAppState}
                  ></ReservationCreation>
                ) : null}
                {this.state.isDisplaying_ReservationDetail &&
                (this.state.selectedReservation || this.state.incomingReservationDetail) ? (
                  <ReservationDetail
                    reservation={
                      (this.props.appReservations.find(
                        (c) => (this.state.selectedReservation || this.state.incomingReservationDetail)?.id === c.id
                      ) ||
                        this.state.selectedReservation ||
                        this.state.incomingReservationDetail)!
                    }
                    close={this._handleCloseDrawer}
                    appIsMobile={this.props.appIsMobile}
                    appIsStandalone={this.props.appIsStandalone}
                    assignTableToReservation={this._handleTableAssignment}
                    unassignTableToReservation={this._handleTableUnassignment}
                    tableList={this.props.appTables}
                    appColors={this.props.appColors}
                    updateReservation={this._handleUpdateReservation}
                    handleCancel={this._handleCancelReservation}
                    handleConfirm={this._handleConfirmReservation}
                    handleReschedule={this._handleRescheduleReservation}
                    handleWalkedInWithReservation={this._handleWalkedInWithReservation}
                    handleLeave={this._handleLeave}
                    handleGuestAmountChange={this._handleGuestAmountUpdateOfReservation}
                    getEventsForReservation={this._handleGetEventsForDetail}
                    isLocked={false}
                    appEvents={this.props.appEvents}
                    appReservations={this.props.appReservations}
                    appSummary={this.props.appSummary}
                    getSummary={this.props.appGetSummary}
                    getEmail={this.props.getEmail}
                    sendEmail={this.props.sendEmail}
                    setNewReservationPrimer={this.props.setNewReservationPrimer}
                    createBankett={this.props.createBankett}
                  ></ReservationDetail>
                ) : null}
              </div>

              <div
                className={`absolute top-0 right-0 flex flex-col w-full h-full transition-transform transform ease-in-out duration-150 ${
                  this.state.isDisplaying_DateSelect &&
                  (this.state.isDisplaying_CreateReservation || this.state.isDisplaying_ReservationDetail)
                    ? "-translate-x-full z-20 pt-10 pointer-events-none"
                    : "translate-x-0 z-20"
                }`}
              >
                <div
                  className={`flex flex-col w-full h-full min-h-0 bg-gray-100 rounded-lg shadow-md ${
                    this.state.isDisplaying_DateSelect &&
                    (this.state.isDisplaying_CreateReservation || this.state.isDisplaying_ReservationDetail)
                      ? "bg-opacity-80 pointer-events-auto"
                      : "bg-opacity-100"
                  }`}
                >
                  <div className="flex justify-between items-center h-8 flex-shrink-0 flex-grow-0 px-2 pb-1 pr-3 group">
                    <span className={`w-10 text-primary-700 font-semibold`}>Datum</span>
                    <XIcon
                      className="w-5 h-5 cursor-pointer group-hover:bg-gray-300 group-hover:text-gray-800 rounded-full hover:shadow-md transition-all ease-in-out duration-150"
                      onClick={this._handleShowDateSelection}
                    />
                  </div>
                  <div className={`flex flex-1 flex-col overflow-auto w-full`}>
                    <DateTable
                      preCreatedSummary={this.props.appSummary || undefined}
                      size={this.props.appDisplayMode === "Web" ? "dense" : "full"}
                      selectedDate={this.props.appReservationDate.toISOString().slice(0, 10)}
                      appColors={this.props.appColors}
                      getSummary={this.props.appGetSummary}
                      selectDate={this.props.appSetDate}
                      getCreatedSummary={(summary) =>
                        this.props.appSummary ? null : this.props.setAppState("appSummary", summary)
                      }
                    ></DateTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Whitebox>
      </DetailViewLayout>
    );
  }
}

export default withAITracking(reactPlugin, ReservierungWeb, "ReservierungWeb", "flex flex-col w-full h-full min-h-0");
