import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import * as React from "react";
import { Heroicons } from "../../components/Heroicon/Heroicon";
import { useCurrentDate } from "../../hooks/useCurrentDate";
import { useTheme } from "../../hooks/useTheme";
import { Reservation, ReservationStates } from "../../models/Reservation";
import { useReservierungenViewModel } from "./ReservierungenViewModel";
import { ReservierungenViewModelTypes } from "./ReservierungenViewModelTypes";

const theadHeight = "h-8";

const ReservierungenView = (props: ReservierungenViewModelTypes.Props) => {
  const viewModel = useReservierungenViewModel(props);
  const { theme } = useTheme();

  const [width, setWidth] = React.useState<string>("0px");
  const [listRef, setListRef] = React.useState<HTMLDivElement | null>(null);

  const [currentDate] = useCurrentDate(false, 5);
  const currentHour = React.useMemo(() => {
    return currentDate.getHours();
  }, [currentDate]);

  const [isSorting, setIsSorting] = React.useState<false | keyof Reservation>(false);
  const [toggledSort, setToggledSort] = React.useState<boolean | null>(null);

  const isDisplayingOverviewRows = React.useMemo(() => isSorting === "dateOfArrival", [isSorting]);

  React.useEffect(() => {
    setIsSorting(() => {
      setToggledSort(false);
      return "dateOfArrival";
    });
  }, []);

  const sortByKey = React.useCallback(
    (key: keyof Reservation) => () => {
      setToggledSort((t) => {
        setIsSorting((c) => {
          if (c === key) {
            return c;
          } else {
            return key;
          }
        });
        return !t;
      });
      return;
    },
    []
  );

  React.useEffect(() => {
    if (isSorting) setToggledSort(false);
  }, [isSorting]);

  const measuredRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setWidth(`calc(100% - (${node.offsetWidth}px - ${node.clientWidth}px))`);
    }
  }, []);

  const defaultHeader: ReservierungenViewModelTypes.Header[] = React.useMemo(
    () =>
      [
        {
          label: "Zeit",
          keyOfValue: "dateOfArrival",
          alignment: "center",
          formatFunc: (res) =>
            `${new Date(res.dateOfArrival).toLocaleString("de-de", { hour: "2-digit", minute: "2-digit" })}`,
          flex: 1,
        },
        {
          label: "Tisch",
          keyOfValue: "tables",
          alignment: "center",
          formatFunc: (res) =>
            `${res.tables && res.tables.length >= 1 ? res.tables.map((tab) => tab.name).join(", ") : "--"}`,
          flex: 1,
        },
        {
          label: "Name",
          keyOfValue: "name",
          alignment: "start",
          formatFunc: (res) => res.name,
          flex: 3,
        },
        {
          label: "Pers.",
          keyOfValue: "guestAmount",
          alignment: "center",
          formatFunc: (res) =>
            res.guestAmount.childAmount > 0
              ? `${res.guestAmount.adultAmount} + ${res.guestAmount.childAmount}`
              : res.guestAmount.adultAmount + "",
          flex: 1,
        },
        {
          label: "Status",
          keyOfValue: "state",
          alignment: "end",
          formatFunc: (res) => ReservationStates[res.state],
          flex: 1,
        },
      ] as ReservierungenViewModelTypes.Header[],
    []
  );

  const header = React.useMemo(() => {
    return (props.headerToShow || defaultHeader).map((head) => ({ ...head, sortFunc: sortByKey(head.keyOfValue) }));
  }, [defaultHeader, props.headerToShow, sortByKey]);

  React.useEffect(() => {
    if (listRef) {
      disableBodyScroll(listRef);
      return () => {
        enableBodyScroll(listRef);
      };
    }
  }, [listRef]);

  let theadRef = React.useRef(null);

  const _mapHeaderToBody: (header: ReservierungenViewModelTypes.Header[]) => {
    [key in keyof Partial<Reservation>]: ReservierungenViewModelTypes.Header;
  } = React.useCallback((header) => header.reduce((a, b) => ({ ...a, [b.keyOfValue]: b }), {}), []);

  const body = React.useMemo(() => _mapHeaderToBody(header), [_mapHeaderToBody, header]);

  const _mapValuesToShow: (head: ReservierungenViewModelTypes.Header[]) => (keyof Reservation)[] = React.useCallback(
    (head) => head.reduce((a, b) => [...a, b.keyOfValue], [] as (keyof Reservation)[]),
    []
  );

  const valuesToShow = React.useMemo(() => _mapValuesToShow(header), [_mapValuesToShow, header]);

  const _sortReservations = React.useCallback(
    (res: Reservation[]) => {
      const toggledModifier = toggledSort ? -1 : 1;
      const handleNumberReturn = (a: number, b: number, inversed: boolean) => {
        return inversed ? b - a : a - b;
      };
      const handleStringReturn = (a: string, b: string, inversed: boolean) => {
        return inversed ? b.toLocaleLowerCase().localeCompare(a) : a.toLocaleLowerCase().localeCompare(b);
      };
      let sort = (a: Reservation, b: Reservation) => 0;
      if (isSorting === false || isSorting === "dateOfArrival") {
        sort = (a, b) => {
          const aTime = +a.dateOfArrival.slice(11, 16).replace(/:/g, "");
          const bTime = +b.dateOfArrival.slice(11, 16).replace(/:/g, "");
          return handleNumberReturn(aTime, bTime, toggledSort === true);
        };
      } else if (isSorting === "tables") {
        sort = (a, b) => {
          const aTables = a.tables;
          const bTables = b.tables;
          if (aTables === undefined || aTables?.length === 0) return -1;
          else if (bTables === undefined || bTables?.length === 0) return 1;
          else if (aTables && bTables) {
            const aFirstTable = aTables.sort((a, b) => +a.name - +b.name)[0];
            const bFirstTable = bTables.sort((a, b) => +a.name - +b.name)[0];
            return handleNumberReturn(+aFirstTable.name, +bFirstTable.name, toggledSort === true);
          } else {
            return 0;
          }
        };
      } else if (isSorting === "guestAmount") {
        sort = (a, b) => {
          const aAdults = a.guestAmount.adultAmount;
          const bAdults = b.guestAmount.adultAmount;
          return handleNumberReturn(+aAdults, +bAdults, toggledSort === true);
        };
      } else {
        sort = (a, b) => {
          const aValue = a[isSorting];
          const bValue = b[isSorting];
          if (typeof aValue === "number" && typeof bValue === "number") {
            return handleNumberReturn(aValue, bValue, toggledSort === true);
          } else if (typeof aValue === "string" && typeof bValue === "string") {
            if (isNaN(+aValue) || isNaN(+bValue)) {
              return handleStringReturn(aValue, bValue, toggledSort === true);
            } else {
              return handleNumberReturn(+aValue, +bValue, toggledSort === true);
            }
          } else {
            return 0;
          }
        };
      }
      const sorted = res.sort((a, b) =>
        +a.state === 5 ? 1 : +b.state === 5 ? -1 : +a.state === 5 && +b.state === 5 ? 0 : sort(a, b)
      );
      return sorted;
    },
    [isSorting, toggledSort]
  );

  const _renderOverviewRow = React.useCallback(
    (res: Reservation[], idx: number | string) => {
      const reducePeople = (a: { adults: number; children: number }, b: Reservation) => {
        return { adults: a["adults"] + b.guestAmount.adultAmount, children: a["children"] + b.guestAmount.childAmount };
      };
      const filterReqCancelled = (r: Reservation) => !(+r.state === 5 || +r.state === 0);
      const notRequestedOrCancelled = res.filter(filterReqCancelled);
      const { adults, children } = res.filter(filterReqCancelled).reduce(
        (a, b) => {
          return {
            adults: a["adults"] + b.guestAmount.adultAmount,
            children: a["children"] + b.guestAmount.childAmount,
          };
        },
        { adults: 0, children: 0 }
      );
      const filterInHouse = (r: Reservation) =>
        !(+r.state === 5 || +r.state === 0) && (+r.state === 3 || +r.state === 4);
      const { adults: adultsInHouse, children: childreninHouse } = res
        .filter(filterInHouse)
        .reduce(reducePeople, { adults: 0, children: 0 });

      const isCurrentHour = currentHour === new Date(res[0].dateOfArrival).getHours();

      const isReservationConfirmedOrHigher = (r: Reservation) => +r.state >= 2 && +r.state !== 5;
      const filterAssignedTable = (r: Reservation) => isReservationConfirmedOrHigher(r) && r.tables !== undefined;
      const assignedTable = res.filter(filterAssignedTable);

      return (
        <tr
          key={idx}
          className={`relative flex flex-grow-0 flex-shrink-0 h-6 w-full justify-start content-start border-b px-1 text-xxs leading-none transition-all duration-200 ease-in-out border-gray-200 tabular-nums ${
            isCurrentHour
              ? `bg-primary-100 text-primary-600 font-bold`
              : `bg-gray-100 text-gray-700 font-semibold shadow-inner`
          }`}
        >
          <td className={`flex flex-1 justify-start items-center pl-2 font-bold tracking-wide`}>
            {new Date(new Date(res[0].dateOfArrival).setMinutes(0)).toLocaleString("de-de", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </td>
          <td className={`flex flex-1 justify-start items-center pl-1`}>
            <Heroicons.Solid.BookmarkIcon className="w-3 h-3" />
            <span className="px-1">{notRequestedOrCancelled.length}</span>
          </td>

          <td className={`flex flex-1 justify-start items-center`}>
            <Heroicons.Solid.UsersIcon className="w-3 h-3" />
            <span className="px-1">{`${adults} ${children ? `+ ${children}` : ""}`}</span>
          </td>
          <td className={`flex flex-1 justify-start items-center`}>
            <Heroicons.Solid.HomeIcon className="w-3 h-3" />
            <span className="px-1">{`${adultsInHouse} ${childreninHouse ? `+ ${childreninHouse}` : ""}`}</span>
          </td>
          <td className="flex flex-1 justify-start items-center">
            <Heroicons.Solid.DownloadIcon className="w-3 h-3" />
            <span className="px-1">{assignedTable.length}</span>
          </td>
        </tr>
      );
    },
    [currentHour]
  );

  const sortedReservations = React.useMemo(
    () => _sortReservations(viewModel.reservierungen),
    [_sortReservations, viewModel.reservierungen]
  );

  const checkLoading = React.useCallback(
    (id: string) => viewModel.loadingReservierungen.some((loadingRes) => loadingRes.id === id),
    [viewModel.loadingReservierungen]
  );
  const checkSelected = React.useCallback(
    (id: string) => !!(props.selectedReservation && props.selectedReservation.id === id),
    [props.selectedReservation]
  );

  const renderedRows = React.useMemo(() => {
    const mapReservationToRow = (res: Reservation) => {
      return (
        <TableRow
          key={res.id}
          res={res}
          fontWeight="normal"
          handleClick={viewModel.selectReservierung}
          body={body}
          isItemLoading={checkLoading}
          isSelected={checkSelected}
          noItemSelected={props.selectedReservation === undefined}
          rowHeight={10}
          textSize="sm"
          valuesToShow={valuesToShow}
        ></TableRow>
      );
    };
    if (isSorting === "dateOfArrival" || isSorting === false) {
      const sortedTime = Array.from(new Set(sortedReservations.map((r) => +r.dateOfArrival.slice(11, 13)))).sort(
        (a, b) => (toggledSort ? b - a : a - b)
      );
      const timeMap = new Map(
        sortedTime.map((time) => [time, sortedReservations.filter((r) => +r.dateOfArrival.slice(11, 13) === time)])
      );
      const overviewMap = new Map(sortedTime.map((time) => [time, _renderOverviewRow(timeMap.get(time) || [], time)]));
      const rendered = sortedTime.reduce((a: any, b, redidx) => {
        const map = timeMap.get(b);
        let rows = map?.map(mapReservationToRow);
        if (rows && map) {
          if (isDisplayingOverviewRows) {
            const overview = overviewMap.get(b);
            rows = overview ? [overview, ...rows] : rows;
          }
          return [...a, ...rows];
        } else return a;
      }, []);
      return rendered;
    } else {
      return sortedReservations.map(mapReservationToRow);
    }
  }, [
    _renderOverviewRow,
    body,
    checkLoading,
    checkSelected,
    isDisplayingOverviewRows,
    isSorting,
    props.selectedReservation,
    sortedReservations,
    toggledSort,
    valuesToShow,
    viewModel.selectReservierung,
  ]);

  const _preparedRows = React.useMemo(() => {
    return [
      ...renderedRows,
      <tr key={"emptyRow"} className={"flex flex-grow-0 flex-shrink-0 w-full h-" + 12}>
        <td className={"flex flex-grow-0 flex-shrink-0 w-full h-" + 12}></td>
      </tr>,
    ];
  }, [renderedRows]);

  const _handleRef = React.useCallback(
    (ref: HTMLDivElement | null) => {
      measuredRef(ref);
      setListRef(ref);
    },
    [measuredRef]
  );

  const style = React.useMemo(() => ({ width }), [width]);

  const mappedHeaders = React.useMemo(
    () =>
      props.reservations && props.reservations[0] ? (
        header.map((head, idx, arr) => {
          const isLast = idx === arr.length - 1;
          const isSortedBy = isSorting === head.keyOfValue;

          const alignmentOffset = `${head.alignment === "start" ? "-ml-2" : ""} ${
            head.alignment === "end" ? "-mr-2" : ""
          } ${head.alignment === "center" ? (isLast ? "-ml-4" : "-mr-4") : ""}`;
          const sortedByClasses = `bg-primary-100 py-1 px-2 ${isLast ? "pl-6" : "pr-6"} ${alignmentOffset}`;

          return (
            <th
              key={head.label}
              className={`flex items-center p-1 pb-1 px-2 content-start text-xxs uppercase justify-${
                head.alignment || "start"
              } font-bold text-primary-400 tracking-wide flex-${head.flex}`}
              onClick={head.sortFunc}
            >
              <span
                className={`relative flex justify-center items-center transition-colors duration-100 ease-in-out ${
                  isSortedBy ? sortedByClasses : ""
                } rounded-lg select-none`}
              >
                <Heroicons.Solid.ArrowUpIcon
                  className={`absolute top-0 ${
                    isLast ? "left-0 ml-1 pl-px" : "right-0 mr-1 pr-px"
                  } transition-all transform duration-100 ease-in-out ${isSortedBy ? "opacity-100" : "opacity-0"} ${
                    toggledSort ? "rotate-180" : "rotate-0"
                  } w-4 h-full`}
                />
                <span>{head.label}</span>
              </span>
            </th>
          );
        })
      ) : (
        <th className={`flex items-end p-1 pb-1 px-2 content-start text-xs justify-center font-medium`} />
      ),
    [header, isSorting, props.reservations, toggledSort]
  );

  return (
    <table
      className={`flex flex-col max-w-full w-full h-full max-h-full min-h-0 flex-1 relative bg-white rounded-t-lg overflow-hidden ${
        props.isOpaque ? "opacity-80" : "opacity-100"
      } ${props.className}`}
    >
      {width && width !== "0px" ? (
        <thead
          ref={theadRef}
          style={style}
          className={`flex flex-grow-0 ${theadHeight} absolute top-0  z-20 bg-white rounded-t-lg`}
        >
          <tr
            className={`flex h-full justify-start content-start ${
              props.reservations && props.reservations.length > 0 ? "border-gray-300" : "border-transparent"
            } border-b-2 text-primary-700  opacity-100  w-full rounded-t-lg`}
          >
            {mappedHeaders}
          </tr>
        </thead>
      ) : null}
      <tbody
        key={"fadetbody"}
        ref={_handleRef}
        className={`flex flex-col flex-grow-0 h-full max-h-full overflow-hidden overflow-y-auto min-h-0 ${
          props.hasSpaceForModal ? "pb-56" : "pb-32"
        }`}
      >
        <tr
          className={`flex flex-grow-0 flex-shrink-0 ${theadHeight} w-full justify-start content-start border-b border-gray-400`}
        >
          <td />
        </tr>
        {viewModel.reservierungen && viewModel.reservierungen.length > 0 ? (
          _preparedRows
        ) : (
          <tr className="flex flex-col w-full h-full justify-center items-center pb-20">
            <td className="relative flex w-full h-full flex-col justify-center items-center ">
              <div className={`absolute w-64 h-24 rounded-xl bg-gray-200 mt-6 shadow-xl border`}></div>
              <span
                className={`relative flex h-15 w-15 text-white p-2 justify-center items-center`}
                onClick={props.emptyClick}
              >
                <Heroicons.Solid.PlusIcon className={`bg-primary-600 shadow-md rounded-lg p-1 w-full h-full`} />
              </span>
              <span className={`relative text-md text-primary-800 pt-1 mt-2 leading-tight`}>
                Erstelle jetzt eine Reservierung
              </span>
              <span
                className={`relative text-xxs text-primary-500 font-bold pt-1 uppercase tracking-wide border-b-2 pb-1 border-gray-200`}
              >
                Keine Reservierungen für diesen Tag
              </span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const TableRow = ({
  res,
  noItemSelected,
  rowHeight,
  valuesToShow,
  fontWeight,
  textSize,
  body,
  ...props
}: {
  res: Reservation;
  isItemLoading: (id: Reservation["id"]) => boolean;
  isSelected: (id: Reservation["id"]) => boolean;
  noItemSelected: boolean;
  rowHeight: number;
  fontWeight: string;
  textSize: string;
  valuesToShow: (keyof Reservation)[];
  body: { [key in keyof Partial<Reservation>]: ReservierungenViewModelTypes.Header };
  handleClick: (res: Reservation) => void;
}) => {
  const { theme: colors } = useTheme();
  const hourDifference = React.useMemo(
    () => (new Date(res.dateOfArrival).getTime() - Date.now()) / 36e5,
    [res.dateOfArrival]
  );
  const isInOneHourOrLess = React.useMemo(() => hourDifference <= 1, [hourDifference]);

  const isItemStrikeThrough = React.useMemo(() => +res.state === 5 || +res.state === 6, [res.state]);
  const isItemBold = React.useMemo(() => +res.state < 3 && isInOneHourOrLess, [isInOneHourOrLess, res.state]);

  const isSelected = React.useMemo(() => {
    return props.isSelected.call(undefined, res.id);
  }, [props.isSelected, res.id]);

  const isItemLoading = React.useMemo(() => {
    return props.isItemLoading.call(undefined, res.id);
  }, [props.isItemLoading, res.id]);

  const _handleClick = React.useCallback(() => {
    props.handleClick.call(undefined, res);
  }, [props.handleClick, res]);

  const entries = React.useMemo(
    () =>
      valuesToShow.map((key, idx) => {
        const fittingBody = body[key] || {
          alignment: "start",
          flex: 1,
          formatFunc: (res) => res[key] + "",
        };
        const value = fittingBody.formatFunc(res);
        return (
          <td
            key={res.id + "_" + idx}
            className={`flex px-2 py-1 items-center content-start ${fontWeight ? `font-${fontWeight}` : "font-light"} ${
              `text-${textSize}` || "text-lg"
            } ${fittingBody.alignment ? `justify-${fittingBody.alignment}` : "justify-start"} ${
              typeof value === "string" ? "truncate" : "overflow-x-auto"
            } ${
              !noItemSelected
                ? isSelected
                  ? `opacity-100 text-primary-800`
                  : `${isItemLoading ? "opacity-90" : isItemStrikeThrough ? "opacity-80" : "opacity-90"} `
                : `${isItemLoading ? "opacity-90" : isItemStrikeThrough ? "opacity-80" : "opacity-100"}`
            } ${isItemBold ? `font-semibold` : ""} ${fittingBody.flex ? `flex-${fittingBody.flex}` : "flex-1"}`}
          >
            <span className="truncate" title={key}>
              {value}
            </span>
          </td>
        );
      }),
    [
      body,
      fontWeight,
      isItemBold,
      isItemLoading,
      isItemStrikeThrough,
      isSelected,
      noItemSelected,
      res,
      textSize,
      valuesToShow,
    ]
  );

  const selectionClasses = React.useMemo(
    () =>
      !noItemSelected
        ? isSelected
          ? `bg-gray-100` // This element is currently selected
          : `hover:bg-gray-100` // An element is currently selected
        : `hover:bg-gray-100`, // No element is currently selected
    [isSelected, noItemSelected]
  );

  const row = React.useMemo(
    () => (
      <tr
        // pose={isSelected ? "active" : "notactive"}
        key={res.id}
        className={`relative flex flex-grow-0 transition-all duration-200 ease-in-out flex-shrink-0 w-full justify-start content-start cursor-pointer border-b border-gray-200 shadow-none bg-white ${
          rowHeight ? `h-${rowHeight}` : "h-10"
        } ${selectionClasses}`}
        onClick={_handleClick}
      >
        <td
          className={`absolute flex h-full w-full px-3 justify-center items-center transition-opacity duration-100 ease-in-out ${
            isItemStrikeThrough ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className={`flex w-full bg-primary-700 rounded-full opacity-70 h-px py-px`}></div>
        </td>

        <td
          className={`absolute flex h-full w-full justify-end items-center px-3 z-10 pointer-events-none transition-opacity duration-100 ease-in-out`}
        >
          <Heroicons.Outline.RefreshIcon
            className={`h-10 w-10 p-2 text-primary-700 rounded-full bg-white ${
              isItemLoading ? "bg-opacity-70 opacity-100 animate-spin" : "opacity-0 bg-opacity-0"
            } transition-all duration-200 ease-in-out`}
          />
        </td>
        {entries}
      </tr>
    ),
    [_handleClick, entries, isItemLoading, isItemStrikeThrough, res.id, rowHeight, selectionClasses]
  );

  return row;
};
export default ReservierungenView;
