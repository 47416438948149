import * as React from "react";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { useSettings } from "../../clientApi/SettingsProvider";
import { BaseInput } from "../../components/baseinput/BaseInput";
import { ReservationButton } from "../../components/ReservationButton";
import { useTheme } from "../../hooks/useTheme";

export const NachrichtenCancel = (props: {
  nachricht: Nachricht.Client<any>;
  canCancel?: boolean;
  onCancel?: (nachricht: Nachricht.Client<any>, note: string) => void;
}) => {
  const { theme } = useTheme();

  const [emailText, setEmailText] = React.useState<string | null>(null);

  const resetEmailText = React.useCallback(() => {
    return setEmailText(null);
  }, []);

  const currentEmailText = React.useMemo(() => emailText || "", [emailText]);

  const handleOnCancel = React.useCallback(() => {
    if (props.onCancel && typeof props.onCancel === "function") {
      return props.onCancel.call(undefined, props.nachricht, currentEmailText);
    }
  }, [currentEmailText, props.nachricht, props.onCancel]);

  const { isUsingVirtualKeyboard } = useSettings();

  return (
    <div className="flex flex-1 flex-col min-h-0 overflow-hidden">
      <div className="flex flex-1 bg-gray-200 shadow-inner rounded-lg p-2">
        <BaseInput
          isTextArea
          icon="MailIcon"
          height="h-full"
          value={currentEmailText}
          onChange={setEmailText}
          label={"Email Text"}
          isVirtualKeyboard={isUsingVirtualKeyboard}
        />
      </div>
      <div className="flex h-14 flex-col w-full pt-2 pb-2">
        <ReservationButton
          unlockedHeight
          color={"blue"}
          isLocked={false}
          onClickHandler={handleOnCancel}
          additionalClasses={`rounded-lg transition-all duration-200 ${
            props.canCancel ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          Absagen
        </ReservationButton>
      </div>
    </div>
  );
};
