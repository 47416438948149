import { useCallback, useLayoutEffect, useState } from "react";
import { Reservation } from "../../../models/Reservation";
import useAbsprachen from "../hooks/useAbsprachen";
import useAbsprachenApi from "../hooks/useAbsprachenApi";
import AbspracheMainViewTypes from "./AbspracheMainViewTypes";

export const useAbspracheMainViewModel = (props: AbspracheMainViewTypes.Props) => {
  const { absprachen, isLoading } = useAbsprachen();
  const { getAbsprachen, createAbspracheOnDate, isCreating, isPulling, addReservierungToAbspracheOnDate } =
    useAbsprachenApi();

  useLayoutEffect(() => {
    getAbsprachen();
  }, [getAbsprachen]);

  const createAbsprache = useCallback(
    async (date: Date) => {
      try {
        const resp = await createAbspracheOnDate(date);
        if (resp) {
          await getAbsprachen();
        }
      } catch (error) {
        throw error;
      }
    },
    [createAbspracheOnDate, getAbsprachen]
  );

  const [addingResId, setAddingResId] = useState<string | null>(null);

  const AddResToAbsprache = useCallback(
    async (date: Date, res: Reservation) => {
      try {
        setAddingResId(res.id);
        const resp = await addReservierungToAbspracheOnDate(res, date);
        if (resp) {
          await getAbsprachen();
        }
      } catch (error) {
        throw error;
      } finally {
        setAddingResId(null);
      }
    },
    [addReservierungToAbspracheOnDate, getAbsprachen]
  );

  return {
    absprachen,
    createAbsprache,
    isLoading,
    isCreating,
    isPulling,
    AddResToAbsprache,
    addingResId,
  };
};
