import { PublicClientApplication } from "@azure/msal-browser";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNavigationClient from "./NavigationClient";

function ClientSideNavigation({
  pca,
  children,
}: {
  pca: PublicClientApplication;
  children: ReactNode | ReactNode[] | null;
}) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  return firstRender ? <>Lädt...</> : <>{children}</>;
}

export default ClientSideNavigation;
