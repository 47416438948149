import { Heroicons } from "../../Heroicon/Heroicon";
import TastaturButton from "./TastaturButton";

type TastaturShiftButtonProps = {
  onPress: () => void;
  isShifted?: boolean;
};

const TastaturShiftButton = ({ onPress, isShifted }: TastaturShiftButtonProps) => {
  return (
    <TastaturButton
      onPress={onPress}
      innerClassName={`h-full text-gray-700 ${isShifted ? "bg-white" : "bg-gray-400"}`}
      className="col-span-2 h-full"
    >
      {isShifted ? (
        <Heroicons.Solid.ArrowCircleUpIcon className="h-full w-6 mx-auto" />
      ) : (
        <Heroicons.Outline.ArrowCircleUpIcon className="h-full w-6 mx-auto" />
      )}
    </TastaturButton>
  );
};
export default TastaturShiftButton;
