import React, { useCallback, useRef, useState } from "react";
import * as uuid from "uuid";
import { useEventListener } from "../../hooks/useEventlistener";
import { ToasterMessage } from "./ToasterMessage";
import * as Heroicons from "@heroicons/react/solid";

export const Events = {
  TOASTER_ADD: "TOASTER_ADD",
  TOASTER_HIDE: "TOASTER_HIDE",
};

export type ToasterHandlerProps = {
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
};

const positionClasses: { [key in Required<ToasterHandlerProps>["position"]]: string } = {
  "top-left": "justify-start items-start pl-2",
  "bottom-left": "justify-end items-start pl-2",
  "top-right": "justify-start items-end pr-2",
  "bottom-right": "justify-end items-end pr-2",
};

export function ToasterHandler({ position = "bottom-left", ...props }: ToasterHandlerProps) {
  const ToasterRef = useRef<HTMLDivElement | null>(null);
  const [currentMessages, setCurrentMessages] = useState<JSX.Element[]>([]);

  const removeMessage = useCallback((id: number | string) => {
    setCurrentMessages((c) => c.filter((e) => e.props.id !== id));
  }, []);

  const addMessage = useCallback(
    (
      title: string,
      text: string,
      icon: (p: any) => JSX.Element,
      color: string,
      delay?: number,
      onClick?: () => any
    ) => {
      const id = uuid.v4();
      const element = (
        <ToasterMessage
          key={id}
          text={text}
          title={title}
          delay={delay}
          id={id}
          onRemove={removeMessage}
          color={color}
          Icon={icon}
          position={position}
          onClick={onClick}
        />
      );

      setCurrentMessages((cm) => [...cm, element]);
    },
    [position, removeMessage]
  );

  const handleAddMessageCall = useCallback(
    (
      ev: CustomEvent<{
        title: string;
        text: string;
        icon: keyof typeof Heroicons;
        color: string;
        delay?: number;
        onClick?: () => any;
      }>
    ) => {
      if (ev?.detail) {
        const { title, text, delay, icon, color, onClick } = ev.detail;
        const iconElement = Heroicons[icon];
        addMessage(title, text, iconElement, color, delay, onClick);
      }
    },
    [addMessage]
  );

  useEventListener(Events.TOASTER_ADD, handleAddMessageCall);

  return (
    <div
      ref={ToasterRef}
      className={`fixed inset-0 z-50 flex flex-col w-full h-full pointer-events-none ${positionClasses[position]} space-y-2 py-2`}
    >
      {currentMessages}
    </div>
  );
}

export default ToasterHandler;
