import { useCallback, useMemo, useRef, useState } from "react";
import { useEventListener } from "./useEventlistener";

const EventName = "clientApiLog";

const useClientApiLog = () => {
  const textsRef = useRef<string[]>([]);
  const [texts, setTexts] = useState<string[]>(textsRef.current);
  const lastText = useMemo(() => texts[texts.length - 1], [texts]);

  const addToText = useCallback((ev: CustomEvent<string>) => {
    const { detail } = ev;
    const now = new Date().toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      fractionalSecondDigits: 3,
    });
    const text = `${now} - ${detail}`;
    setTexts((c) => {
      textsRef.current = [...c, text];
      return textsRef.current;
    });
  }, []);

  useEventListener(EventName, addToText);

  return [texts, lastText] as [string[], string];
};
export default useClientApiLog;
