import { useRef, useEffect } from "react";

export const useEventListener = (
  eventName: string | string[] | undefined,
  handler: (...props: any) => any,
  element: HTMLElement | null | typeof window = window,
  passive: boolean = true
) => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => savedHandler.current(event);
    if (element) {
      if (Array.isArray(eventName)) {
        eventName.forEach((entry) => {
          element.addEventListener(entry, eventListener, { passive });
        });
        return () => {
          eventName.forEach((entry) => {
            element.removeEventListener(entry, eventListener);
          });
        };
      } else {
        if (eventName) {
          element.addEventListener(eventName, eventListener, { passive });
          return () => {
            element.removeEventListener(eventName, eventListener);
          };
        } else return;
      }
    }
  }, [element, eventName, passive]);
};
