import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { FC, ReactNode } from "react";
import { reactPlugin } from "../AppInsights";
import { ErrorAppInsights } from "./ErrorAppInsights";

type AppInsightsWrapperProps = {
  children?: ReactNode | ReactNode[];
};

const AppInsightsWrapper: FC<AppInsightsWrapperProps> = (props) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorAppInsights}>
        {props.children as any}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};
export default AppInsightsWrapper;
