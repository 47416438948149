namespace Tastatur {
  export enum Art {
    Text,
    Numeric,
  }

  export enum Mode {
    Text,
    Signs,
  }

  export namespace Key {
    export enum Special {
      "Shift" = "Shift",
      "Switch" = "Switch",
      "Enter" = "Enter",
      "Send" = "Send",
      "Space" = "Space",
      "Backspace" = "Backspace",
      "SpecialChars" = "SpecialChars",
    }
  }
  export type Key = string;

  export type Props = {
    art: Art;
    onKeyPress: (key: Key | Key.Special) => void;
    isOpen?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
  };
}

export default Tastatur;
