import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PersistentStorageRepository } from "../../../clientApi/ClientApi";
import StorageDetailViewTypes from "./StorageDetailViewTypes";

export const useStorageDetailViewModel = (props: StorageDetailViewTypes.Props) => {
  const params = useParams<StorageDetailViewTypes.Params>();

  const [isLocal, setIsLocal] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const items = useMemo(() => {
    if (selectedItem && Array.isArray(selectedItem)) {
      return selectedItem;
    } else return [];
  }, [selectedItem]);

  const removeElement = useCallback(
    async (item: any | null, idx: number) => {
      try {
        const temp = [...items];
        if (typeof item === "object" && "id" in item) {
          if (temp[idx].id !== item.id) {
            return null;
          }
        }
        const removedOnTemp = idx === 0 ? temp.slice(1) : [...temp.slice(0, idx - 1), ...temp.slice(idx)];
        if (isLocal && params.key) {
          localStorage.setItem(params.key, JSON.stringify(removedOnTemp));
        } else if (params.key) {
          await PersistentStorageRepository!.dangerouslySet(params.key, removedOnTemp);
        }
        await props.refresh();
      } catch (error) {
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, params.key, props.refresh]
  );

  useEffect(() => {
    const key = params.key;
    const isPersistent = props.persistentStorageKeys.find((c) => c.toString() === key);
    if (isPersistent && key) {
      PersistentStorageRepository!.dangerouslyGet(key).then((item) => setSelectedItem(item ?? null));
    } else if (key) {
      const isLocal = props.localstorageKeys.find((c) => c === key);
      setIsLocal(!!isLocal);
      if (isLocal) {
        const item = localStorage.getItem(key);
        const isParseable = item !== undefined && (item?.startsWith("{") || item?.startsWith("["));
        if (isParseable) {
          setSelectedItem(item ? JSON.parse(item) : null);
        } else setSelectedItem(item);
      } else {
        setSelectedItem(null);
      }
    }
  }, [params.key, props.localstorageKeys, props.persistentStorageKeys]);

  return { params, selectedItem, items, removeElement };
};
