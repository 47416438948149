import { FC, ReactNode } from "react";
import Toggle from "./Toggle";

type SettingsToggleProps = Parameters<typeof Toggle>[0] & {children?: ReactNode | ReactNode[]};

const SettingsToggle: FC<SettingsToggleProps> = ({ children, ...props }) => {
  return (
    <label
      htmlFor={props.idName}
      className="inline-flex justify-between py-2.5 w-full items-center px-3.5 bg-white rounded border text-xs cursor-pointer "
    >
      <span>{children}</span>
      <Toggle {...props} />
    </label>
  );
};
export default SettingsToggle;
