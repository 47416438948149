import * as React from "react";
import { SummaryTableTime } from "../models/Summary";
import { Heroicons } from "./Heroicon/Heroicon";
import { TimeDiagram } from "./TimeDiagram";

export const DateTimeModule = React.memo(
  (props: {
    dateOfDay: string | number;
    summaryTableTime: SummaryTableTime | null | undefined;
    onClick?: (date: string) => void;
    isSelected: boolean;
    reference?: (ref: HTMLDivElement | null) => void;
    veranstaltung?: string;
    children?: React.ReactNode | React.ReactNode[];
  }) => {
    const onClickRef = React.useRef(props.onClick);
    React.useEffect(() => {
      onClickRef.current = props.onClick;
    }, [props.onClick]);
    const currentDate = React.useMemo(
      () =>
        new Date(props.dateOfDay).toLocaleString("de-de", {
          day: "numeric",
          weekday: "short",
        }),
      [props.dateOfDay]
    );

    const _handleClick = React.useCallback(() => {
      return onClickRef.current ? onClickRef.current(props.dateOfDay + "") : () => null;
    }, [props.dateOfDay]);

    const [day, date] = React.useMemo(
      () => (currentDate.includes(",") ? currentDate.split(",") : currentDate.split(" ")),
      [currentDate]
    );

    const diagram = React.useMemo(
      () =>
        props.summaryTableTime ? (
          <TimeDiagram Times={props.summaryTableTime} isSelected={props.isSelected} date={date}></TimeDiagram>
        ) : null,
      [date, props.isSelected, props.summaryTableTime]
    );

    const _handleRef = React.useCallback(
      (ref: HTMLDivElement | null) => {
        if (props.reference) props.reference.call(undefined, ref);
      },
      [props.reference]
    );

    const totalReservations = React.useMemo(() => {
      return (props.summaryTableTime?.reservationTotal || 0).toString();
    }, [props.summaryTableTime]);

    const totalGuests = React.useMemo(() => {
      if (props.summaryTableTime) {
        if (props.summaryTableTime.reservationsByState) {
          const requested = props.summaryTableTime.reservationsGuestsByState["0"] ?? 0;
          return `${+props.summaryTableTime?.guestTotal || 0}${+requested ? ` (${+requested})` : ""}`;
        } else {
          return (props.summaryTableTime?.guestTotal || 0).toString();
        }
      } else return "0";
    }, [props.summaryTableTime]);

    if (currentDate) {
      return (
        <div
          ref={_handleRef}
          className={`inline-flex flex-col justify-start items-start content-start w-full aspect-[1/1] min-h-0 cursor-pointer group overflow-x-auto overflow-y-hidden bg-white ring-2 ring-transparent duration-150 ease-in-out hover:z-10 ${
            props.isSelected ? "ring-primary-500/100 hover:ring-primary-600 z-20" : "hover:ring-primary-100"
          } snap-x snap-proximity`}
          onClick={_handleClick}
        >
          <div
            className={`relative inline-flex flex-shrink-0 w-full justify-start items-center px-2 py-1 cursor-pointer min-h-0 border-b ${
              props.isSelected
                ? `bg-primary-500 group-hover:bg-primary-600 border-primary-500`
                : ` group-hover:bg-primary-100 border-gray-200`
            } transition-colors duration-150 ease-in-out`}
          >
            <span
              className={`inline-flex w-full justify-start items-baseline min-h-0 ${
                props.isSelected ? `text-primary-50` : `text-primary-500`
              } font-semibold tabular-nums text-xl gap-x-2`}
            >
              <span>{date?.trim()}</span>
              <small className="font-bold">{day}</small>
            </span>
            <div
              className={`inline-flex justify-end items-center ${
                props.isSelected ? `text-primary-100` : `text-primary-700`
              } text-sm tabular-nums w-full whitespace-nowrap`}
            >
              <Heroicons.Outline.BookmarkIcon className="flex flex-grow-0 flex-shrink-0 w-3.5 aspect-sqaure stroke-1" />
              <span className="pl-1">{totalReservations}</span>
              <Heroicons.Outline.UsersIcon className="flex flex-grow-0 flex-shrink-0 w-3.5 aspect-sqaure ml-2 stroke-1" />
              <span className="pl-1">{totalGuests}</span>
            </div>
          </div>
          {props.children}
          <div
            className={`inline-flex w-full flex-shrink-0 items-center px-2 tracking-wider text-xs font-semibold border-b pt-px ${
              props.veranstaltung
                ? `bg-primary-50 text-primary-500 border-primary-50`
                : "bg-transparent text-transparent border-transparent"
            } gap-1`}
          >
            <Heroicons.Outline.SparklesIcon className="w-3 aspect-square" />
            <span>{props.veranstaltung ?? "Kein Event"}</span>
          </div>

          {diagram}
        </div>
      );
    } else return null;
  }
);
