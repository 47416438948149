import Tools from "../../Tools";
import Datum from "./Datum";

namespace DateFormatter {
  export const toString = (d: Date) => d.toISOString();
  export const toIsoLike = (d: Date) => Tools.dateToIsoLike(d);
  export const toLong = (d: Date) =>
    d.toLocaleString("de-de", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  export const toShort = (d: Date) => d.toLocaleString("de-de", { day: "2-digit", month: "2-digit", year: "numeric" });
  export const toDatum = (d: Date | string) => {
    const tempDate = new Date(d);
    const temp: Datum = {
      obj: tempDate,
      string: toString(tempDate),
      isoLike: toIsoLike(tempDate),
      long: toLong(tempDate),
      short: toShort(tempDate),
    };
    return temp;
  };
  export const utcToDate = (d: Date) => new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
}

export default DateFormatter;
