import * as React from "react";

export interface WhiteboxProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: (props?: any) => any;
  flexrow?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export const Whitebox: React.FC<WhiteboxProps> = (props) => (
  <div
    className={`flex ${
      props.flexrow ? "flex-row" : "flex-col"
    } flex-1 w-full max-h-full h-full bg-white shadow min-h-0 ${props.className}`}
    style={{
      ...props.style,
    }}
    onClick={props.onClick}
  >
    {props.children}
  </div>
);
