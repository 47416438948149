import { Route, Routes } from "react-router-dom";
import StorageRoutes from "../constants/StorageRoutes";
import StorageDetailView from "../detail/StorageDetailView";
import StorageOverviewView from "../overview/StorageOverviewView";
import { useStorageMainViewModel } from "./StorageMainViewModel";
import StorageMainViewTypes from "./StorageMainViewTypes";

const StorageMainView = (props: StorageMainViewTypes.Props) => {
  const viewModel = useStorageMainViewModel(props);

  return (
    <Routes>
      <Route path={StorageRoutes.detail} element={<StorageDetailView {...viewModel} />} />
      <Route path={"/"} element={<StorageOverviewView {...viewModel} />} />
    </Routes>
  );
};
export default StorageMainView;
