import { ReservationState } from "./Reservation";

export type Summary = {
  reservationDate: string;
  reservationTimeSummaries: TimeSummary[];
};

type TimeSummary = {
  reservationTime: string;
  reservationStateSummaries: StateSummary[];
};

type StateSummary = {
  state: keyof ReservationState;
  reservationCount: number;
};

export type SummaryTableTime = {
  [key: string]: { [key in keyof ReservationState]: number } & TableObjectInformation;
};

export type SummaryTable = TableObjectInformation & {
  reservationsByState: { [key in keyof ReservationState]: number };
  reservationsGuestsByState: { [key in keyof ReservationState]: number };
} & {
  [key: string]: SummaryTableTime;
};

type TableObjectInformation = {
  reservationTotal: number;
  guestTotal: number;
};

export const mapSummariesToTableObject = (summaries: Summary[]) => {
  try {
    return summaries.reduce((prev, current) => {
      const [day, month, year] = new Date(current.reservationDate)
        .toLocaleString("de-de", { day: "2-digit", month: "2-digit", year: "numeric" })
        .split(".");
      return {
        ...prev,
        [`${year}-${month}-${day}`]: mapTimeSummariesToTableObject(current.reservationTimeSummaries),
      };
    }, {});
  } catch (error) {
    throw error;
  }
};

export const mapTimeSummariesToTableObject = (summaries: TimeSummary[]) => {
  try {
    const mapped = summaries.reduce((prev, current) => {
      // const time = new Date(current.reservationTime).toLocaleString("de-de", { hour: "2-digit", minute: "2-digit" });
      // const timeCollection = Object.keys([...Array(24)])
      //   .reduce(
      //     (all: any, current) => [...all, `${("0" + current).slice(-2)}:00`, `${("0" + current).slice(-2)}:30`],
      //     []
      //   )
      //   .reduce((prev: string[], cur: string) => ({ ...prev, [cur]: cur === time ? current : {} }), {});
      const timeCollection = { lädt: { "0": "lädt" } };
      return { ...prev, ...timeCollection };
    }, {});
    return mapped;
  } catch (error) {
    throw error;
  }
};
