import { TableItem } from "../components/TableView";
import { Size } from "../types/Sizes";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const EMPTY_DATETIME = "0001-01-01T00:00:00";

export type TailwindBackgroundColors =
  | "yellow"
  | "amber"
  | "orange"
  | "red"
  | "rose"
  | "pink"
  | "purple"
  | "violet"
  | "indigo"
  | "darkBlue"
  | "blue"
  | "teal"
  | "cyan"
  | "lime"
  | "green"
  | "emerald"
  | "fuchsia"
  | "gray";

export interface AppColors {
  backgroundcolor: TailwindBackgroundColors;
  textcoloroncolor: "gray-900" | "white";
  textdefaultcolor: "gray-900" | "white";
}

export type selectedItem<K> = {
  ItemInTable: TableItem | undefined;
  ActualItem: K | undefined;
};

export interface AppTableView<K> {
  isLoading: boolean;
  currentSearchTerm: string;
  initialTableItems: TableItem[];
  currentTableItems: TableItem[] | undefined;
  selectedItem: selectedItem<K>;
}

export const getObjectsInArrayByString = (ArrayToBeSearched: Array<any>, searchString: string) =>
  ArrayToBeSearched.filter((item) => {
    return !!~JSON.stringify(item).toLowerCase().indexOf(searchString.toLowerCase());
  });

export const filterKeys: <K>(key: keyof K, filterArray: (keyof K)[]) => boolean = (key, filterArray) =>
  !filterArray.some((entry) => entry === key);

export const isAppMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check;
};

export const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

type UserSettings = {
  AppColor: AppColors;
  showInstallPrompt: boolean;
  showNotificationPrompt: boolean;
  appDisplayMode: DisplayMode | null;
  appColSize: number;
  appSize: Size;
};

const BaseUser: UserSettings = {
  AppColor: { backgroundcolor: "blue", textcoloroncolor: "white", textdefaultcolor: "gray-900" },
  showInstallPrompt: true,
  showNotificationPrompt: true,
  appDisplayMode: null,
  appColSize: 0,
  appSize: "base",
};

export const getSettingsLocation = (restaurantId: string) => `${restaurantId}_settings`;
export const getSettingsFromStorage = (settingsLocation: string) => {
  if (window && window.localStorage) {
    const currentSettingsJSON = window.localStorage.getItem(settingsLocation);
    if (currentSettingsJSON) {
      const parsed: UserSettings = JSON.parse(currentSettingsJSON);
      return parsed;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const saveSettingsInStorage = (storageLocation: string, newSettings: Partial<UserSettings>) => {
  if (window && window.localStorage) {
    const settingLocation = storageLocation;
    const currentSettings: UserSettings | null = getSettingsFromStorage(settingLocation);
    const mergedSettings: UserSettings = { ...(currentSettings || BaseUser), ...newSettings };
    window.localStorage.setItem(settingLocation, JSON.stringify(mergedSettings));
    return mergedSettings;
  } else {
    return null;
  }
};

export const hexToRgb = (hexcolor: string) => {
  let numbers = hexcolor;
  const isRgb = numbers.includes("rgb");
  const isRgba = numbers.includes("rgba");
  if (isRgb || isRgba) {
    const arr = numbers.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (arr) {
      return [arr[1], arr[2], arr[3]];
    }
  }
  if (hexcolor.length > 6) {
    numbers = hexcolor.slice(1);
  }
  const rgb = [numbers.slice(0, 2), numbers.slice(2, 4), numbers.slice(4, 6)].map((hex) => parseInt(hex, 16));
  return rgb;
};

export const setShadow = (element: HTMLElement) => {
  const computedStyle = window.getComputedStyle(element);
  if (computedStyle && computedStyle.boxShadow && computedStyle.backgroundColor) {
    const rgb = hexToRgb(computedStyle.backgroundColor);
    let shadow = computedStyle.boxShadow;
    if (shadow) {
      element.style.boxShadow = shadow.replace(/0, 0, 0,|0,0,0,/g, `${rgb[0]}, ${rgb[1]}, ${rgb[2]}, `);
    }
  }
};

export const getLocalstorageSize = (logToConsole?: boolean) => {
  let _lsTotal = 0,
    _xLen,
    _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue;
    }
    _xLen = (localStorage[_x].length + _x.length) * 2;
    _lsTotal += _xLen;
    if (logToConsole) {
      console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB");
    }
  }
  if (logToConsole) {
    console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
  }
  return (_lsTotal / 1024).toFixed(2) + " KB";
};

export type DisplayMode = "Web" | "Tablet" | "Mobile";
