import * as React from "react";
import { AppColors, getObjectsInArrayByString } from "../models/General";
import { LoadingBar } from "./LoadingBar";

interface TableViewProps {
  itemsToBeDisplayed: TableItem[] | undefined;
  rowHeight?: number;
  colors: AppColors;
  activeElement: string | undefined;
  className?: string;
  style?: React.CSSProperties;
  showAddButton?: boolean;
  emptyMessage?: string;
  fontWeight?: string;
  textSize?: string;
  loadingEntry?: boolean;
}

type stylings = "strikeThrough" | "bold" | "loading";

export type TableItem = {
  [key: string]: { flex?: number; value: any; fixation?: "center" | "start" | "end" };
} & RequiredTableFields;

type RequiredTableFields = {
  ITEM_ID: { value: string };
  STYLING: { value: stylings[] };
  onClick: { value: (prop?: any) => any };
};

export const filterItemsByAndMap = (
  itemsToFilter: any,
  searchString: string | undefined,
  mapFunction: (prop: any) => TableItem[]
) => {
  if (searchString && searchString !== "") {
    let searchedDishes = getObjectsInArrayByString(itemsToFilter || [], searchString);
    let tableItems: TableItem[] | undefined = mapFunction(searchedDishes);
    tableItems = tableItems.length > 0 ? tableItems : undefined;
    return tableItems;
  } else {
    return undefined;
  }
};

export const TableView: React.FC<TableViewProps> = (props) => {
  const [width, setWidth] = React.useState<string>("0px");
  const [activeElement, setActiveElement] = React.useState<string | undefined>(undefined);

  const measuredRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setWidth(`calc(100% - (${node.offsetWidth}px - ${node.clientWidth}px))`);
    }
  }, []);

  React.useEffect(() => {
    setActiveElement(props.activeElement);
  }, [props.activeElement]);

  let theadRef = React.useRef(null);

  const theadHeight = "h-8";

  const HIDDEN_PROPERTIES = ["onClick", "ITEM_ID", "STYLING"] as (keyof RequiredTableFields)[];

  return (
    <table
      className={`flex flex-col max-w-full w-full h-full max-h-full relative ${props.className}`}
      style={props.style}
    >
      {width && width !== "0px" && (
        <thead
          ref={theadRef}
          style={{ width: width }}
          className={`flex flex-grow-0 ${theadHeight} absolute top-0 bg-white z-20`}
        >
          <tr
            className={`flex h-full justify-start content-start border-b-2 border-l-4 text-primary-700 bg-white opacity-100 border-gray-300 w-full`}
          >
            {props.itemsToBeDisplayed && props.itemsToBeDisplayed[0] ? (
              Object.keys(props.itemsToBeDisplayed[0])
                .filter((key) => !HIDDEN_PROPERTIES.some((notShow) => key === notShow))
                .map((key, idx) => (
                  <th
                    key={idx}
                    className={`flex items-end p-1 pb-1 px-2 content-start font-medium text-${
                      props.textSize || "sm"
                    } justify-${(props.itemsToBeDisplayed && props.itemsToBeDisplayed[0][key].fixation) || "start"}`}
                    style={{
                      flex: props.itemsToBeDisplayed && props.itemsToBeDisplayed[0][key].flex,
                    }}
                  >
                    {key}
                  </th>
                ))
            ) : (
              <th className={`flex items-end p-1 pb-1 px-2 content-start font-medium text-sm justify-center`} />
            )}
          </tr>
        </thead>
      )}
      <tbody
        key={"fadetbody"}
        ref={measuredRef}
        className="flex flex-col flex-grow-0 h-full max-h-full overflow-hidden overflow-y-auto min-h-0"
      >
        <tr
          className={`flex flex-grow-0 flex-shrink-0 ${theadHeight} w-full justify-start content-start border-b border-gray-400`}
        >
          <td />
        </tr>
        {props.loadingEntry ? (
          <tr
            className={`flex flex-grow-0 flex-shrink-0 ${theadHeight} w-full justify-start content-start border-b border-l-4`}
          >
            <td
              className="flex justify-center items-center h-full w-full"
              colSpan={
                props.itemsToBeDisplayed && props.itemsToBeDisplayed[0]
                  ? Object.keys(props.itemsToBeDisplayed[0]).length
                  : 1
              }
            >
              <LoadingBar color={props.colors.backgroundcolor}></LoadingBar>
            </td>
          </tr>
        ) : null}

        {props.itemsToBeDisplayed && props.itemsToBeDisplayed.length > 0 ? (
          props.itemsToBeDisplayed.map((item, tridx) => {
            const itemStyling = item.STYLING ? item.STYLING.value : [];
            const isItemStrikeThrough = itemStyling.some((entry) => entry === "strikeThrough");
            const isItemBold = itemStyling.some((entry) => entry === "bold");
            const isItemLoading = itemStyling.some((entry) => entry === "loading");

            return (
              <tr
                key={tridx}
                className={`relative flex flex-grow-0 flex-shrink-0 ${
                  props.rowHeight ? `h-${props.rowHeight}` : "h-10"
                } w-full justify-start content-start border-l-4 cursor-pointer ${
                  activeElement !== undefined
                    ? activeElement === item.ITEM_ID.value
                      ? `opacity-100 bg-white border-primary-400 z-10 shadow` // This element is currently selected
                      : `opacity-100 bg-white hover:bg-gray-100 border-b border-gray-300 shadow-none` // An element is currently selected
                    : ` bg-white hover:bg-gray-100 border-b border-gray-300 shadow-none` // No element is currently selected
                } `}
                style={{
                  transition: `background-color 240ms, opacity 150ms, box-shadow 200ms, border-color ${
                    activeElement !== undefined ? (activeElement === item.ITEM_ID.value ? "1ms" : "") : ""
                  }`,
                  borderBottom:
                    activeElement !== undefined
                      ? activeElement === item.ITEM_ID.value
                        ? "1px solid transparent"
                        : undefined
                      : "",
                  marginBottom:
                    activeElement !== undefined ? (activeElement === item.ITEM_ID.value ? "0px" : "0px") : "",
                }}
                onClick={() => item.onClick && item.onClick.value()}
              >
                {isItemStrikeThrough ? (
                  <td className="absolute flex h-full w-full px-3 justify-center items-center">
                    <div
                      className={`flex w-full bg-primary-700 shadow opacity-70`}
                      style={{
                        height: "0.1rem",
                      }}
                    ></div>
                  </td>
                ) : null}
                {isItemLoading ? (
                  <td className="absolute flex h-full w-full px-3 justify-center items-center">
                    <LoadingBar color={props.colors.backgroundcolor}></LoadingBar>
                  </td>
                ) : null}
                {props.itemsToBeDisplayed &&
                  props.itemsToBeDisplayed.length > 0 &&
                  Object.keys(props.itemsToBeDisplayed[0])
                    .filter((key) => !HIDDEN_PROPERTIES.some((notShow) => key === notShow))
                    .map((key, idx) => (
                      <td
                        key={tridx + "" + idx}
                        className={`flex px-2 py-1 items-center content-start ${
                          props.fontWeight ? `font-${props.fontWeight}` : "font-light"
                        } ${props.textSize ? `text-${props.textSize}` : "text-lg"} ${
                          item[key].fixation ? `justify-${item[key].fixation}` : "justify-start"
                        } ${typeof item[key].value === "string" ? "truncate" : "overflow-x-auto"} ${
                          activeElement !== undefined
                            ? activeElement === item.ITEM_ID.value
                              ? `opacity-100 text-primary-500`
                              : `${isItemLoading ? "opacity-50" : isItemStrikeThrough ? "opacity-80" : "opacity-90"} `
                            : `${isItemLoading ? "opacity-50" : isItemStrikeThrough ? "opacity-80" : "opacity-100"}`
                        } ${isItemBold ? "font-bold" : ""} `}
                        style={{ flex: item[key].flex || 1 }}
                      >
                        <span className="truncate" title={item[key].value}>
                          {item[key].value}
                        </span>
                      </td>
                    ))}
              </tr>
            );
          })
        ) : (
          <tr
            className={`flex flex-grow-0 flex-shrink-0 h-10 w-full justify-start content-start border-b border-gray-300  border-l-4`}
          >
            <td
              className={`flex flex-1 w-full h-full items-center content-start justify-center p-1 font-light text-sm
      }`}
            >
              {props.emptyMessage ? props.emptyMessage : "Keine Daten gefunden"}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export const TableButton: React.FC<{
  colors: AppColors;
  size?: number;
  onClick: (prop?: any) => any;
  children?: React.ReactNode | React.ReactNode[];
}> = (props) => (
  <div
    className={`flex flex-grow-0 flex-shrink-0 h-8 ${
      props.size ? `w-${props.size}` : "w-32"
    }  border-l border-r hover:border-primary-200 flex justify-center items-center p-2 cursor-pointer  text-sm text-primary-700 hover:text-primary-300 select-none transition-all transform duration-200 ease-in-out`}
    onClick={props.onClick}
  >
    {props.children}
  </div>
);
