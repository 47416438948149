import * as React from "react";

export const InformationBar: React.FC<{
  height?: string;
  color?: string;
  additionalClasses?: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ ...props }) => {
  return (
    <div
      className={`relative inline-flex flex-grow-0 flex-shrink-0 min-h-0 w-full h-${
        props.height ? props.height : 8
      } bg-${props.color ? props.color : "white"} ${props.additionalClasses} `}
    >
      <div className={`flex flex-1 ${props.height ? `h-${props.height}` : "h-8"}  justify-start items-center px-2`}>
        {props.children}
      </div>
    </div>
  );
};
