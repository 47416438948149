import { useEffect, useMemo, useState } from "react";

export const useFadeInComponent = (after?: boolean) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (after || after === undefined) {
      setIsVisible(true);
      return () => {
        setIsVisible(false);
      };
    }
  }, [after]);

  useEffect(() => {
    if (!isVisible) {
      return () => {
        setTimeout(() => {
          setIsDone(true);
        }, 500);
      };
    }
  }, [isVisible]);

  const opacityClasses = useMemo(
    () => `transition-opacity duration-500 ease-in-out ${isVisible ? "opacity-100" : "opacity-0"}`,
    [isVisible]
  );

  return { isVisible, opacityClasses, isDone };
};
