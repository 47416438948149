import { useCallback, useEffect, useMemo, useState } from "react";

export const useIndex = (
  length: number,
  selectedIndex: number = 0,
  canOverflow: boolean = true,
  ignorePropIndex: boolean = false
) => {
  const [index, setIndex] = useState<number>(selectedIndex);

  const moveIndex = useCallback(
    (dir: 1 | -1) => {
      setIndex((cidx) => {
        let nextIdx = cidx + dir;
        if (nextIdx < 0 && dir === -1) {
          nextIdx = canOverflow ? length - 1 : 0;
        } else if (nextIdx >= length && dir === 1) {
          nextIdx = canOverflow ? 0 : length - 1;
        }
        return nextIdx;
      });
    },
    [canOverflow, length]
  );

  const selectIndex = useCallback(
    (nidx: number) => {
      setIndex((idx) => {
        if (nidx >= 0 && nidx < length) {
          return nidx;
        } else {
          return idx;
        }
      });
    },
    [length]
  );

  useEffect(() => {
    if (!ignorePropIndex) {
      selectIndex(selectedIndex);
    }
  }, [ignorePropIndex, selectIndex, selectedIndex]);

  const nextIndex = useMemo(() => () => moveIndex(1), [moveIndex]);
  const prevIndex = useMemo(() => () => moveIndex(-1), [moveIndex]);

  return { index, selectIndex, nextIndex, prevIndex };
};
