import { Nachricht } from "../clientApi/models/Nachricht";
import { EMPTY_DATETIME, EMPTY_GUID } from "./General";
import { Table } from "./Table";

export type Reservation = NewReservation & {
  id: string;
  tables: Table[] | undefined;
  state: keyof ReservationState;
  causeId: string;
  createdBy: string;
  modifiedBy: string;
  leaveDateTime: string;
  leaveNotice: string;
  satisfaction: number;
  listWordDocUrl: string[];
};

export type NewReservation = {
  dateOfArrival: string;
  name: string;
  durationOfStay: number;
  guestAmount: {
    adultAmount: number;
    childAmount: number;
  };
  note?: string | null;
  email?: string;
  phoneNumber?: string;
  requestChannel: string;
  cause?: Partial<Nachricht.Client<any>>;
};

export type walkedInConfirmation = {
  arrivalDateTime: string;
  guestAmount: Reservation["guestAmount"];
  tableIds: Table["id"][] | null;
};

export type ReservationState = {
  0: "Requested"; // Die Reservierung wurde angefragt und es wird auf Bestätigung gewartet.
  1: "Confirmed"; // Die Reservierung wurde bestätigt.
  2: "TableAssigned"; // Der Reservierung wurde ein Tisch zugewiesen.
  3: "WalkedInWithReservation"; // Die Gäste der Reservierung sind eingetroffen.
  4: "WalkedInWithoutReservation"; // Ein Gast ohne Reservierung ist eingetroffen.
  5: "Cancelled"; // Die Reservierung wurde abgesagt.
  6: "Left"; // Der Gast hat das Restaurant verlassen.
  7: "Hidden"; // Reservierung wurde verschoben.
};

export const ReservationStates: ReservationState = {
  0: "Requested", // Die Reservierung wurde angefragt und es wird auf Bestätigung gewartet.
  1: "Confirmed", // Die Reservierung wurde bestätigt.
  2: "TableAssigned", // Der Reservierung wurde ein Tisch zugewiesen.
  3: "WalkedInWithReservation", // Die Gäste der Reservierung sind eingetroffen.
  4: "WalkedInWithoutReservation", // Ein Gast ohne Reservierung ist eingetroffen.
  5: "Cancelled", // Die Reservierung wurde abgesagt.
  6: "Left", // Der Gast hat das Restaurant verlassen.
  7: "Hidden", // Reservierung wurde verschoben.
};

export const ReservationStateLocalization: { [key in keyof ReservationState]: string } = {
  0: "Angefragt",
  1: "Bestätigt",
  2: "Tisch zugewiesen",
  3: "Angekommen",
  4: "Ohne Reservierung",
  5: "Abgesagt",
  6: "Gegangen",
  7: "Verschoben",
};

export const ReservationCancelReasons = ["Krank", "Termin", "Persönlich", "Meinung", "Anderes"];
export const ReservationSatisfactionLevels = ["❓", "☹", "🙁", "😐", "🙂", "😄"];

export const createFakeReservation = (reservation: NewReservation, id: string, state: keyof ReservationState) => {
  const fake: Reservation = {
    id: id,
    causeId: EMPTY_GUID,
    createdBy: "",
    modifiedBy: "",
    leaveDateTime: EMPTY_DATETIME,
    leaveNotice: "",
    satisfaction: 5,
    state,
    tables: [],
    listWordDocUrl: [],
    ...reservation,
  };
  return fake;
};
