import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import * as React from "react";
import { AppState } from "../App";
import VeranstaltungSimplified from "../clientApi/models/VeranstaltungSimplified";
import { getSummariesCallBack } from "../controller/ReservierungDataController";
import useIsRendered from "../hooks/useIsRendered";
import useInformationen from "../Informationen/api/useInformationen";
import LimitHint from "../Informationen/components/LimitHint";
import NotizHint from "../Informationen/components/NotizHint";
import DateFormatter from "../Informationen/helper/DateFormatter";
import { AppColors } from "../models/General";
import { mapSummariesToTableObject, Summary, SummaryTable } from "../models/Summary";
import { useVeranstaltungSimpleViewModel } from "../views/VeranstaltungSimple/VeranstaltungSimpleViewModel";
import { DateTimeModule } from "./DateTimeModule";
import { Heroicons } from "./Heroicon/Heroicon";

const now = new Date();

const months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

const years = [...Array(16).fill(undefined)].map((year, index) => index + now.getFullYear() - 5);

const DateTable = ({
  selectedDate,
  appColors,
  getSummary,
  shouldLoadSummary: shouldLoad = true,
  ...props
}: {
  appColors: AppColors;
  getSummary: ({ year, month }: { year: number; month: number }, cb: getSummariesCallBack) => void;
  selectDate: (date: string) => void;
  selectedDate?: string;
  size?: "dense" | "full" | "week";
  shouldNotScroll?: boolean;
  appIsStandalone?: AppState["appIsStandalone"];
  getCreatedSummary?: (summary: SummaryTable | null) => void;
  preCreatedSummary?: SummaryTable;
  shouldLoadSummary?: boolean;
}) => {
  const getSummaryRef = React.useRef(getSummary);
  const parsedSelectedDate: Date | undefined = React.useMemo(
    () => (selectedDate ? new Date(selectedDate) : undefined),
    [selectedDate]
  );
  const [currentDate, setCurrentDate] = React.useState(selectedDate ? new Date(selectedDate) : new Date());
  const datum = React.useMemo(() => DateFormatter.toDatum(currentDate), [currentDate]);
  const VeranstProps = React.useMemo(
    () => ({
      appReservationDate: datum.obj,
    }),
    [datum.obj]
  );
  const { changeDate: changeVeranstDate, ...veranstaltungModel } = useVeranstaltungSimpleViewModel(VeranstProps, true);

  const isRendered = useIsRendered();

  const { informationen } = useInformationen(currentDate);

  React.useEffect(() => {
    if (parsedSelectedDate) {
      console.log(parsedSelectedDate);
      setCurrentDate(parsedSelectedDate);
    }
  }, [parsedSelectedDate]);

  React.useEffect(() => {
    changeVeranstDate(currentDate);
  }, [changeVeranstDate, currentDate]);

  const [year, month] = React.useMemo(() => [datum.obj.getFullYear(), datum.obj.getMonth()], [datum.obj]);

  const mappedVeranstaltungen: Map<string, VeranstaltungSimplified.Client> = React.useMemo(() => {
    if (!veranstaltungModel.veranstaltungen || veranstaltungModel.veranstaltungen.length <= 0) {
      return new Map();
    } else if (veranstaltungModel.veranstaltungen) {
      const isoDate = datum.isoLike.slice(0, 7);
      const filtered = veranstaltungModel.veranstaltungen.filter(({ veranstaltungDatumIso }) => {
        return veranstaltungDatumIso.includes(isoDate);
      });

      return new Map<string, VeranstaltungSimplified.Client>(
        filtered.map((v) => [v.veranstaltungDatumIso, v] as [string, VeranstaltungSimplified.Client])
      );
    } else return new Map();
  }, [datum.isoLike, veranstaltungModel.veranstaltungen]);

  const savedEmptySummaryKey = React.useMemo(
    () => `${year}-${("0" + (+month + 1)).slice(-2)}_BaseTable`,
    [month, year]
  );

  const amountOfDays = React.useMemo(() => {
    const tempDate = new Date(currentDate);
    tempDate.setMonth(tempDate.getMonth() + 1);
    tempDate.setDate(0);
    return tempDate.getDate();
  }, [currentDate]);

  const savedEmptySummary = React.useMemo(() => {
    return window.localStorage.getItem(savedEmptySummaryKey);
  }, [savedEmptySummaryKey]);
  const emptySummary = React.useMemo(() => {
    if (savedEmptySummary) {
      return JSON.parse(savedEmptySummary);
    } else {
      return Array(amountOfDays)
        .fill(null)
        .map((_, day) => {
          return {
            reservationDate: new Date(
              `${year}-${("0" + (+month + 1)).slice(-2)}-${("0" + (+day + 1)).slice(-2)}`
            ).toISOString(),
            reservationTimeSummaries: [{}],
          };
        });
    }
  }, [amountOfDays, month, savedEmptySummary, year]);

  React.useEffect(() => {
    if (emptySummary) {
      window.localStorage.setItem(savedEmptySummaryKey, JSON.stringify(emptySummary));
    }
  }, [emptySummary, savedEmptySummaryKey]);

  const mappedSummaries = React.useMemo(() => mapSummariesToTableObject(emptySummary as Summary[]), [emptySummary]);

  const [summaryObject, setSummaryObject] = React.useState<SummaryTable | null>(
    props.preCreatedSummary
      ? props.preCreatedSummary
      : ({
          ...mappedSummaries,
          reservationTotal: 0,
          guestTotal: 0,
        } as SummaryTable)
  );

  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);

  const selectedDateRef = React.useRef<HTMLDivElement | null>(null);

  const [isPulling, setIsPulling] = React.useState(false);

  const baseTable = React.useMemo(
    () =>
      ({
        ...mappedSummaries,
        reservationTotal: 0,
        guestTotal: 0,
      } as SummaryTable),
    [mappedSummaries]
  );

  const _handleSummaryCallback = React.useCallback(
    (summary: SummaryTable | undefined, respyear: number, respmonth: number, isFresh: boolean) => {
      setSummaryObject((curr) => {
        let sum = curr;
        if (month + 1 === +respmonth && year === respyear) {
          sum = summary || baseTable;
        } else if (curr) {
          sum = curr;
        } else {
          sum = {
            ...baseTable,
            reservationTotal: 0,
            guestTotal: 0,
          } as SummaryTable;
        }
        return sum;
      });
      setIsPulling((c) => {
        console.log("cb pulling", c, isFresh === true ? false : c);
        return isFresh === true ? false : c;
      });
    },
    [baseTable, month, year]
  );

  React.useEffect(() => {
    if (shouldLoad && isRendered) {
      try {
        console.log("start pull");
        setIsPulling(true);
        getSummaryRef.current({ year, month: month + 1 }, _handleSummaryCallback);
      } catch (error) {
        console.log("error stop pull");
        setIsPulling(false);
        console.error(error);
      }
    }
  }, [_handleSummaryCallback, isRendered, month, shouldLoad, year]);

  React.useEffect(() => {
    if (summaryObject && props.getCreatedSummary && props.preCreatedSummary === undefined) {
      props.getCreatedSummary.call(undefined, summaryObject);
    }
  }, [props.getCreatedSummary, props.preCreatedSummary, summaryObject]);

  React.useEffect(() => {
    if (props.preCreatedSummary) {
      setSummaryObject(props.preCreatedSummary);
    }
  }, [props.preCreatedSummary]);

  React.useEffect(() => {
    if (containerRef) {
      disableBodyScroll(containerRef);
      return () => {
        enableBodyScroll(containerRef);
      };
    }
  });

  React.useEffect(() => {
    if (!props.shouldNotScroll && containerRef) {
      const timeout = setTimeout(() => {
        if (selectedDateRef.current)
          containerRef.scrollTo({ top: selectedDateRef.current.offsetTop, behavior: "smooth" });
      }, 300);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [containerRef, props.shouldNotScroll]);

  const _handleDateSelect = React.useCallback(
    (date: string) => {
      return props.selectDate.call(undefined, date);
    },
    [props.selectDate]
  );

  const _keys = React.useMemo(() => {
    const keysToFilter = ["reservationTotal", "guestTotal", "reservationsByState", "reservationsGuestsByState"];
    return summaryObject
      ? (Object.keys(summaryObject) as (keyof SummaryTable)[]).filter((key) => keysToFilter.every((c) => c !== key))
      : [];
  }, [summaryObject]);

  const _renderDateModules = React.useMemo(() => {
    if (summaryObject !== null && summaryObject !== undefined && _keys.length > 0) {
      return _keys.map((dateOfDay, index) => {
        const veranstaltung = mappedVeranstaltungen.get(dateOfDay + "");
        return (
          <div
            key={dateOfDay}
            className={`inline-flex flex-col w-full min-h-0`}
            ref={dateOfDay === selectedDate ? selectedDateRef : undefined}
          >
            <DateTimeModule
              isSelected={dateOfDay === selectedDate}
              onClick={_handleDateSelect}
              summaryTableTime={summaryObject[dateOfDay]}
              dateOfDay={dateOfDay}
              veranstaltung={veranstaltung?.titel}
            >
              <span className="inline-flex w-full min-h-0 gap-1 justify-end items-center px-1 text-xs bg-primary-50 text-primary-500 empty:hidden font-semibold border-b tabular-nums pr-1">
                <LimitHint date={dateOfDay + ""} informationen={informationen} className="">
                  <span className="mr-auto gap-1 inline-flex pl-1">
                    <Heroicons.Outline.UserRemoveIcon className="w-3 aspect-square" />
                    Gäste Limit
                  </span>
                </LimitHint>
              </span>
              <span className="inline-flex w-full min-h-0 gap-1 justify-end items-center px-1 text-xs bg-primary-50 text-primary-500 empty:hidden font-semibold border-b tabular-nums pr-1">
                <NotizHint date={dateOfDay + ""} informationen={informationen} className="">
                  <span className="mr-auto gap-1 inline-flex pl-1">
                    <Heroicons.Outline.DocumentIcon className="w-3 aspect-square" />
                    Notizen
                  </span>
                </NotizHint>
              </span>
            </DateTimeModule>
          </div>
        );
      });
    } else return null;
  }, [summaryObject, _keys, mappedVeranstaltungen, selectedDate, _handleDateSelect, informationen, selectedDateRef]);

  const _handleMonthBack = React.useCallback(
    () =>
      setCurrentDate((currDate) => {
        let temp = new Date(currDate);
        temp.setDate(1);
        temp.setMonth(temp.getMonth() - 1);
        return temp;
      }),
    []
  );
  const _handleMonthForward = React.useCallback(
    () =>
      setCurrentDate((currDate) => {
        let temp = new Date(currDate);
        temp.setDate(1);
        temp.setMonth(temp.getMonth() + 1);
        return temp;
      }),
    []
  );

  const _handleMonthSelect = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    const value = ev.target.value;
    return setCurrentDate((currDate) => {
      let temp = new Date(currDate);
      temp.setDate(1);
      temp.setMonth(+value);
      return temp;
    });
  }, []);

  const _handleYearBack = React.useCallback(
    () =>
      setCurrentDate((currDate) => {
        let temp = new Date(currDate);
        temp.setDate(1);
        temp.setFullYear(temp.getFullYear() - 1);
        return temp;
      }),
    []
  );
  const _handleYearForward = React.useCallback(
    () =>
      setCurrentDate((currDate) => {
        let temp = new Date(currDate);
        temp.setDate(1);
        temp.setFullYear(temp.getFullYear() + 1);
        return temp;
      }),
    []
  );

  const _handleYearSelect = React.useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    const val = ev.target.value;
    return setCurrentDate((currDate) => {
      let temp = new Date(currDate);
      temp.setFullYear(+val);
      return temp;
    });
  }, []);

  const _resetDate = React.useCallback(
    () => props.selectDate.call(undefined, new Date().toISOString()),
    [props.selectDate]
  );

  const _months = React.useMemo(
    () =>
      months.map((mo, index) => (
        <option key={mo} value={index}>
          {mo}
        </option>
      )),
    []
  );

  const _years = React.useMemo(
    () =>
      years.map((ye, index) => (
        <option key={ye} value={ye}>
          {ye}
        </option>
      )),
    []
  );

  return isRendered ? (
    <div className="flex flex-1 flex-col justify-start items-start h-full w-full z-10 ">
      <div className="flex justify-center items-end flex-shrink-0 flex-grow-0 h-10 w-full z-10 px-1">
        <div
          className={`flex justify-between items-center flex-shrink-0 flex-grow-0 h-10 w-full bg-white shadow-md rounded-lg ${
            isPulling ? "animate-pulse" : ""
          }`}
        >
          <div className={`flex flex-1 h-full justify-around items-center text-primary-700 px-3`}>
            <Heroicons.Solid.ChevronLeftIcon className="w-5 h-5 flex-grow-0 flex-shrink-0" onClick={_handleMonthBack} />
            <span className="flex flex-1 justify-center items-center font-semibold leading-none outline-none">
              <select className="font-semibold" value={month} onChange={_handleMonthSelect}>
                {_months}
              </select>
            </span>
            <Heroicons.Solid.ChevronRightIcon
              className="w-5 h-5 flex-grow-0 flex-shrink-0"
              onClick={_handleMonthForward}
            />
          </div>
          <Heroicons.Solid.XIcon
            className={`flex justify-center items-center h-full w-5 flex-shrink-0 flex-grow-0 text-primary-700`}
            onClick={_resetDate}
          />
          <div className={`flex flex-1 h-full justify-around items-center text-primary-700 px-3`}>
            <Heroicons.Solid.ChevronLeftIcon className="w-5 h-5 flex-grow-0 flex-shrink-0" onClick={_handleYearBack} />
            <span className="flex flex-1 justify-center items-center font-semibold leading-none">
              <select className="font-semibold" value={year} onChange={_handleYearSelect}>
                {_years}
              </select>
            </span>
            <Heroicons.Solid.ChevronRightIcon
              className="w-5 h-5 flex-grow-0 flex-shrink-0"
              onClick={_handleYearForward}
            />
          </div>
        </div>
      </div>
      <div
        ref={setContainerRef}
        className={`relative flex flex-1 flex-col w-full pb-20 min-h-0 max-h-full h-full overflow-y-auto overflow-x-hidden py-2 px-2`}
      >
        <div
          className={`inline-grid grid-flow-row justify-start items-start w-full ${
            props.size === "dense"
              ? "grid-cols-1 xxs:grid-cols-1 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3"
              : props.size === "week"
              ? "grid-cols-7"
              : "grid-cols-2 xxs:grid-cols-2 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
          } gap-0.5 rounded-md`}
        >
          {_renderDateModules}
          {props.appIsStandalone ? (
            <div className="w-full h-8 bg-transparent flex flex-grow-0 flex-shrink-0"></div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : null;
};
export default DateTable;
