import * as uuid from "uuid";
import { Cause } from "../models/Cause";
import { ApiCall } from "../models/Controller";
import * as Queue from "../models/Queue";
import { commandProps } from "./Commands";
import { EMPTY_GUID } from "../models/General";

export const getCausesForDateSpan = async (restaurantId: string, startDate: string, endDate: string) => {
  try {
    const handledResponse = await ApiCall<{ causes: Cause[] }>(
      `Causes?restaurantId=${restaurantId}&startDate=${startDate}&endDate=${endDate}`,
      "GET",
      undefined,
      (response: Cause[]) => {
        return { causes: response || [] };
      }
    );
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

export const getCause = async (restaurantId: string, causeId: Cause["id"]) => {
  try {
    const handledResponse = await ApiCall<Cause>(`Causes/${causeId}?restaurantId=${restaurantId}`, "GET");
    return handledResponse;
  } catch (error) {
    throw error;
  }
};

// export const processCause = async (restaurantId: string, requestReservation: boolean, cause: Cause) => {
//   try {
//     const fetchResponse = await adalApiFetch(
//       fetch,
//       `Causes/${cause.id}?restaurantId=${restaurantId}&requestReservation=${requestReservation}`,
//       {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${getToken()}`,
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify(cause)
//       }
//     );
//     let returnJson = undefined;
//     try {
//       returnJson = await fetchResponse.json();
//       return returnJson;
//     } catch (error) {
//       return;
//     }
//   } catch (error) {
//     throw error;
//   }
// };

type processCauseCall = commandProps<Cause, { cause: Cause; requestReservation: boolean }>;

export const processCauseCall = async (props: processCauseCall) =>
  await ApiCall<Cause>(
    `Causes/${props.cause.id}?restaurantId=${props.restaurantId}&requestReservation=${props.requestReservation}`,
    "POST",
    JSON.stringify(props.body),
    undefined,
    { ...props.cause, state: props.requestReservation ? 1 : 2 }
  );

export const processCause = async (restaurantId: string, cause: Cause, requestReservation: boolean) => {
  //Temporary, hard coded fix:
  if (!cause.reservationId || cause.reservationId === "undefined") {
    cause.reservationId = EMPTY_GUID;
  }

  Queue.addToQueue<processCauseCall>({
    guid: uuid.v4(),
    command: "processCause",
    data: { restaurantId, cause: { ...cause, state: requestReservation ? 1 : 2 }, requestReservation, body: cause },
  });
  return { ...cause, state: requestReservation ? 1 : 2 } as Cause;
};

// const checkQueueForCauses = (causes: Cause[]) => {
//   const queue = Queue.getQueue();
//   const queueMessages = queue
//     .filter(message =>
//       causeCommands.some(key => key === message.command) &&
//       message.data &&
//       (message.data as commandProps<any, any>).body.id
//         ? causes.some(caus => (message.data as commandProps<any, any>).body.id === caus.id)
//         : false
//     )
//     .map(res => res.data.cause);
//   return [...causes, ...queueMessages];
// };

export const saveCauseInStorage = (cause: Cause, restaurantId: string) => {
  const timestamp = cause.timestamp.slice(0, 10);
  const location = getCausesStorageLocation({ restaurantId, date: timestamp });
  const causes = window.localStorage.getItem(location);
  let toSave = [];
  if (causes) {
    const parsed = JSON.parse(causes);
    toSave = parsed;
  }
  toSave = [...toSave, cause];
  return window.localStorage.setItem(location, JSON.stringify(toSave));
};

export const saveCausesInStorageForDateSpan = (
  causes: Cause[],
  dateStart: string,
  dateEnd: string,
  restaurantId: string
) => {
  try {
    const newCauses = causes.reduce((a: { [key: string]: Cause[] }, b: Cause) => {
      const date = b.timestamp.split("T")[0];
      return { ...a, [date]: [...(a[date] || []), b] };
    }, {});

    const dates = Array((+new Date(dateEnd) - +new Date(dateStart)) / 864e5 + 1)
      .fill(null)
      .map((_, idx) => new Date(new Date(dateStart).setDate(+new Date(dateStart).getDate() + idx)));

    dates.map((date) => {
      const iso = date.toISOString().slice(0, 10);
      if (newCauses[iso]) {
        saveCausesInStorageForDay({ restaurantId: restaurantId, date: iso, causes: newCauses[iso] });
      }
      return date;
    });

    return true;
  } catch (error) {
    console.error(error);
    throw new Error("Saving Causes failed");
  }
};
const storageKeyBeginning = (restaurantId: string) => `${restaurantId}_getCausesForDate_`;
export const getCausesStorageLocation = ({ restaurantId, date }: { restaurantId: string; date: string }) =>
  `${storageKeyBeginning(restaurantId)}${date}`;

export const removeOldStorages = (restaurantId: string, locationToKeep: string) => {
  const storageKey = storageKeyBeginning(restaurantId);
  const matchingKeys = Object.keys(localStorage).filter((key) => key.startsWith(storageKey));
  for (const key of matchingKeys) {
    if (key !== locationToKeep) {
      localStorage.removeItem(key);
    }
  }
};

export const saveCausesInStorageForDay = ({
  restaurantId,
  date,
  causes,
}: {
  restaurantId: string;
  date: string;
  causes: Cause[];
}) => {
  const storageLocation = getCausesStorageLocation({ restaurantId: restaurantId, date: date });
  window.localStorage.setItem(storageLocation, JSON.stringify(causes));
  removeOldStorages(restaurantId, storageLocation);
  return storageLocation;
};

export const getCausesForDate = (
  { restaurantId, dateStart, dateEnd }: { restaurantId: string; dateStart: string; dateEnd: string },
  cb: any
) => {
  const dates = Array((+new Date(dateEnd) - +new Date(dateStart)) / 864e5 + 1)
    .fill(null)
    .map((_, idx) => new Date(new Date(dateStart).setDate(+new Date(dateStart).getDate() + idx)));

  const getCauses = async () => {
    try {
      const resp = await getCausesForDateSpan(restaurantId, dateStart, dateEnd);
      saveCausesInStorageForDateSpan(resp.causes, dateStart, dateEnd, restaurantId);
      return cb(resp.causes, dateStart, true);
    } catch (error) {
      const message = (error as Error).message ?? "";
      if (error instanceof DOMException && error.name === "AbortError") {
        return;
      } else if (message.includes("403") || message.includes("401")) {
        return;
      } else
        setTimeout(() => {
          getCauses();
        }, 1500);
    }
  };

  const storageLocations = dates.map((causeDate) =>
    getCausesStorageLocation({ restaurantId: restaurantId, date: causeDate.toISOString().slice(0, 10) })
  );

  try {
    if (window && window.localStorage && window.localStorage.getItem) {
      // const causes = window.localStorage.getItem(storageLocation);
      const causes = storageLocations.reduce((a, b) => {
        if (b) {
          const savedLocation = window.localStorage.getItem(b);
          if (savedLocation && savedLocation !== null && savedLocation !== undefined) {
            const savedCausesForDay = JSON.parse(savedLocation);
            return [...a, ...savedCausesForDay];
          } else return a;
        } else return a;
      }, [] as Cause[]);

      if (causes !== null) {
        cb(causes, dateStart, false);
      } else {
        cb([], dateStart, false);
      }
      getCauses();
    }
  } catch (error) {
    if (error instanceof DOMException && error.name === "AbortError") {
      return;
    } else throw error;
  }
};

export type CauseCommands = "processCause";

export const causeCommands = ["processCause"];
