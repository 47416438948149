import * as React from "react";

export const NachrichtenNotiz = (props: { note?: string; otherNotes?: string[]; subTitle?: string }) => {
  return (
    <div className="flex flex-1 flex-col bg-gray-200 shadow-inner rounded-lg p-2 min-h-0 overflow-hidden space-y-2">
      <div className="flex flex-1 flex-col bg-white rounded-lg shadow-md text-sm text-gray-700 text-left p-2 overflow-auto">
        {props.note || ""}
      </div>

      {props.otherNotes ? (
        <>
          <span className="text-xs text-gray-700 font-semibold tracking-wide uppercase">{props.subTitle}</span>
          <div className="inline-flex flex-col w-full min-h-0 flex-1 overflow-auto gap-1">
            {props.otherNotes.map((note, key) => (
              <div
                key={key}
                className="inline-flex flex-col bg-white rounded-lg shadow-md text-sm text-gray-700 text-left p-2 overflow-auto w-full flex-shrink-0"
              >
                {note || ""}
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};
