import { CogIcon, XIcon } from "@heroicons/react/solid";
import { AppColors } from "../../hooks/useTheme";

type UserMenuHeaderProps = {
  color: AppColors;
  onClose: () => any;
};

const UserMenuHeader = (props: UserMenuHeaderProps) => {
  return (
    <header className={`relative flex w-full h-8 shadow-inner items-center bg-primary-100`}>
      <CogIcon
        className={`flex justify-center items-center w-8 h-6 bg-primary-200 rounded-r-lg text-white p-1 mt-1 mb-1 shadow-inner`}
      />
      <span
        className={`flex h-full justify-center items-center text-xs font-semibold uppercase tracking-wider text-primary-500 leading-none pl-2 pb-px`}
      >
        Einstellungen
      </span>
      <span
        className={`absolute flex justify-center items-center right-0 w-8 h-full text-primary-600 p-1 cursor-pointer rounded-full`}
        onClick={props.onClose}
      >
        <XIcon
          className={`flex flex-shrink-0 flex-grow-0 w-6 h-6 bg-primary-100 rounded-full hover:bg-primary-300 hover:text-white p-1  transition-all duration-200 ease-in-out`}
        />
      </span>
    </header>
  );
};
export default UserMenuHeader;
