import * as React from "react";
import { Nachricht } from "../../clientApi/models/Nachricht";
import { Heroicon, Heroicons } from "../../components/Heroicon/Heroicon";
import { RoundButton } from "../../components/RoundButton";
import { Reservation } from "../../models/Reservation";

const checkValidGuid = (id: string | undefined) => id !== "00000000-0000-0000-0000-000000000000" && id !== undefined;

export const NachrichtBubble = React.memo(
  (props: {
    nachricht: Nachricht.Client<any>;
    isLoading: boolean;
    isGettingReservation: boolean;
    isSelected: boolean;
    onAdd: (nachricht: Nachricht.Client<any>) => void;
    onRemove: (nachricht: Nachricht.Client<any>) => void;
    onCancel?: (nachricht: Nachricht.Client<any>) => void;
    onDetailRequest: (res: Reservation) => Promise<any>;
    onOpenNote: (nachricht: Nachricht.Client<any>) => void;
    onSelect: (nachricht: Nachricht.Client<any>) => any;
    onEditNote: (nachricht: Nachricht.Client<any>) => void;
    onOpenEmail: () => any;
    causeTypeToIcon: Map<Nachricht.Type, Heroicon>;
    causeStateToIconAndColor: Map<keyof Nachricht.State, [Heroicon, string]>;
    initialReservation?: Reservation;
    callAmount?: number;
    onSearchFor?: (str: string) => any;
    frozenDate: Date;
  }) => {
    const elementId = React.useId();

    const now = React.useMemo(
      () =>
        props.frozenDate.toLocaleDateString("de-de", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        }),
      [props.frozenDate]
    );

    const causeHref: { [key in Nachricht.Type]: (from: string, subject: string) => string } = React.useMemo(
      () => ({
        Call: (contact) => `tel:${contact}`,
        Email: (contact, subject) => `mailto:${contact}?subject=RE:${subject}`,
        Form: (contact) => ``,
        MissedCall: (contact) => `tel:${contact}`,
        MissedCallMessage: (contact, subject) => `tel:${contact}`,
      }),
      []
    );

    const timestampNachricht = React.useMemo(() => new Date(props.nachricht.timestamp), [props.nachricht.timestamp]);

    const [weekday, date] = React.useMemo(
      () =>
        timestampNachricht
          .toLocaleString("de-de", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            weekday: "short",
          })
          .split(","),
      [timestampNachricht]
    );

    const day = React.useMemo(
      () =>
        now ===
        timestampNachricht.toLocaleString("de-de", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        })
          ? "Heute"
          : null,
      [timestampNachricht, now]
    );

    const time = React.useMemo(
      () =>
        timestampNachricht.toLocaleString("de-de", {
          hour: "2-digit",
          minute: "2-digit",
        }),
      [timestampNachricht]
    );

    const _preventDefault = React.useCallback((cb: (...p: any) => any) => {
      return (ev: React.MouseEvent) => {
        if (ev && "preventDefault" in ev && typeof ev.preventDefault === "function") ev.stopPropagation();
        if (cb && typeof cb === "function") return cb();
        return;
      };
    }, []);

    const [reservation, setReservation] = React.useState<Reservation | undefined>(props.initialReservation);

    const _handleContactRequest = React.useCallback(() => {
      if (props.isSelected) {
        window.location.href = causeHref[props.nachricht.type](props.nachricht.from, props.nachricht.subject);
      }
    }, [causeHref, props.isSelected, props.nachricht.from, props.nachricht.subject, props.nachricht.type]);

    React.useEffect(() => {
      setReservation((c) => {
        if (!c && props.initialReservation) return props.initialReservation;
        else if (c && props.initialReservation) {
          if (c.modifiedBy !== props.initialReservation.modifiedBy) return props.initialReservation;
          if (c.note !== props.initialReservation.note) return props.initialReservation;
          if (c.dateOfArrival !== props.initialReservation?.dateOfArrival) return props.initialReservation;
        }
        return c;
      });
    }, [props.initialReservation]);

    const resDate = React.useMemo(
      () =>
        reservation
          ? new Date(reservation.dateOfArrival).toLocaleDateString("de-de", {
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })
          : null,
      [reservation]
    );

    const dateToShow = React.useMemo(() => (resDate ? resDate.slice(0, 6) : null), [resDate]);

    const timeToShow = React.useMemo(() => (resDate ? resDate.slice(-5) : null), [resDate]);

    const guests = React.useMemo(
      () =>
        reservation
          ? `${reservation.guestAmount.adultAmount}${
              reservation.guestAmount.childAmount ? `+${reservation.guestAmount.childAmount}` : ""
            }`
          : null,
      [reservation]
    );

    const selectBubble = React.useCallback(() => {
      props.onSelect.call(undefined, props.nachricht);
    }, [props.nachricht, props.onSelect]);

    const [TypeIconSolid, TypeIconOutline] = React.useMemo(() => {
      const type = props.causeTypeToIcon.get(props.nachricht.type) || "QuestionMarkCircleIcon";
      const solid = Heroicons.Solid[type];
      const outline = Heroicons.Outline[type];
      return [solid, outline];
    }, [props.causeTypeToIcon, props.nachricht.type]);

    const [StateIconName, stateColor] = React.useMemo(
      () =>
        props.causeStateToIconAndColor.get(props.nachricht.state) ||
        (["QuestionMarkCircleIcon", "red-500"] as [Heroicon, string]),
      [props.causeStateToIconAndColor, props.nachricht.state]
    );

    const StateIcon = React.useMemo(() => Heroicons.Outline[StateIconName], [StateIconName]);

    const title = React.useMemo(() => {
      if (props.nachricht.type === "Call" || props.nachricht.type === "MissedCall") {
        return props.nachricht.from;
      } else if (props.nachricht.type === "MissedCallMessage") {
        return `AB Nachricht    ${props.nachricht.from}`;
      } else return props.nachricht.subject;
    }, [props.nachricht.from, props.nachricht.subject, props.nachricht.type]);

    const stateLocalization: Nachricht.StateLocalization = React.useMemo(
      () => ({
        0: "Neu",
        1: "Erstellt",
        2: "Nichts",
        3: "Abgelehnt",
        4: "Notiz",
      }),
      []
    );

    const _handleAddClick = React.useCallback(() => {
      if (props.isSelected) {
        props.onAdd(props.nachricht);
      } else return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onAdd, props.isSelected, props.nachricht]);

    const _handleRemoveClick = React.useCallback(
      () => (props.isSelected ? props.onRemove.call(undefined, props.nachricht) : null),
      [props.onRemove, props.isSelected, props.nachricht]
    );

    const _handleCancelClick = React.useCallback(
      () => (props.isSelected && props.onCancel ? props.onCancel.call(undefined, props.nachricht) : null),
      [props.isSelected, props.onCancel, props.nachricht]
    );

    const _handleModifyClick = React.useCallback(
      () => (props.isSelected && reservation ? props.onDetailRequest.call(undefined, reservation) : null),
      [props.onDetailRequest, props.isSelected, reservation]
    );

    const _handleOpenNote = React.useCallback(
      () => props.isSelected && props.onOpenNote.call(undefined, props.nachricht),
      [props.isSelected, props.nachricht, props.onOpenNote]
    );

    const _handleEditNote = React.useCallback(
      () => props.isSelected && props.onEditNote.call(undefined, props.nachricht),
      [props.isSelected, props.nachricht, props.onEditNote]
    );

    const onSearchForPhoneNumber = React.useCallback(
      (nr: string) => () => {
        if (props.onSearchFor) {
          props.onSearchFor.call(undefined, nr);
        }
      },
      [props.onSearchFor]
    );

    const modifiedDate = React.useMemo(
      () =>
        props.nachricht.modified
          ? props.nachricht.modified.toLocaleDateString("de-de", {
              day: "2-digit",
              month: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })
          : "--",
      [props.nachricht.modified]
    );

    return (
      <label
        htmlFor={elementId}
        className={`isolate inline-flex justify-start items-center content-start w-full outline-none min-h-0 flex-shrink-0 max-h-full snap-start cursor-pointer`}
        tabIndex={0}
      >
        <div
          className={`relative inline-flex justify-start items-center content-start w-full ${
            props.isSelected ? `bg-primary-100` : "bg-white"
          } rounded-lg shadow-md overflow-hidden h-full pr-1 top-0 right-0 transition-all duration-150 ease-in-out`}
        >
          {props.nachricht.modifiedBy ? (
            <div
              className={`absolute bottom-0 right-0 inline-flex justify-start items-center mr-2 z-10 gap-x-1 text-gray-500 mb-1`}
            >
              <span className={`text-xs`}>{modifiedDate}</span>
              <Heroicons.Outline.PencilIcon className="w-3 h-3" />
              <span className={`text-xs`}>{props.nachricht.modifiedBy}</span>
            </div>
          ) : null}

          <div
            className={`relative inline-flex flex-grow-0 flex-col flex-shrink-0 justify-center items-center bg-${stateColor} w-10 h-full content-start`}
          >
            <span className={`w-5 h-5 flex-shrink-0 flex-grow-0 text-primary-100`}>
              {props.isLoading ? (
                <Heroicons.Outline.RefreshIcon className="w-full h-full animate-spin" />
              ) : (
                <StateIcon className={`w-full h-full text-primary-100`} />
              )}
            </span>
            <span className={`text-primary-100 text-xxs`}>{stateLocalization[props.nachricht.state]}</span>
          </div>

          <div className="absolute flex justify-end items-center h-full w-full">
            <TypeIconOutline
              className={` w-18 h-18 flex-shrink-0 flex-grow-0 ${
                props.isSelected ? "text-white" : "text-primary-100"
              } transform rotate rotate-6`}
            />
          </div>

          <div
            className={`relative flex flex-3 flex-col justify-start items-center w-full h-full px-3 pr-12 py-1 overflow-hidden z-10 transition-all duration-150 ease-in-out space-y-2 pt-2`}
          >
            <div
              className={`inline-flex min-h-0 justify-start items-center w-full text-base text-primary-800`}
              title={props.nachricht.from}
            >
              <span
                className={`inline-flex justify-start items-center ${
                  props.callAmount && props.callAmount > 1
                    ? `text-primary-700 bg-primary-50 px-2 pr-2 -ml-2 py-px rounded tabular-nums `
                    : `text-primary-700`
                } space-x-1 pointer-events-auto`}
              >
                <TypeIconOutline className={`w-4 h-4 flex-shrink-0 flex-grow-0 -ml-1`} />
                {props.callAmount && props.callAmount > 1 ? (
                  <span className="text-xs tracking-wide">
                    {props.callAmount}x (...{props.nachricht.from.slice(-4)})
                  </span>
                ) : null}
              </span>

              <span className="pl-1 truncate flex-grow-0 leading-tight font-semibold pr-1">{title}</span>
              {reservation && props.nachricht.state > 0 ? (
                <div className="inline-flex justify-center items-center flex-grow-0">
                  <Heroicons.Outline.UsersIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                  <span className="text-xs pr-1 pl-1 truncate flex-grow-0 leading-tight whitespace-no-wrap">
                    {reservation.name}
                  </span>
                </div>
              ) : null}
            </div>
            <div
              className={`inline-flex min-h-0 justify-start items-center w-full text-primary-600 ${
                props.nachricht.modifiedBy ? "pb-4" : "pb-1"
              }`}
            >
              <div className="flex flex-col w-full h-full -ml-1 space-y-2">
                <div className="flex flex-1 w-full justify-start items-center space-x-3">
                  {day ? (
                    <div className="inline-flex justify-center items-center">
                      <Heroicons.Outline.CalendarIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                      <span className="text-xs pr-1 pl-1 leading-tight">{day}</span>
                    </div>
                  ) : (
                    <div className="inline-flex justify-center items-center">
                      <Heroicons.Outline.CalendarIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                      <span className="text-xs pr-1 pl-1 leading-tight">{weekday}</span>
                      <span className="text-xs leading-tight">{date}</span>
                    </div>
                  )}
                  <div className="inline-flex justify-center items-center">
                    <Heroicons.Outline.ClockIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                    <span className="text-xs pr-1 pl-1 font-semibold leading-tight">{time}</span>
                  </div>
                </div>
                {props.nachricht.state >= 2 && props.nachricht.note ? (
                  <div className={`flex flex-1 w-full justify-start items-center overflow-hidden`}>
                    <div className="inline-flex justify-center items-center truncate">
                      <Heroicons.Outline.ClipboardIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                      <span className="text-xs pr-1 pl-1 leading-tight truncate">{props.nachricht.note}</span>
                    </div>
                  </div>
                ) : null}
                {props.nachricht.state > 0 && props.nachricht.state !== 2 && reservation ? (
                  <div
                    className={`flex flex-1 w-full justify-start items-center space-x-1 ${
                      props.isGettingReservation ? `bg-primary-100 rounded-lg px-1 -ml-1` : ""
                    } flex-shrink-0`}
                  >
                    <div
                      className={`w-3 h-3 ${
                        reservation || props.isGettingReservation ? "opacity-100" : "opacity-0"
                      } transition-all duration-150 ease-in-out flex-shrink-0`}
                    >
                      {props.isGettingReservation ? (
                        <Heroicons.Outline.RefreshIcon className="w-3 h-3 flex-shrink-0 animate-spin" />
                      ) : (
                        <Heroicons.Outline.BookmarkIcon className="w-3 h-3" />
                      )}
                    </div>
                    <div className="inline-flex justify-center items-center">
                      <Heroicons.Outline.CalendarIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                      <span className="text-xs pr-1 pl-1 leading-tight whitespace-no-wrap">{dateToShow}</span>
                    </div>
                    <div className="inline-flex justify-center items-center">
                      <Heroicons.Outline.ClockIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                      <span className="text-xs pr-1 pl-1 leading-tight">{timeToShow}</span>
                    </div>
                    <div className="inline-flex justify-center items-center">
                      <Heroicons.Outline.UsersIcon className="w-4 h-3 flex-shrink-0 flex-grow-0" />
                      <span className="text-xs pr-1 pl-1 leading-tight">{guests}</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={`absolute inline-flex flex-2 flex-shrink-0 justify-end items-center h-full min-h-0 min-w-0 px-1 z-10 right-0 top-0 gap-x-3 pr-2 cursor-pointer group overflow-hidden`}
          >
            {props.isSelected ? (
              <>
                {props.callAmount && props.callAmount > 1 ? (
                  <RoundButton
                    key="callAmount_btn"
                    identifier="callAmount_btn"
                    onClick={_preventDefault(onSearchForPhoneNumber(props.nachricht.from))}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                    className=""
                  >
                    <Heroicons.Solid.PhoneIncomingIcon className={`w-full h-full text-primary-100`} />
                    <Heroicons.Solid.SearchIcon className={`w-full h-full text-primary-100`} />
                  </RoundButton>
                ) : null}

                {props.nachricht.type !== "Form" &&
                props.nachricht.type !== "MissedCallMessage" &&
                props.nachricht.type !== "MissedCall" ? (
                  <RoundButton
                    key="type_btn"
                    identifier="type_btn"
                    onClick={_handleContactRequest}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <TypeIconSolid className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}

                {props.nachricht.type === "MissedCallMessage" || props.nachricht.type === "MissedCall" ? (
                  <RoundButton
                    key="Call_btn"
                    identifier="Call_btn"
                    onClick={_handleContactRequest}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.PhoneIcon className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}

                {props.nachricht.body ? (
                  <RoundButton
                    key="body_btn"
                    identifier="body_btn"
                    onClick={props.onOpenEmail}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.DocumentSearchIcon
                      className={`w-full min-h-0 aspect-square p-px text-primary-50`}
                    />
                  </RoundButton>
                ) : null}
                {props.nachricht.state === 2 || props.nachricht.state === 0 || props.nachricht.state === 4 ? (
                  <RoundButton
                    key="state_btn"
                    identifier="state_btn"
                    onClick={_handleAddClick}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.PlusIcon className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}

                {props.nachricht.state === 0 || props.nachricht.state === 4 ? (
                  <RoundButton
                    key="comment_btn"
                    identifier="comment_btn"
                    onClick={_handleEditNote}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.PencilIcon className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}

                {props.nachricht.state <= 0 || props.nachricht.state === 4 ? (
                  <RoundButton
                    key="reject_btn"
                    identifier="reject_btn"
                    onClick={_handleRemoveClick}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.XIcon className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}
                {props.nachricht.state <= 0 && props.onCancel ? (
                  <RoundButton
                    key="cancel_btn"
                    identifier="cancel_btn"
                    onClick={_handleCancelClick}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.BanIcon className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}
                {reservation && props.nachricht.state > 0 ? (
                  <RoundButton
                    key="res_btn"
                    identifier="res_btn"
                    onClick={_handleModifyClick}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.BookmarkIcon className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}
                {props.nachricht.state > 0 && props.nachricht.note ? (
                  <RoundButton
                    key="note_btn"
                    identifier="note_btn"
                    onClick={_handleOpenNote}
                    isVisible
                    withEvent
                    disabled={!props.isSelected}
                  >
                    <Heroicons.Solid.ClipboardIcon className={`w-full min-h-0 aspect-square p-px text-primary-50`} />
                  </RoundButton>
                ) : null}
              </>
            ) : null}

            {props.isSelected ? null : (
              <button
                className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-8 h-8 aspect-square text-primary-400 group-hover:text-primary-600 p-1 group-hover:bg-primary-200 rounded-full transition-colors ease-in-out duration-150`}
                key="menu_btn"
                onClick={selectBubble}
                id={elementId}
              >
                <Heroicons.Outline.DotsHorizontalIcon className="w-full h-full" />
              </button>
            )}
          </div>
        </div>
      </label>
    );
  }
);
