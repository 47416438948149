import { useEffect, useLayoutEffect, useRef } from "react";
import { NavigateOptions, To, useNavigate } from "react-router-dom";

type DelayedNavigateProps = NavigateOptions & {
  to: To;
  timeoutInMs?: number;
};

function DelayedNavigate({ timeoutInMs = 250, to, ...options }: DelayedNavigateProps) {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (navigateRef.current) {
        navigateRef.current(to, options);
      }
    }, timeoutInMs);
    return () => {
      if (timeout) clearTimeout(timeoutInMs);
    };
  }, [options, timeoutInMs, to]);

  return null;
}
export default DelayedNavigate;
