import * as React from "react";

export const TableImage: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  size?: number;
  alt?: string;
  shadow?: boolean;
  onClick?: () => void;
  backgroundSize?: "cover" | "contain";
  url?: string | null;
  children?: React.ReactNode | React.ReactNode[];
}> = (props) => {
  return (
    <div
      className={`relative inline-flex ${props.size ? `h-${props.size}` : "h-12"} ${
        props.size ? `w-${props.size}` : "w-12"
      }`}
    >
      <img
        alt="userimg"
        src={props.url || ""}
        className={`absolute inset-0 w-full h-full rounded-full ${
          props.shadow || props.shadow === undefined ? "shadow" : ""
        } ${props.className} object-contain z-0 ${
          props.url ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 ease-in`}
        onClick={props.onClick}
      />
      <div className="inline-flex w-full h-full">{props.children}</div>
    </div>
  );
};
