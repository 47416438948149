import React, { ReactNode, useCallback } from "react";
import { Routes, useNavigate } from "react-router-dom";
import { useEventListener } from "../hooks/useEventlistener";
const style = { flexBasis: "100%" };

export const AppRouteSwitch: React.FC<{ children?: ReactNode | ReactNode[] }> = (props) => {
  const navigate = useNavigate();
  const redirectTo = useCallback(
    (ev: CustomEvent<string>) => {
      const { detail } = ev;
      navigate(detail, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );
  useEventListener("redirectMe", redirectTo);

  return (
    <div
      className="flex flex-col flex-1 flex-grow-0 flex-shrink-0 h-full max-h-full min-h-0 w-full overflow-hidden -mt-10 pt-10"
      style={style}
    >
      <Routes>{props.children}</Routes>
    </div>
  );
};
