import { useCallback, useEffect, useMemo, useState } from "react";
import VeranstaltungSimplified from "../../clientApi/models/VeranstaltungSimplified";
import { useEventListener } from "../../hooks/useEventlistener";
import ServerApi from "../../serverApi/ServerApi";
import Tools from "../../Tools";
import { clientApi } from "../Authenticated/AuthenticatedView";
import VeranstaltungSimpleViewTypes from "./VeranstaltungSimpleViewTypes";

export const useVeranstaltungSimpleViewModel = (
  props: Pick<VeranstaltungSimpleViewTypes.Props, "appReservationDate">,
  doNotPull?: boolean,
  doNotLoad?: boolean
) => {
  const [dateString, setDateString] = useState<string>(Tools.dateToIsoLike(props.appReservationDate));

  const [titel, setTitel] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPulling, setIsPulling] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const [veranstaltungen, setVeranstaltungen] = useState<VeranstaltungSimplified.Client[]>([]);
  const currentVeranstaltung = useMemo(
    () => veranstaltungen.find((c) => c.veranstaltungDatumIso === dateString),
    [dateString, veranstaltungen]
  );

  const changeDate = useCallback((date: string | Date) => {
    if (typeof date === "string") {
      setDateString(date);
    } else setDateString(Tools.dateToIsoLike(date));
  }, []);

  const pullVeranstaltungen = useCallback(async (date: Date) => {
    setIsPulling(true);
    try {
      console.log("pulling veranstaltungen");
      const resp = await clientApi.Queries.getVeranstaltungen(date, true);
      console.log("pulling veranstaltungen done", resp);
      setVeranstaltungen(resp);
    } catch (error) {
      return null;
    } finally {
      setIsPulling(false);
    }
  }, []);

  const updateVeranstaltungen = useCallback(async () => {
    console.log("test");
    setIsLoading(true);
    try {
      console.log("updating veranstaltungen");
      const resp = await clientApi.Queries.getVeranstaltungen(new Date(dateString), false);
      console.log("updating veranstaltungen done", resp);
      setVeranstaltungen(resp);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [dateString]);

  const loadVeranstaltungen = useCallback(async (date: Date) => {
    setIsLoading(true);
    try {
      console.log("loading veranstaltungen");
      const resp = await clientApi.Queries.getVeranstaltungen(date, false);
      console.log("loading veranstaltungen done", resp);
      setVeranstaltungen(resp);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const createVeranstaltungOnDay = useCallback(async () => {
    try {
      if (titel) {
        setIsCreating(true);
        const resp = await ServerApi.addVeranstaltung({ titel, veranstaltungDatum: new Date(dateString) });
        setTitel(null);
        loadVeranstaltungen(new Date(dateString));
        await pullVeranstaltungen(new Date(dateString));
      }
    } catch (error) {
      throw error;
    } finally {
      setIsCreating(false);
    }
  }, [dateString, loadVeranstaltungen, pullVeranstaltungen, titel]);

  const removeCurrentVeranstaltung = useCallback(async () => {
    try {
      if (currentVeranstaltung) {
        setIsRemoving(true);
        const resp = await ServerApi.removeVeranstaltung(currentVeranstaltung);
        setTitel(null);
        loadVeranstaltungen(new Date(dateString));
        await pullVeranstaltungen(new Date(dateString));
      }
    } catch (error) {
      throw error;
    } finally {
      setIsRemoving(false);
    }
  }, [currentVeranstaltung, dateString, loadVeranstaltungen, pullVeranstaltungen]);

  useEffect(() => {
    if (!doNotPull) {
      pullVeranstaltungen(new Date(dateString));
    } else if (!doNotLoad) {
      loadVeranstaltungen(new Date(dateString));
    } else {
    }
  }, [dateString, doNotLoad, doNotPull, loadVeranstaltungen, pullVeranstaltungen]);

  useEventListener(clientApi.Events.VERANSTALTUNGEN_UPDATED, doNotLoad ? () => null : updateVeranstaltungen);

  return {
    dateString,
    setDateString,
    changeDate,
    veranstaltungen,
    createVeranstaltungOnDay,
    isLoading,
    isPulling,
    isCreating,
    isRemoving,
    titel,
    setTitel,
    currentVeranstaltung,
    removeCurrentVeranstaltung,
  };
};
