import { useDebounce } from "./useDebounce";
import { useState, useEffect, useCallback, useRef } from "react";

type ExtractKeysOfType<T, K extends keyof T, P> = Extract<K, T[K] extends P ? K : never>[];

export const useFilterByTerm = <T, K extends keyof T>(
  data: T[],
  keys: ExtractKeysOfType<T, K, string | object | undefined | null>,
  delay?: number
) => {
  const filterKeys = useRef(keys);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const debouncedTerm = useDebounce(searchTerm, delay ?? 200);
  const [filteredData, setFilteredData] = useState<T[]>(data);

  const _filterData = useCallback(
    (item: T) => {
      return filterKeys.current.some((key) => {
        if (item && item[key] !== undefined && item[key] !== null) {
          if (typeof item[key] === "string") {
            return (item[key] + "").toLocaleLowerCase().includes((debouncedTerm || "").toLocaleLowerCase());
          } else if (typeof item[key] === "object") {
            let term: string | null = null;
            const itemAsObject = item[key] as any as object;
            if ("toLocaleDateString" in itemAsObject && "toISOString" in itemAsObject) {
              try {
                term = (itemAsObject as any as Date).toLocaleDateString();
              } catch (error) {
                return false;
              }
            } else if ("name" in itemAsObject && typeof itemAsObject === "string") {
              try {
                term = (itemAsObject as any as { name: string }).name;
              } catch (error) {
                return false;
              }
            }
            if (term === null) return false;
            else {
              return term.toLocaleLowerCase().includes((debouncedTerm || "").toLocaleLowerCase());
            }
          } else return false;
        } else return false;
      });
    },
    [debouncedTerm]
  );

  const resetSearch = useCallback(() => {
    setSearchTerm(null);
  }, []);

  useEffect(() => {
    setFilteredData(() => {
      return data.filter(_filterData);
    });
  }, [_filterData, data]);

  useEffect(() => {
    filterKeys.current = keys;
  }, [keys]);

  return { searchTerm, setSearchTerm, filteredData, resetSearch };
};
