import { commands } from "../controller/Commands";
import { useState, useCallback } from "react";
import { useLocalStorage } from "@rehooks/local-storage";

export type QueueMessage<C> = {
  guid: string;
  command: string;
  data: C;
};

const queueStoreName = "MKGASTRO_QUEUE";

export function useQueue() {
  const [queue, setQueue, deleteQueue] = useLocalStorage<QueueMessage<any>[]>(queueStoreName, []);

  const addToQueue = useCallback(
    <T>(message: QueueMessage<T>) => {
      setQueue([...queue, message]);
    },
    [queue, setQueue]
  );

  const removeFromQueue = useCallback(
    (id: string) => {
      setQueue(queue.filter((msg) => msg.guid !== id));
    },
    [queue, setQueue]
  );
}

export const getQueue = () => {
  const queue = window.localStorage.getItem(queueStoreName);
  if (queue) {
    return JSON.parse(queue) as QueueMessage<any>[];
  } else {
    return initialiseQueue();
  }
};

export const callback: { cb: ((id: string) => void) | undefined } = {
  cb: undefined,
};

export const setQueue = (newQueue: QueueMessage<any>[]) => {
  return window.localStorage.setItem(queueStoreName, JSON.stringify(newQueue));
};

export const initialiseQueue = () => {
  const newQueue: QueueMessage<any>[] = [];
  window.localStorage.setItem(queueStoreName, JSON.stringify(newQueue));
  return newQueue;
};

export const addToQueue: <T>(message: QueueMessage<T>) => void = (message) => {
  const cachedQueue = window.localStorage.getItem(queueStoreName) || "[]";
  const queue = [...(JSON.parse(cachedQueue) as QueueMessage<any>[]), message];
  window.localStorage.setItem(queueStoreName, JSON.stringify(queue));
  callback.cb!(queue[0].guid);
};

export const removeFromQueue = (idToFilter: string) => {
  const newQueue = getQueue().filter((item) => item.guid !== idToFilter);
  return setQueue(newQueue);
};

export const popQueue = () => {
  const queue = getQueue();
  const newQueue = [...queue];
  newQueue.pop();
  return setQueue(newQueue);
};

export const modifyInQueue = (idToModify: string, value: QueueMessage<any>) => {
  const newQueue = getQueue().map((item) => (item.guid === idToModify ? { ...item, ...value } : item));
  return setQueue(newQueue);
};

export const getMessage = (id: string) => getQueue().find((item) => item.guid === id);

export const handledProcessCommand: { [key: string]: ((resp: any) => any) | undefined } = {};

export const processcommand = async (id: string, isRepetition?: boolean, isWaiting?: boolean) => {
  try {
    if (!queueProcessing || (isRepetition && !isWaiting)) {
      queueProcessing = true;
      const lastCall = getMessage(id);
      if (lastCall) {
        const resp = await (commands as any)[lastCall.command]()(lastCall.data);
        if (handledProcessCommand !== undefined && handledProcessCommand[lastCall.command] !== undefined) {
          handledProcessCommand[lastCall.command]!(resp);
        }
        queueProcessing = false;
        removeFromQueue(lastCall.guid);
        const queue = getQueue();
        if (queue.length > 0) {
          processcommand(queue[0].guid);
        }
      } else {
        queueProcessing = false;
        return;
      }
    } else {
      isWaiting = true;
      throw new Error("Queue processing");
    }
  } catch (error) {
    if (error instanceof Error && error.message.startsWith("500")) {
      const call = getMessage(id);
      if (call) {
        const shouldRemove = window.confirm(
          `${call.command} - Processing failed - 500 Internal Server Error - Remove from Queue and write to Protocol?`
        );
        if (shouldRemove) {
          removeFromQueue(call.guid);
          const queue = getQueue();
          if (queue.length > 0) {
            processcommand(queue[0].guid);
          }
          return;
        }
      }
    }
    setTimeout(() => {
      isRepetition = true;
      if (isRepetition && !isWaiting) {
        processcommand(id, true, false);
      } else {
        processcommand(id, true, true);
      }
    }, 1500);
  }
};

let queueProcessing = false;

callback.cb = processcommand;
(() => (getQueue()[0] ? processcommand(getQueue()[0].guid) : null))();

// getQueue().forEach(async ({ guid }) => {
//   try {
//     await processcommand(guid);
//     return;
//   } catch (error) {
//     return;
//   }
// });

// Daten aus Cache holen, gleichzeitig senden an Backend
// Wenn Daten in Queue, diese Daten Überschreiben
// Queue muss persistent sein => Localstorage

// Command in der Queue sendet ID mit ans Backend
// wenn id bearbeitet wurde, sendet das Backend das Ok zurück
// wie höre ich darauf, dass etwas pending ist?

// Function auf dass das Frontend "subscriben" kann

// 01772420850
// ph.jander@gmx.de
// info@jander.it
// jander@janso.de
