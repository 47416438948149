import * as React from "react";
import { AppColors } from "../models/General";

type ViewHeaderProps = {
  appColors: AppColors;
  children?: React.ReactNode | React.ReactNode[];
};

export const ViewHeader: React.FC<ViewHeaderProps> = (props) => {
  return (
    <h3
      className={`flex h-10 flex-shrink-0 flex-grow-0 w-full justify-between items-center text-md text-primary-100 pr-3 bg-primary-500 z-10 shadow-md`}
    >
      {props.children}
    </h3>
  );
};
