import { useCallback, useState } from "react";
import { addMessage } from "../components/ToasterHandler/useToasterHandler";
import { addProtocol } from "./useProtocol";

const maxTriesIndexedDBDeletion = 5;
const wait = (sleep: number = 500) => new Promise((r) => setTimeout(r, sleep));

const useEmergencyBrake = () => {
  const [isBraking, setIsBraking] = useState<boolean>(false);

  const clearIndexedDB: any = useCallback(async (tries: number = 0) => {
    try {
      if (tries > 0) await wait();
      const idb = window.indexedDB;
      if (idb && "databases" in idb && typeof (idb as any).databases === "function") {
        const databases: IDBObjectStore[] = await (idb as any).databases();
        await Promise.all(databases.map(({ name }) => idb.deleteDatabase(name)));
      }
    } catch (error) {
      addMessage({
        text: `IDB konnte nicht ordentlich gelöscht werden, es wird nun mehrmals versucht`,
        title: "Löschung DB",
        color: "error",
        icon: "ExclamationCircleIcon",
      });
      if (tries < maxTriesIndexedDBDeletion) return clearIndexedDB(tries + 1);
      addMessage({
        text: `IDB konnte nicht ordentlich gelöscht werden nach ${maxTriesIndexedDBDeletion} Versuchen`,
        title: "Löschung DB",
        color: "error",
        icon: "ExclamationCircleIcon",
      });
      return null;
    }
  }, []);

  const properClearIndexedDB = useCallback(async () => {
    try {
      const idb = window.indexedDB;
      if (idb && "databases" in idb && typeof (idb as any).databases === "function") {
        const databases: IDBObjectStore[] = await (idb as any).databases();
        for (const dbStore of databases) {
          try {
            const request = idb.open(dbStore.name);
            await new Promise<void>((resolve, reject) => {
              try {
                setTimeout(() => {
                  reject(dbStore.name + " has timed out");
                }, 1500);
                request.addEventListener("blocked", (ev) => {
                  console.log(`${dbStore.name} blockiert`);
                  const db = request.result;
                  console.log(`Versucht ${dbStore.name} zu schließen`);
                  db.close();
                  throw new Error(db.name + " is blocked");
                });
                request.addEventListener("error", (ev) => {
                  console.log(`${dbStore.name} fehler`);
                  const db = request.result;
                  throw new Error(db.name + " encountered an error");
                });
                request.addEventListener("success", (ev) => {
                  console.log(`Versucht ${dbStore.name} zu schließen`);
                  const db = request.result;
                  db.close();
                  resolve();
                  console.log(`${dbStore.name} geschlossen`);
                });
              } catch (error) {
                reject(error);
              }
            });
          } catch (error) {
            addProtocol({
              data: (error as any).message,
              desc: "EmergencyBrake - Fehler",
              type: "USER",
              date: new Date(),
            });
            console.error(error);
          }
        }
        return clearIndexedDB();
      }
    } catch (error) {
      throw error;
    }
  }, [clearIndexedDB]);

  const clearStorages = useCallback(async () => {
    try {
      if (localStorage) localStorage.clear();
      if (sessionStorage) sessionStorage.clear();
      return null;
    } catch (error) {
      return null;
    }
  }, []);

  const clearCache = useCallback(async () => {
    try {
      if (caches && "keys" in caches && typeof caches.keys === "function") {
        const keys = await caches.keys();
        await Promise.allSettled(keys.map(caches.delete));
      } else return null;
    } catch (error) {
      return null;
    }
  }, []);

  const unregisterServiceWorker = useCallback(async () => {
    try {
      if (navigator && "serviceWorke" in navigator) {
        const swReg = await navigator.serviceWorker.getRegistration();
        if (swReg && "unregister" in swReg && typeof swReg.unregister === "function") {
          return await swReg.unregister();
        }
        return null;
      } else return null;
    } catch (error) {
      return null;
    }
  }, []);

  const brake = useCallback(async () => {
    setIsBraking(true);
    try {
      await clearStorages();
      await properClearIndexedDB();
      await clearCache();
      await unregisterServiceWorker();
      setTimeout(() => {
        window.location.replace(window.location.origin);
      }, 1);
    } catch (error) {
      console.error(error);
    }
  }, [clearCache, clearStorages, properClearIndexedDB, unregisterServiceWorker]);

  return [brake, isBraking] as [typeof brake, typeof isBraking];
};
export default useEmergencyBrake;
