export const ErrorAppInsights = (error: any) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-gray-100">
      <h1 className="text-red-500 text-2xl h-12 border-b-2 border-red-800">AppInsights encountered an Error</h1>
      <code className="my-2 whitespace-pre-wrap">{JSON.stringify(error, null, 2)}</code>
      <button className="px-3 py-1 text-white bg-red-500 tracking-wide mt-2" onClick={() => window.location.reload()}>
        Reload
      </button>
    </div>
  );
};
