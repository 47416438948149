import { NavLink } from "react-router-dom";
import { Heroicons } from "../../../components/Heroicon/Heroicon";
import Tools from "../../../Tools";
import { useAbspracheOverviewViewModel } from "./AbspracheOverviewViewModel";
import AbspracheOverviewViewTypes from "./AbspracheOverviewViewTypes";

const AbspracheOverviewView = (props: AbspracheOverviewViewTypes.Props) => {
  const viewModel = useAbspracheOverviewViewModel(props);
  return (
    <>
      <header className="inline-flex flex-col justify-center items-start px-3 max-w-screen-sm w-full mx-auto my-6 mb-8 gap-1">
        <span className="text-xl font-black tracking-wider">Absprachen</span>
        <span className="text-xs">Über die Datumsauswahl zu einem Datum springen</span>
      </header>
      <ol className="inline-flex flex-col divide-y justify-start items-start content-start w-full min-h-0">
        {viewModel.absprachen
          .sort((a, b) => +b.tag - +a.tag)
          .map(({ id, titel, tag, attachments }) => (
            <NavLink
              key={id}
              to={`/Absprache/${Tools.dateToIsoLike(tag)}`}
              className="inline-flex w-full px-3 text-xs font-semibold tracking-wide bg-white py-3 hover:underline"
            >
              <span>{titel}</span>
              <span className="text-xs ml-4 font-normal text-gray-500 rounded-lg">
                {attachments.length || ""}{" "}
                {attachments.length ? (attachments.length > 1 ? `Einträge` : "Eintrag") : "Keine Einträge"}
              </span>
              <Heroicons.Outline.ChevronRightIcon className="w-4 h-4 ml-auto mr-3 text-blue-500" />
            </NavLink>
          ))}
      </ol>
    </>
  );
};
export default AbspracheOverviewView;
