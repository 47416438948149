import { useState, useCallback, useEffect } from "react";
import { useEventListener } from "./useEventlistener";
import { ProtocolEntry } from "../models/ProtocolEntry";
import * as uuid from "uuid";
import { useToggle } from "./useToggle";

const PROTOCOL_STORE = "PROTOCOL_STORE";

const Events = {
  PROTOCOL_UPDATED: "PROTOCOL_UPDATED",
};

const _dispatchEvent = <T>(event: string, detail?: T) => {
  if (detail) {
    const eventToDispatch = new CustomEvent<typeof detail>(event, { detail });
    return window.dispatchEvent(eventToDispatch);
  } else {
    const eventToDispatch = new Event(event);
    return window.dispatchEvent(eventToDispatch);
  }
};

export const addProtocol = async (value: ProtocolEntry, flush?: boolean) => {
  try {
    const store: ProtocolEntry[] = JSON.parse(sessionStorage.getItem(PROTOCOL_STORE) || createProtocolStore());
    if (store && !flush) {
      sessionStorage.setItem(
        PROTOCOL_STORE,
        JSON.stringify([{ ...value, date: new Date(), id: uuid.v4() }, ...store].slice(0, 50))
      );
      _dispatchEvent(Events.PROTOCOL_UPDATED);
      return sessionStorage.getItem(PROTOCOL_STORE);
    } else if (flush) {
      const newValue = JSON.stringify([{ ...value, date: new Date(), id: uuid.v4() }, ...store].slice(0, 10));
      sessionStorage.setItem(PROTOCOL_STORE, newValue);
      _dispatchEvent(Events.PROTOCOL_UPDATED);
      return sessionStorage.getItem(PROTOCOL_STORE);
    }
    return store;
  } catch (error) {
    addProtocol(value, true);
    console.error(error);
  }
};

const clear = () => {
  sessionStorage.removeItem(PROTOCOL_STORE);
  return createProtocolStore();
};

const createProtocolStore = () => {
  let store = sessionStorage.getItem(PROTOCOL_STORE);
  if (store) return store;
  else {
    sessionStorage.setItem(PROTOCOL_STORE, JSON.stringify([]));
    _dispatchEvent(Events.PROTOCOL_UPDATED);
    return sessionStorage.getItem(PROTOCOL_STORE) || "";
  }
};

const intialize = () => {
  createProtocolStore();
};

export const useProtocol = () => {
  const [protocol, setProtocol] = useState<ProtocolEntry[]>([]);
  const [isPaused, togglePause, pause, resume] = useToggle(false);

  const _handleUpdate = useCallback(
    (incomingStore?: ProtocolEntry[]) => {
      if (isPaused) return null;
      const store = sessionStorage.getItem(PROTOCOL_STORE);
      if (store) {
        setProtocol(JSON.parse(store));
      } else if (incomingStore) {
        setProtocol(incomingStore);
      }
    },
    [isPaused]
  );

  const add = useCallback(
    async (entry: ProtocolEntry) => {
      try {
        const protocol = await addProtocol(entry);
        if (protocol) {
          _handleUpdate(JSON.parse(protocol as string));
        }
      } catch (error) {
        throw error;
      }
    },
    [_handleUpdate]
  );

  useEventListener(Events.PROTOCOL_UPDATED, _handleUpdate);

  useEffect(() => {
    _handleUpdate();
  }, [_handleUpdate]);

  return { protocol, add, clear, isPaused, resume, pause, togglePause };
};

(() => intialize())();
