import { useEffect, useState } from "react";
import { ExportMainViewTypes } from "./ExportMainViewTypes";

export const useExportMainViewModel = (props: ExportMainViewTypes.Props) => {
  const [selectedDate, setSelectedDate] = useState(props.date ?? new Date());

  useEffect(() => {
    if (props.date) {
      setSelectedDate(props.date);
    }
  }, [props.date]);

  return { selectedDate };
};
